jQuery(document).ready(function() {

  if ( $('#user_conferencerooms-table').length ) {

    var entry_id;
    var table1 = activate_tables('#user_conferencerooms-table', false, [-1]);

    table1.on( 'draw', function () {
      $('#user_conferencerooms-table .delete_entry').on('click', function() {
        delete_entry($(this));
      });
    });

    $('#user_conferencerooms-table .delete_entry').on('click', function() {
      delete_entry($(this));
    });

    // Löschen eines Eintrags
    var entry_id;
    function delete_entry(entry) {
      entry.tooltip('hide');
      entry_id = entry.data('entry-id');
      $('#name').html(entry.data('name'));
      $('#room').html(entry.data('room'));
      $('#conferenceroom_destroy').modal();
    }

    $('#delete-button').on('click', function() {
      $('#delete-form').attr('action', '/user/conferencerooms/' + entry_id);
      $('#delete-form').submit();
    });

    activate_tables('#user_conference_rec-table', false, [-1]);
  }

  if ( $('#user_conference_edit-title').length ) {
    var idx = 0;
    var mods = [];
    var moderators_current;
    var m = $('#conferenceroom_moderators').val();
    if ( m !== undefined ) {
      if ( m !== "" )
        mods = m.split(';')
    }
    mods.push($('#jsdata').data('exten'));
    mods.sort();
    $('#list_moderators').text(mods.join(', '));

    $('#conferenceroom_is_moderated').on('change', function() {
      if ( $(this).prop('checked') ) 
        $('#moderated_opts').show();
      else
        $('#moderated_opts').hide();
    });
    $('#conferenceroom_is_moderated').trigger('change');

    $('#edit_moderators').on('click', function() {
      $('#callerid').val("");
      $('#moderators_form .moderator').remove();
      var m = $('#conferenceroom_moderators').val();
      if ( m === "" )
        moderators_current = [];
      else
        moderators_current = m.split(';').sort();
      moderators_current.forEach(function(number, index) {
        var sample = $('div#mod_sample').clone().addClass('moderator');
        sample.removeAttr('id').show();
        sample.find('input[type=checkbox]').attr('id', 'number_' + idx).prop('checked', true).val(number);
        sample.find('label').attr('for', 'number_' + idx++).text(number);
        $('#moderators_form div.modal-body').append(sample);
      });
      $('#moderators_form').modal();
    });

    $('#moderators_form').on('shown.bs.modal', function() {
      $(this).find('#callerid').focus();
    });

    $('#moderators_form #add_mod').on('click', function() {
      console.log("Click");
      $('#add_mod').tooltip('hide');
      if ( $('#callerid').val() ) {
        var number = $('#callerid').val();
        console.log("Wert " + number);
        var sample = $('div#mod_sample').clone().addClass('moderator');
        sample.removeAttr('id').show();
        sample.find('input[type=checkbox]').attr('id', 'number_' + idx).prop('checked', true).val(number);
        sample.find('label').attr('for', 'number_' + idx++).text(number);
        $('#moderators_form div.modal-body').append(sample);
        $('#callerid').val("");
      }
      $('#moderators_form').find('#callerid').focus();
    });

    $('#moderators_form #save-button').on('click', function() {
      var mods = [];
      $('#moderators_form input[type=checkbox]:checked').each(function(index) {
        console.log("Wert " + $(this).val());
        mods.push($(this).val());
      });
      $('#conferenceroom_moderators').val(mods.join(';'));
      mods.push($('#jsdata').data('exten'));
      mods.sort();
      $('#list_moderators').text(mods.join(', '));
      $('#moderators_form').modal('hide');
    });

    $('#moderators_form #callerid').autocomplete( {
      source: function (request, response) {
        $.get('/misc/ajax/user_lookupjs?search=' + request.term, function(data) {
          var result = []
          for ( var i=0; i<data.length; i++ ) {
            var s = data[i].sur_name + ', ' + data[i].gv_name + ' [' + data[i].exten + ']';
            result.push({value: data[i].exten, label: s, location_id: data[i].location_id});
          }
          response(result);
        })
      },
      select: function (event, ui) {
        if ( ui.item.location_id )
          $('input[name=exten_loc_to_test]').val(ui.item.value + "_" + ui.item.location_id);
      },
    });
  }

  if ( $('#user_conference_moderate-table').length ) {
    console.log("Mod-Table")
    var entry_id;
    var table = activate_tables($('#user_conference_moderate-table', false, [-1]));

    table.on( 'draw', function () {
      set_event_handler();
    });
    set_event_handler();
    
    function set_event_handler() {
      $('input[type=checkbox][id^=mute_]').click(function() {
        var id = $(this).data('id');
        modify_member(id, 'mute', $(this).attr('data-member'), $(this).prop('checked'));
      });
      $('a[data-member]').click(function() {
        var id = $(this).data('id');
        $(this).tooltip('hide');
        modify_member(id, 'remove', $(this).attr('data-member'), undefined);
      });
    }

    function modify_member(id, action, channel, state) {
      var vals = {};
      vals['id'] = id;
      vals['op'] = action;
      vals['channel'] = channel;
      if ( state !== undefined ) {
        vals['state'] = state;
      }
      start_spinner_popup();
      console.log({vals});
      $.post("/user/conferencerooms/ajax_update", vals, function(data, status) {
        stop_spinner_popup();
        console.log({data});
        if ( action == "remove" ) {
          window.location = "/user/conferencerooms/" + id + "/moderate"
        }
      });
    }

  }

});

import { activate_tables, start_spinner_popup, stop_spinner_popup, url_get_id } from '../iptam.js';

jQuery(document).ready(function() {

  // Index-Seite
  if ( $('#lcr_title').length ) {
    var table = activate_tables('#lcr-table', false, [-1]);
    
    table.on( 'draw', function () {
      $('.alert').remove();
      $('.delete_entry').on('click', function() {
        delete_entry($(this));
      });
    });
    
    $('.delete_entry').on('click', function() {
      delete_entry($(this));
    });
    
    // Löschen einer LCR-Regel aus der Tabelle
    var entry_id;
    function delete_entry(entry) {
      entry.tooltip('hide');
      entry_id = entry.data('entry-id');
      start_spinner_popup();
      $.get('/admin/lcrs/' + entry_id + '/get_data4delete', function(data, status) {
        stop_spinner_popup();
        $('#lcr_destroy #name').html(data['name']);
        $('#lcr_destroy #rules').html(data['rules']);
        if ( data["not_used"] ) {
          $('#lcr_destroy #not_used').show();
        } else {
          $('#lcr_destroy #used').show();
        }
        $('#lcr_destroy').modal();
      });
    };
    
    $('#lcr_destroy #delete-button').on('click', function() {
      $('#lcr_destroy #delete-form').attr('action', '/admin/lcrs/' + entry_id);
      $('#lcr_destroy #delete-form').submit();
    });
  }

  // Edit-View
  if ( $('#lcr_edit_title').length ) {
    /* Beim Laden Pattern in der Spalte "Verfügbar" und "Eingesetzt" draggable */
    $('.lcr-av-rules').draggable({
      helper: "clone",
      zIndex: 200,
      opacity: 0.7,
    });
    $('.lcr-del-rule').draggable({
      helper: "clone",
      zIndex: 200,
      opacity: 0.7,
    });

    // Bearbeiten oder Löschen eines Musters
    var entry_id;
    var id;
    $('.lcr-drop-button').droppable({
      accept: '.lcr-av-rules, .lcr-del-rule',
      classes: { "ui-droppable-hover": "highlight-target" },
      drop: function(event, ui) {
        var used_rules = $(ui.draggable).data('used-rules-id')
        if ( $(this).attr('id') == "lcr-edit" ) {
          $('input[type=hidden][id=pattern-action]').val('edit-pattern');
          $('input[type=hidden][id=rules-id]').val(used_rules);
          start_spinner_popup();
          $('form[action^="/admin/lcrs"]').submit();
        } else if ( $(this).attr('id') == "lcr-delete" ) {
          if ( $(ui.draggable).hasClass('lcr-del-rule') ) {
            $(ui.draggable).remove();
            var liste = [];
            $('.lcr-del-rule').each(function(idx) {
              if ( $(this).data('used-rules-id') != used_rules )
                liste.push($(this).data('used-rules-id'));
            });
            $('#used-rules').val(liste.join(';'));
          } else {
            start_spinner_popup();
            id = url_get_id('form[action^="/admin/lcrs/');
            var vals = {};
            vals['used_rules'] = used_rules;
            entry_id = used_rules;
            $.get('/admin/lcrs/' + id + '/get_data4usedrules', vals, function(data, status) {
              stop_spinner_popup();
              $('#lcrrule_destroy #name').html(data['name']);
              if ( data['rules'] ) {
                $('#lcrrule_destroy #rules').html(data['rules']);
                $('#lcrrule_destroy #not_used').hide();
                $('#lcrrule_destroy #used').show();
                $('#delete-pattern-button').hide();
              } else {
                $('#lcrrule_destroy #used').hide();
                $('#lcrrule_destroy #not_used').show();
                $('#delete-pattern-button').show();
              }
              $('#lcrrule_destroy').modal();
            });
          }
        }
      },
    });

    // Entfernen eines Musters
    $('#delete-pattern-button').on('click', function() {
      $('#delete-pattern-form').attr('action', '/admin/lcrs/' + id + '/delete_rule');
      $('#lcrrule_destroy #entry_id').val(entry_id);
      $('#delete-pattern-form').submit();
    });

    $('.lcr-used-rules').droppable({
      accept: function(elem) {
        if ( !elem.hasClass('lcr-av-rules') )
          return false;
        var neu = elem.data('used-rules-id').toString();
        var current = $('#used-rules').val().split(';');
        if ( current.indexOf(neu) >= 0 )
          return false;
        return true;
      },
      classes: { "ui-droppable-hover": "highlight-target" },
      drop: function(event, ui) {
        var elem = $(ui.draggable).clone();
        elem.draggable({
          revert: 'invalid',
        });
        elem.removeClass("lcr-av-rules");
        elem.addClass("lcr-del-rule");
        $(this).append(elem);
        var liste = [];
        $(this).find('.lcr-del-rule').each(function(idx) {
          liste.push($(this).data('used-rules-id'));
        })
        $('#used-rules').val(liste.join(';'));
      },
    });

  }

});

var dir_col_index;
window.dir_col_index = dir_col_index;

export function block_enable(idx) {
  var enable = '#h' + idx + '-enable';
  var block = '#show-h' + idx;
  $(enable).on('click', function() {
    var classes = $(enable).attr('class');
    if ( classes.includes('up') ) {
      $(enable).attr('class', classes.replace('up', 'down'));
      $(block).hide();
    } else {
      $(enable).attr('class', classes.replace('down', 'up'));
      $(block).show();
    }
  });
}

export function url_get_id(url, position) {
  var parts = $(url).attr('action').split('/');
  if ( position === undefined )
    position = -1;
  return parts.slice(position)[0]
}

export function start_spinner_popup() {
  $('#spinner-fade-bg').css('display', 'block');
  $('#spinner-modal-fg').css('display', 'block');
};
window.start_spinner_popup = start_spinner_popup;

export function stop_spinner_popup() {
  $('#spinner-fade-bg').css('display', 'none');
  $('#spinner-modal-fg').css('display', 'none');
};
window.stop_spinner_popup = stop_spinner_popup;

export function activate_filestyles() {
  $('[type="file"]').filestyle({text: '<i class="fas fa-upload fa-lg"></i> Datei wählen', btnClass: 'btn btn-block btn-default iptam-filestyle-button'});
};
window.activate_filestyles = activate_filestyles;

export function show_flash(type, title, msg) {
  var type_text;
  if ( type == 'error' ) type_text = 'danger';
  else if ( type == 'notice' ) type_text = 'warning';
  else if ( type == 'info' ) type_text = 'info';
  var flash_text = "<h2>" + title + "</h2>" + msg;
  var flash = "<div class=\"alert alert-" + type_text + "\" role=\"alert\">" + flash_text + "</div>";
  if ( $('.iptam-main .alert-' + type_text).length > 0 ) {
    $('.iptam-main .alert-' + type_text).html(flash_text);
  } else {
    $('.iptam-main').prepend(flash);
  }
};
window.show_flash = show_flash;

export function clear_flash(type) {
  var type_text;
  if ( type == 'error' ) type_text = 'danger';
  else if ( type == 'notice' ) type_text = 'warning';
  else if ( type == 'info' ) type_text = 'info';
  $('div.alert-' + type_text).remove();
};
window.clear_flash = clear_flash;

export function clear_all_flash() {
  $('div.alert').remove();
};

export function show_result_info(data) {
  if ( data.error ) {
    show_flash('notice', data.error.title, data.error.msg);
  } else {
    clear_flash('notice');
  }
  if ( data.info ) {
    show_flash('info', data.info.title, data.info.msg);
  } else {
    clear_flash('info');
  }
};

export function activate_tables(id, no_search, no_order, serverside, url, ignore_length) {
  var table;
  var dom;
  var lang = {
            "lengthMenu": "Zeige _MENU_ Einträge je Seite",
            "search": "Suche",
            "info": "Zeige _PAGE_ von _PAGES_ Seiten",
            "sInfoFiltered": "(gefiltert von _MAX_ Einträgen)",
            "sZeroRecords":  "Keine Einträge vorhanden.",
            "sInfoEmpty":       "0 bis 0 von 0 Einträgen",
            "sProcessing":      "Bitte warten...",
            "oPaginate": {
                "sFirst":       "Erste",
                "sPrevious":    "Zurück",
                "sNext":        "Nächste",
                "sLast":        "Letzte"
            },
  };
  if ( serverside === undefined || serverside == false ) {
    if ( $(id).length && !ignore_length ) {
      var len = $(id).find('tbody > tr').length;
      if ( len < 10 ) {
        dom = 't';
      }
    }
  }
  if ( dom === undefined ) {
    if ( no_search ) {

      dom = "<'row'<'col-sm-6'l>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-5'i><'col-sm-7'p>>";
    } else {
      dom = "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-5'i><'col-sm-7'p>>";
    }
  }

  var disable_order = [];
  var first_col_disabled = false;
  var init_order = [];
  var enable_ordering = true;
  if ( no_order ) {
    var columns = $(id).find('thead th').length;
    for (var i=0; i<no_order.length; i++ ) {
      var col = ( no_order[i] < 0 ? (columns + no_order[i]) : no_order[i]);
      if ( no_order[i] == 0 )
        first_col_disabled = true;
      disable_order.push({ "orderable": false, "targets": col });
    }
    disable_order.push({ className: "dt-center", targets: [ columns-1 ] })
    init_order = first_col_disabled ? [[1, 'asc']] : [[0, 'asc']];
  } else if ( no_order === false ) {
    enable_ordering = false;
  }

  if ( serverside ) {
    table = $(id).DataTable({
      language: lang,
      dom: dom,
      lengthMenu: [ [10, 25, 50, 100, -1], [10, 25, 50, 100, "Alle"] ],
      stateSave: true,
      stateDuration: -1,
      columnDefs: disable_order,
      processing: true,
      serverSide: true,
      ajax: {
        url: url,
        },
    });
  } else {
    table = $(id).DataTable({
      ordering: enable_ordering,
      language: lang,
      dom: dom,
      lengthMenu: [ [10, 25, 50, 100, -1], [10, 25, 50, 100, "Alle"] ],
      stateSave: true,
      stateDuration: -1,
      columnDefs: disable_order,
      order: init_order,
    });
  }

  return table;
}
window.activate_tables = activate_tables;

var entityMap = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
  '/': '&#x2F;',
  '`': '&#x60;',
  '=': '&#x3D;'
};
 
export function escapeHtml(string) {
  return String(string).replace(/[&<>"'`=\/]/g, function (s) {
    return entityMap[s];
  });
}

$(document).ready(function() {

  /* Alle Tooltips aktivieren */
  $('[data-toggle="tooltip"]').tooltip();

  /* Navigations-Menü */
  $('.collapse').on('show.bs.collapse', function () {
    var id = $(this).attr('id');
    if ( id.substr(0,4) == 'menu') {
      $('div[id^=menu]').each(function(index) {
        if ( $(this).attr('id') != id ) {
          $(this).collapse("hide");
        }
      });
    }
  });

  /* Alle File-Types aktivieren */
  activate_filestyles();

  /* Spinner starten bei Submit */
  $("form").on('submit', function() {
    var no_spinner = $(this).data('sendfile-no-spinner');
    if ( typeof no_spinner === 'undefined' || no_spinner == 0 ) {
      start_spinner_popup();
    }
  });

  $("a.do-spinner").on('click', function() {
    start_spinner_popup();
  });

});

jQuery(document).ready(function() {

  if ( $('#cert_title').length ) {
    var idx = 1;
    $('#add_altname').on('click', function() {
      var line = $('#template_line').clone();
      var name = 'new_alt_name_' + idx
      line.attr('id', 'new_alt_line_' + idx);
      line.addClass('new_alt_line');
      line.find('input').first().attr('id', name).attr('name', 'alt_name[]');
      line.find('.delete_entry').first().attr('id', 'del_new_' + idx);
      line.show();
      $('#template_line').before(line);
      $('#del_new_' + idx).on('click', function() {
        var id = $(this).attr('id').split('_').pop();
        $('#new_alt_line_' + id).remove();
      });
      $('#' + name).focus();
      idx++;
    });
    $('.delete_entry').on('click', function() {
      $(this).parent().parent().find('input').val("")
      $(this).parent().parent().hide();
    });
  }

});

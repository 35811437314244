import { activate_tables, start_spinner_popup, stop_spinner_popup } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#pickupgroups-table').length ) {
    var table = activate_tables('#pickupgroups-table', false, [-1]);

    table.on( 'draw', function () {
      $('.alert').remove();
      $('.delete_entry').on('click', function() {
        delete_entry($(this));
      });
    });
    
    $('.delete_entry').on('click', function() {
      delete_entry($(this));
    });
    
    // Löschen einer Gruppe
    var entry_id;
    function delete_entry(entry) {
      entry.tooltip('hide');
      entry_id = entry.data('entry-id');
      start_spinner_popup();
      $.get('/admin/pickupgroups/' + entry_id + '/get_data4delete', function(data,  status) {
        stop_spinner_popup();
        if ( data['error'] ) {
          $('.iptam-main').prepend("<div class='alert alert-danger' role='alert'>" + data['error'] + "</div>");
        } else {
          $('span[id=name]').html(data['name']);
          if ( data['user_count'] > 0 ) {
            $('#not_empty_group').show();
            $('#empty_group').hide();
            var list = "";
            for (var i=0; i < data['user_count']; i++ ) {
              list += data['user_list'][i][0] + " [" + data['user_list'][i][1] + "]<br />";
            }
            $('div[id=user_list]').html(list);
          } else {
            $('#empty_group').show();
            $('#not_empty_group').hide();
          }
          $('#pickupgroup_destroy').modal();
        }
      });
    }

    /* Löschen tatsächlich ausführen */
    $('#delete-button').on('click', function() {
      $('#delete-form').attr('action', '/admin/pickupgroups//' + entry_id);
      $('#delete-form').submit();
    });
  }

});

import { activate_tables, start_spinner_popup, stop_spinner_popup, url_get_id, clear_flash } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#pagegroup-table').length ) {
    var table = activate_tables('#pagegroup-table', false, [-1]);

    table.on( 'draw', function () {
      $('.alert').remove();
      $('.delete_entry').on('click', function() {
        delete_entry($(this));
      });
    });
    
    $('.delete_entry').on('click', function() {
      delete_entry($(this));
    });
    
    // Löschen einer Gruppe
    var entry_id;
    function delete_entry(entry) {
      entry.tooltip('hide');
      entry_id = entry.data('entry-id');
      start_spinner_popup();
      $.get('/admin/pagegroups/' + entry_id + '/get_data4delete', function(data,  status) {
        stop_spinner_popup();
        if ( data['error'] ) {
          $('.iptam-main').prepend("<div class='alert alert-danger' role='alert'>" + data['error'] + "</div>");
        } else {
          $('#name').html(data['name']);
          if ( data['enable_mcast'] ) {
            $('#mcast_group').show();
            $('#multiple_members').hide();
            $('#no_members').hide();
            $('span#mcast_ip').text(data['mcast_ip']);
          } else {
            $('#mcast_group').hide();
            if ( data['user_count'] > 0 ) {
              $('#multiple_members').show();
              $('#no_members').hide();
              var list = "";
              for (var i=0; i < data['user_count']; i++ ) {
                list += data['user_list'][i][0] + " [" + data['user_list'][i][1] + "]<br />";
              }
              $('div[id=user_list]').html(list);
            } else {
              $('#no_members').show();
              $('#multiple_members').hide();
            }
          }
          $('#pagegroup_destroy').modal();
        }
      });
    };

    /* Löschen tatsächlich ausführen */
    $('#delete-button').on('click', function() {
      $('#delete-form').attr('action', '/admin/pagegroups//' + entry_id);
      $('#delete-form').submit();
    });

  }

  if ( $('#pagegroup_entry_title').length ) {
    
    var type_of_action;

    /* Autofocus beim Erscheinen der Dialog-Box */
    $('#select_phone').on('shown.bs.modal', function() {
      if ( $('#phone_own').length == 0 ) {
        $(this).find('#number').focus();
      }
    });

    $('#select_phone').keypress(function(e) {
      if ( e.which == 13) {
        $(this).find('#save-button').click();
        return false;
      }
    });

    $('#pagegroup_enable_dialin').on('change', function() {
      if ( $(this).prop('checked') ) {
        $('.mode_dialin').show();
      } else {
        $('.mode_dialin').hide();
      }
    });
    $('#pagegroup_enable_dialin').trigger('change');

    $('#pagegroup_enable_mcast').on('change', function() {
      if ( $(this).prop('checked') ) {
        $('.mode_mcast_pg').show();
        $('.mode_unicast_pg').hide();
      } else {
        $('.mode_mcast_pg').hide();
        $('.mode_unicast_pg').show();
      }
    });
    $('#pagegroup_enable_mcast').trigger('change');

    $('input[type=radio][name="pagegroup[greeting]"]').on('change', function() {
      if ( $(this).val() == '-1' ) {
        $('#show_greet_file').show();
      } else {
        $('#show_greet_file').hide();
      }
      clear_flash('error');
      clear_flash('notice');
      clear_flash('info');
    });
    if ( $('input[type=radio][name="pagegroup[greeting]"]:checked').val() != -1 ) {
      $('#show_greet_file').hide();
    }

    /* Aufnehmen von Ansagen via Telefon */
    $('button[name=record]').on('click', function(e) {
      type_of_action = 'record';
      $('#select_phone').modal();
    });

    /* Abspielen von Ansagen via Telefon */
    $('button[name=play]').on('click', function(e) {
      type_of_action = 'play';
      $('#select_phone').modal();
    });

    /* Hochladen von Audio-Dateien */
    var entry_id;
    $('button[name=upload]').on('click', function(e) {
      $('[data-toggle="tooltip"]').tooltip('hide');
      entry_id = url_get_id('form[action^="/admin/pagegroups/');
      $('h5').html($(this).data('title'));
      $('#file_upload #file_type').html($(this).data('file-type'));
      $('#file_upload #upload-save-button').attr('data-url', '/admin/pagegroups/' + entry_id + '/ajax_update');
      $('#file_upload').modal();
    });

    $('#select_phone #number').click(function(e) {
      $('#phone_number').prop("checked", true);
    });

    $('input[type=radio][name=phone]').on('change', function() {
      if ( $(this).val() != 'number' )
        $('#number').val("");
    });

    /* Speichern Telefonauswahl und Aufnahme / Abspielen ausführen */
    $('#select_phone #save-button').on('click', function() {
      var phone = $('input[type=radio][name=prompt]:checked').val();
      var number = $('#number').val();
      var id = url_get_id('form[action^="/admin/pagegroups/');
      var vals = get_current_vals();
      vals['what'] = type_of_action;
      vals['phone'] = (phone == 'own' || !number) ? 'own' : number;
      $('#select_phone').modal('hide');
      $.post("/admin/pagegroups/" + id + "/ajax_update", vals, function(data, status) {
      });
    });

    $('#upload-button').on('click', function() {
      var form_data = new FormData(jQuery('#upload-form')[0]);
      form_data.append('what', 'upload');
      form_data.append('type', $('#file_upload #upload-save-button').attr('data-filetype'));
      var vals = get_current_vals();
      for (let key in vals) {
        form_data.append(key, vals[key]);
      }
      $.ajax({
        url: '/admin/pagegroups/' + entry_id + '/ajax_update',
        method: 'POST',
        data: form_data,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          $('#file_upload').modal('hide');
        }
      });
    });

    if ( $('#pagegroup_prompt_file').val().length > 0) {
      $('button[name=play]').show();
    } else {
      $('button[name=play]').hide();
    }

    function get_current_vals() {
      var result = {};
      result['prompt_file'] = $('#pagegroup_prompt_file').val();
      return result;
    }

  }

});

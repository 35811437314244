import { start_spinner_popup, stop_spinner_popup, escapeHtml } from './iptam.js';

var listform_source_list;
var listform_dest_list;

var listform_show_search = function() {

  listform_source_list = [];
  listform_dest_list = [];
  $('select[name="source_list[]"] option').each(function(idx, obj) {
    var value = obj.value;
    var text = obj.text;
    listform_source_list.push([value, text]);
  });

  $('select[name="dest_list[]"] option').each(function(idx, obj) {
    var value = obj.value;
    var text = obj.text;
    listform_dest_list.push([value, text]);
  });

  if ( listform_source_list.length > 20 )
    $('#source_search').show();
  else
    $('#source_search').hide();

  if ( listform_dest_list.length > 20 )
    $('#dest_search').show();
  else
    $('#dest_search').hide();
};
window.listform_show_search = listform_show_search;

jQuery(document).ready(function() {

  listform_show_search();

  $('#source_search').on('keyup', function() {
    var list = []
    var lookup = $(this).val();
    $.each(listform_source_list, function(idx, entry) {
      if ( entry[1].toLowerCase().indexOf(lookup.toLowerCase()) >= 0 )
        list.push(entry);
    });
    var opts = '';
    $.each(list, function(idx, entry) {
      opts += "<option value='" + entry[0] + "'>" + escapeHtml(entry[1]) + "</option>"
    });
    $('select[name="source_list[]"]').html(opts);
    var size;
    if ( list.length == 0) size = 1;
    else if ( list.length > 4 ) size = 4;
    else size = list.length;
    $('select[name="source_list[]"]').attr('size', size);
  });

  $('#dest_search').on('keyup', function() {
    var list = []
    var lookup = $(this).val();
    $.each(listform_dest_list, function(idx, entry) {
      if ( entry[1].toLowerCase().indexOf(lookup.toLowerCase()) >= 0 )
        list.push(entry);
    });
    var opts = '';
    $.each(list, function(idx, entry) {
      opts += "<option value='" + entry[0] + "'>" + escapeHtml(entry[1]) + "</option>"
    });
    $('select[name="dest_list[]"]').html(opts);
    var size;
    if ( list.length == 0) size = 1;
    else if ( list.length > 4 ) size = 4;
    else size = list.length;
    $('select[name="dest_list[]"]').attr('size', size);
  });

  function do_post(what, list, add_value) {
    var url_orig = $('form[action!=""]').attr('action');
    var parts = url_orig.split('/');
    var id = parts[parts.length-1];
    if ( isNaN(id) ) {
      parts.length = parts.length-1;
    }
    parts.push('update_list')
    var url = parts.join('/');
    start_spinner_popup();
    $.post(url, { what: what, id: id, data: list, add_value: add_value}, function(data, status) {
      stop_spinner_popup();
      $('#source_search').val("");
      $('#dest_search').val("");
    });
  }

  $('button[name=add_member]').on('click', function() {
    var list = [];
    $('select[name="source_list[]"] :selected').each(function(idx, obj) {
      list.push(obj.value);
    });
    if ( list.length == 0 ) {
      var add_obj = $('#source_search ~ input');
      if ( add_obj.length == 1 ) {
        var value = add_obj.val();
        add_obj.val("");
      }
    }
    do_post('add', list.join(','), value);
  });

  $('button[name=del_member]').on('click', function() {
    var list = [];
    $('select[name="dest_list[]"] :selected').each(function(idx, obj) {
      list.push(obj.value);
    });
    do_post('del', list.join(','));
  });

});

jQuery(document).ready(function() {

  if ( $('#timeframe_title').length ) {
    function delete_line(id) {
      $('.tooltip').tooltip('hide');
      $('a[id=' + id +']').parent().parent().remove();
    };

    $('a[id^=destroy]').on('click', function(e) {
      delete_line(this.id);
    });

    $('#add_timeofday').on('click', function(e) {
      var lastline = $('#timeofday-table tr:last').clone();
      console.log({lastline});
      lastline.find('*[id]').each(function(index) {
        console.log({index});
        var matches = $(this).attr('id').match(/^([a-z]+)_([0-9]+)_?(.*)?$/);
        console.log({matches});
        if ( matches !== null && matches.length == 4 ) {
          var newid = parseInt(matches[2]) + 1;
          $(this).attr('id', matches[1] + newid + matches[3]);
          if ( matches[1] == 'day' ) {
            $(this).attr('name', matches[1] + newid + '[]');
            check_id = matches[1] + '_' + newid + '_' + matches[3];
            $(this).attr('id', check_id);
            $(this).prop('checked', false);
            var label = $(this).next();
            label.attr('for', check_id);
          } else if ( matches[1] == 'start' || matches[1] == 'stop' ) {
            $(this).attr('name', matches[1] + newid);
            $(this).attr('id', matches[1] + '_' +newid);
            $(this).val('');
          } else if ( matches[1] == 'destroy' ) {
            $(this).attr('id', matches[1] + '_' +newid);
            $(this).on('click', function(e) {
              delete_line(this.id);
            });
          }
          $('#tod_max_index').val(newid);
        }
      });
      lastline.find('.timepicker').clockpicker({
        placement: 'top',
        align: 'left',
        autoclose: true,
        'default': 'now'
      });
      $('#timeofday-table').append(lastline);
    });

    /* Initialisieren max-Id */
    var count = -2;
    $('#timeofday-table tr').each(function(idx) {
      count++;
    });
    $('#tod_max_index').val(count);

    $('.timepicker').clockpicker({
      placement: 'top',
      align: 'left',
      autoclose: true,
      'default': 'now'
    });
  }
});

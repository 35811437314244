import { activate_tables, start_spinner_popup, stop_spinner_popup } from './iptam.js';

$(document).ready(function() {

  if ( $('#show_user_wo_default').length ) {

    $('#show_missing_outbound').on('click', function() {
      start_spinner_popup();
      $.get("/admin/siptrunks/get_no_outbound_sipid", function(data, status) {
        stop_spinner_popup();
        for ( var i=0; i < data.no_outbound.length; i++ ) {
          var line = "<tr><td>" + data.no_outbound[i]['exten'] + "</td><td>" + data.no_outbound[i]['name'] + "</td></tr>";
          $('#show_user_wo_default tbody').append(line);
        }
        var table_no_outbound_sipid = activate_tables('#no_outbound_sipid', false);
        $('#show_user_wo_default').on('hidden.bs.modal', function (e) {
          console.log("Destroy");
          table_no_outbound_sipid.destroy();
          $('#show_user_wo_default tbody tr').remove();
        });
        $('#show_user_wo_default').modal();
      });
    });

    $('#show_user_wo_default #save-button').on('click', function() {
      var vals = {};
      vals['default_sipid'] = $('#def_account option:selected').val();;
      $('#show_user_wo_default').modal('hide');
      start_spinner_popup();
      $.post("/admin/siptrunks/set_outbound_sipid", vals, function(data, status) {
        $('div.alert-info').remove();
        stop_spinner_popup();
      });
    });

  }

});

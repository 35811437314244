jQuery(document).ready(function() {

  if ( $('#phonepreset_title').length ) {
    $('#call_divert-save-button').on('click', function(e) {
      var result = 0;
      $('input[type=checkbox][name="cf_text_status[]"]').each(function() {
        if ( $(this).prop('checked') ) {
          result += parseInt($(this).val());
        }
      });
      $('#provisioning_cf_text_status').val(result);
      var liste = []
      if ( (result & 128) != 0 )
        liste.push("Zeitprofile");
      if ( (result & 1) != 0 )
        liste.push("Permanent");
      if ( (result & 2) != 0 )
        liste.push("Nichterreichbarkeit");
      if ( (result & 4) != 0 )
        liste.push("Besetzt");
      if ( (result & 8) != 0 )
        liste.push("Bitte-nicht-stören");
      if ( (result & 16) != 0 )
        liste.push("Zeitgesteuert");
      if ( (result & 32) != 0 )
        liste.push("Interne Anrufer");
      if ( (result & 64) != 0 )
        liste.push("Externe Anrufer");
      var v = liste.join(', ');

      $('#display-call-divert').html(v);
      $('#display_call_divert-dialog').modal('hide');
    });

    $('#provisioning_enable_pictures').on('click', function() {
      if ( $(this).prop('checked') ) 
        $('#show_use_as_avatar').show();
      else
        $('#show_use_as_avatar').hide();
    });
    if ( $('#provisioning_enable_pictures').prop('checked') ) 
      $('#show_use_as_avatar').show();
    else
      $('#show_use_as_avatar').hide();
      
  }

});

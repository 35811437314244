import { activate_tables, block_enable, url_get_id } from '../iptam.js';
import { check_heading, csv_table_column_selection } from '../shared/directory.js';

jQuery(document).ready(function() {

  if ( $('#user_directory_title').length ) {
    $('#h1-enable').css('cursor', 'pointer');
    $('#h2-enable').css('cursor', 'pointer');
    $('#h3-enable').css('cursor', 'pointer');
    block_enable(1);
    block_enable(2);
    block_enable(3);

    $('#user_pb_display_mode').on('change', function() {
      if ( $(this).val() < 0 )
        $('#system_display_mode').show();
      else
        $('#system_display_mode').hide();
    });
    $('#user_pb_display_mode').trigger('change');

    var entry_id;
    var table = activate_tables('#dir_lookup-table', false, [-1], true, '/user/directory/get_table');
    table.on('draw', function () {
      /* Links in der Suchansicht */
      $('a[href="#"][id^=dial_]').on('click', function() {
        $('[data-toggle="tooltip"]').tooltip('hide');
        window.location = "/user/directory/" + $(this).attr('id').substr(5) + "/dial";
      });
      $('a[href="#"][id^=dial_]').tooltip();
      $('a[href="#"][id^=edit_]').on('click', function() {
        $('[data-toggle="tooltip"]').tooltip('hide');
        window.location = "/user/directory/" + $(this).attr('id').substr(5) + "/edit";
      });
      $('a[href="#"][id^=edit_]').tooltip();
      $('a[href="#"][id^=delete_]').on('click', function() {
        $('[data-toggle="tooltip"]').tooltip('hide');
        entry_id = $(this).attr('id').substr(7);
        var list = [];
        $(this).parent().parent().find("td").each(function() {
          list.push($(this).text());
        });
        var t;
        if ( list[2].length > 0 )
          t = list[1] + " " + list[0] + " (" + list[2] + ")";
        else
          t = list[1] + " " + list[0];
        $('#contact_destroy #contact').text(t);
        $('#contact_destroy').modal();
      });
      $('a[href="#"][id^=delete_]').tooltip();
    });

    $('#contact_destroy #delete-button').on('click', function() {
      $('#contact_destroy').modal('hide');
      start_spinner_popup();
      $.ajax({
        url: '/user/directory/' + entry_id,
        method: 'DELETE',
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          console.log({data});
          stop_spinner_popup();
          window.location = "/user/directory";
        }
      });
    });
    
  }

  /* Auf der Seite "Telefonbucheintrag" (Neu oder Bearbeiten) */
  if ( $('#user_phonebook_entry_title').length ) {
    var entry_id;
    var idx = 1;
    $('.delete_numentry').tooltip();
    $('.pin_entry').tooltip();
    $('#add_number').tooltip();
    $('input[type=radio][name="contact[dir_type]"]').change(function() {
      if ( this.value == 'pub' )
         $('#show_call_protect').show();
      else
         $('#show_call_protect').hide();
    });
    if ( $('input[name="contact[dir_type]"]:checked').val() != 'priv' ) 
      $('#show_call_protect').hide();
    $('#add_number').on('click', function() {
      $('#add_number').tooltip('hide');
      var line = $('#number_entry_line').clone();
      line.attr('id', "number_line_"+idx);
      line.find('input').each(function() {
        if ( $(this).attr('id') == 'number_new_0' ) {
          $(this).attr('id', "number_new_" + idx);
          $(this).attr('name', "number_new_" + idx);
        } else if ( $(this).attr('id') == 'type_long_new_0' ) {
          $(this).attr('id', "type_long_new_" + idx);
          $(this).attr('name', "type_long_new_" + idx);
        } else if ( $(this).attr('id') == 'type_short_new_0' ) {
          $(this).attr('id', "type_short_new_" + idx);
          $(this).attr('name', "type_short_new_" + idx);
        } else if ( $(this).attr('id') == 'cp_pin_new_0' ) {
          $(this).attr('id', "cp_pin_new_" + idx);
          $(this).attr('name', "cp_pin_new_" + idx);
          $(this).next('a').attr('data-entry-id', "new_" + idx);
        }
        $(this).val("");
      });
      line.find('select').each(function() {
        var s = $(this).find('option:selected');
        if ( $(this).attr('id') == 'type_sel_new_0' ) {
          $(this).attr('id', "type_sel_new_" + idx);
          $(this).attr('name', "type_sel_new_" + idx);
        }
      });
      $('#last_idx').val(idx);
      line.find('.pin_entry').tooltip();
      line.find('.pin_entry').on('click', function() {
        entry_id = $(this).data('entry-id');
        if ( $('input#number_' + entry_id).val().length ) {
          $('span#phone-number').text($('input#number_' + entry_id).val());
          $('#dir_set_callprotect_pin-form #unknown-number').hide();
          $('#dir_set_callprotect_pin-form #known-number').show();
        } else {
          $('#dir_set_callprotect_pin-form #unknown-number').show();
          $('#dir_set_callprotect_pin-form #known-number').hide();
        }
        $('input#pin').val($('input#cp_pin_' + entry_id).val());
        $('#dir_set_callprotect_pin-form').modal();
      });
      idx++;
      $('.entry_line').last().after(line);
    });
    $('.delete_numentry').tooltip();
    $('.delete_numentry').on('click', function() {
      $(this).tooltip('hide');
      if ( $(this).parent().parent().attr('id') != "number_entry_line" ) {
        $(this).parent().parent().remove();
      } else {
        $(this).parent().parent().find('input').val("");
      }
    });
    $('.pin_entry').tooltip();
    $('.pin_entry').on('click', function() {
      $(this).tooltip('hide');
      entry_id = $(this).data('entry-id');
      if ( entry_id > 0 ) {
        $('span#phone-number').text($('input#number_' + entry_id).val());
        $('input#pin').val($('input#cp_pin_' + entry_id).val());
        $('#dir_set_callprotect_pin-form #unknown-number').hide();
        $('#dir_set_callprotect_pin-form #known-number').show();
      } else {
        if ( $('input#number_new_0').val().length ) {
          $('span#phone-number').text($('input#number_new_0').val());
          $('#dir_set_callprotect_pin-form #unknown-number').hide();
          $('#dir_set_callprotect_pin-form #known-number').show();
        } else {
          $('#dir_set_callprotect_pin-form #unknown-number').show();
          $('#dir_set_callprotect_pin-form #known-number').hide();
        }
        $('input#pin').val($('input#cp_pin_new_0').val());
      }
      $('#dir_set_callprotect_pin-form').modal();
    });

    $('#dir_set_callprotect_pin-form #save-button').on('click', function() {
      var v = $('input#pin').val();
      if ( isNaN(entry_id) ) {
        $('input#cp_pin_' + entry_id).val(v);
      } else {
        if ( entry_id > 0 )
          $('input#cp_pin_' + entry_id).val(v);
        else
          $('input#cp_pin_new_0').val(v);
      }
      $('#dir_set_callprotect_pin-form').modal('hide');
    });

    $('#picture_file').on('change', function() {
      var formData;
      var entry_id = 0;
      if ( $('form.new_contact').length > 0 ) {
        formData = new FormData(jQuery('form.new_contact')[0]);
      } else if ( $('form.edit_contact').length > 0 ) {
        formData = new FormData(jQuery('form.edit_contact')[0]);
        entry_id = url_get_id('form.edit_contact', -1);
      } else {
        return;
      }
      start_spinner_popup();
      $.ajax({
        url: '/user/directory/' + entry_id + '/photo_upload',
        method: 'POST',
        data: formData,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          console.log({data});
          if ( Array.isArray(data) ) {
            var path = data[0];
            var hash = data[1];
            $('img.img-thumbnail').attr('src','/pictures/' + path + '.png');
            $('#contact_picture').val(hash + ';' + path);
          }
          stop_spinner_popup();
        }
      });
    });

    $('.delete_pic').on('click', function() {
      $(this).tooltip('hide');
      $('img.img-thumbnail').attr('src','');
      $('#contact_picture').val('');
    });
  }

  if ( $('#user_directory_import_title').length ) {

    $('input[type=radio][name=file_type]').change(function() {
      $('.data_valid').hide();
      $('.data_valid_csv').hide();
    });

    /* Import-File: Hochgeladene Datei (CSV, vCard) prüfen */
    $('button[id=checkfile]').on('click', function() {
      $('#check_result_error').hide();
      $('#error_msg').html("");
      $('.check_result_csv').hide();
      $('#check_result_value').html("");
      start_spinner_popup();
      var form_data = new FormData(jQuery('#dir_import_form')[0]);
      form_data.append('what', 'check_file');
      $.ajax({
        url: '/user/directory/ajax_update',
        method: 'POST',
        data: form_data,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          stop_spinner_popup();
          console.log({data});
          if ( data['error'] ) {
            $('#error_msg').html(data['error']);
            $('#check_result_error').show();
          } else if ( Array.isArray(data) ) {
            if ( $('input[type=radio][name="file_type"]:checked').val() == "csv") {
              var cols = "<tr>";
              var hdr = "<tr>";
              if ( $('#firstline_heading:checked').val() ) {
                for (var i = 0; i < data.length; i++) {
                  var entry = check_heading(data[i]);
                  if ( !$.isEmptyObject(entry) ) {
                    if ( entry.base_data ) {
                      cols += "<td data-index=" + i +  " data-base_data=\"" + entry.base_data +  "\">" + data[i] + "</td>";
                      hdr += "<th data-index=" + i +  ">" + data[i] + "</th>";
                    } else {
                      cols += "<td style=\"color: red\" data-index=" + i +  " data-phonenumber_select=\"" + entry.phonenumber_select +  "\" data-type_long=\"" + entry.phone_text + "\" data-type_short=\"" + entry.phonenumber_select + "\">" + data[i] + "</td>";
                      hdr += "<th data-index=" + i +  ">" + entry.phone_text + " (" + entry.phonenumber_select + ")</th>";
                    }
                  } else {
                    cols += "<td data-index=" + i +  ">" + data[i] + "</td>";
                    hdr += "<th data-index=" + i +  ">Kein Import</th>";
                  }
                }
              } else {
                for (var i = 0; i < data.length; i++) {
                  cols += "<td data-index=" + i +  ">" + data[i] + "</td>";
                  hdr += "<th data-index=" + i +  ">Kein Import</th>";
                }
              }
              cols = cols + "</tr>";
              csv_table_column_selection(cols, hdr);
            } else {
              var s = "";
              for (var i = 0; i < data.length; i++) {
                s += '<div class="form-group row newvc"><div class="col-sm-3" data-new-vc-index="' + i + '">' + data[i] + '</div><div class="col-sm-3"><input id="new_long_' + i + '" type="text" class="form-control"/></div><div class="col-sm-1"><input id="new_short_' + i + '" type="text" class="form-control"/></div></div>';
              }
              $('.check_result_vcard').append(s);
              $('.check_result_vcard').show();
            }
          } else if ( typeof(data) == "object") {
            if ( $('input[type=radio][name="file_type"]:checked').val() == "csv") {
              var prefs = data.prefs;
              var line = data.firstline;
              var cols = "<tr>";
              var hdr = "<tr>";
              for ( var i = 0; i <= prefs.csv_last_idx; i++ ) {
                var type = prefs['csv_' + i + '_type'];
                if ( type ) {
                  if ( type == "base_data" ) {
                    var text = $("#base_data > option[value=\"" + prefs['csv_' + i + '_val'] + "\"]").text();
                    var value = $("#base_data > option[value=\"" + prefs['csv_' + i + '_val'] + "\"]").val();
                    cols += "<td data-index=" + i +  " data-base_data=\"" +  value +  "\">" + line[i] + "</td>";
                    hdr += "<th data-index=" + i + ">" + text + "</th>";
                  } else if ( type == "phonenumber" || type == "new_phone_num" ) {
                    var long = prefs['csv_' + i + '_val_l'];
                    var short = prefs['csv_' + i + '_val_s'];
                    var format = prefs['csv_' + i + '_format'];
                    if ( format !== undefined )
                      cols += "<td data-index=" + i + " data-type_long=\"" + long + "\" data-type_short=\"" + short + "\" data-numberformat=\"" + format + "\" style=\"color: black\">" + line[i] + "</td>";
                    else
                      cols += "<td data-index=" + i + " data-type_long=\"" + long + "\" data-type_short=\"" + short + "\" style=\"color: red\">" + line[i] + "</td>";
                    hdr += "<th data-index=" + i + ">" + long + " (" + short + ")</th>";
                  }
                } else {
                  cols += "<td data-index=" + i +  ">" + line[i] + "</td>";
                  hdr += "<th data-index=" + i +  ">Kein Import</th>";
                }
              }
              cols = cols + "</tr>";
              hdr = hdr + "</tr>";
              csv_table_column_selection(cols, hdr);
            }
          }
          $('.data_valid').show();
          if ( $('input[type=radio][name="file_type"]:checked').val() == "csv")
            $('.data_valid_csv').show();
        }
      });
    });

    /* Import-File: Speichern der Import-Datei: Übernahme Datei und Formulardaten */
    $('#save_import_file').on('click', function() {
      $('#check_result_error').hide();
      $('#error_msg').html("");
      var form_data = new FormData(jQuery('#dir_import_form')[0]);
      form_data.append('what', 'import_file');
      var last_idx = 0;
      if ( $('input[type=radio][name="file_type"]:checked').val() == "csv") {
        $('td[data-index]').each(function( i ) {
          var idx = $(this).data('index');
          if ( idx > last_idx )
            last_idx = idx;
          if ( $(this).data('base_data') ) {
            form_data.append('csv_' + idx + '_type', 'base_data');
            form_data.append('csv_' + idx + '_val', $(this).data('base_data'));
          } else if ( $(this).data('phonenumber_select') ) {
            form_data.append('csv_' + idx + '_type', 'phonenumber');
            form_data.append('csv_' + idx + '_val_l', $(this).data('type_long'));
            form_data.append('csv_' + idx + '_val_s', $(this).data('type_short'));
            form_data.append('csv_' + idx + '_format', $(this).data('numberformat'));
          } else if ( $(this).data('type_long') ) {
            form_data.append('csv_' + idx + '_type', 'new_phone_num');
            form_data.append('csv_' + idx + '_val_l', $(this).data('type_long'));
            form_data.append('csv_' + idx + '_val_s', $(this).data('type_short'));
            form_data.append('csv_' + idx + '_format', $(this).data('numberformat'));
          }
        });
        form_data.append('csv_last_idx', last_idx); 
      } else {
        $('input[type=text][id^=new_]').each(function(i) {
          var parts = $(this).attr('id').split("_");
          var idx = parts[2];
          if ( idx > last_idx )
            last_idx = i;
          if ( $('#new_long_' + idx).val().length ) {
            form_data.append('vcnew_' + idx + '_' + parts[1], $(this).val());
            if ( parts[1] == 'long' )
              form_data.append('vcnew_' + idx + '_vcard', $('div[data-new-vc-index='+idx+']').text());
          }
        });
        form_data.append('vc_last_idx', last_idx); 
      }
      start_spinner_popup();
      $.ajax({
        url: '/user/directory/ajax_update',
        method: 'POST',
        data: form_data,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          stop_spinner_popup();
          console.log({data});
          if ( data['error'] ) {
            $('#error_msg').html(data['error']);
            $('#check_result_error').show();
          } else {
            $('#result_msg').html(data['msg']);
            $('#check_result').show();
            setTimeout(function() {
              window.location = "/user/directory";
              }, 
              2000);
          }
        }
      });
    });

    /* Löschen einer vCard Attribut Zuordnung ausführen */
    $('#vcard_attr_destroy #delete-button').on('click', function() {
      $('#vcard_attr_destroy').modal('hide');
      console.log("Löschen des Eintrags " + vc_card_del_idx);
      var vals = {};
      vals['what'] = 'delete_vcard_attr';
      vals['vcid'] = vc_card_del_idx;
      start_spinner_popup();
      console.log({vals});
      $.post("/user/directory/ajax_update", vals, function(data, status) {
        stop_spinner_popup();
        console.log({data});
        console.log({status});
        if ( !data.error )
          $('div[id=vc_row_' + vc_card_del_idx + ']').remove();
      });
    });

  }

  if ( $('#user_directory_export_title').length ) {

    var directory_edit_attr_tree;
    var new_attr_idx = 0;

    function delete_phonebook_attr(id) {
      var elem = $('#'+id).parent().parent();
      $('#'+id).parent().parent().remove();
    }

    /* Export-File: Dateityp (CSV/vCard) ändern */
    $('input[type=radio][name="file_type"]').on('change', function() {
      $('#error_msg').html("");
      if ( $('input[type=radio][name="file_type"]:checked').val() == "csv") {
        $('.file_is_csv').show();
        $('.file_is_vcard').hide();
      } else if ( $('input[type=radio][name="file_type"]:checked').val() == "vcard") {
        $('.file_is_csv').hide();
        $('.file_is_vcard').show();
      } else {
        $('.file_is_csv').hide();
        $('.file_is_vcard').hide();
      }
    });
    $('input[type=radio][name="file_type"]').change();

    /* Export CSV: Speichern der Einstellungen */
    $('#download_csv_export').on('click', function() {
      start_spinner_popup();
      var f = $('form#dir_export_form');
      var form_data = new FormData(f[0]);
      form_data.append('what', 'export_csv');
      $.ajax({
        url: '/user/directory/ajax_update',
        method: 'POST',
        data: form_data,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          console.log({data});
          stop_spinner_popup();
          if ( data.result == 'download' ) {
            window.location = '/user/directory/download?fn=' + data.filename;
          } else if ( Array.isArray(data) ) {
            if ( data.length ) {
              res = {info: {title: "", msg: data.join("<br />")}};
            } else {
              res = {}
            }
            show_result_info(res);
          } else {
            show_result_info(data);
          }
        }
      });
    });

    /* Export vCard: Speichern der Einstellungen */
    $('#download_vcard_export').on('click', function() {
      start_spinner_popup();
      var f = $('form#dir_export_form');
      var form_data = new FormData(f[0]);
      form_data.append('what', 'export_vcard');
      $('#attributes-form input[type=text]').each(function(){
        form_data.append($(this).attr('id'), $(this).val());
      });
      $.ajax({
        url: '/user/directory/ajax_update',
        method: 'POST',
        data: form_data,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          console.log({data});
          stop_spinner_popup();
          if ( data.result == 'download' ) {
            window.location = '/user/directory/download?fn=' + data.filename;
          } else if ( Array.isArray(data) ) {
            if ( data.length ) {
              res = {info: {title: "", msg: data.join("<br />")}};
            } else {
              res = {}
            }
            show_result_info(res);
          } else {
            show_result_info(data);
          }
        }
      });
    });

    if ( $('#carddav_edit_attributes').length ) {
      if ( directory_edit_attr_tree )
        directory_edit_attr_tree.remove();
      directory_edit_attr_tree = $('#edit-attributes-form').clone();
      $('button[name="sync-save-button"]').attr('id', 'save_import_carddav');
    }

    $('#carddav_edit_attributes').on('click', function() {
      var tree = directory_edit_attr_tree.clone();
      $('#edit-attributes-form').find('div').remove();
      $('#edit-attributes-form').append(tree);

      /* CardDAV, neue Attribute */
      $('#new_attribute').on('click', function() {
        $(this).tooltip('hide');
        var s = '<div class="form-group row"><div class="col-sm-4"><input id="new_vcard_' +  new_attr_idx + '" type="text" class="form-control"/></div><div class="col-sm-4"><input id="new_long_' +  new_attr_idx + '" type="text" class="form-control"/></div><div class="col-sm-2"><input id="new_short_' +  new_attr_idx + '" type="text" class="form-control"/></div><div class="col-sm-2 col-form-label"><a href="#" id="del-new-' +  new_attr_idx + '"><i class="fas fa-trash-alt fa-lg"></i></a></div></div>';
        $('#edit-attributes-form').append(s);
        $('#del-new-'+new_attr_idx).on('click', function() {
          delete_phonebook_attr($(this).attr('id'));
        })
        new_attr_idx++;
      });

      /* CardDAV, Attribute löschen */
      $('a[id^=delete_]').on('click', function() {
        $(this).tooltip('hide');
        delete_phonebook_attr($(this).attr('id'));
      });
      $('a[id^=del-new-]').on('click', function() {
        $(this).tooltip('hide');
        delete_phonebook_attr($(this).attr('id'));
      });
      $('#attributes-form').modal();
    });

    $('#attributes-form #save-button').on('click', function() {
      $('#attributes-form').modal('hide');
      if ( directory_edit_attr_tree )
        directory_edit_attr_tree.remove();
      directory_edit_attr_tree = $('#edit-attributes-form').clone();
    });
  }

});

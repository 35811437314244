var PhoneGigaset_de410 = (function(modul) {

  function init(pdata, funcs) {
    console.log("Telefon " + pdata.vendor + " " + pdata.model);
    funcs.set_initial_data(pdata.own_exten, {pkey: [1,7]});

    for (var i = 1; i <= 7; i++) {
      $('#pkey'+i).droppable({
        accept: '.key',
        hoverClass: 'highlight-target',
        drop: function(event, ui) {
          if ( ui.draggable.attr('id') == "f_line" ) {
            funcs.set_line($(this));
          } else if ( ui.draggable.attr('id') == "f_blf" ) {
            funcs.set_blf($(this));
          } else if ( ui.draggable.attr('id') == "f_direct" ) {
            funcs.set_direct($(this));
          } else if ( ui.draggable.attr('id') == "f_cfwdgrp" ) {
            funcs.set_cfwdgrp($(this));
          } else if ( ui.draggable.attr('id') == "f_agent" ) {
            funcs.set_agent($(this));
          } else if ( ui.draggable.attr('id') == "f_icom" ) {
            funcs.set_icom($(this));
          } else if ( ui.draggable.attr('id') == "f_dtmf" ) {
            funcs.set_dtmf($(this));
          } else if ( ui.draggable.attr('id') == "f_profileled" ) {
            funcs.set_profileled($(this));
          } else {
            funcs.set_keyfunc($(this), $(ui.draggable));
          }
        }
      });
    }

    $('#dirkey').on('click', function() {
      funcs.click_change_text('dirkey', 1, 'gigaset-dirkey-texts');
    });
  };


  function do_submit(funcs) {
    console.log("Submit");
    funcs.do_submit();
  };

  return {
    init: init,
    do_submit: do_submit
  };
})(PhoneGigaset_de410 || {});
window.PhoneGigaset_de410 = PhoneGigaset_de410;

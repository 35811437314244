import { activate_tables } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#netroute-table').length ) {
    var table = activate_tables('#netroute-table', false, [-1]);
    
    table.on( 'draw', function () {
      $('.alert').remove();
      $('.delete_entry').on('click', function() {
        delete_entry($(this));
      });
    });
    
    $('.delete_entry').on('click', function() {
      delete_entry($(this));
    });
    
    // Löschen einer Route
    var entry_id;
    function delete_entry(entry) {
      entry.tooltip('hide');
      entry_id = entry.data('entry-id');
      $('span[id=target]').html(entry.data('target'));
      $('span[id=nexthop]').html(entry.data('nexthop'));
      $('#networkroute_destroy').modal();
    }

    $('#networkroute_destroy #delete-button').on('click', function() {
      $('#networkroute_destroy #delete-form').attr('action', '/admin/networkroutes/' + entry_id);
      $('#networkroute_destroy #delete-form').submit();
    });

  }

});

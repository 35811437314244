import { activate_tables, start_spinner_popup, stop_spinner_popup } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#ringgroups-user-table').length ) {
    var table = activate_tables('#ringgroups-user-table', false, [-1]);
  }

  if ( $('#waitingqueues-user-table').length ) {
    /* Übersicht Warteschlange */
    var table = activate_tables('#waitingqueues-table', false, [-1]);
  }

});

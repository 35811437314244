import { activate_tables, start_spinner_popup, stop_spinner_popup } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#ringgroups-table').length ) {
    var table = activate_tables('#ringgroups-table', false, [-1]);

    table.on( 'draw', function () {
      $('.alert').remove();
      $('.delete_entry').on('click', function() {
        delete_entry($(this));
      });
    });
    
    $('.delete_entry').on('click', function() {
      delete_entry($(this));
    });
    
    // Löschen einer Klingelgruppe
    var entry_id;
    function delete_entry(entry) {
      entry.tooltip('hide');
      entry_id = entry.data('entry-id');
      start_spinner_popup();
      $.get('/admin/ringgroups/' + entry_id + '/get_data4delete', function(data,  status) {
        stop_spinner_popup();
        if ( data['error'] ) {
          $('.iptam-main').prepend("<div class='alert alert-danger' role='alert'>" + data['error'] + "</div>");
        } else {
          $('#name').html(data['name']);
          if ( data['user_count'] > 0 ) {
            $('#multiple_members').show();
            $('#no_members').hide();
            var list = "";
            for (var i=0; i < data['user_count']; i++ ) {
              list += data['user_list'][i][0] + " [" + data['user_list'][i][1] + "]<br />";
            }
            $('div[id=user_list]').html(list);
          } else {
            $('#no_members').show();
            $('#multiple_members').hide();
          }
          $('#ringgroup_destroy').modal();
        }
      });
    };

    /* Löschen tatsächlich ausführen */
    $('#delete-button').on('click', function() {
      $('#delete-form').attr('action', '/admin/ringgroups/' + entry_id);
      $('#delete-form').submit();
    });

  }

  if ( $('#ringgroup_edit_title').length ) {
    function check_autologout() {
      var dyn = $('#ringgroup_dynphonesupport').prop('checked');
      var login = $('#ringgroup_dynactivate').val();
      var logout = $('#ringgroup_dyndeactivate').val();
      if ( dyn || ( login.length > 0 && logout.length > 0 ) ) {
        $('.show_autologout').show();
      } else {
        $('.show_autologout').hide();
      }
    }

    $('#ringgroup_autologout').on('change', function() {
      if ( $('#ringgroup_autologout').prop('checked') ) {
        $('.logout_time').show();
      } else {
        $('.logout_time').hide();
      }
    });
    $('#ringgroup_autologout').change();

    $('.timepicker').clockpicker({autoclose: true, placement: 'top'});

    $('#ringgroup_dynactivate').on('change', check_autologout);
    $('#ringgroup_dyndeactivate').on('change', check_autologout);
    $('#ringgroup_dynphonesupport').on('change', check_autologout);
    check_autologout();

    $('#speed_select_members').on('click', function() {
      var quick_agents = $('#ringgroup_quick_agents').val();
      $('#speed_select_members-dialog #static_members').prop('checked', quick_agents & 2);
      $('#speed_select_members-dialog #dynamic_members').prop('checked', quick_agents & 1);
      permitted_users_show_search();
      $('#speed_select_members-dialog').modal();
    });

    $('#speed_select_members-dialog #save-button').on('click', function() {
      var value = 0;
      if ( $('#speed_select_members-dialog #static_members').prop('checked') )
        value |= 2;
      if ( $('#speed_select_members-dialog #dynamic_members').prop('checked') )
        value |= 1;
      $('#ringgroup_quick_agents').val(value);
      $('#speed_select_members-dialog').modal('hide');
    });
  }

});

jQuery(document).ready(function() {

  if ( $('#iaxtrunkacc_title').length ) {
    if ( $('#bo_clid').val() !== "" ) {
      $('#iaxtrunkacc_breakout_clid_other').prop("checked", true);
      $('#iaxtrunkacc_breakout_clid_operator').prop("checked", false);
      $('#iaxtrunkacc_breakout_clid_origin').prop("checked", false);
    }
    $('#bo_clid').click(function() {
      $('#iaxtrunkacc_breakout_clid_operator').prop("checked", false);
      $('#iaxtrunkacc_breakout_clid_origin').prop("checked", false);
      $('#iaxtrunkacc_breakout_clid_other').prop("checked", true);
    });
    $('#iaxtrunkacc_breakout_clid_operator').click(function() {
      $('#bo_clid').val("");
    });
    $('#iaxtrunkacc_breakout_clid_origin').click(function() {
      $('#bo_clid').val("");
    });
  }
});


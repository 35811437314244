import { start_spinner_popup } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#networktime_title').length ) {
    var networktim_set_event_handler = function() {
      $('button[name="add_client_allowed"]').off();
      $('button[name="add_client_allowed"]').on('click', function() {
        start_spinner_popup();
        var vals = {};
        vals['ntp_client_allowed'] = $('#ntp_client_allowed').val();
        vals['what'] = "add"
        $.post('/admin/networktime/ajax_update', vals, function(data, status) {
        });
      });
      
      $('button[name="del_client_allowed"]').off();
      $('button[name="del_client_allowed"]').on('click', function() {
        start_spinner_popup();
        var vals = {};
        vals['what'] = "delete"
        var to_delete = $('select[name="client_list[]"]').val();
        var client_list = $('#network_ntp_client_allowed').val().split(';');
        $.each(to_delete, function(idx, entry) {
          var i = client_list.indexOf(entry);
          if ( i >= 0 )
            client_list.splice(i, 1);
        });
        vals['client_list'] = client_list.join(';');
        $.post('/admin/networktime/ajax_update', vals, function(data, status) {
        });
      });

    }
    window.networktim_set_event_handler = networktim_set_event_handler;
    networktim_set_event_handler();
  }

});

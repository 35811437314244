import { activate_tables, start_spinner_popup, stop_spinner_popup } from '../iptam.js';
import { check_heading, csv_table_column_selection } from '../shared/directory.js';

jQuery(document).ready(function() {

  if ( $('#dir_action').length ) {
    
    var new_attr_idx = 0;
    var directory_edit_attr_tree;

    function delete_phonebook_attr(id) {
      var elem = $('#'+id).parent().parent();
      $('#'+id).parent().parent().remove();
    }

    /* Ändern der Auswahl */
    $('#dir_action').on('change', function() {
      start_spinner_popup();
      $('#dir_action_form').submit();
    });
    $('#update_dir_action').on('click', function() {
      start_spinner_popup();
      $('#dir_action_form').submit();
    });

    /* Settings: LDAP-Einstellung ändern */
    $('input[type=radio][name="dir_settings[ldap]"]').on('change', function() {
      if ( $('input[type=radio][name="dir_settings[ldap]"]:checked').val() == "true")
        $('.ldap_active').show();
      else
        $('.ldap_active').hide();
    });
    $('input[type=radio][name="dir_settings[ldap]"]').change();

    /* Ändern der Import-Einstellungen */
    $('#import_action').on('change', function() {
      start_spinner_popup();
      $('#dir_action_form').submit();
    });

    /* Anzeige der Import-Optionen */
    if ( $('#dir_action').val() == 'import' ) {
      $('#show_import').show();
    }
    
    /* Ändern der Export-Einstellungen */
    $('#export_action').on('change', function() {
      start_spinner_popup();
      $('#dir_action_form').submit();
    });

    /* Anzeige der Export-Optionen */
    if ( $('#dir_action').val() == 'export' ) {
      $('#show_export').show();
    }

    $('input[type=radio][name=file_type]').change(function() {
      $('.data_valid').hide();
      $('.data_valid_csv').hide();
    });

    /* Import-File: Hochgeladene Datei (CSV, vCard) prüfen */
    $('button[id=checkfile]').on('click', function() {
      $('#check_result_error').hide();
      $('#error_msg').html("");
      $('.check_result_csv').hide();
      $('#check_result_value').html("");
      start_spinner_popup();
      var form_data = new FormData(jQuery('#dir_import_form')[0]);
      form_data.append('what', 'check_file');
      $.ajax({
        url: '/admin/directory/import_action',
        method: 'POST',
        data: form_data,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          stop_spinner_popup();
          console.log({data});
          if ( data['error'] ) {
            $('#error_msg').html(data['error']);
            $('#check_result_error').show();
          } else if ( Array.isArray(data) ) {
            if ( $('input[type=radio][name="file_type"]:checked').val() == "csv") {
              var cols = "<tr>";
              var hdr = "<tr>";
              if ( $('#firstline_heading:checked').val() ) {
                for (var i = 0; i < data.length; i++) {
                  var entry = check_heading(data[i]);
                  if ( !$.isEmptyObject(entry) ) {
                    if ( entry.base_data ) {
                      cols += "<td data-index=" + i +  " data-base_data=\"" + entry.base_data +  "\">" + data[i] + "</td>";
                      hdr += "<th data-index=" + i +  ">" + data[i] + "</th>";
                    } else {
                      cols += "<td style=\"color: red\" data-index=" + i +  " data-phonenumber_select=\"" + entry.phonenumber_select +  "\" data-type_long=\"" + entry.phone_text + "\" data-type_short=\"" + entry.phonenumber_select + "\">" + data[i] + "</td>";
                      hdr += "<th data-index=" + i +  ">" + entry.phone_text + " (" + entry.phonenumber_select + ")</th>";
                    }
                  } else {
                    cols += "<td data-index=" + i +  ">" + data[i] + "</td>";
                    hdr += "<th data-index=" + i +  ">Kein Import</th>";
                  }
                }
              } else {
                for (var i = 0; i < data.length; i++) {
                  cols += "<td data-index=" + i +  ">" + data[i] + "</td>";
                  hdr += "<th data-index=" + i +  ">Kein Import</th>";
                }
              }
              cols = cols + "</tr>";
              csv_table_column_selection(cols, hdr);
            } else {
              var s = "";
              for (var i = 0; i < data.length; i++) {
                s += '<div class="form-group row newvc"><div class="col-sm-3" data-new-vc-index="' + i + '">' + data[i] + '</div><div class="col-sm-3"><input id="new_long_' + i + '" type="text" class="form-control"/></div><div class="col-sm-1"><input id="new_short_' + i + '" type="text" class="form-control"/></div></div>';
              }
              $('.check_result_vcard').append(s);
              $('.check_result_vcard').show();
            }
          } else if ( typeof(data) == "object") {
            if ( $('input[type=radio][name="file_type"]:checked').val() == "csv") {
              var prefs = data.prefs;
              var line = data.firstline;
              var cols = "<tr>";
              var hdr = "<tr>";
              for ( var i = 0; i <= prefs.csv_last_idx; i++ ) {
                var type = prefs['csv_' + i + '_type'];
                if ( type ) {
                  if ( type == "base_data" ) {
                    var text = $("#base_data > option[value=\"" + prefs['csv_' + i + '_val'] + "\"]").text();
                    var value = $("#base_data > option[value=\"" + prefs['csv_' + i + '_val'] + "\"]").val();
                    cols += "<td data-index=" + i +  " data-base_data=\"" +  value +  "\">" + line[i] + "</td>";
                    hdr += "<th data-index=" + i + ">" + text + "</th>";
                  } else if ( type == "phonenumber" || type == "new_phone_num" ) {
                    var long = prefs['csv_' + i + '_val_l'];
                    var short = prefs['csv_' + i + '_val_s'];
                    var format = prefs['csv_' + i + '_format'];
                    if ( format !== undefined )
                      cols += "<td data-index=" + i + " data-type_long=\"" + long + "\" data-type_short=\"" + short + "\" data-numberformat=\"" + format + "\" style=\"color: black\">" + line[i] + "</td>";
                    else
                      cols += "<td data-index=" + i + " data-type_long=\"" + long + "\" data-type_short=\"" + short + "\" style=\"color: red\">" + line[i] + "</td>";
                    hdr += "<th data-index=" + i + ">" + long + " (" + short + ")</th>";
                  }
                } else {
                  cols += "<td data-index=" + i +  ">" + line[i] + "</td>";
                  hdr += "<th data-index=" + i +  ">Kein Import</th>";
                }
              }
              cols = cols + "</tr>";
              hdr = hdr + "</tr>";
              csv_table_column_selection(cols, hdr);
            }
          }
          $('.data_valid').show();
          if ( $('input[type=radio][name="file_type"]:checked').val() == "csv")
            $('.data_valid_csv').show();
        }
      });
    });

    /* Import-File: Speichern der Import-Datei: Übernahme Datei und Formulardaten */
    $('#save_import_file').on('click', function() {
      $('#check_result_error').hide();
      $('#error_msg').html("");
      var form_data = new FormData(jQuery('#dir_import_form')[0]);
      form_data.append('what', 'import_file');
      var last_idx = 0;
      if ( $('input[type=radio][name="file_type"]:checked').val() == "csv") {
        $('td[data-index]').each(function( i ) {
          var idx = $(this).data('index');
          if ( idx > last_idx )
            last_idx = idx;
          if ( $(this).data('base_data') ) {
            form_data.append('csv_' + idx + '_type', 'base_data');
            form_data.append('csv_' + idx + '_val', $(this).data('base_data'));
          } else if ( $(this).data('phonenumber_select') ) {
            form_data.append('csv_' + idx + '_type', 'phonenumber');
            form_data.append('csv_' + idx + '_val_l', $(this).data('type_long'));
            form_data.append('csv_' + idx + '_val_s', $(this).data('type_short'));
            form_data.append('csv_' + idx + '_format', $(this).data('numberformat'));
          } else if ( $(this).data('type_long') ) {
            form_data.append('csv_' + idx + '_type', 'new_phone_num');
            form_data.append('csv_' + idx + '_val_l', $(this).data('type_long'));
            form_data.append('csv_' + idx + '_val_s', $(this).data('type_short'));
            form_data.append('csv_' + idx + '_format', $(this).data('numberformat'));
          }
        });
        form_data.append('csv_last_idx', last_idx); 
      } else {
        $('input[type=text][id^=new_]').each(function(i) {
          var parts = $(this).attr('id').split("_");
          var idx = parts[2];
          if ( idx > last_idx )
            last_idx = i;
          if ( $('#new_long_' + idx).val().length ) {
            form_data.append('vcnew_' + idx + '_' + parts[1], $(this).val());
            if ( parts[1] == 'long' )
              form_data.append('vcnew_' + idx + '_vcard', $('div[data-new-vc-index='+idx+']').text());
          }
        });
        form_data.append('vc_last_idx', last_idx); 
      }
      start_spinner_popup();
      $.ajax({
        url: '/admin/directory/import_action',
        method: 'POST',
        data: form_data,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          stop_spinner_popup();
          console.log({data});
          if ( data['error'] ) {
            $('#error_msg').html(data['error']);
            $('#check_result_error').show();
          } else {
            $('#result_msg').html(data['msg']);
            $('#check_result').show();
            setTimeout(function() {
              window.location = "/admin/directory";
              }, 
              2000);
          }
        }
      });
    });

    /* Löschen einer vCard Attribut Zuordnung ausführen */
    $('#vcard_attr_destroy #delete-button').on('click', function() {
      $('#vcard_attr_destroy').modal('hide');
      console.log("Löschen des Eintrags " + vc_card_del_idx);
      var vals = {};
      vals['what'] = 'delete_vcard_attr';
      vals['vcid'] = vc_card_del_idx;
      start_spinner_popup();
      console.log({vals});
      $.post("/admin/directory/import_action", vals, function(data, status) {
        stop_spinner_popup();
        console.log({data});
        console.log({status});
        $('div[id=vc_row_' + vc_card_del_idx + ']').remove();
      });
    });

    /* Import CardDAV: Attribut-Zuordnung */
    if ( $('#carddav_edit_attributes').length ) {
      if ( directory_edit_attr_tree )
        directory_edit_attr_tree.remove();
      directory_edit_attr_tree = $('#edit-attributes-form').clone();
      $('button[name="sync-save-button"]').attr('id', 'save_import_carddav');
    }
    $('#dir_settings_carddav_url').on('change', function() {
      if ( /^https:/.test($(this).val()) ) {
        $('#show_cert_option').show();
      } else {
        $('#show_cert_option').hide();        
      }
    });
    $('#dir_settings_carddav_url').change();
    $('#carddav_resync').on('click', function() {
      $(this).tooltip('hide');
      start_spinner_popup();
      var f = $('form[action="/admin/directory/import_action"]');
      var form_data = new FormData(f[0]);
      form_data.append('what', 'source_resync');
      form_data.append('source', 'carddav');
      $.ajax({
        url: '/admin/directory/import_action',
        method: 'POST',
        data: form_data,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          console.log({data});
          stop_spinner_popup();
          show_result_info(data);
        }
      });      
    });
    $('#carddav_edit_attributes').tooltip();
    $('#carddav_edit_attributes').on('click', function() {
      var tree = directory_edit_attr_tree.clone();
      $('#edit-attributes-form').find('div').remove();
      $('#edit-attributes-form').append(tree);

      /* CardDAV, neue Attribute */
      $('#new_attribute').on('click', function() {
        $(this).tooltip('hide');
        var s = '<div class="form-group row"><div class="col-sm-4"><input id="new_vcard_' +  new_attr_idx + '" type="text" class="form-control"/></div><div class="col-sm-4"><input id="new_long_' +  new_attr_idx + '" type="text" class="form-control"/></div><div class="col-sm-2"><input id="new_short_' +  new_attr_idx + '" type="text" class="form-control"/></div><div class="col-sm-2 col-form-label"><a href="#" id="del-new-' +  new_attr_idx + '"><i class="fas fa-trash-alt fa-lg"></i></a></div></div>';
        $('#edit-attributes-form').append(s);
        $('#del-new-'+new_attr_idx).on('click', function() {
          delete_phonebook_attr($(this).attr('id'));
        })
        new_attr_idx++;
      });

      /* CardDAV, Attribute löschen */
      $('a[id^=delete_]').on('click', function() {
        $(this).tooltip('hide');
        delete_phonebook_attr($(this).attr('id'));
      });
      $('a[id^=del-new-]').on('click', function() {
        $(this).tooltip('hide');
        delete_phonebook_attr($(this).attr('id'));
      });
      $('#attributes-form').modal();
    });

    /* Anzeige der fehlerhaften Sync-Läufe (CardDAV, LDAP) */
    if ( $('div[data-dirty-count]').length ) {
      console.log("Wert ist " + $('div[data-dirty-count]').data('dirty-count'));
      if ( $('div[data-dirty-count]').data('dirty-count') > 0 ) {
        $('div[data-dirty-count]').show();
      } else {
        $('div[data-dirty-count]').hide();
      }
    }

    /* Synchronisieren der fehlerhaft synchronisierten Kontakte (CardDAV, LDAP) */
    $('#dirty-sync-button').on('click', function() {
      start_spinner_popup();
      $('#dirty-sync-button').tooltip('hide');
      var f = $('form[action="/admin/directory/import_action"]');
      var form_data = new FormData(f[0]);
      form_data.append('what', 'dirty_sync');
      $.ajax({
        url: '/admin/directory/import_action',
        method: 'POST',
        data: form_data,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          console.log({data});
          stop_spinner_popup();
          if ( data.open_dirty == 0 ) {
            $('div[data-dirty-count]').hide();
          }
          if ( Array.isArray(data) ) {
            if ( data.length ) {
              res = {info: {title: "", msg: data.join("<br />")}};
            } else {
              res = {}
            }
            show_result_info(res);
          } else {
            show_result_info(data);
          }
        }
      });
    });

    /* Import CardDAV, Edit Attributes: Save */
    $('#attributes-form #save-button').on('click', function() {
      $('#attributes-form').modal('hide');
      if ( directory_edit_attr_tree )
        directory_edit_attr_tree.remove();
      directory_edit_attr_tree = $('#edit-attributes-form').clone();
    });

    /* Import CardDAV oder LDAP: Änderung Aktion */
    $('input[type=radio][name="sync_action"]').on('change', function() {
      if ( $('input[type=radio][name="sync_action"]:checked').val() == "periodic" ) {
        $('.timepicker').clockpicker({autoclose: true, placement: 'top'});
        $('.repitition').show();
        $('.no-repitition').hide();
        $('#dir_settings_sync_when').change();
      } else {
        $('.repitition').hide();
        $('.no-repitition').show();
      }
    });
    $('input[type=radio][name="sync_action"]').change();
    $('#dir_settings_sync_when').on('change', function() {
      if ( $('input[type=radio][name="sync_action"]:checked').val() == "periodic" ) {
        if ( $(this).val() != -2 ) {
          $('.daily').show();
          $('.not-daily').hide();
        } else {
          $('.daily').hide();
          $('.not-daily').show();
        }
      }
    });
    $('#dir_settings_sync_when').change();

    /* Import CardDAV: Aktion ausführen */
    $('#save_import_carddav').on('click', function() {
      start_spinner_popup();
      var f = $('form[action="/admin/directory/import_action"]');
      var form_data = new FormData(f[0]);
      form_data.append('what', 'import_carddav');
      $('#attributes-form input[type=text]').each(function(){
        form_data.append($(this).attr('id'), $(this).val());
      });
      $.ajax({
        url: '/admin/directory/import_action',
        method: 'POST',
        data: form_data,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          console.log({data});
          stop_spinner_popup();
          if ( Array.isArray(data) ) {
            if ( data.length ) {
              res = {info: {title: "", msg: data.join("<br />")}};
            } else {
              res = {}
            }
            show_result_info(res);
          } else {
            show_result_info(data);
          }
        }
      });
    });

    /* Import LDAP: Attribut-Zuordnung */
    if ( $('#ldap_edit_attributes').length ) {
      if ( directory_edit_attr_tree )
        directory_edit_attr_tree.remove();
      directory_edit_attr_tree = $('#edit-attributes-form').clone();
      $('button[name="sync-save-button"]').attr('id', 'save_import_ldap');
    }

    $('#ldap_resync').on('click', function() {
      $(this).tooltip('hide');
      start_spinner_popup();
      var f = $('form[action="/admin/directory/import_action"]');
      var form_data = new FormData(f[0]);
      form_data.append('what', 'source_resync');
      form_data.append('source', 'ldap');
      $.ajax({
        url: '/admin/directory/import_action',
        method: 'POST',
        data: form_data,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          console.log({data});
          stop_spinner_popup();
          show_result_info(data);
        }
      });      
    });

    /* Import LDAP: Attribut-Zuordnung */
    $('#ldap_edit_attributes').tooltip();
    $('#ldap_edit_attributes').on('click', function() {
      $(this).tooltip('hide');
      var tree = directory_edit_attr_tree.clone();
      $('#edit-attributes-form').find('div').remove();
      $('#edit-attributes-form').append(tree);
      start_spinner_popup();
      show_result_info({});
      var f = $('form[action="/admin/directory/import_action"]');
      var form_data = new FormData(f[0]);
      form_data.append('what', 'check_connect');
      form_data.append('get_attrs', 1);
      $.ajax({
        url: '/admin/directory/import_action',
        method: 'POST',
        data: form_data,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          console.log({data});
          stop_spinner_popup();
          if ( data.error ) {
            show_result_info(data);
          } else {
            $('input[id^=ldap_]').autocomplete({source: data.result});
            $('input[id^=new_phone_]').autocomplete({source: data.result});
            $('#new_attribute').on('click', function() {
              $(this).tooltip('hide');
              var s = '<div class="form-group row"><div class="col-sm-4"><input id="new_phone_' +  new_attr_idx + '" type="text" class="form-control"/></div><div class="col-sm-4"><input id="new_long_' +  new_attr_idx + '" type="text" class="form-control"/></div><div class="col-sm-2"><input id="new_short_' +  new_attr_idx + '" type="text" class="form-control"/></div><div class="col-sm-2 col-form-label"><a href="#" id="del-new-' +  new_attr_idx + '"><i class="fas fa-trash-alt fa-lg"></i></a></div></div>';
              $('#edit-attributes-form').append(s);
              $('#new_phone_' +  new_attr_idx).autocomplete({source: data.result});
              $('#del-new-'+new_attr_idx).on('click', function() {
                delete_phonebook_attr($(this).attr('id'));
              });
              new_attr_idx++;
            });
            /* LDAP, Attribute löschen */
            $('a[id^=delete_]').on('click', function() {
              $(this).tooltip('hide');
              delete_phonebook_attr($(this).attr('id'));
            });
            $('a[id^=del-new-]').on('click', function() {
              $(this).tooltip('hide');
              delete_phonebook_attr($(this).attr('id'));
            });
            $('#attributes-form').modal();
          }
        }
      });
    });

    /* Import LDAP: Test der Verbindung */
    $('#ldap-check_connect').on('click', function() {
      start_spinner_popup();
      var f = $('form[action="/admin/directory/import_action"]');
      var form_data = new FormData(f[0]);
      form_data.append('what', 'check_connect');
      $.ajax({
        url: '/admin/directory/import_action',
        method: 'POST',
        data: form_data,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          console.log({data});
          stop_spinner_popup();
          if ( Array.isArray(data) ) {
            if ( data.length ) {
              res = {info: {title: "", msg: data.join("<br />")}};
            } else {
              res = {}
            }
            show_result_info(res);
          } else if ( data['result'] ) {
            $('#show_result_msg').text(data['result']);
            show_result_info(data);   // Zum Löschen eventueller früherer Flash-Infos
          } else {
            show_result_info(data);
          }
          $('#show_result').show();
        }
      });
    });

    /* Import LDAP: Änderungen der Verbindungsdaten löscht Testergebnis */
    $('input[id^=dir_settings_ldap_]').on('change', function() {
      $('#show_result_msg').text("");
      $('#show_result').hide();
    });

    /* Import LDAP: Speichern der Einstellungen */
    $('#save_import_ldap').on('click', function() {
      start_spinner_popup();
      var f = $('form[action="/admin/directory/import_action"]');
      var form_data = new FormData(f[0]);
      form_data.append('what', 'import_ldap');
        $('#attributes-form input[type=text]').each(function() {
          form_data.append($(this).attr('id'), $(this).val());
        });
      $.ajax({
        url: '/admin/directory/import_action',
        method: 'POST',
        data: form_data,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          console.log({data});
          stop_spinner_popup();
          if ( Array.isArray(data) ) {
            if ( data.length ) {
              res = {info: {title: "", msg: data.join("<br />")}};
            } else {
              res = {}
            }
            show_result_info(res);
          } else {
            show_result_info(data);
          }
        }
      });
    });

    /* Export CSV: Speichern der Einstellungen */
    $('#download_csv_export').on('click', function() {
      start_spinner_popup();
      var f = $('form[action="/admin/directory/export_action"]');
      var form_data = new FormData(f[0]);
      form_data.append('what', 'export_csv');
      $.ajax({
        url: '/admin/directory/export_action',
        method: 'POST',
        data: form_data,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          console.log({data});
          stop_spinner_popup();
          if ( data.result == 'download' ) {
            window.location = '/admin/directory/download?fn=' + data.filename;
          } else if ( Array.isArray(data) ) {
            if ( data.length ) {
              res = {info: {title: "", msg: data.join("<br />")}};
            } else {
              res = {}
            }
            show_result_info(res);
          } else {
            show_result_info(data);
          }
        }
      });
    });

    /* Export vCard: Speichern der Einstellungen */
    $('#download_vcard_export').on('click', function() {
      start_spinner_popup();
      var f = $('form[action="/admin/directory/export_action"]');
      var form_data = new FormData(f[0]);
      form_data.append('what', 'export_vcard');
      $('#attributes-form input[type=text]').each(function(){
        form_data.append($(this).attr('id'), $(this).val());
      });
      $.ajax({
        url: '/admin/directory/export_action',
        method: 'POST',
        data: form_data,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          console.log({data});
          stop_spinner_popup();
          if ( data.result == 'download' ) {
            window.location = '/admin/directory/download?fn=' + data.filename;
          } else if ( Array.isArray(data) ) {
            if ( data.length ) {
              res = {info: {title: "", msg: data.join("<br />")}};
            } else {
              res = {}
            }
            show_result_info(res);
          } else {
            show_result_info(data);
          }
        }
      });
    });

    if ( $('#dir_settings_http_charset').length ) {
      $('.timepicker').clockpicker({autoclose: true, placement: 'top'});
      $('#dir_settings_sync_when').on('change', function() {
        if ( $(this).val() != -2 ) {
          $('.daily').show();
          $('.not-daily').hide();
        } else {
          $('.daily').hide();
          $('.not-daily').show();
        }
      });
      $('#dir_settings_sync_when').change();      
    }

    /* Export HTTP: Speichern der Einstellungen */
    $('#http_export').on('click', function() {
      start_spinner_popup();
      var f = $('form[action="/admin/directory/export_action"]');
      var form_data = new FormData(f[0]);
      form_data.append('what', 'export_http');
      $.ajax({
        url: '/admin/directory/export_action',
        method: 'POST',
        data: form_data,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          console.log({data});
          stop_spinner_popup();
          if ( Array.isArray(data) ) {
            if ( data.length ) {
              res = {info: {title: "", msg: data.join("<br />")}};
            } else {
              res = {}
            }
            show_result_info(res);
          } else {
            show_result_info(data);
          }
        }
      });
    });

    if ( $('#dir_lookup-table').length ) {
      var entry_id;
      var table = activate_tables('#dir_lookup-table', false, [-1], true, '/admin/directory/get_table');
      table.on('draw', function () {
        /* Links in der Suchansicht */
        $('a[href="#"][id^=edit_]').on('click', function() {
          $('[data-toggle="tooltip"]').tooltip('hide');
          window.location = "/admin/directory/" + $(this).attr('id').substr(5) + "/edit";
//           $.get('/admin/directory/update_action?dir_action=edit&id=' + $(this).attr('id').substr(5) , function(data, status) {
//           });
        });
        $('a[href="#"][id^=edit_]').tooltip();
        $('a[href="#"][id^=delete_]').on('click', function() {
          $('[data-toggle="tooltip"]').tooltip('hide');
          entry_id = $(this).attr('id').substr(7);
          var list = [];
          $(this).parent().parent().find("td").each(function() {
              list.push($(this).text());
          });
          var t;
          if ( list[2].length > 0 )
            t = list[1] + " " + list[0] + " (" + list[2] + ")";
          else
            t = list[1] + " " + list[0];
          $('#contact_destroy #contact').text(t);
          $('#contact_destroy').modal();
        });
        $('a[href="#"][id^=delete_]').tooltip();
      });

      $('#contact_destroy #delete-button').on('click', function() {
        $('#contact_destroy').modal('hide');
        start_spinner_popup();
        $.ajax({
          url: '/admin/directory/' + entry_id,
          method: 'DELETE',
          processData: false,  // tell jQuery not to process the data
          contentType: false,  // tell jQuery not to set contentType
          success: function(data) {
            console.log({data});
            stop_spinner_popup();
            window.location = "/admin/directory/search_action";
          }
        });
      });
    }

    /* Auf der Seite "Telefonbucheintrag" (Neu oder Bearbeiten) */
    var idx = 1;
    if ( $('#phonebook_entry_title').length ) {
      var entry_id;
      idx = 1;
      $('.delete_numentry').tooltip();
      $('.pin_entry').tooltip();
      $('#add_number').tooltip();
      $('#add_number').on('click', function() {
        $('#add_number').tooltip('hide');
        var line = $('#number_entry_line').clone();
        line.attr('id', "number_line_"+idx);
        line.find('input').each(function() {
          if ( $(this).attr('id') == 'number_new_0' ) {
            $(this).attr('id', "number_new_" + idx);
            $(this).attr('name', "number_new_" + idx);
          } else if ( $(this).attr('id') == 'type_long_new_0' ) {
            $(this).attr('id', "type_long_new_" + idx);
            $(this).attr('name', "type_long_new_" + idx);
          } else if ( $(this).attr('id') == 'type_short_new_0' ) {
            $(this).attr('id', "type_short_new_" + idx);
            $(this).attr('name', "type_short_new_" + idx);
          } else if ( $(this).attr('id') == 'cp_pin_new_0' ) {
            $(this).attr('id', "cp_pin_new_" + idx);
            $(this).attr('name', "cp_pin_new_" + idx);
            $(this).next('a').attr('data-entry-id', "new_" + idx);
          }
          $(this).val("");
        });
        line.find('select').each(function() {
          var s = $(this).find('option:selected');
          if ( $(this).attr('id') == 'type_sel_new_0' ) {
            $(this).attr('id', "type_sel_new_" + idx);
            $(this).attr('name', "type_sel_new_" + idx);
          }
        });
        $('#last_idx').val(idx);
        line.find('.pin_entry').tooltip();
        line.find('.pin_entry').on('click', function() {
          entry_id = $(this).data('entry-id');
          if ( $('input#number_' + entry_id).val().length ) {
            $('span#phone-number').text($('input#number_' + entry_id).val());
            $('#dir_set_callprotect_pin-form #unknown-number').hide();
            $('#dir_set_callprotect_pin-form #known-number').show();
          } else {
            $('#dir_set_callprotect_pin-form #unknown-number').show();
            $('#dir_set_callprotect_pin-form #known-number').hide();
          }
          $('input#pin').val($('input#cp_pin_' + entry_id).val());
          $('#dir_set_callprotect_pin-form').modal();
        });
        idx++;
        $('.entry_line').last().after(line);
      });
      $('.delete_numentry').tooltip();
      $('.delete_numentry').on('click', function() {
        $(this).tooltip('hide');
        if ( $(this).parent().parent().attr('id') != "number_entry_line" ) {
          $(this).parent().parent().remove();
        } else {
          $(this).parent().parent().find('input').val("");
        }
      });
      $('.pin_entry').tooltip();
      $('.pin_entry').on('click', function() {
        $(this).tooltip('hide');
        entry_id = $(this).data('entry-id');
        if ( entry_id > 0 ) {
          $('span#phone-number').text($('input#number_' + entry_id).val());
          $('input#pin').val($('input#cp_pin_' + entry_id).val());
          $('#dir_set_callprotect_pin-form #unknown-number').hide();
          $('#dir_set_callprotect_pin-form #known-number').show();
        } else {
          if ( $('input#number_new_0').val().length ) {
            $('span#phone-number').text($('input#number_new_0').val());
            $('#dir_set_callprotect_pin-form #unknown-number').hide();
            $('#dir_set_callprotect_pin-form #known-number').show();
          } else {
            $('#dir_set_callprotect_pin-form #unknown-number').show();
            $('#dir_set_callprotect_pin-form #known-number').hide();
          }
          $('input#pin').val($('input#cp_pin_new_0').val());
        }
        $('#dir_set_callprotect_pin-form').modal();
      });

      $('#dir_set_callprotect_pin-form #save-button').on('click', function() {
        var v = $('input#pin').val();
        if ( isNaN(entry_id) ) {
          $('input#cp_pin_' + entry_id).val(v);
        } else {
          if ( entry_id > 0 )
            $('input#cp_pin_' + entry_id).val(v);
          else
            $('input#cp_pin_new_0').val(v);
        }
        $('#dir_set_callprotect_pin-form').modal('hide');
      });

      $('#picture_file').on('change', function() {
        var formData;
        entry_id = 0;
        if ( $('form.new_contact').length > 0 ) {
          formData = new FormData(jQuery('form.new_contact')[0]);
        } else if ( $('form.edit_contact').length > 0 ) {
          formData = new FormData(jQuery('form.edit_contact')[0]);
          entry_id = url_get_id('form.edit_contact', -1);
        } else {
          return;
        }
        start_spinner_popup();
        $.ajax({
          url: '/admin/directory/' + entry_id + '/photo_upload',
          method: 'POST',
          data: formData,
          processData: false,  // tell jQuery not to process the data
          contentType: false,  // tell jQuery not to set contentType
          success: function(data) {
            console.log({data});
            if ( Array.isArray(data) ) {
              var path = data[0];
              var hash = data[1];
              $('img.img-thumbnail').attr('src','/pictures/' + path + '.png');
              $('#contact_picture').val(hash + ';' + path);
            }
            stop_spinner_popup();
          }
        });
      });

      $('.delete_pic').on('click', function() {
        $(this).tooltip('hide');
        $('img.img-thumbnail').attr('src','');
        $('#contact_picture').val('');
      });
    }

    $('#directory_destroy #delete-button').on('click', function() {
      $('#directory_destroy').modal('hide');
      start_spinner_popup();
      $.ajax({
        url: '/admin/directory/destroy_all',
        method: 'POST',
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          console.log({data});
          stop_spinner_popup();
          window.location = "/admin/directory/settings_action";
        }
      });
    });
  }

});

jQuery(document).ready(function() {
  if ( $('#update_running_title').length ) {
    
    function set_progress_bar(value) {
      $('.progress-bar').attr('aria-valuenow', value);
      $('.progress-bar').css('width', value);
    }

    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    
    async function do_progress(delay) {
      
      var value = 0;
      var max = delay * 5;
      for (let i = 0; i < max; i++) {
        set_progress_bar(value * 100 / max);
        value +=5 ;
        await sleep(200);
      }
      $('#update-progress').modal('hide');
      $("#finished").show();
    }

    var delay = $('div#updatepbx-js-data').data('duration');
    if ( delay ) {
      set_progress_bar(0);
      $('#update-progress').modal();

      $('div#finished button').on('click', function() {
        window.location = "/logins";
      });

      do_progress(delay);
    }
  }

});

var PhoneMitel_6757 = (function(modul) {

  function init(pdata, funcs) {
    console.log("Telefon " + pdata.vendor + " " + pdata.model);
    var layer1 = 1;
    var layer2 = 1;
    // Initiales setzen, eigene Nebenstelle, Tasten mit BLF-Verschiebung
    funcs.set_initial_data(pdata.own_exten, {skey: [1,20], tskey: [1,10]});

    // Funktionstasten mit Ebenen (rechts und links oben )
    for ( var i = 1; i <= 5; i++) {
      $('#tskey'+i).droppable({
        accept: '.key',
        hoverClass: 'highlight-target',
        drop: function(event, ui) {
          if ( ui.draggable.attr('id') == "f_blf" ) {
            funcs.set_blf($(this), true);
          } else if ( ui.draggable.attr('id') == "f_direct" ) {
            funcs.set_direct($(this), true);
          } else if ( ui.draggable.attr('id') == "f_cfwdgrp" ) {
            funcs.set_cfwdgrp($(this), true);
          } else if ( ui.draggable.attr('id') == "f_call-lists" ) {
            funcs.set_call_lists($(this), true);
          } else if ( ui.draggable.attr('id') == "f_agent" ) {
            funcs.set_agent($(this), true);
          } else if ( ui.draggable.attr('id') == "f_icom" ) {
            funcs.set_icom($(this), true);
          } else if ( ui.draggable.attr('id') == "f_dtmf" ) {
            funcs.set_dtmf($(this), true);
          } else if ( ui.draggable.attr('id') == "f_profileled" ) {
            funcs.set_profileled($(this));
          } else if ( ui.draggable.attr('id') == "f_nconfig" || ui.draggable.attr('id') == "f_noop" ) {
            funcs.set_keyfunc($(this), $(ui.draggable), false);
          } else {
            funcs.set_keyfunc($(this), $(ui.draggable), true);
          }
        }
      });
    }

    // Funktionstasten mit Ebenen (rechts und links vom Display)
    for ( var i = 1; i <= 5; i++) {
      $('#skey'+i).droppable({
        accept: '.key',
        hoverClass: 'highlight-target',
        drop: function(event, ui) {
          if ( ui.draggable.attr('id') == "f_blf" ) {
            funcs.set_blf($(this), true);
          } else if ( ui.draggable.attr('id') == "f_direct" ) {
            funcs.set_direct($(this), true);
          } else if ( ui.draggable.attr('id') == "f_cfwdgrp" ) {
            funcs.set_cfwdgrp($(this), true);
          } else if ( ui.draggable.attr('id') == "f_call-lists" ) {
            funcs.set_call_lists($(this), true);
          } else if ( ui.draggable.attr('id') == "f_agent" ) {
            funcs.set_agent($(this), true);
          } else if ( ui.draggable.attr('id') == "f_icom" ) {
            funcs.set_icom($(this), true);
          } else if ( ui.draggable.attr('id') == "f_dtmf" ) {
            funcs.set_dtmf($(this), true);
          } else if ( ui.draggable.attr('id') == "f_profileled" ) {
            funcs.set_profileled($(this), true);
          } else if ( ui.draggable.attr('id') == "f_nconfig" || ui.draggable.attr('id') == "f_noop" ) {
            funcs.set_keyfunc($(this), $(ui.draggable), false);
          } else {
            funcs.set_keyfunc($(this), $(ui.draggable), true);
          }
        }
      });
    }

    // Ebenenwechsel (Top Softkeys)
    $('#layer1').on('click', function() {
      funcs.change_layer_up(layer1, 2, 1, 5, 'tskey' );
      layer1 += 1;
      if ( layer1 > 2 )
        layer1 = 1;
      $('#layer1').text("Ebene " + layer1);
      $('.tskey-label').each(function(idx) {
        $(this).text("Taste " + ((layer1-1)*5+idx+1));
      });
    });
    
    // Ebenenwechsel (Softkeys)
    $('#layer2').on('click', function() {
      funcs.change_layer_up(layer2, 4, 1, 5, 'skey' );
      layer2 += 1;
      if ( layer2 > 4 )
        layer2 = 1;
      $('#layer2').text("Ebene " + layer2);
      $('.skey-label').each(function(idx) {
        $(this).text("Taste " + ((layer2-1)*5+idx+1));
      });
    });

    $('#username').on('click', function() {
      funcs.click_change_text('username', 4, 'phone-name-texts');
    });

    $('#extension').on('click', function() {
      funcs.click_change_text('extension', 5, 'phone-name-texts');
    });

    $('#datetime').on('click', function() {
      funcs.click_change_text('datetime', 4, 'mitel-datetime-texts');
    });
  };

  function do_submit(funcs) {
    console.log("Submit");
    funcs.do_submit();
  };

  return {
    init: init,
    do_submit: do_submit
  };
})(PhoneMitel_6757 || {});
window.PhoneMitel_6757 = PhoneMitel_6757;

jQuery(document).ready(function() {

  if ( $('#diag_audio_title').length ) {

    $('#audio_reporting_filter').autocomplete( {
      source: function (request, response) {
        $.get('/misc/ajax/user_lookupjs?search=' + request.term, function(data) {
          var result = [];
          for ( var i=0; i<data.length; i++ ) {
            var s = data[i].sur_name + ', ' + data[i].gv_name + ' [' + data[i].exten + ']';
            var ext_id = data[i].ext_id;
            result.push({value: data[i].exten, label: s, alias: data[i].short, ext_id: ext_id});
          }
          response(result);
        })
      },
    });

  }

});

import { url_get_id } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#form_siptrunkaccs').length ) {

    $('button#add_location').on('click', function() {
      $('#errorExplanation').hide()
      $('#add_location-form input').val("");
      $('#add_location-form').modal();
      $('#add_location-form input[type=text][id=name]').focus();
    });

    $('#add_location-form button#save-button').on('click', function() {
      start_spinner_popup();
      var vals = {};
      vals['name'] = $('#add_location-form input[type=text][id=name]').val();
      vals['desc'] = $('#add_location-form input[type=text][id=desc]').val();
      vals['country'] = $('#add_location-form input[type=text][id=country]').val();
      vals['city'] = $('#add_location-form input[type=text][id=city]').val();
      vals['prefix'] = $('#add_location-form input[type=text][id=prefix]').val();
      var id = url_get_id('form[action^="/admin/siptrunkaccs/"]');
      $.post("/admin/siptrunkaccs/" + id + "/add_location", vals, function(data, status) {
        stop_spinner_popup();
        if ( data.error ) {
          var list = "";
          $.each(data.msgs, function(key, value) {
            list = list + "<li>" + value + "</li>";
          });
          $('#error-list').html(list);
          $('#errorExplanation').show();
        } else {
          $('select#location_id').append("<option value=\"" + data.location_id + "\">" + data.name + "</option>")
          $('select#location_id option[value="' + data.location_id +'"]').prop('selected', true);
          $('#add_location-form').modal('hide');
        }
      });
    });
  }

});

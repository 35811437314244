import { activate_tables } from '../iptam.js';

jQuery(document).ready(function() {
  if ( $('#interfaces-1-table').length ) {

    var entry_id;

    activate_tables('#interfaces-1-table', false, [0, -1]);
    activate_tables('#interfaces-2-table');

    $('a.delete').on('click', function() {
      entry_id = $(this).data('entry-id');
      console.log("Entry id " + entry_id);
      $('#interface_destroy #name').text($(this).data('name'));
      $('#interface_destroy').modal();
    });

    $('#interface_destroy #delete-button').on('click', function() {
      console.log("Entry id " + entry_id);
      start_spinner_popup();
      $('#delete-form').attr('action', '/admin/interfaces/' + entry_id);
      $('#delete-form').submit();
    });

  }

  if ( $('#interfaces_edit_title').length ) {
    $('#interface_prefix').on('change', function() {
      if ( $(this).val().length > 0 ) {
        var prefix = parseInt($(this).val());
        var CDIR2netmask = (bitCount) => {
          var mask=[];
          for(var i=0;i<4;i++) {
            var n = Math.min(bitCount, 8);
            mask.push(256 - Math.pow(2, 8-n));
            bitCount -= n;
          }
          return mask.join('.');
        }
        $('#show_mask').html(CDIR2netmask(prefix));
      } else {
        $('#show_mask').html("");
      }
    });
    $('#interface_prefix').trigger('change');

  }

});

import { initial_setup_number_pattern, pattern_list } from '../number_patterns.js';

jQuery(document).ready(function() {

  if ( $('#lcrrules_title').length ) {

    var update_pattern_table = function() {
      console.log("In update_pattern_table");
      var first_empty_line = -1;
      var x = 0;
      $(".lcr-rule").each(function(index) {
        var len = $(this).find('.lcr-dragbutton').length;
        x = ( len == 1 );
        if ( first_empty_line < 0 && len == 0 )
          first_empty_line = index;
      });
      $(".lcr-rule").each(function(index) {
        if ( index > first_empty_line )
          $(this).hide();
        else
          $(this).show();
      });
    }

    $('button[type=button][name=save_pattern]').click(function() {
      var result = "";
      $('.lcr-rule').each(function(index) {
        var line = "";
        $(this).find('.lcr-dragbutton').each(function(index) {
          var button = $(this).text().trim();
          jQuery.each(pattern_list, function(idx, val) {
            if ( button == val ) {
              if ( line.length == 0 )
                line = idx.toString();
              else
                line += ',' + idx.toString();
            }
          });
        });
        if ( line.length > 0 ) {
          if ( result.length == 0 )
            result = line;
          else
            result += ';' + line;
        }
      });
      $('#lcrrule_regex').val(result);
      $('form[action*=lcrrules]').submit();
    });

    if ( $('#lcrrule_regex').length ) {
      console.log("Initial aufrufen");
      initial_setup_number_pattern($('#lcrrule_regex').val(), update_pattern_table);
    }
  }

});


const images = require.context('../images', true);

require("jquery")
import $ from 'jquery';

window.$ = $
window.jQuery = $

require("jquery-ui")
require("@rails/ujs").start()
require("bootstrap")
require('clockpicker/dist/bootstrap-clockpicker')
require('bootstrap-datepicker')
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.de.min.js')
require('datatables.net-bs4')
require('bootstrap-filestyle2')

let webpackContext = require.context('../src/javascripts', true, /\.js$/)
for(let key of webpackContext.keys()) { webpackContext(key) }

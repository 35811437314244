jQuery(document).ready(function() {

  if ( $('#user_settings_title').length ) {

    function update_setting(v) {
      var group;
      switch(v) {
        case 1:
          group = $('#extension_clip_agent').val();
          if ( group.substring(0, 5) == "ring_" ) {
            var n = group.substring(5)
            $('#clip_agent_r option[value=' + n +']').attr('selected', 'selected');
          } else {
            $('#clip_agent_r option[value=0]').attr('selected', 'selected');
          }
          $('#show_ringgroup').show();
          $('#show_ringgroup_dyn').hide();
          $('#show_queue').hide();
          $('#show_queue_dyn').hide();
          break;
        case 3:
          group = $('#extension_clip_agent').val();
          if ( group.substring(0, 5) == "ring_" ) {
            var n = group.substring(5)
            $('#clip_agent_dyn_r option[value=' + n +']').attr('selected', 'selected');
          } else {
            $('#clip_agent_dyn_r option[value=0]').attr('selected', 'selected');
          }
          $('#show_ringgroup_dyn').show();
          $('#show_ringgroup').hide();
          $('#show_queue').hide();
          $('#show_queue_dyn').hide();
          break;
        case 2:
          group = $('#extension_clip_agent').val();
          if ( group.substring(0, 6) == "queue_" ) {
            var n = group.substring(6)
            $('#clip_agent_q option[value=' + n +']').attr('selected', 'selected');
          } else {
            $('#clip_agent_q option[value=0]').attr('selected', 'selected');
          }
          $('#show_queue').show();
          $('#show_queue_dyn').hide();
          $('#show_ringgroup').hide();
          $('#show_ringgroup_dyn').hide();
          break;
        case 4:
          group = $('#extension_clip_agent').val();
          if ( group.substring(0, 6) == "queue_" ) {
            var n = group.substring(6)
            $('#clip_agent_dyn_q option[value=' + n +']').attr('selected', 'selected');
          } else {
            $('#clip_agent_dyn_q option[value=0]').attr('selected', 'selected');
          }
          $('#show_queue_dyn').show();
          $('#show_queue').hide();
          $('#show_ringgroup').hide();
          $('#show_ringgroup_dyn').hide();
          break;
        default:
          $('#show_ringgroup').hide();
          $('#show_ringgroup_dyn').hide();
          $('#show_queue').hide();
          $('#show_queue_dyn').hide();
      }
    }

    $('#clip_dynamic').on('click', function() {
      if ( $(this).prop('checked') ) {
        $('#show_ringgroup').hide();
        $('#show_queue').hide();
        $('.no-dynamic-clip').hide();
      } else {
        $('.no-dynamic-clip').show();
        var v = $('#extension_clip_usersetting').val();
        $('input:radio[name=clip_usersetting]').val([v]);
        update_setting(parseInt(v));
      }
    });

    $('input:radio[name=clip_usersetting]').on('change', function() {
      update_setting(parseInt($(this).val()));
    });

    $('#conf_variable_clip').on('click', function() {
      if ( $('#extension_clip_dynamic').val() == "true" ) {
        $('#clip_dynamic').prop('checked', true);
        $('.no-dynamic-clip').hide();
        $('#show_ringgroup').hide();
        $('#show_ringgroup_dyn').hide();
        $('#show_queue').hide();
        $('#show_queue_dyn').hide();
      } else {
        $('#clip_dynamic').prop('checked', false);
        $('.no-dynamic-clip').show();
        var v = $('#extension_clip_usersetting').val();
        $('input:radio[name=clip_usersetting]').val([v]);
        update_setting(parseInt(v));
      }
      $('#clip-dialog').modal();
    });

    $('#clip-dialog #save-button').on('click', function() {
      var valid = true;
      $('#extension_clip_dynamic').val($('#clip_dynamic').prop('checked'));
      var v = parseInt($('input:radio[name=clip_usersetting]:checked').val());
      $('#extension_clip_usersetting').val(v);
      switch ( v ) {
        case 1:
          var group = $('#clip_agent_r option:selected').val();
          if ( parseInt(group) > 0 )
            $('#extension_clip_agent').val('ring_' + group);
          else
            valid= false;
          break;
        case 2:
          var group = $('#clip_agent_q option:selected').val();
          if ( parseInt(group) > 0 )
            $('#extension_clip_agent').val('queue_' + group);
          else
            valid= false;
          break;
        case 3:
          var group = $('#clip_agent_dyn_r option:selected').val();
          if ( parseInt(group) > 0 )
            $('#extension_clip_agent').val('ring_' + group);
          else
            valid= false;
          break;
        case 4:
          var group = $('#clip_agent_dyn_q option:selected').val();
          if ( parseInt(group) > 0 )
            $('#extension_clip_agent').val('queue_' + group);
          else
            valid= false;
          break;
      }
      if ( valid ) {
        var vals = {};
        vals['clip_dynamic'] = $('#extension_clip_dynamic').val();
        vals['clip_usersetting'] = $('#extension_clip_usersetting').val();
        vals['clip_agent'] = $('#extension_clip_agent').val();
        vals['exten_id'] = $('#extension_id').val();
        start_spinner_popup();
        $.post("/user/settings/update_clip", vals, function(data, status) {
          console.log({data});
          $('a[id=conf_variable_clip]').text(data['linktext']);
          stop_spinner_popup();
          $('#clip-dialog').modal('hide');
        });
      }
    });
  }

});

import { activate_tables, url_get_id } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#pbx_trunk-table').length ) {
    var table = activate_tables('#pbx_trunk-table', false, [-1]);

    table.on( 'draw', function () {
      $('.alert').remove();
      $('.delete_entry').on('click', function() {
        delete_entry($(this));
      });
    });
    
    $('.delete_entry').on('click', function() {
      delete_entry($(this));
    });
    
    // Löschen einer PBX-Vernetzung
    var entry_id;
    function delete_entry(entry) {
      entry.tooltip('hide');
      entry_id = entry.data('entry-id');
      $('span[id=name]').html(entry.data('name'));
      $('span[id=exten_list]').html(entry.data('exten-list'));
      entry_id = entry.data('entry-id');
      $('#pbx_trunk_destroy').modal();
    };

    $('#pbx_trunk_destroy #delete-button').on('click', function() {
      $('#pbx_trunk_destroy #delete-form').attr('action', '/admin/pbx_trunks/' + entry_id);
      $('#pbx_trunk_destroy #delete-form').submit();
    });

  }

  if ( $('#pbx_trunk_edit_title').length ) {

    function list_update() {
      var options = "";
      $('#pbx_trunk_extensions').val().split(';').sort().forEach(function(entry) {
        options += '<option value="' + entry + '">' + entry + '</option>';
      });
      $('#delete_exten').find('option').remove().end().append(options);
      var length = $('#pbx_trunk_extensions').val().split(';').length;
      if ( length > 4 )
        length = 4;
      $('#delete_exten').attr('size', length);
    }

    $('button[id=add_exten]').click(function(e) {
      e.preventDefault();
      if ( /^(\d+|\d+\-\d+)$/.test($('#new_exten').val()) ) {
        var entry_id = url_get_id('form[action^="/admin/pbx_trunks/');
        $.getJSON("/misc/ajax/check_new_exten", {id: entry_id, type: 'pbx_trunk', exten: $('#new_exten').val()}, function(json) {
          if ( json.state == 0 ) {
            var current = $('#pbx_trunk_extensions').val().split(';');
            current.push($('#new_exten').val());
            var idx = current.indexOf("");
            if ( idx >= 0 )
              current.splice(idx, 1);
            $('#pbx_trunk_extensions').val(current.join(';'));
            list_update();
            $('#new_exten').val("");
          } else {
            $('#pbx_trunk_message .modal-body').html(json.msg);
            $('#pbx_trunk_message').modal();
          }
        });
      } else {
        $('#new_exten').val("");
      }
    });

    $('button[id=del_exten]').click(function(e) {
      e.preventDefault();
      var current = $('#pbx_trunk_extensions').val().split(';');
      var to_delete  = $('#delete_exten').val();
      to_delete.forEach(function(entry) {
        var index = current.indexOf(entry);
        if ( index >= 0 ) {
          current.splice( index, 1 );
        }
      });
      $('#pbx_trunk_extensions').val(current.join(';'));
      list_update();
    });

    $('#pbx_trunk_clid_ext_prefix').click(function() {
      $('#pbx_trunk_clid_mode_1').prop("checked", true);
    });

    $('#pbx_trunk_clid_int_length').change(function() {
      $('#pbx_trunk_clid_mode_2').prop("checked", true);
    });
  }

});

import { activate_tables, block_enable } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#phonetemplate-table').length ) {

    /* Auf der Index-Seite */
    var table = activate_tables('#phonetemplate-table', false, [-1]);

    table.on( 'draw', function () {
      $('.alert').remove();
      $('.delete_entry').on('click', function() {
        delete_entry($(this));
      });
      $('.copy_entry').on('click', function() {
        copy_entry($(this));
      });
    });
    
    $('.delete_entry').on('click', function() {
      delete_entry($(this));
    });
    
    $('.copy_entry').on('click', function() {
      copy_entry($(this));
    });

    $('#phonetemplate_copy').on('shown.bs.modal', function() {
      $(this).find('#new_name').focus();
    });

    $('#templ_from_phone').on('click', function() {
      $.get('/admin/phonetemplates/get_phones', function(data) {
        console.log({data})
        var options = "";
        for ( var i=0; i < data.length; i++ ) {
          options += ("<option value='" + data[i]['id'] + "'>" + data[i]['name'] + " (" + data[i]['phone'] + ")</option>");
        }
        $('#phones').html(options);
        $('#phones').attr('size', data.length > 5 ? 5 : data.length);
        $('#phonetemplate_phonecopy').modal();
      });
    });

    $('#phonetemplate_phonecopy').on('shown.bs.modal', function() {
      $(this).find('#new_name').focus();
    });

    // Löschen einer Telefonschablone
    var entry_id;
    function delete_entry(entry) {
      entry.tooltip('hide');
      entry_id = entry.data('entry-id');
      $('span[id=name]').html(entry.data('name'));
      $('span[id=vendor]').html(entry.data('vendor'));
      $('span[id=model]').html(entry.data('model'));
      if ( entry.data('count') > 0 ) {
        $('div[id=used]').show();
        $('div[id=notused]').hide();
        $('span[id=count]').html(entry.data('count'));
      } else {
        $('div[id=used]').hide();
        $('div[id=notused]').show();
        if ( entry.data('is-hd') ) {
          $('div[id=hotdesking]').show();
          $('div[id=normalusage]').hide();
        } else {
          $('div[id=hotdesking]').hide();
          $('div[id=normalusage]').show();
        }
      }
      $('#phonetemplate_destroy').modal();
    }

    /* Löschen tatsächlich ausführen */
    $('#delete-button').on('click', function() {
      $('#delete-form').attr('action', '/admin/phonetemplates/' + entry_id);
      $('#delete-form').submit();
    });

    // Kopieren einer Schablone
    function copy_entry(entry) {
      entry.tooltip('hide');
      entry_id = entry.data('entry-id');
      $('span[id=name]').html(entry.data('name'));
      $('span[id=vendor]').html(entry.data('vendor'));
      $('span[id=model]').html(entry.data('model'));
      $('#phonetemplate_copy').modal();
    }

    /* Kopieren ausführen */
    $('#phonetemplate_copy #copy-button').on('click', function() {
      var vals = {};
      vals['what'] = 'from_templ';
      vals['src_id'] = entry_id;
      vals['dst_name'] = $('#phonetemplate_copy #new_name').val();
      $.post("/admin/phonetemplates/ajax_update", vals, function(data, status) {
        console.log({data});
        if ( data.error ) {
          stop_spinner_popup();
          $('#phonetemplate_copy #errorExplanation').html("<h2>" + data.error.title + "</h2>" + data.error.msg).show();
        } else {
          $('#phonetemplate_copy').modal('hide');
          window.location = '/admin/phonetemplates';
        }
      });
    });

    /* Kopie einer Telefonkonfiguation als Schablone übernehmen */
    $('#phonetemplate_phonecopy #copy-button').on('click', function() {
      var vals = {};
      vals['what'] = 'from_phone';
      vals['src_id'] = $('#phonetemplate_phonecopy #phones option:selected').val();
      vals['dst_name'] = $('#phonetemplate_phonecopy #new_name').val();
      console.log({vals});
      $.post("/admin/phonetemplates/ajax_update", vals, function(data, status) {
        console.log({data});
        if ( data.error ) {
          stop_spinner_popup();
          $('#phonetemplate_phonecopy #errorExplanation').html("<h2>" + data.error.title + "</h2>" + data.error.msg).show();
        } else {
          $('#phonetemplate_copy').modal('hide');
          window.location = '/admin/phonetemplates';
        }
      });
    });

  }

  if ( $('#phonetemplate_edit_title').length > 0 ) {
    
    /* Auf der Edit-Seite */
    $('#h1-enable').css('cursor', 'pointer');
    block_enable(1);

    // Tastenerweiterungen: Änderungen speichern?
    $('button[name=config_ext]').on('click', function() {
      var config = PhoneFunctions.get_configuration();
      if ( PhoneFunctions.check4changes() ) {
        $('#save_changes-form #save-button').on('click', function() {
          $('<input />').attr('type', 'hidden').attr('name', 'save_changes').attr('value', 'true').appendTo('form#phonetemplate_form');
          $('#save_changes-form').modal('hide');
        });
        $('#save_changes-form').modal();
        $('#save_changes-form').on('hidden.bs.modal', function() {
          $('<input />').attr('type', 'hidden').attr('name', 'config_ext').attr('value', 'Konifg.').appendTo('form#phonetemplate_form');
          $('input[type=hidden][id=phonesettings]').val(JSON.stringify(config));
          $('form#phonetemplate_form').submit();
        });
      } else {
        $('<input />').attr('type', 'hidden')
          .attr('name', 'config_ext')
          .attr('value', 'Konifg.')
          .appendTo('form#phonetemplate_form');
        $('input[type=hidden][id=phonesettings]').val(JSON.stringify(config));
        $('form#phonetemplate_form').submit();
      }
    });

    // Klingelton einstellen: Modal öffnen
    $('button[name=phone_ringer_button]').on('click', function() {
      $('#phone-ringer-form').modal();
    });
  }

});

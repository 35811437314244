import { activate_tables, url_get_id, start_spinner_popup, stop_spinner_popup } from '../iptam.js';

$(document).ready(function() {

  if ( $('#open-ext-column-dialog').length ) {

    /* Auf der Index-Seite */
    var entry_id;
    var table;
    function delete_entry(id) {
      start_spinner_popup();
      entry_id = id;
      $.get('/admin/extensions/' + entry_id + '/get_data4delete', function(data, status) {
        stop_spinner_popup();
        if ( data['error'] ) {
          $('.iptam-main').prepend("<div class='alert alert-danger' role='alert'>" + data['error'] + "</div>");
        } else {
          $('#user_name').html(data['user_name']);
          $('#user_name_nat').html(data['user_name_nat']);
          $('#exten_account').html(data['exten_account']);
          $('#exten_exten').html(data['exten_exten']);
          if ( data['exten_is_synced'] ) {
            $('#is_synced').show();
          } else {
            $('#is_synced').hide();
          }
          if ( data['user_faxexten'].length ) {
            $('#user_has_fax').show();
            $('#user_faxexten').html(data['user_faxexten']);
          }
          $('#extension_destroy').modal();
        }
      });
    };

    if ( $('#extension-table').data('ajax-load') == true ) {
      table = activate_tables('#extension-table', false, [-2, -1], true, '/admin/extensions/get_table');
    } else {
      table = activate_tables('#extension-table', false, [-2, -1]);
      $('.delete_entry').on('click', function() {
        delete_entry($(this).data('entry-id'));
      });
    }

    var format;
    $('#open-ext-print-dialog').on('click', function (e) {
      $('#ext-print-dialog').modal();
    });

    $('#ext-print-dialog #print_format').on('change', function() {
      format = this.value;
      if ( this.value == 'a4' ) {
        $('.landscape').hide();
        $('.portrait').show();
      } else {
        $('.portrait').hide();
        $('.landscape').show();
      }
    });

    format = $('select[id=print_format] option').filter(':selected').val();
    if ( format == 'a4' ) {
      $('.landscape').hide();
      $('.portrait').show();
    } else {
      $('.portrait').hide();
      $('.landscape').show();
    }

    $('#ext-print-dialog #use_logo').on('change', function() {
      if ( $('#ext-print-dialog #use_logo').prop("checked") )
        $('.use-logo').show();
      else
        $('.use-logo').hide();
    });
    if ( $('#ext-print-dialog #use_logo').prop("checked") )
      $('.use-logo').show();
    else
      $('.use-logo').hide();

    $('#ext-print-dialog #save-button').on('click', function() {
      var form_data = new FormData(jQuery('#ext-print-form')[0]);
      form_data.append('print_format', format);
      form_data.append('columns', $('#ext-print-dialog input[type=radio][name=columns]:checked').val());
      if ( $('#ext-print-dialog select#location').length ) {
        form_data.append('location', $('#ext-print-dialog select[id=location] option').filter(':selected').val());
      }
      start_spinner_popup();
      $('#ext-print-dialog').modal('hide');
      $.ajax({
        url: "/admin/extensions/print_pdf",
        method: 'POST',
        data: form_data,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          console.log({data});
          stop_spinner_popup();
          if ( data.download ) {
            window.location = '/admin/extensions/download?fn=' + data.filename;
          } else {
            $('#ext-print-dialog').modal('hide');
          }
        }
      });
    });

    $('span.toggle-vis').css('cursor', 'pointer');
    $('span.toggle-vis').on('click', function (e) {
      e.preventDefault();

      // Get the column API object
      var column = table.column( $(this).attr('data-column') );

      // Toggle the visibility
      column.visible( ! column.visible() );
      if ( column.visible() ) {
        $(this).addClass("col-visible");
        $(this).removeClass("col-invisible");
      } else {
        $(this).addClass("col-invisible");
        $(this).removeClass("col-visible");
      }
    });

    table.on( 'draw', function () {
//       $('.alert').remove();
      $('.delete_entry').on('click', function() {
        delete_entry($(this).data('entry-id'));
      });
    });

    /* Löschen tatsächlich ausführen */
    $('#delete-button').on('click', function() {
      $('#delete-form').attr('action', '/admin/extensions/' + entry_id);
      $('#delete-form').submit();
    });

    $('#open-ext-column-dialog').on('click', function (e) {
      $('#ext-column-dialog').modal();
    });

  }

  if ( $('#extension_edit_basic_title').length ) {

    $('#show_sync_hint').click(function() {
      $('#extension_sync_hint').modal();
    });

    $('#showsippw').click(function (event) {
      if ( $('#sippw').is(":hidden") ) {
        $('#sippw').text($('#showsippw').data('pw'));
        $('#showsippw').text("Passwort verbergen");
        $('#sippw').show();
      } else {
        $('#showsippw').text("Aktuelles Passwort anzeigen");
        $('#sippw').hide();
      }
    });

    $('#picture_file').on('change', function() {
      var id = url_get_id('form[action^="/admin/extensions/"]', -2);
      console.log('ID: ' + id);
      var formData = new FormData(jQuery('form[action^="/admin/extensions/"]')[0]);
      start_spinner_popup();
      $.ajax({
        url: '/admin/extensions/' + id + '/photo_upload',
        method: 'POST',
        data: formData,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          console.log({data});
          if ( Array.isArray(data) ) {
            var path = data[0];
            var hash = data[1];
            $('img.img-thumbnail').attr('src','/pictures/' + path + '.png');
            $('#extension_picture').val(hash + ';' + path);
          }
          stop_spinner_popup();
        }
      });
    });

    $('.delete_pic').on('click', function() {
      $(this).tooltip('hide');
      $('img.img-thumbnail').attr('src','');
      $('#extension_picture').val('');
    });

  }

  if ( $('#extension_edit_title').length ) {

    $('#extension_ext_mode').change(function(e) {
      var v = $(this).val();
      if ( v == 'phone' ) {
        $('.mode_fax').hide();
        $('.mode_door').hide();
        $('.mode_phone').show();
        if ( $('input[type=radio][name="user[ctperm]"]:checked').val() == 'true' ) {
          $('#row_ctauthnum').show();
        } else {
          $('#row_ctauthnum').hide();
        }
      } else if ( v == 'fax' ) {
        $('.mode_phone').hide();
        $('.mode_door').hide();
        $('.mode_fax').show();
      } else if ( v == 'door' ) {
        $('.mode_fax').hide();
        $('.mode_phone').hide();
        $('.mode_door').show();
      }
      if ( $('#extension_clip_mode > option:selected').val() != -1 )
        $('#clip_manuell').hide();
    });

    var v = $('#extension_door_cam').val();
    $('#test_connect_url').attr('src', v);
    $('#extension_door_cam').change(function(e) {
      var v = $(this).val();
      $('#test_connect_url').attr('src', v);
    });

    if ( $('input[type=radio][name="user[ctperm]"]:checked').length == 0 ) {
      $('input[type=radio][name="user[ctperm]"][value=false]').prop('checked', true);
    }

    $('input[type=radio][name="user[ctperm]"]').change(function() {
      if ( $(this).val() == 'true' )
        $('#row_ctauthnum').show();
      else
        $('#row_ctauthnum').hide();
    });

    $('#extension_ext_mode').change();

    $('#extension_clip_mode').change(function(e){
      if ( $(this).val() == -1 )
        $('#clip_manuell').show();
      else
        $('#clip_manuell').hide();
    });

    $('button[type=button][name=conf_provider]').on('click', function() {
      var inbound_sip = $("input[type=hidden][id=provider_id]").val();
      $("#select_provider_account-form option[value='" + inbound_sip + "']").prop('selected', true);
      $("#select_provider_account-form").modal();
    });
    
    $('#select_provider_account-form #save-button').on('click', function() {
      var option = $('#select_provider_account-form #siptrunkacc_id option:selected');
      $("input[type=hidden][id=provider_id]").val(option.val());
      if ( option.parent().attr('label') )
        $('#provider_account').text(option.parent().attr('label') + ": " + option.text());
      else
        $('#provider_account').text("");
      $("#extension_siptrunkacc_id option[value='" + option.val() + "']").prop('selected', true);
      $('#select_provider_account-form').modal('hide');
    });

    $('#alt_callerid').autocomplete( {
      source: function (request, response) {
        $.get('/misc/ajax/user_lookupjs?search=' + request.term, function(data) {
          console.log({data});
          var result = []
          for ( var i=0; i<data.length; i++ ) {
            var name = data[i].sur_name + ', ' + data[i].gv_name;
            var label = name + ' [' + data[i].exten + ']';
            var ext_id = data[i].ext_id;
            result.push({value: data[i].exten, label: label, location: data[i].location, name: name, ext_id: ext_id});
          }
          response(result);
        })
      },
      select: function (event, ui) {
        $('#alt_callerid_name').text(ui.item.label);
        $('#extension_alt_ext_id').val(ui.item.ext_id);
      },
    });

  }

  if ( $('#extension_new_title').length ) {

    $('#picture_file').on('change', function() {
      var formData = new FormData(jQuery('form[action="/admin/extensions"]')[0]);
      start_spinner_popup();
      $.ajax({
        url: '/admin/extensions/0/photo_upload',
        method: 'POST',
        data: formData,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          console.log({data});
          if ( Array.isArray(data) ) {
            var path = data[0];
            var hash = data[1];
            $('img.img-thumbnail').attr('src','/pictures/' + path + '.png');
            $('#extension_picture').val(hash + ';' + path);
          }
          stop_spinner_popup();
        }
      });
    });

    $('.delete_pic').on('click', function() {
      $(this).tooltip('hide');
      $('img.img-thumbnail').attr('src','');
      $('#extension_picture').val('');
    });

  }

});

jQuery(document).ready(function() {

  if ( $('#jitsi_dialins_title').length ) {
    if ( $('input[type=radio][name="jitsi_dialin[use_iptam_mapper]"]:checked').val() == 'true' ) {
      $(".external_mapper").hide();
    } else {
      $(".external_mapper").show();
    }
    $('input[type=radio][name="jitsi_dialin[use_iptam_mapper]"]').on('change', function() {
      if ( this.value == "true" ) {
        $(".external_mapper").hide();
      } else {
        $(".external_mapper").show();
      }
    });

  }
  
});

var PhoneYealink_t48 = (function(modul) {

  function init(pdata, funcs) {
    console.log("Telefon " + pdata.vendor + " " + pdata.model);
    var layer = 1;
    // Tastenbreite ermitteln (Unter Display) Tasten-ID und Anzahl
    funcs.set_softkeywidth('#pkey1', 4);
    funcs.set_softkeywidth2('.hardkeys', '#pkey1', 2);
    // Initiales setzen, eigene Nebenstelle, Tasten mit BLF-Verschiebung
    funcs.set_initial_data(pdata.own_exten, {skey: [1,29]});

    // Funktionstasten (links und rechts neben dem Display)
    var acc = function(drag) {
      return ['f_reg', 'f_menuint', 'f_state'].indexOf(drag.attr('id')) < 0;
    }
    for (var i = 1; i <= 29; i++) {
      $('#skey'+i).droppable({
        accept: acc,
        hoverClass: 'highlight-target',
        drop: function(event, ui) {
          if ( ui.draggable.attr('id') == "f_line" ) {
            funcs.set_line($(this), true);
          } else if ( ui.draggable.attr('id') == "f_blf" ) {
            funcs.set_blf($(this), true);
          } else if ( ui.draggable.attr('id') == "f_direct" ) {
            funcs.set_direct($(this), true);
          } else if ( ui.draggable.attr('id') == "f_cfwdgrp" ) {
            funcs.set_cfwdgrp($(this), true);
          } else if ( ui.draggable.attr('id') == "f_call-lists" ) {
            funcs.set_call_lists($(this), true);
          } else if ( ui.draggable.attr('id') == "f_agent" ) {
            funcs.set_agent($(this), true);
          } else if ( ui.draggable.attr('id') == "f_icom" ) {
            funcs.set_icom($(this), true);
          } else if ( ui.draggable.attr('id') == "f_dir" ) {
            funcs.set_directory($(this), true);
          } else if ( ui.draggable.attr('id') == "f_dtmf" ) {
            funcs.set_dtmf($(this), true);
          } else if ( ui.draggable.attr('id') == "f_profileled" ) {
            funcs.set_profileled($(this), true);
          } else if ( ui.draggable.attr('id') == "f_action" ) {
            funcs.set_action_url($(this), true);
          } else if ( ui.draggable.attr('id') == "f_nconfig" || ui.draggable.attr('id') == "f_noop" ) {
            funcs.set_keyfunc($(this), $(ui.draggable), false);
          } else {
            funcs.set_keyfunc($(this), $(ui.draggable), true);
          }
        }
      });
    }

    // Funktionstasten unter dem Display
    var acc = function(drag) {
      return ['f_line', 'f_blf', 'f_agent', 'f_dtmf', 'f_cfwdgrp', 'f_rec', 'f_profileled'].indexOf(drag.attr('id')) < 0;
    }
    for (var i = 1; i <= 4; i++) {
      $('#pkey'+i).droppable({
        accept: acc,
        hoverClass: 'highlight-target',
        drop: function(event, ui) {
          if ( ui.draggable.attr('id') == "f_direct" ) {
            funcs.set_direct($(this), true);
          } else if ( ui.draggable.attr('id') == "f_call-lists" ) {
            funcs.set_call_lists($(this), true);
          } else if ( ui.draggable.attr('id') == "f_icom" ) {
            funcs.set_icom($(this), true);
          } else if ( ui.draggable.attr('id') == "f_dir" ) {
            funcs.set_directory($(this), true);
          } else if ( ui.draggable.attr('id') == "f_action" ) {
            funcs.set_action_url($(this), true);
          } else if ( ui.draggable.attr('id') == "f_nconfig" || ui.draggable.attr('id') == "f_noop" ) {
            funcs.set_keyfunc($(this), $(ui.draggable), false);
          } else {
            funcs.set_keyfunc($(this), $(ui.draggable), true);
          }
        }
      });
    }

    // Weitere Funktionstasten
    var acc = function(drag) {
      return ['f_line', 'f_blf', 'f_agent', 'f_dtmf', 'f_cfwdgrp', 'f_rec', 'f_pickup', 'f_profileled'].indexOf(drag.attr('id')) < 0;
    }
    for (var i = 5; i <= 18; i++) {
      $('#pkey'+i).droppable({
        accept: acc,
        hoverClass: 'highlight-target',
        drop: function(event, ui) {
          if ( ui.draggable.attr('id') == "f_direct" ) {
            funcs.set_direct($(this));
          } else if ( ui.draggable.attr('id') == "f_call-lists" ) {
            funcs.set_call_lists($(this));
          } else if ( ui.draggable.attr('id') == "f_icom" ) {
            funcs.set_icom($(this));
          } else if ( ui.draggable.attr('id') == "f_dir" ) {
            funcs.set_directory($(this));
          } else if ( ui.draggable.attr('id') == "f_action" ) {
            funcs.set_action_url($(this));
          } else if ( ui.draggable.attr('id') == "f_nconfig" || ui.draggable.attr('id') == "f_noop" ) {
            funcs.set_keyfunc($(this), $(ui.draggable), false);
          } else {
            funcs.set_keyfunc($(this), $(ui.draggable));
          }
        }
      });
    }

    if ( funcs.check_layer_usage('skey', 11, 27) ) {
      $('.rightkey #skey10').attr('id', 'skey0');
      funcs.reset_key_function('#skey0');
      $('#skey0').droppable('disable');
      $('#skey0').text(funcs.limit_field_width('Ebene wechseln'));
      $('#skey0 + .skey-label').hide();
    }

    $('#show-bg-dialog').on('click', function() {
      funcs.set_phone_background();
    });

    $('#display-time-control').on('click', function() {
      funcs.show_display_yealink_tc();
    });

    $('#border-led').on('click', function() {
      funcs.change_border_led();
    });

    $('span#display').on('click', function() {
      funcs.click_change_text('display', 4, 'phone-name-texts');
    });

    $('#datetime').on('click', function() {
      funcs.click_change_text('datetime', 4, 'yealink-datetime-texts');
    });

  };

  function do_submit(funcs) {
    console.log("Submit");
    funcs.do_submit();
  };

  return {
    init: init,
    do_submit: do_submit
  };
})(PhoneYealink_t48 || {});
window.PhoneYealink_t48 = PhoneYealink_t48;

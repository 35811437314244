import { start_spinner_popup, activate_filestyles } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#security_title').length ) {
    
    $('input[type=radio][name="system[cert_auth]"]').on('change', function() {
      $('form[action="/admin/security/update"]').append("<input type='hidden' name='change_cert_auth'>");
      $('form[action="/admin/security/update"]').submit();
    });

    $('#filename').on('change', function() {
      $('#upload-file-info').html(this.files[0].name);
    });

    $('#int_cert_load').on('change', function() {
      $('#int_cert-info').html(this.files[0].name);
    });

    var button_is_download = false;
    $('button[type=submit][name^=download]').on('click', function() {
      button_is_download = true;
    });

    $('form[action="/admin/security/update"]').on('submit', function(e) {
      if ( !button_is_download )
        start_spinner_popup();
    });

    $('#system_sip_encryption').on('change', function() {
      if ( $(this).prop('checked') ) {
        $('#select_default_sip_enc').modal();
      }
    });

    $('#select_default_sip_enc #default_sip_enc-save-button').on('click', function() {
      var value = $('#default_sip_enc').prop('checked');
      $('#default_sip_enc_extensions').val(value ? "1" : "0");
      $('#select_default_sip_enc').modal('hide');
    });
  }

});

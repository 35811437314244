export function check_heading(h) {
  var entry = {};
  $("#base_data > option").each(function() {
    if ( this.text == h ) {
      entry.base_data = this.value;
      return;
    }
  });
  $("#phonenumber_select > option").each(function() {
    if ( this.text == h ) {
      entry.phonenumber_select = this.value;
      entry.phone_text = this.text;
      return;
    }
  });
  return entry;
}

export function csv_table_column_selection (cols, hdr) {
  $('#check_result_value').html("<table class='table table-bordered table-sm compact'>" + cols + hdr + "</table>");
  $('#check_result_value td').css('cursor', 'pointer');
  $('#check_result_value td').on('click', function() {
    dir_col_index = $(this).data("index");
    $('#base_data').val($(this).data("base_data"));
    $('#phonenumber_select').val($(this).data("phonenumber_select"));
    $('#type_long').val($(this).data("type_long"));
    $('#type_short').val($(this).data("type_short"));
    var numberformat = $(this).data("numberformat");
    if ( numberformat !== undefined ) {
      $('#numberformat_'+ numberformat).prop('checked', true);
      $('input[type=radio][name=numberformat]').prop('disabled', false);                    
    } else {
      $('input[type=radio][name=numberformat]').prop('checked', false);
      if ( $(this).data("type_long") && $(this).data("type_long").length > 0 )
        $('input[type=radio][name=numberformat]').prop('disabled', false);
      else
        $('input[type=radio][name=numberformat]').prop('disabled', true);                    
    }
    $('#csv_import-form').modal();
  });                
  $('.check_result_csv').show();
}

jQuery(document).ready(function() {

  if ( $('#dir_action').length || $('#user_directory_import_title').length ) {

    /* Import-File: Dateityp (CSV/vCard) ändern */
    $('input[type=radio][name="file_type"]').on('change', function() {
      $('.data_valid').hide();
      $('.check_result_csv').hide();
      $('.check_result_vcard').hide();
      $('.newvc').remove();
      $('#check_result_error').hide();
      $('#error_msg').html("");
      $('input[type=file][name="filename"]').filestyle('clear');
      if ( $('input[type=radio][name="file_type"]:checked').val() == "csv") {
        $('.file_is_csv').show();
        $('.file_type_selected').show();
      } else {
        $('.file_is_csv').hide();
        if ( $('input[type=radio][name="file_type"]:checked').val() == "vcard") {
          $('.file_type_selected').show();
        } else {
          $('.file_type_selected').hide();
        }
      }
    });
    $('input[type=radio][name="file_type"]').change();

    /* Import-File: Datei Auswahlbox geändert */
    $('input[type=file][name="filename"]').on('change', function() {
      $('.check_result_csv').hide();
      $('.check_result_vcard').hide();
      $('.newvc').remove();
      $('#check_result_error').hide();
      $('#error_msg').html("");
    });

    /* Speichern im Modal-Dialog: CSV Spalten Zuordnung */
    $('#csv_import-form #save-button').on('click', function() {
      var base_data = $('#base_data option:selected').val();
      var base_text = $('#base_data option:selected').text();
      var phonenumber_select = $('#phonenumber_select option:selected').val();
      var phone_text = $('#phonenumber_select option:selected').text();
      var type_long = $('#type_long').val();
      var type_short = $('#type_short').val();
      var numberformat = $('input[type=radio][name="numberformat"]:checked').val();
      console.log("Index " + dir_col_index + ": base " + base_data + ", " + phonenumber_select + ", long " + type_long + ", short " + type_short);
      if ( (phonenumber_select || type_long || type_short) && !numberformat ) {
        console.log("Rufnummer ohne Format");
        $('#hint_empty_format').css('color', 'red');
        return;
      }
      $('td[data-index="' + dir_col_index + '"]').data("base_data", "");
      $('td[data-index="' + dir_col_index + '"]').data("phonenumber_select", "");
      $('td[data-index="' + dir_col_index + '"]').data("type_long", "");
      $('td[data-index="' + dir_col_index + '"]').data("type_short", "");
      if ( numberformat ) {
        console.log("Format " + numberformat);
        $('td[data-index="' + dir_col_index + '"]').data("numberformat", numberformat).css('color', 'black');
      }
      if ( base_data ) {
        console.log("Setze Base");
        $('td[data-index="' + dir_col_index + '"]').data("base_data", base_data);
        $('th[data-index="' + dir_col_index + '"]').text(base_text);
      } else if ( phonenumber_select ) {
        console.log("Setze Phone");
        $('td[data-index="' + dir_col_index + '"]').data("phonenumber_select", phonenumber_select);
        $('th[data-index="' + dir_col_index + '"]').text(phone_text + " (" + phonenumber_select + ")");
        $('td[data-index="' + dir_col_index + '"]').data("type_long", phone_text);
        $('td[data-index="' + dir_col_index + '"]').data("type_short", phonenumber_select);
      } else if ( type_long || type_short ) {
        console.log("Setze Long");
        $('td[data-index="' + dir_col_index + '"]').data("type_long", type_long);
        $('th[data-index="' + dir_col_index + '"]').text(type_long + " (" + type_short + ")");
        $('td[data-index="' + dir_col_index + '"]').data("type_short", type_short);
      } else {
        console.log("Setze Unbenutzt");
        $('th[data-index="' + dir_col_index + '"]').text("Keine Auswahl");
      }
      $('#csv_import-form').modal('hide');
    });

    /* Ändern Kontaktdaten: Rücksetzen Rufnummer und Rufnummerntypen */
    $('#base_data').on('change', function() {
      $('#hint_empty_format').css('color', 'black');
      $('#phonenumber_select').val("");
      $('#type_long').val("");
      $('#type_short').val("");
      if ( $(this).val() ) {
        console.log("Keine Rufnummer");
        $('input[type=radio][name=numberformat]').prop('checked', false);
        $('input[type=radio][name=numberformat]').prop('disabled', true);
      }
    });

    /* Ändern Rufnummer: Rücksetzen Kontaktdaten und Rufnummerntypen */
    $('#phonenumber_select').on('change', function() {
      $('#hint_empty_format').css('color', 'black');
      $('#base_data').val("");
      $('#type_long').val("");
      $('#type_short').val("");
      if ( $(this).val() ) {
        console.log("Rufnummer");
        $('input[type=radio][name=numberformat]').prop('disabled', false);
      }
    });

    /* Ändern Langform: Rücksetzen Kontaktdaten, Rufnummer und Rufnummerntypen */
    $('#type_long').on('change', function() {
      $('#hint_empty_format').css('color', 'black');
      $('#base_data').val("");
      $('#phonenumber_select').val("");
      if ( $(this).val() ) {
        console.log("Rufnummer");
        $('input[type=radio][name=numberformat]').prop('disabled', false);
      }
    });

    /* Ändern Kurzform: Rücksetzen Kontaktdaten, Rufnummer und Rufnummerntypen */
    $('#type_short').on('change', function() {
      $('#hint_empty_format').css('color', 'black');
      $('#base_data').val("");
      $('#phonenumber_select').val("");      
      if ( $(this).val() ) {
        console.log("Rufnummer");
        $('input[type=radio][name=numberformat]').prop('disabled', false);
      }
    });

    /* Ändern Rufnummernformats: Rücksetzen der Warnung */
    $('input[type=radio][name="numberformat"]').on('change', function() {
      $('#hint_empty_format').css('color', 'black');
    });

    $('#csv_import-form').on('hidden.bs.modal', function() {
      $('#hint_empty_format').css('color', 'black');
    });

    /* Import-File: Löschen von vCard Attribut Zuordnungen */
    $('.delete_entry').tooltip();
    $('.delete_entry').on('click', function() {
      vc_card_del_idx = $(this).data('entry-id');
      $('#vcard_attr_destroy #vc').text($(this).data('name'));
      $('#vcard_attr_destroy #long').text($('#vc_long_'+vc_card_del_idx).val());
      $('#vcard_attr_destroy').modal();
    });

  }
});

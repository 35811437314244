import { activate_tables, start_spinner_popup, url_get_id } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#moh_title').length ) {
    var table = activate_tables('#moh-table', false, [-1]);

    table.on( 'draw', function () {
      $('.alert').remove();
      $('.delete_entry').on('click', function() {
        delete_entry($(this));
      });
    });

    $('.delete_entry').on('click', function() {
      delete_entry($(this));
    });

    // Löschen einer Wartemusik
    var entry_id;
    function delete_entry(entry) {
      entry.tooltip('hide');
      entry_id = entry.data('entry-id');
      $('span[id=name]').html(entry.data('name'));
      $('#moh_destroy').modal();
    };

    $('#moh_destroy #delete-button').on('click', function() {
      $('#moh_destroy #delete-form').attr('action', '/admin/musicclasses/' + entry_id);
      $('#moh_destroy #delete-form').submit();
    });
  }

  if ( $('#moh_edit_title').length ) {

    var musicclasses_set_event_handler = function() {

      $('#add').on('click', function() {
        start_spinner_popup();
        var form_data = new FormData(jQuery('#moh-upload-form')[0]);
        form_data.append('what', 'upload');
        var entry_id = url_get_id('form[action^="/admin/musicclasses/');
        $.ajax({
          url: '/admin/musicclasses/' + entry_id + '/ajax_update',
          method: 'POST',
          data: form_data,
          processData: false,  // tell jQuery not to process the data
          contentType: false,  // tell jQuery not to set contentType
          success: function(data) {
          }
        });
      });

      $('#del').on('click', function() {
        start_spinner_popup();
        var str = "";
        var vals = {};
        vals['what'] = "delete"
        $('select[name="musiclist[]"]').find(":selected").each(function() {
          str += $( this ).text() + ";";
        });
        vals['selected-files'] = str;
        var entry_id = url_get_id('form[action^="/admin/musicclasses/');
        $.post('/admin/musicclasses/' + entry_id + '/ajax_update', vals, function(data, status) {
        });
      });
    }
    musicclasses_set_event_handler();
    window.musicclasses_set_event_handler = musicclasses_set_event_handler;
  }

});


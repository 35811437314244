import { activate_tables } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#nonbusiness-table').length ) {
    var table = activate_tables('#nonbusiness-table', false, [-1]);

    table.on( 'draw', function () {
      $('.alert').remove();
      $('.delete_entry').on('click', function() {
        delete_entry($(this));
      });
    });
    
    $('.delete_entry').on('click', function() {
      delete_entry($(this));
    });
    
    // Löschen eines Feiertags
    var entry_id;
    function delete_entry(entry) {
      entry.tooltip('hide');
      entry_id = entry.data('entry-id');
      $('span[id=name]').html(entry.data('name'));
      $('span[id=date]').html(entry.data('date'));
      $('#nonbusiness_day_destroy').modal();
    }

    $('#nonbusiness_day_destroy #delete-button').on('click', function() {
      $('#nonbusiness_day_destroy #delete-form').attr('action', '/admin/nonbusiness_days/' + entry_id);
      $('#nonbusiness_day_destroy #delete-form').submit();
    });

  }

  if ( $('#nonbusinesday_title').length ) {
    $('.datepicker').datepicker({
      todayHighlight: true,
    });
  }

  if ( $('#holidays-table').length ) {

    /* Eine Feiertag wurde aktiviert/deaktiviert */
    function change_selection(elem) {
      var e = elem.attr('id').split('_');
      var entry = e[1];
      var ar = $('#holiday_list').val().split(',');
      if ( elem.prop('checked') ) {
        /* Feiertag aktivieren */
        var found = false;
        for ( var i=0; i < ar.length; i++ ) {
          if ( ar[i] == entry )
            found = true;
        }
        if ( !found )
          ar.push(entry);
      } else {
        /* Feiertag deaktivieren */
        var idx = -1;
        for ( var i=0; i < ar.length; i++ ) {
          if ( ar[i] == entry ) {
            idx = i;
            break;
          }
        }
        if ( idx >= 0 ) {
          ar.splice(idx, 1);
        }
      }
      $('#holiday_list').val(ar.join(','));
    }

    var table = activate_tables('#holidays-table', false, [0, -1]);
    
    $('.holiday-entry').on('change', function() {
      change_selection($(this));
    });
    
    table.on('draw', function() {
      $('.holiday-entry').on('change', function() {
        change_selection($(this));
      });
    });

  };

});

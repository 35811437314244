jQuery(document).ready(function() {

  if ( $('#user_mobility-title').length ) {

    $('a[data-id=create_new_pw]').click(function() {
      $.get("/user/mobility/create_new_pw", function(data, status) {
        $('#new_pw').text(data);
      })
      $('#show_new_pw').show();
    });

    $('#dismiss_new_pw').click(function() {
      $('#show_new_pw').hide();
    });

    $('#save_new_pw').click(function() {
      $.get("/user/mobility/save_new_pw", function(data, status) {
        $('#current_pw').text(data);
        var d = new Date();
        $('#qrimage').attr('src', "/user/mobility/qr?t=" + d.getMilliseconds());
        $('#show_new_pw').hide();
      });
    });
  }
  
});

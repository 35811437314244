var PhoneYealink_t38 = (function(modul) {

  function init(pdata, funcs) {
    console.log("Telefon " + pdata.vendor + " " + pdata.model);
    // Tastenbreite ermitteln (Unter Display) Tasten-ID und Anzahl
    funcs.set_softkeywidth('#pkey1', 4);
    // Initiales setzen, eigene Nebenstelle, Tasten mit BLF-Verschiebung
    funcs.set_initial_data(pdata.own_exten, {mkey: [1,13]});

    // Tasten unter dem Bildschirm
    var acc = function(drag) {
      return ['f_line', 'f_blf', 'f_direct', 'f_agent', 'f_dtmf', 'f_cfwdgrp', 'f_profileled'].indexOf(drag.attr('id')) < 0;
    }
    for (var i = 1; i <= 4; i++) {
      $('#pkey'+i).droppable({
        accept: acc,
        hoverClass: 'highlight-target',
        drop: function(event, ui) {
          if ( ui.draggable.attr('id') == "f_direct" ) {
            funcs.set_direct($(this), true);
          } else if ( ui.draggable.attr('id') == "f_call-lists" ) {
            funcs.set_call_lists($(this), true);
          } else if ( ui.draggable.attr('id') == "f_icom" ) {
            funcs.set_icom($(this), true);
          } else if ( ui.draggable.attr('id') == "f_dir" ) {
            funcs.set_directory($(this), true);
          } else if ( ui.draggable.attr('id') == "f_action" ) {
            funcs.set_action_url($(this), true);
          } else if ( ui.draggable.attr('id') == "f_nconfig" || ui.draggable.attr('id') == "f_noop" ) {
            funcs.set_keyfunc($(this), $(ui.draggable), false);
          } else {
            funcs.set_keyfunc($(this), $(ui.draggable), true);
          }
        }
      });
    }

   // Funktionstasten
    var acc = function(drag) {
      return ['f_dirint', 'f_reg', 'f_menuint'].indexOf(drag.attr('id')) < 0;
    }
    for (var i = 1; i <= 16; i++) {
      $('#mkey'+i).droppable({
        accept: acc,
        hoverClass: 'highlight-target',
        drop: function(event, ui) {
          var key_id = parseInt($(this).attr('id').substr(4));
          var label = key_id >= 11;
          if ( ui.draggable.attr('id') == "f_line" ) {
            funcs.set_line($(this), label);
          } else if ( ui.draggable.attr('id') == "f_blf" ) {
            funcs.set_blf($(this), label);
          } else if ( ui.draggable.attr('id') == "f_direct" ) {
            funcs.set_direct($(this), label);
          } else if ( ui.draggable.attr('id') == "f_cfwdgrp" ) {
            funcs.set_cfwdgrp($(this), label);
          } else if ( ui.draggable.attr('id') == "f_call-lists" ) {
            funcs.set_call_lists($(this), label);
          } else if ( ui.draggable.attr('id') == "f_agent" ) {
            funcs.set_agent($(this), label);
          } else if ( ui.draggable.attr('id') == "f_icom" ) {
            funcs.set_icom($(this), label);
          } else if ( ui.draggable.attr('id') == "f_dir" ) {
            funcs.set_directory($(this), label);
          } else if ( ui.draggable.attr('id') == "f_dtmf" ) {
            funcs.set_dtmf($(this), label);
          } else if ( ui.draggable.attr('id') == "f_profileled" ) {
            funcs.set_profileled($(this), label);
          } else if ( ui.draggable.attr('id') == "f_action" ) {
            funcs.set_action_url($(this), label);
          } else if ( ui.draggable.attr('id') == "f_nconfig" || ui.draggable.attr('id') == "f_noop" ) {
            funcs.set_keyfunc($(this), $(ui.draggable), false);
          } else {
            funcs.set_keyfunc($(this), $(ui.draggable), label);
          }
        }
      });
    }

    $('#show-bg-dialog').on('click', function() {
      funcs.set_phone_background();
    });

    $('span#display').on('click', function() {
      funcs.click_change_text('display', 4, 'phone-name-texts');
    });

    $('#datetime').on('click', function() {
      funcs.click_change_text('datetime', 4, 'yealink-datetime-texts');
    });

  };

  function do_submit(funcs) {
    console.log("Submit");
    funcs.do_submit();
  };

  return {
    init: init,
    do_submit: do_submit
  };
})(PhoneYealink_t38 || {});
window.PhoneYealink_t38 = PhoneYealink_t38;

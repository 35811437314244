jQuery(document).ready(function() {

  if ( $('#user_faxstatus-table').length ) {

    var table = activate_tables('#user_faxstatus-table', false, [-1]);

    table.on('draw', function () {
      set_event_handler();
    });
    set_event_handler();

    function set_event_handler() {
      $('.faxabort').on('click', function() {
        var vals = {};
        vals['what'] = 'abort-job';
        vals['id'] = $(this).data('jobid');
        start_spinner_popup();
        $.post("/user/fax/ajax_update", vals, function(data, status) {
          window.location = "/user/fax";
        });
      });

    }

  }
  

});

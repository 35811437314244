import { activate_tables } from '../iptam.js';

$(document).ready(function() {
  if ( $('#locations_title').length ) {

    /* Übersicht Orte */
    var table = activate_tables('#locations-table', false, [-1]);
    
    table.on( 'draw', function () {
      $('.alert').remove();
      $('.delete_entry').on('click', function() {
        delete_entry($(this));
      });
    });

    $('.delete_entry').on('click', function() {
      delete_entry($(this));
    });

    // Löschen einer Telefonschablone
    var entry_id;
    function delete_entry(entry) {
      entry.tooltip('hide');
      entry_id = entry.data('entry-id');
      $('#name').html(entry.data('name'));
      $('#room').html(entry.data('room'));
      $('#location_destroy').modal();
    };

    $('#delete-button').on('click', function() {
      $('#delete-form').attr('action', '/admin/locations/' + entry_id);
      $('#delete-form').submit();
    });
  }

});


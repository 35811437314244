jQuery(document).ready(function() {
  if ( $('#upgrade_lic_title').length ) {
    $('#upgrade_proxy').change(function() {
      if ( $('#upgrade_proxy').prop('checked') )
        $('.proxy_visible').show();
      else
        $('.proxy_visible').hide();
    });
    if ( $('#upgrade_proxy').prop('checked') ) {
      $('.proxy_visible').show();
    } else {
      $('.proxy_visible').hide();
    }
  }
});

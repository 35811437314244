import { activate_tables, url_get_id } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#isdn_title').length ) {
    activate_tables('#isdn-table', false, [-1]);

    /* Löschen eines Gateways: Sicherheitsabfrage */
    var card_id;
    $('.delete_entry').on('click', function() {
      $(this).tooltip('hide');
      $.get("isdns/delgw?mac=" + $(this).data('mac'), function(data) {
        $('#ipaddr').html(data.ipaddr);
        card_id = data.id;
        var body = $('#isdn_destroy').find('tbody');
        body.html("");
        var len = data.ports.length;
        for (var i=0; i < len; i++) {
          body.append("<tr><td>" + data.ports[i].portno + "</td><td>" + data.ports[i].type + "</td><td>" + data.ports[i].parameter + "</td></tr>");
        }
        $('#isdn_destroy').modal();
      });
    });

    /* Löschen eines Gateways ausführen */
    $('#delete-button').on('click', function() {
      var form = $('#isdn_destroy').find('form');
      form.find('#id').val(card_id);
      form.attr('action', '/admin/isdns/destroy').submit();
    });
  }

  // GSM
  if ( $('#isdn_gsm_title').length ) {
    $('#isdn_sms_support').change(function() {
      if ( $('#isdn_sms_support').prop('checked') )
        $('.sms-support').show();
      else
        $('.sms-support').hide();
    });
    if ( $('#isdn_sms_support').prop('checked') ) {
      $('.sms-support').show();
    } else {
      $('.sms-support').hide();
    }

    var vals = [];
    var serviceno = $('#isdn_gsm_serviceno').val();
    if ( serviceno ) {
      vals = serviceno.split(";");
    }
    for (var index = 0; index < vals.length; ++index) {
      if ( vals[index] )
        jQuery('<option>'+vals[index]+'</option>').appendTo('#gsm_serviceno');
    }
    $('#gsm_serviceno').attr('size', $('#gsm_serviceno > option').length);
    $('#gsm_serviceno > option').prop('selected', false);
    $('#add').click(function() {
      var v = $('#new_service').val();
      if ( v !== '' ) {
        jQuery('<option>'+v+'</option>').appendTo('#gsm_serviceno');
        $('#gsm_serviceno').attr('size', $('#gsm_serviceno > option').length);
        if ( $('#isdn_gsm_serviceno').val() === '' )
          $('#isdn_gsm_serviceno').val(v);
        else
          $('#isdn_gsm_serviceno').val($('#isdn_gsm_serviceno').val() + ";" + v);
        $('#new_service').val("");
      }
    });
    $('#del').click(function() {
      $('option').remove(':contains('+$('#gsm_serviceno').val()+')');
      $('#gsm_serviceno').attr('size', $('#gsm_serviceno > option').length);
      if ( $('#gsm_serviceno').attr('size') == 1 )
        $('#gsm_serviceno > option').prop('selected', 'selected');
      var v='';
      $('#gsm_serviceno > option').each(function(){
        if ( v === '' )
          v = $(this).text();
        else
          v = v+";"+$(this).text();
      });
      $('#isdn_gsm_serviceno').val(v);
    });
  }

  // New ISDN
  if ( $('#isdn_new_title').length ) {
    $('input[type=radio][id*=mac_]').prop('checked', false);
    $('input[type=text][id^=ip2mac]').click(function(e) {
      var n = $(this).attr('name');
      $('input[name=' + n.replace(/ip2mac_/, 'remac_') + ']').prop('checked', false);
    });
    $('input[type=radio][id*=mac_]').click(function(e) {
      n = $(this).attr('name');
      $('input[name=' + n.replace(/remac_/, 'ip2mac_') + ']').val('');
    });
  }

  // PTM
  if ( $('#isdn_ptm_title').length ) {
    $('#addmsn').on('click', function() {
      var vals = {};
      vals['what'] = 'addmsn';
      vals['msn'] = $('#newmsn').val();
      var id = url_get_id('form[action^="/admin/isdns/"]', 3);
      $.post("/admin/isdns/" + id + "/ajax_update", vals, function(data, status) {
      });
    });
    $('#delmsn').on('click', function() {
      var vals = {};
      vals['what'] = 'delmsn';
      var to_delete = $('#sel_msns').val();
      vals['to_del'] = to_delete.join(';');
      var id = url_get_id('form[action^="/admin/isdns/"]', 3);
      $.post("/admin/isdns/" + id + "/ajax_update", vals, function(data, status) {
      });
    });
  }

  // PTP
  if ( $('#isdn_ptp_title').length ) {
    $('#isdn_clipnoscr').change(function() {
      if ( $('#isdn_clipnoscr').prop('checked') )
        $('#preserve_callerid_visible').show();
      else
        $('#preserve_callerid_visible').hide();
    });
    if ( $('#isdn_clipnoscr').prop('checked') ) {
      $('#preserve_callerid_visible').show();
    } else {
      $('#preserve_callerid_visible').hide();
    }
  }

});


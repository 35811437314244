jQuery(document).ready(function() {

  if ( $('button[name=phone_mcast_button]').length ) {

    $('#mcast_groups-form select').on('change', function() {
      var id = $(this).attr('id').split('_')[2];
      if ( $(this).val() == 0 ) {
        $('#mcast_groups-form #mcast_'+id).prop('checked', false);
      } else {
        $('#mcast_groups-form #mcast_'+id).prop('checked', true);
      }
    });

    $('button[name=phone_mcast_button]').on('click', function() {
      start_spinner_popup();
      var table = $('#mcast-js-data').data('table');
      var id = $('#mcast-js-data').data('id');
      var phone_groups = $('#mcast-js-data').data('phone-groups');
      $.get('/misc/ajax/mcast_data', function(data, status) {
        stop_spinner_popup();
        var mcast_data = PhoneFunctions.get_mcast_settings(phone_groups);
        var s = "<option value='0'>Keine Auswahl</option>";
        data.forEach(function (item, index) {
          var opt = "<option value='" + item.id + "'>" + item.name + "</option>";
          s += opt;
        });
        $('#mcast_groups-form select').html(s);

        var mcast_setting = mcast_data['settings'];
        groups = mcast_setting.split(',');
        $('#mcast_groups-form input[type=checkbox]').prop('checked', false);
        for ( var i=0; i < phone_groups; i++ ) {
          if ( groups[i] > 0 ) {
            $('#mcast_groups-form #mcast_' + (i+1)).prop('checked', true);
            $('#mcast_groups-form #mcast_member_' + (i+1)).val(groups[i]);
          }
        }
        $('#mcast_groups-form #mcast_barge_in').prop('checked', mcast_data['barge_in']);
        $('#mcast_groups-form').modal();
      });
    });

    // Speichern-Taste im Dialog betätigt
    $('#mcast_groups-form #save-button').on('click', function() {
      var phone_groups = $('#mcast-js-data').data('phone-groups');
      var ar = [];
      for ( var i=0; i < phone_groups; i++ ) {
        if ( $('#mcast_groups-form #mcast_' + (i+1)).prop('checked') ) {
          ar.push($('#mcast_groups-form #mcast_member_' + (i+1)).val());
        } else {
          ar.push(0);
        }
      }
      var v = ar.join(',');
      PhoneFunctions.set_mcast_settings(v, $('#mcast_groups-form #mcast_barge_in').prop('checked'));
      $('#mcast_groups-form').modal('hide');
    });
    
  }

});

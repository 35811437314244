jQuery(document).ready(function() {

  if ( $('#teams_integration_title').length > 0 ) {

    function set_current(value) {
      if ( /^[0-9\+]+$/.test(value) ) {
        $('input[type=radio][name=on_state]').val(['number']);
        $('#teams_select_fwd input#number').val(value);
      } else {
        $('input[type=radio][name=on_state]').val([value]);
        $('#teams_select_fwd input#number').val("");
      }
    }
    function set_spinner_option(v1, v2) {
      if ( v1 || v2 ) 
        $('form.edit_ms_team').data('sendfile-no-spinner', 1);
      else
        $('form.edit_ms_team').data('sendfile-no-spinner', 0);
    }

    var dr_enabled = $('input[type=radio][name="ms_team[dr_enable]"]:checked').val() == "true";
    var use_app_certificate = $('input[type=radio][name="ms_team[use_app_certificate]"]:checked').val() == "true";
    var dr_enabled_certload = dr_enabled && $('#ms_team_use_own_cert').prop('checked');

    set_spinner_option(dr_enabled_certload, use_app_certificate);

    $('input[type=radio][name="ms_team[use_app_certificate]"]').on('change', function() {
      var val=  this.value;
      if ( this.value == "true" ) {
        $('.use-clientsecret').hide();
        $('.use-certificate').show();
        use_app_certificate = true;
      } else {
        $('.use-certificate').hide();
        $('.use-clientsecret').show();
        use_app_certificate = false;
      }
      set_spinner_option(dr_enabled_certload, use_app_certificate);
    });
    if ( $('input[type=radio][name="ms_team[use_app_certificate]"]:checked').val() == "true") {
      $('.use-clientsecret').hide();
      $('.use-certificate').show();
    } else {
      $('.use-certificate').hide();
      $('.use-clientsecret').show();
    }

    $('input[type=radio][name="ms_team[dr_enable]"').on('change', function() {
      if ( this.value == "true" ) {
        $('.direct_routing_enabled').show();
        if ( $('#ms_team_use_own_cert').prop('checked') ) {
          $('.use_own_cert').show();
          dr_enabled_certload = true;
        } else {
          $('.use_own_cert').hide();
          dr_enabled_certload = false;
        }
        dr_enabled = true;
      } else {
        $('.direct_routing_enabled').hide();
        $('form.edit_ms_team').data('sendfile-no-spinner', 0);
        dr_enabled = false;
        dr_enabled_certload = false;
      }
      set_spinner_option(dr_enabled_certload, use_app_certificate);
    });

    if ( dr_enabled ) {
      $('.direct_routing_enabled').show();
    } else {
      $('.direct_routing_enabled').hide();
    }
    
    $('#ms_team_use_own_cert').on('click', function() {
      if ( this.checked ) {
        $('.use_own_cert').show();
        dr_enabled_certload = true;
      } else {
        $('.use_own_cert').hide();
        dr_enabled_certload = false;
      }
      set_spinner_option(dr_enabled_certload, use_app_certificate);
    });
    if ( $('#ms_team_use_own_cert').prop('checked') && dr_enabled ) {
      $('.use_own_cert').show();
    } else {
      $('.use_own_cert').hide();
    }

    $('#set_on_busy').on('click', function() {
      var t = $('div[id=teams-state_text]').data('busy');
      $('.fwd_type').text(t);
      set_current($(this).data('current'));
      $('#teams_select_fwd #save-button').data("type", "busy");
      $('#teams_select_fwd').modal();
    });

    $('#set_on_dnd').on('click', function() {
      var t = $('div[id=teams-state_text]').data('dnd');
      $('.fwd_type').text(t);
      set_current($(this).data('current'));
      $('#teams_select_fwd #save-button').data("type", "dnd");
      $('#teams_select_fwd').modal();
    });

    $('#set_on_away').on('click', function() {
      var t = $('div[id=teams-state_text]').data('away');
      $('.fwd_type').text(t);
      set_current($(this).data('current'));
      $('#teams_select_fwd #save-button').data("type", "away");
      $('#teams_select_fwd').modal();
    });

    $('#set_on_offline').on('click', function() {
      var t = $('div[id=teams-state_text]').data('offline');
      $('.fwd_type').text(t);
      set_current($(this).data('current'));
      $('#teams_select_fwd #save-button').data("type", "offline");
      $('#teams_select_fwd').modal();
    });

    $('#teams_select_fwd input#number').on('focusin', function(e) {
      $('input[type=radio][name=on_state]').val(['number']);
    });

    $('input[type=radio][name=on_state]').on('click', function() {
      var v = $(this).val();
      if ( v != 'number' )
        $('#teams_select_fwd input#number').val("");
    });

    $('#teams_select_fwd #save-button').on('click', function() {
      var value = $('input[type=radio][name="on_state"]:checked').val();
      var key = $(this).data('type');
      var name = "dest_on_" + key;
      var linkname = "set_on_" + key;
      var action_texts = $('div[id=teams-state_action]').data('action');
      if ( value == 'number' ) {
        var dest = $('#teams_select_fwd input#number').val();
        $('input[type=hidden][name="ms_team[' + name + ']"]').val(dest);
        $('a#' + linkname).data('current', dest);
        $('a#' + linkname).text(action_texts[value] + dest);
      } else {
        $('input[type=hidden][name="ms_team[' + name + ']"]').val(value);
        $('a#' + linkname).data('current', value);
        $('a#' + linkname).text(action_texts[value]);
      }
      $('#teams_select_fwd').modal('hide');
    });
  }
});

jQuery(document).ready(function() {

  // Phone-Config-Seite: Tastenerweiterungen
  if ( $('#user_phone_edit_title').length > 0 ) {

    // Tastenerweiterungen: Änderungen speichern?
    $('button[name=config_ext]').on('click', function() {
      var config = PhoneFunctions.get_configuration();
      if ( PhoneFunctions.check4changes() ) {
        $('#save_changes-form #save-button').on('click', function() {
          $('<input />').attr('type', 'hidden').attr('name', 'save_changes').attr('value', 'true').appendTo('form#phonetemplate_form');
          $('#save_changes-form').modal('hide');
        });
        $('#save_changes-form').modal();
        $('#save_changes-form').on('hidden.bs.modal', function() {
          $('<input />').attr('type', 'hidden').attr('name', 'config_ext').attr('value', 'Konifg.').appendTo('form#phonetemplate_form');
          $('input[type=hidden][id=phonesettings]').val(JSON.stringify(config));
          $('form#phonetemplate_form').submit();
        });
      } else {
        $('<input />').attr('type', 'hidden')
          .attr('name', 'config_ext')
          .attr('value', 'Konifg.')
          .appendTo('form#phonetemplate_form');
        $('input[type=hidden][id=phonesettings]').val(JSON.stringify(config));
        $('form#phonetemplate_form').submit();
      }
    });

    // Tastenerweiterungen: Änderungen speichern?
    $('button[name=phone_ringer_button]').on('click', function() {
      $('#phone-ringer-form').modal();
    });
    
  }

});

import { activate_tables } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#profiles-table').length ) {
    var table = activate_tables('#profiles-table', false, false);

    table.on( 'draw', function () {
      $('.alert').remove();
      $('.delete_entry').on('click', function() {
        delete_entry($(this));
      });
    });
    
    $('.delete_entry').on('click', function() {
      delete_entry($(this));
    });
    
    // Löschen eines Zeitprofils
    var entry_id;
    function delete_entry(entry) {
      entry.tooltip('hide');
      entry_id = entry.data('entry-id');
      if ( entry.data('name').length )
        $('span[id=name]').html(entry.data('name'));
      else
        $('span[id=name]').parent().hide();
      $('#profile_destroy').modal();
    }

    /* Löschen tatsächlich ausführen */
    $('#delete-button').on('click', function() {
      $('#delete-form').attr('action', '/admin/profiles/' + entry_id);
      $('#delete-form').submit();
    });

    $('form#update_activity_form').on('submit', function() {
      var form = this;
      var data = table.rows( function ( idx, data, node ) {
        var cells = $(node).find('input[type="checkbox"]');
        cells.filter(function (index) {
          if ( $(cells[index]).prop('checked') ) {
            var id = $(cells[index]).attr('id');
            $(form).append('<input type="hidden" name="' + id + '" value="1" /> ');
          }
        });
      });
    });

  }

  if ( $('#edit-profile-table').length ) {

    var entry_id;
    var table;
    var mark_all = false;
    var mark_none = false;

    /* Eine Nebenstelle wurde aktiviert/deaktiviert */
    function change_selection(elem) {
      var ar = $('#profile_extension_list').val().split(',');
      var entry = elem.attr('id');
      if ( elem.prop('checked') ) {
        /* Nebenstelle aktivieren */
        var found = false;
        for ( var i=0; i < ar.length; i++ ) {
          if ( ar[i] == entry )
            found = true;
        }
        if ( !found )
          ar.push(elem.attr('id'));
      } else {
        /* Nebenstelle deaktivieren */
        if ( ar == "ext_any" ) {
          ar = $('#all_extension_list').val().split(',');
        }
        var idx = -1;
        for ( var i=0; i < ar.length; i++ ) {
          if ( ar[i] == entry ) {
            idx = i;
            break;
          }
        }
        if ( idx >= 0 ) {
          ar.splice(idx, 1);
        }
      }
      $('#profile_extension_list').val(ar.join(','));
    }

    /* Markierungen setzen oder aufheben */
    function set_checkboxes(val) {
      $('input[id^=ext_]').prop('checked', val);
      $('input[id^=menu_]').prop('checked', val);
      $('input[id^=ring_]').prop('checked', val);
      $('input[id^=sbr_]').prop('checked', val);
      $('input[id^=queue_]').prop('checked', val);
    }

    /* Auf der Formular-Seite, Liste */
    var table = activate_tables('#edit-profile-table');

    $('a[data-id=new_tf_entry]').click(function() {
      $('form[action^="/admin/profiles"]').append('<input type="hidden" name="new" value="1" />').submit();
    });
    $('a[data-id=edit_tf_entry]').click(function() {
      var id = $('#profile_schedule_id').val();
      $('form[action^="/admin/profiles"]').append('<input type="hidden" name="edit" value="' + id + '" />').submit();
    });
    $('a[data-id=delete_tf_entry]').click(function() {
      entry_id = $('#profile_schedule_id').val();
      if ( parseInt(entry_id) > 0 ) {
        $('#schedule_destroy span[id=name]').html($('#profile_schedule_id option:selected').text());
        $('#schedule_destroy').modal();
      }
    });

    /* Löschen nach Sicherheitsabfrage */
    $('#schedule_destroy #delete-tf-button').on('click', function() {
      $('form[action^="/admin/profiles"]').append('<input type="hidden" name="delete" value="' + entry_id + '" />').submit();
    });

    if ( $('#profile_extension_list').val() == "ext_any" ) {
      mark_all = true;
      mark_none = false;
      set_checkboxes(true);
    }

    $('#mark-all').click(function(e) {
      e.preventDefault();
      $(this).tooltip('hide');
      $('#profile_extension_list').val("ext_any");
      mark_all = true;
      mark_none = false;
      set_checkboxes(true);
    });
    $('#mark-none').click(function(e) {
      e.preventDefault();
      $(this).tooltip('hide');
      $('#profile_extension_list').val("");
      mark_none = true;
      mark_all = false;
      set_checkboxes(false);
    });

    $('.profile-extens').on('change', function() {
      change_selection($(this));
    });
    table.on('draw', function() {
      if ( mark_all ) {
        set_checkboxes(true);
      } else if ( mark_none ) {
        set_checkboxes(false);
      }
      $('.profile-extens').on('change', function() {
        change_selection($(this));
      });      
    });
  }

});


var PhoneSnom_m300 = (function(modul) {

  var ext_info;

  function init(pdata, funcs) {
    console.log("Telefon " + pdata.vendor + " " + pdata.model);
    funcs.set_initial_data("", {});
    var config = funcs.get_configuration();

    ext_info = $('#mobile_extdata').data('ext-info');
    var not_init = true;
    var first_empty = -1;
    for (i = 1; i <= 20; i++) {
      var s = setup_display(ext_info[i-1]);
      if ( !config.hasOwnProperty('display') || config.display[i-1].length === 0 || config.display[i-1] === "" ) {
        $('#display_'+i).val(s);
      } else {
        $('#display_'+i).val(config.display[i-1]);
      }
      if ( first_empty < 0 && s == "" )
        first_empty = i;
      if ( config.in_ident.length > 0 && config.in_ident[i-1] ) {
        var vals = config.in_ident[i-1].split(';');
        for ( var j in vals ) {
          if ( vals[j] ) {
            $('#in_ident_'+i+'_'+vals[j]).prop('checked', true);
            not_init = false;
          }
        }
      }
    }

    if ( not_init ) {
      var i = 1;
      $('.phone-identities').filter(":first").find('input[type=checkbox]').each(function() {
        extid = $(this).attr('id').split('_')[3];
        $('#in_ident_'+i+'_'+extid).prop('checked', true);
        i++;
      });
    }
    if ( first_empty >= 0 ) {
      for ( i=first_empty; i<=20; i++ ) {
        $('#display_' + i).parents('.phone-identities').hide();
      }
    }

    if ( $('#support_g722').length > 0 ) {
      $('#support_g722').prop('checked', config.support_g722 ? true : false);
    }

    /* PIN: Focus setzen, wenn PIN nicht gesetzt oder Default-PIN ist */
    $('#pin').val(config.pin);
    if ( config.pin === '' ) {
      $('#pin').focus();
    }
  };

  function do_submit(funcs) {
    console.log("Submit");
    var pin = $('#pin').val();
    var m5 = $('#support_m5').prop('checked');
    if ( !m5 && (pin === '' || pin == '0000' || pin.length != 4) ) {
      $('#dect-pin-warn-form').modal();
      return false;
    }

    var v = ['', '', '', '', '', '', '', '', '', '', '', '','', '', '', '', '', '', '', ''];
    $('input:checkbox').each(function() {
      if ( $(this).is(':checked') ) {
        id = $(this).attr('id').split('_');
        if (v[id[2]-1]) {
          v[id[2]-1] += ";"+id[3];
        } else {
          v[id[2]-1] = id[3];
        }
      }
    });
    var display = [];
    var in_ident = [];
    for ( var i=1; i<=20; i++ ) {
      in_ident[i-1] = v[i-1];
      display[i-1] = $('#display_'+i).val();
    }
    var support_g722 = -1;
    if ( $('#support_g722').length > 0 ) {
      support_g722 = $('#support_g722').is(":checked");
    }
    funcs.update_dect(pin, support_g722, in_ident, undefined, display);
    funcs.do_submit();
  };

  return {
    init: init,
    do_submit: do_submit
  };
  
  function setup_display(data) {
    var display;
    if ( data ) {
      if ( data.short.length > 0 && data.short.length <= 18 ) {
        display = data.short;
      } else if ( data.gn.length + data.sn.length + 2 > 18 ) {
        if ( data.sn.length + 4 > 18 ) {
          display = data.ext;
        } else {
          display = data.gn[0] + ". " + data.sn;
        }
      } else {
        display = data.gn + " " + data.sn;
      }
    } else {
      display = "";
    }
    return display;
  }

})(PhoneSnom_m300 || {});
window.PhoneSnom_m300 = PhoneSnom_m300;

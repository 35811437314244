jQuery(document).ready(function() {

  if ( $('form[data-formid=mactemplate]').length > 0 ) {
    var manual_id = 0;

    var lines = $('#provisioning_mac_templates').val().split("\n");
    if ( lines ) {
      for( var i=0; i< lines.length; i++ ) {
        var s = jQuery.trim(lines[i]);
        if ( s.length === 0 ) {
          continue;
        }
        var values = s.split(";");
        var new_id = 'del-id-' + i;
        var element = "<div id=\"" + new_id + "\" class=\"div-mac\">" + values[0] + "<br />" + values[1] + "</div>";
        if ( i === 0 ) {
          $('#selected-mac').html(element);
        } else {
          $('#selected-mac').append(element);
        }
        $('#' + new_id).draggable({ opacity: 0.7, helper: 'clone' });
      }
    }

    $('form[data-formid=mactemplate]').submit(function( event ) {
      var result = "";
      $('#selected-mac').find('.div-mac').each(function() {
        result += this.firstChild.data + ';' + this.lastChild.data + '\n';
      });
      $('#provisioning_mac_templates').val(result);
      return true;
    });

    $('#vendor-mac tr').draggable({ opacity: 0.7, helper: 'clone', zIndex: 1000 });
    $('#selected-mac').droppable({
      accept: function(drag) {
        const regex = /^del-id-/;
        if ( drag.attr('id').search(regex)  >= 0 ) {
          return false;
        }
        if ( drag.attr('id') == 'mac_n' ) {
          var e = drag.find("td > input");
          return true;
        }
        if ( $('#del-id-' +  drag.attr('id') ).length > 0 ) {
          return false;
        }
        return true;
      },
      hoverClass: 'highlight-target',
      drop: function(event, ui) {
        var element;
        var id = ui.draggable.attr('id');
        var new_id = 'del-id-' + id;
        if ( id == 'mac_n' ) {
          var vendor = $('#mac_n').find('input[id=vendor]').val();
          var mac = $('#mac_n').find('input[id=mac]').val();
          new_id = new_id + '_' + manual_id++;
          element = "<div id=\"" + new_id + "\" class=\"div-mac\">" + vendor + "<br />" + mac + "</div>";
          $('input#vendor').val('');
          $('input#mac').val('');
        } else {
          var parts = ui.draggable.text().split('-');
          if ( parts.length > 1 ) {
            parts[1] = parts[1].replace("()", "");
          }
          element = "<div id=\"" + new_id + "\" class=\"div-mac\">" + parts[0] + "<br />" + parts[1] + "</div>";
        }
        $('#selected-mac').append(element);
        $('#' + new_id).draggable({ opacity: 0.7, helper: 'clone' });
      },
    });
    $('#delete-area').droppable({
      accept: '.div-mac',
      hoverClass: 'highlight-target',
      over: function(event, ui) {
        $(this).css('height', '3rem');
      },
      drop: function(event, ui) {
        $(this).css('height', '2rem');
        var id = ui.draggable.attr('id');
        $('#selected-mac').find('#' + id).remove();
      },
    });
  }
});

jQuery(document).ready(function() {

  if ( $('#user_record_download-table').length ) {
    activate_tables('#user_record_download-table', false, [-1]);
  }

  if ( $('#user_recording-title').length ) {
    
    $('button[name=rec_start]').on('click', function() {
      var direction = $(this).data('direction');
      console.log("Direction " + direction);
      if ( direction == 'int2ext' || direction == 'ext2int' ) {
        do_start_stop('start', $(this).data('channel'));
      } else {
        $('#not_able_to_record-dialog').modal();
      }
    });

    $('button[name=rec_stop]').on('click', function() {
      do_start_stop('stop', $(this).data('channel'));
    });

    function do_start_stop(action, channel) {
      var vals = {};
      vals['what'] = action;
      vals['channel'] = channel;
      start_spinner_popup();
      console.log({vals});
      $.post("/user/recording/ajax_update", vals, function(data, status) {
        console.log({data});
        window.location = "/user/recording/";
      });
      
    }
  };

});

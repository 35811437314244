var update_pattern_table;

export const pattern_list = ['[0-9]', '[1-9]', '[2-9]', '0', '1', '2', '3', '4', '5',
 '6', '7', '8', '9', '...'];
export function initial_setup_number_pattern(regex, fn) {
  console.log("initial_setup_number_pattern");
  console.log({regex});
  console.log({fn});
  var reg_line = regex.split(';');
  for ( var i=0; i < reg_line.length; i++) {
    var line = $('.lcr-rule').filter(function(index) {
      return index === i;
    });
    var entries = reg_line[i].split(',');
    for ( var j=0; j < entries.length; j++) {
      var e = $('#pat_' + entries[j]).clone();
      e.removeAttr('id');
      e.appendTo(line.find('td:last-child'));
    }
  }
  update_pattern_table = fn;
  fn();
}

jQuery(document).ready(function() {

  if ( $('#lcrrules_title').length || $('#sbr_edit_title').length ) {
    
    function fixHelper( e, ui ) {
      var $ctr = $(this);
      ui.helper.addClass('horizontal').end();
    }

    $('div.lcr-dragbutton').draggable({
      opacity: 0.7,
      helper: 'clone',
      revert: "invalid",
      connectToSortable: ".connectedSortable",
      cursor: 'move',
      zIndex: 200,
    });

    $('.lcr-rule').height(2 * $('div.lcr-dragbutton').height());

    $(".lcr-pattern").sortable({
      connectWith: ".connectedSortable",
//       beforeStop: function(ev, ui) {
//       },
      stop: function(ev, ui) {
        if ( ui.position.left < 0 ) {
          ui.item.remove();
        }
        update_pattern_table();
      },
    });
  }

});

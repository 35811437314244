import { activate_tables, url_get_id } from '../iptam.js';

jQuery(document).ready(function() {

  /* Auf der Index-Seite */
  if ( $('#voiceprompts_title').length ) {

    var vp_description;
    var entry_id;

//     activate_tables('#voiceprompts-table', false);

    $('button[name=play]').on('click', function() {
      clear_flash("notice");
      var selected = $('input[type=radio][name=voiceprompt]:checked').val();
      console.log("sel " + selected);
      if ( !selected ) {
        show_flash('notice', $('div#jsdata').data('error-title'), $('div#jsdata').data('error-msg'));
        return;
      }
      vp_description = $('#voiceprompt_' + selected).data('desc');
      entry_id = selected;
      console.log("Desc " + vp_description);
      $('#select_phone').modal();
    });

    /* Speichern Telefonauswahl, Abspielen ausführen */
    $('#select_phone #save-button').on('click', function() {
      var phone = $('input[type=radio][name=prompt]:checked').val();
      var number = $('#number').val();
      var vals = {};
      vals['what'] = 'play'
      vals['phone'] = (phone == 'own' || !number) ? 'own' : number;
      vals['vp_description'] = vp_description;
      vals['id'] = entry_id;
      vals['prompt_file'] = $('#prompt_file').val();
      $('#select_phone').modal('hide');
      $.post("/user/voiceprompts/ajax_update", vals, function(data, status) {
      });
    });

    $('button[name=download]').on('click', function() {
      clear_flash("notice");
      var selected = $('input[type=radio][name=voiceprompt]:checked').val();
      console.log("sel " + selected);
      if ( !selected ) {
        show_flash('notice', $('div#jsdata').data('error-title'), $('div#jsdata').data('error-msg'));
        return;
      }
      var vals = {};
      vals['what'] = 'download';
      vals['id'] = selected;
      $.post("/user/voiceprompts/ajax_update", vals, function(data, status) {
        console.log({data})
        if ( data.download ) {
          window.location = '/user/voiceprompts/download?fn=' + data.filename;
        }
      });
    });

    $('.delete_entry').on('click', function() {
      $(this).tooltip('hide');
      entry_id = $(this).data('entry-id');
      var in_use = $(this).data('in-use');
      var usage = $(this).data('usage');
      var desc = $(this).data('desc');
      $('#voiceprompt_destroy #usage').text(usage);
      $('#voiceprompt_destroy #name').text(desc);
      if ( in_use ) {
        $('#voiceprompt_destroy .in_use').show();
        $('#voiceprompt_destroy .not_in_use').hide();
      } else {
        $('#voiceprompt_destroy .in_use').hide();
        $('#voiceprompt_destroy .not_in_use').show();
      }
      $('#voiceprompt_destroy').modal();
    });

    /* Löschen tatsächlich ausführen */
    $('#delete-button').on('click', function() {
      $('#delete-form').attr('action', '/user/voiceprompts/' + entry_id);
      $('#delete-form').submit();
    });

  }

  /* Auf New- oder Edit-Seite */
  if ( $('#edit_voiceprompts_title').length ) {

    var vp_description, type_of_action;

    /* Aufruf der Seite: Aktivieren "Abspielen" und "Download" bei vorhandenem Prompt */
    if ( $('#prompt_file').val().length ) {
      $('.audio_prompt').prop('disabled', false);
    } else {
      if ( $('#prompt_file').data('is-new') )
        $('.audio_prompt').prop('disabled', true);
    }

    /* Telefonauswahl: Klick in Nummerbfeld */
    $('#select_phone #number').click(function(e) {
      $('#phone_number').prop("checked", true);
    });

    /* Telefonauswahl: Änderung der Auswahl */
    $('input[type=radio][name=phone]').on('change', function() {
      if ( $(this).val() != 'number' )
        $('#number').val("");
    });

    /* Aufnehmen via Telefon: Dialog zur Auswahl des Telefons öffnen */
    $('button[name=record]').on('click', function(e) {
      type_of_action = 'record';
      $('#select_phone').modal();
    });

    /* Abspielen via Telefon: Dialog zur Auswahl des Telefons öffnen */
    $('button[name=play]').on('click', function(e) {
      type_of_action = 'play';
      vp_description = $(this).data('desc');
      console.log("Desc " + vp_description);
      if ( !vp_description ) {
        vp_description = $('#voiceprompt_description').val();
      }
      console.log("Desc " + vp_description);
      $('#select_phone').modal();
    });

    /* Speichern Telefonauswahl und Aufnahme / Abspielen ausführen */
    $('#select_phone #save-button').on('click', function() {
      var phone = $('input[type=radio][name=prompt]:checked').val();
      var number = $('#number').val();
      var vals = {};
      vals['what'] = type_of_action;
      vals['phone'] = (phone == 'own' || !number) ? 'own' : number;
      vals['vp_description'] = vp_description;
      vals['prompt_file'] = $('#prompt_file').val();
      var id = url_get_id('form[action^="/user/voiceprompts"]');
      console.log("ID = " + id);
      if ( id == 'voiceprompts' )
        id = "";
      vals['id'] = id;
      $('#select_phone').modal('hide');
      $.post("/user/voiceprompts/ajax_update", vals, function(data, status) {
      });
    });

    $('button[name=upload]').on('click', function(e) {
      $('#file_upload .modal-title').html($(this).data('title'));
      $('#file_upload #upload-button').attr('data-filetype', "prompt_type");
//       $('#file_upload #upload-button').attr('data-entry_id', "id");
      $('#file_upload #file_type').html($(this).data('filetype'));
      $('#file_upload').modal();
    });

    /* Speichern des File-Uploads */
    $('#upload-button').on('click', function() {
      var formData = new FormData(jQuery('#upload-form')[0]);
      formData.append('what', 'upload');
      formData.append('prompt_file', $('#prompt_file').val());
                           
      $.ajax({
        url: '/user/voiceprompts/ajax_update',
        method: 'POST',
        data: formData,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          console.log({data});
          $('#file_upload').modal('hide');
        }
      });
    });

    /* Download des Prompts */
    $('button[name=download]').on('click', function(e) {
      var vals = {};
      vals['what'] = 'download';
      vals['prompt_file'] = $('#prompt_file').val();
      var id = url_get_id('form[action^="/user/voiceprompts"]');
      console.log("ID = " + id);
      if ( id == 'voiceprompts' )
        id = "";
      vals['id'] = id;
      $.post("/user/voiceprompts/ajax_update", vals, function(data, status) {
        console.log({data})
        if ( data.download ) {
          window.location = '/user/voiceprompts/download?fn=' + data.filename;
        }
      });
    });

    /* Aktivieren/Deaktivieren des Löschen-Buttons für die Zeitsteuerung */
    $('#voiceprompt_schedule_id').on('change', function() {
      if ( $(this).val() == 0 ) {
        $('button[name=delete_schedule]').prop('disabled', true);
      } else {
        $('button[name=delete_schedule]').prop('disabled', false);
      }
    });
    $('#voiceprompt_schedule_id').trigger('change');
  }

});

jQuery(document).ready(function() {

  if ( $('#sipprovider_title').length ) {

    $('input[type=checkbox]').change(function() {
      $('[data-toggle="tooltip"]').tooltip('hide');
    });
    $('#clirmethod_4').change(function() {
      if ( $('#clirmethod_4').prop('checked') ) {
        $('.rfc3325_mode').show();
      } else {
        $('.rfc3325_mode').hide();
      }
    });
    if ( $('#clirmethod_4').prop('checked') ) {
      $('.rfc3325_mode').show();
    } else {
      $('.rfc3325_mode').hide();
    }

    $('#sipprovider_is_ddi').change(function() {
      if ( $('#sipprovider_is_ddi').prop("checked") ) {
        $('.ddi_mode').show();
        $('.no_ddi_mode').hide();
      } else {
        $('.ddi_mode').hide();
        $('.no_ddi_mode').show();
      }
    });
    $('#sipprovider_is_ddi').change();

    $('#sipprovider_transport').change(function() {
      if ( $(this).val() == 'tls' )
        $('.mediasec').show();
      else
        $('.mediasec').hide();
    });
    $('#sipprovider_transport').change();

    $('#sipprovider_use_explicit_port').change(function() {
      if ( $('#sipprovider_use_explicit_port').prop("checked") ) {
        $('.explict_port_mode').show();
      } else {
        $('.explict_port_mode').hide();
      }
    });
    $('#sipprovider_use_explicit_port').change();

    if ( $('#sipprovider_preserve_callerid').prop('checked') ) {
      $('#sipprovider_preserve_callerid_int').parent().show();
    } else {
      $('#sipprovider_preserve_callerid_int').parent().hide();
    }
    $('#sipprovider_preserve_callerid').change(function() {
      if ( $('#sipprovider_preserve_callerid').prop('checked') ) {
        $('#sipprovider_preserve_callerid_int').parent().show();
      } else {
        $('#sipprovider_preserve_callerid_int').parent().hide();
      }
    });
  }

});

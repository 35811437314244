import { start_spinner_popup, stop_spinner_popup } from '../iptam.js';
import { directory_entry_id } from '../edit_dir_entry_modal.js';

jQuery(document).ready(function() {

  if ( $('#user_main_title').length ) {

    function do_post(what, list) {
      start_spinner_popup();
      $.post('/user/main/update_favs', { what: what, data: list}, function(data, status) {
        stop_spinner_popup();
      });
    }

    $('button[name=add_user_fav]').on('click', function() {
      var list = [];
      $('select[name="av_menues"] :selected').each(function(idx, obj) {
        if ( obj.value )
          list.push(obj.value);
      });
      $('select[name="av_infos"] :selected').each(function(idx, obj) {
        if ( obj.value )
          list.push(obj.value);
      });
      do_post('add', list.join(','));
    });

    $('button[name=del_user_fav]').on('click', function() {
      var list = [];
      $('select[name="used_entries[]"] :selected').each(function(idx, obj) {
        list.push(obj.value);
      });
      do_post('del', list.join(','));
    });

    $('#edit_dashboard').on('hidden.bs.modal', function() {
      window.location = "/user/main";
    });

    var width = $('#sample-row').width() - 50;
    var col_width = width / 6;
    var col_height = col_width * 1.4;
    $('#favlist').width(width);
    $('#favlist li').each(function(idx, obj) {
      var w = $(this).data('fav-width');
      col_width = width / w;
      if ( w == 2 )
        col_width -= 9;
      $(this).width(col_width);
    });
    var h3_height = $('.fav-element h3').height();
    $('.fav-element table').each(function(idx, obj) {
      var h = 2*h3_height + 10 + $(this).height();
      if ( h > col_height )
        col_height = h;
    })
    $('#favlist li').height(col_height);
    $('#favlist').sortable({
      stop: function(event, ui) {
        var list = []
        $('#favlist li').each(function(idx, obj) {
          list.push($(this).data('fav-id'));
        });
        do_post('prio', list.join(','));
      }
    });

    if ( $('h3#fav-call-list').length ) {
      var list_and_id;

      function get_list_id(list_name, id_val) {
        var list, id;

        if ( list_name == 'all' ) {
          var parts = id_val.split('_');
          list = parts[0];
          id = parts[1];
        } else {
          list = list_name;
          id = id_val;
        }
        return {list: list, id: id};
      }

      function delete_list_entry(list, id, single) {
        var vals = {};
        vals['what'] = 'delete';
        vals['list'] = list;
        vals['id'] = id;
        vals['group'] = single;
        start_spinner_popup();
        $.post("/user/lists/ajax_update", vals, function(data, status) {
          window.location = '/user/main'
        });
      }

      $('a[href="#"][class~=dial]').on('click', function() {
        $('[data-toggle="tooltip"]').tooltip('hide');
        list_and_id = get_list_id($(this).data('list'), $(this).data('id'));
        var vals = {};
        vals['what'] = 'dial';
        vals['type'] = list_and_id['list'];
        vals['id'] = list_and_id['id'];
        start_spinner_popup();
        $.post("/user/lists/ajax_update", vals, function(data, status) {
          stop_spinner_popup();
        });
      });

      $('a[href="#"][class~=edit]').on('click', function() {
        $('[data-toggle="tooltip"]').tooltip('hide');
        list_and_id = get_list_id($(this).data('list'), $(this).data('id'));
        $('input[type=radio][name=dir_type]').prop('checked', false);
        $('input[type=radio][name=use]').prop('checked', false);
        $('#search').val("");
        $('#edit_options .new_entry').hide();
        $('#edit_options .existing_entry').hide();
        $('#edit_options #type_long').val("");
        $('#edit_options #type_short').val("");
        $('#edit_options #type_long_sel').val("");
        $('#edit_options #directory_cont').prop('checked', false);
        $('#error_no_dir_type').hide();
        $('#error_no_edit_type').hide();
        $('#error_no_contact_sel').hide();
        $('#error_no_contact_data').hide();
        $('#error_no_number_type').hide();
        $('#edit_options').modal();
      });

      $('a[href="#"][class~=delete]').on('click', function() {
        $('[data-toggle="tooltip"]').tooltip('hide');
        list_and_id = get_list_id($(this).data('list'), $(this).data('id'));

        if ( list_and_id['list'] == 'missed' && $(this).data('rid') ) {
          // Eintrag bezieht sich verpassten Anruf aus Klingelgruppe
          start_spinner_popup();
          $.get('/misc/ajax/waitqjs', {id: "r_" + $(this).data('rid')}, function(data) {
            stop_spinner_popup();
            $('span#rg_name').text(data.name);
            $('#delete_options #del_entry_single').prop('checked', true);
            $('#delete_options').modal();
          });
        } else {
          delete_list_entry(list_and_id['list'], list_and_id['id'], false)
        }
      });

      $('#delete_options #save-button').on('click', function() {
        group = $('#delete_options input[type=radio][name=del_entry]:checked').val();
        delete_list_entry(list_and_id['list'], list_and_id['id'], group=='group');
      });

      $('#edit_options #save-button').on('click', function() {
        /* Prüfen auf Vollständigkeit */
        var dir_type = 'priv';
        var valid = true;
        if ( $('#edit_options input[type=radio][name=dir_type]').length ) {
          dir_type = $('#edit_options input[type=radio][name=dir_type]:checked').val();
          if ( !dir_type ) {
            $('#error_no_dir_type').show();
            valid = false;
          }
        }
        var edit_type = $('#edit_options input[type=radio][name=use]:checked').val();
        if ( edit_type == 'new' ) {
          if ( $('#edit_options #sur_name').val() == "" && $('#edit_options #given_name').val() == "" && $('#edit_options #company').val() == "" ) {
            $('#error_no_contact_data').show();
            valid = false;
          } else {
            $('#error_no_contact_data').hide();
          }
        } else if ( edit_type == 'existing' ) {
          if ( !directory_entry_id ) {
            $('#error_no_contact_sel').show();
            valid = false;
          }
        } else {
          $('#error_no_edit_type').show();
          valid = false;
        }
        if ( $('#edit_options #type_long').val() == "" && $('#edit_options #type_short').val() == "" && $('#edit_options #type_long_sel').val() == "" ) {
          $('#error_no_number_type').show();
          valid = false;
        } else {
          $('#error_no_number_type').hide();
        }

        if ( !valid )
          return;

        /* Übergabe der Daten */
        var vals = {};
        vals['what'] = 'update_directory';
        vals['list'] = list_and_id['list'];
        vals['id'] = list_and_id['id'];
        vals['entry_id'] = directory_entry_id;
        vals['edit_type'] = edit_type;
        vals['dir_type'] = dir_type;
        vals['directory_cont'] = $('#edit_options #directory_cont').prop('checked');
        var keys = ['given_name', 'sur_name', 'company', 'type_long', 'type_short', 'type_long_sel'];
        for(var i=0; i<keys.length; i++) {
          var k = '#' + keys[i];
          vals[keys[i]] = $(k).val();
        }
        $('#edit_options').modal('hide');
        start_spinner_popup();
        $.post("/user/lists/ajax_update", vals, function(data, status) {
          if ( data.continue ) {
            window.location = "/user/directory/" + data.id + "/edit";
          } else {
            stop_spinner_popup();
          }
        });
      });
    }

    if ( $('h3#fav-faxstat').length ) {
      $('.faxabort').on('click', function() {
        var vals = {};
        vals['what'] = 'abort-job';
        vals['id'] = $(this).data('jobid');
        start_spinner_popup();
        $.post("/user/fax/ajax_update", vals, function(data, status) {
          window.location = "/user/main";
        });
      });

    }

  }
});

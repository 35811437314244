var PhoneUnify_os15 = (function(modul) {

  function init(pdata, funcs) {
    console.log("Telefon " + pdata.vendor + " " + pdata.model);
    funcs.set_initial_data(pdata.own_exten, {pkey: [1,8], pkey: [1001,1008]});

    for (var j = 0; j < 2; j++) {
      for (var i = 1; i <= 8; i++) {
        $('#pkey'+(j*1000 +i)).droppable({
          accept: '.key',
          hoverClass: 'highlight-target',
          drop: function(event, ui) {
            if ( ui.draggable.attr('id') == "f_line" ) {
              funcs.set_line($(this));
            } else if ( ui.draggable.attr('id') == "f_blf" ) {
              funcs.set_blf($(this));
            } else if ( ui.draggable.attr('id') == "f_direct" ) {
              funcs.set_direct($(this));
            } else if ( ui.draggable.attr('id') == "f_cfim-0" ) {
              funcs.set_cfim_0($(this));
            } else if ( ui.draggable.attr('id') == "f_cfwdgrp" ) {
              funcs.set_cfwdgrp($(this));
            } else if ( ui.draggable.attr('id') == "f_agent" ) {
              funcs.set_agent($(this));
            } else if ( ui.draggable.attr('id') == "f_icom" ) {
              funcs.set_icom($(this));
            } else if ( ui.draggable.attr('id') == "f_dtmf" ) {
              funcs.set_dtmf($(this));
            } else if ( ui.draggable.attr('id') == "f_profileled" ) {
              funcs.set_profileled($(this));
            } else if ( ui.draggable.attr('id') == "f_switch" ) {
              var id = parseInt((this.id).substring(4));
              var second_key;
              if ( id > 1000 )
                second_key = id - 1000;
              else
                second_key = id + 1000;
              funcs.set_keyfunc($(this), $(ui.draggable));
              funcs.set_keyfunc($('#pkey'+second_key), $(ui.draggable));
            } else {
              funcs.set_keyfunc($(this), $(ui.draggable));
            }
          }
        });
      }
    }

    $('#display').on('click', function() {
      funcs.click_change_text('display', 8, 'unify-display-texts');
    });

    $('#datetime').on('click', function() {
      funcs.click_change_text('datetime', 2, 'unify-datetime-texts');
    });

  };


  function do_submit(funcs) {
    console.log("Submit");
    funcs.do_submit();
  };

  return {
    init: init,
    do_submit: do_submit
  };
})(PhoneUnify_os15 || {});
window.PhoneUnify_os15 = PhoneUnify_os15;

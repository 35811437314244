jQuery(document).ready(function() {

  if ( $('#user_show_cfwd_forwarding_title').length ) {

    // Ansicht Rufumleitungen
    var trigger = $('#jsdata').data('trigger');
    var show_extended;
    var cf_type;

    // Wert für Radio-Button ermitteln
    function get_cf_value(value) {
      if ( /voicebox/.test(value) ) {
        return 'voicebox';
      }
      if ( /message/.test(value) ) {
        return 'message';
      }
      if ( value == "" ) {
        return 'off';
      }
      return 'number';
    }

    var types = ['cf', 'cf_i', 'cf_e'];
    types.forEach(function(type) {
      $('input[type=radio][name=' + type +']').on('change', function() {
        if ( $(this).val() != 'number' )
          $('#' + type + '_dest').val("");
      });
      $('#' + type + '_dest').click(function(e) {
        $('#' + type + '_number').prop("checked", true);
      });
    });
    $('button[name=extended]').on('click', function() {
      var num = $('#cf_dest').val();
      var v = $('input[type=radio][name=cf]:checked').val();
      if ( v ) {
        $('input[type=radio][name=cf_i]').val([v]);
        $('input[type=radio][name=cf_e]').val([v]);
        if ( num ) {
          $('#cf_i_dest').val(num);
          $('#cf_e_dest').val(num);
        }
      }
      $('.normal_cf').hide();
      $('.extended_cf').show();
      if ( cf_type != 'cfim' )
        $('.extended_cf.show_cfim_sched').hide();
      show_extended = true;
    });
    $('button[name=reduced]').on('click', function() {
      var v_i = $('input[type=radio][name=cf_i]:checked').val();
      var v_e = $('input[type=radio][name=cf_e]:checked').val();
      var dest_i = $('#cf_i_dest').val();
      var dest_e = $('#cf_e_dest').val();
      if ( v_i == v_e && dest_i == dest_e ) {
        $('input[type=radio][name=cf]').val([v_i]);
        $('#cf_dest').val(dest_i);
      } else {
        $('input[type=radio][name=cf]').prop('checked', false);
        $('#cf_dest').val("");
      }
      $('.normal_cf').show();
      if ( cf_type != 'cfim' )
        $('.normal_cf.show_cfim_sched').hide();
      $('.extended_cf').hide();
      show_extended = false;
    });
    $('button[name^=details]').on('click', function() {
      var vals = {};
      vals['type'] = cf_type;
      if ( show_extended ) {
        vals['cf_i'] = $('input[type=radio][name=cf_i]:checked').val();
        vals['cf_e'] = $('input[type=radio][name=cf_e]:checked').val();
        vals['dest_i'] = $('#edit_cfxx #cf_i_dest').val();
        vals['dest_e'] = $('#edit_cfxx #cf_e_dest').val();
        vals['cfim_sched_i'] = $('#edit_cfxx #cfim_sched_i').prop('checked')
        vals['cfim_sched_e'] = $('#edit_cfxx #cfim_sched_e').prop('checked')
      } else {
        vals['cf'] = $('input[type=radio][name=cf]:checked').val();
        vals['dest'] = $('#edit_cfxx #cf_dest').val();
        vals['cfim_sched'] = $('#edit_cfxx #cfim_sched').prop('checked');
      }
      $('#edit_cfxx').modal('hide');
      var type = $(this).attr('name');
      start_spinner_popup();
      $.post('/user/forwardings/set_data', vals, function(data, status) {
        console.log({data});
        window.location = "/user/forwardings/update_cfwd?type=" + type;
      });
    });

    // Speichern der Einstellungen
    $('#edit_cfxx #cfxx-save-button').on('click', function() {
      var vals = {};
      vals['type'] = cf_type;
      if ( show_extended ) {
        vals['cf_i'] = $('input[type=radio][name=cf_i]:checked').val();
        vals['cf_e'] = $('input[type=radio][name=cf_e]:checked').val();
        vals['dest_i'] = $('#edit_cfxx #cf_i_dest').val();
        vals['dest_e'] = $('#edit_cfxx #cf_e_dest').val();
        if ( cf_type == 'cfim' ) {
          vals['cfim_sched_i'] = $('#edit_cfxx #cfim_sched_i').prop('checked')
          vals['cfim_sched_e'] = $('#edit_cfxx #cfim_sched_e').prop('checked')
        } else if ( cf_type == 'cfun' ) {
          vals['timeout'] = $('#edit_cfxx #timeout').val();
        }
      } else {
        vals['cf'] = $('input[type=radio][name=cf]:checked').val();
        vals['dest'] = $('#edit_cfxx #cf_dest').val();
        if ( cf_type == 'cfim' ) {
          vals['cfim_sched'] = $('#edit_cfxx #cfim_sched').prop('checked');
        } else if ( cf_type == 'cfun' ) {
          vals['timeout'] = $('#edit_cfxx #timeout').val();
        }
      }
      $('#edit_cfxx').modal('hide');
      start_spinner_popup();
      $.post('/user/forwardings/set_data', vals, function(data, status) {
        console.log({data});
        window.location = '/user/forwardings';
      });
    });

    // Klick auf einen Link in der Ansicht verarbeiten
    types = ['cfim', 'cfun', 'cfbs', 'cfnocid'];
    types.forEach(function(type) {
      $('#edit_' + type).on('click', function() {
        var title = $(this).data('title');
        start_spinner_popup();
        $.get("/user/forwardings/get_data?type=" + type, function(data, status) {
          console.log({data});
          stop_spinner_popup();
          cf_type = type;
          $('#edit_cfxx_title').text(title);
          $('#edit_cfxx #timeout').val(data['timeout']);
          if ( data['extended_active'] ) {
            $('.normal_cf').hide();
            $('.extended_cf').show();
            if ( type == 'cfim' ) {
              $('#cfim_sched_i').prop('checked', data['cfim_sched_i']);
              $('#cfim_sched_e').prop('checked', data['cfim_sched_e']);
              $('.extended_cf.show_cfim_sched').show();
            } else {
              $('.extended_cf.show_cfim_sched').hide();
            }
            var v = get_cf_value(data[type+'_i']);
            $('input[type=radio][name=cf_i]').val([v]);
            if ( v == 'number' ) {
              $('input[type=text][id=cf_i_dest]').val(data[type+'_i']);
            } else {
              $('input[type=text][id=cf_i_dest]').val("");
            }
            v = get_cf_value(data[type+'_e']);
            $('input[type=radio][name=cf_e]').val([v]);
            if ( v == 'number' ) {
              $('input[type=text][id=cf_e_dest]').val(data[type+'_e']);
            } else {
              $('input[type=text][id=cf_e_dest]').val("");
            }
            show_extended = true;
          } else {
            $('.normal_cf').show();
            $('.extended_cf').hide();
            if ( type == 'cfim' ) {
              $('#cfim_sched').prop('checked', data['cfim_sched']);
              $('.normal_cf.show_cfim_sched').show();
            } else {
              $('.normal_cf.show_cfim_sched').hide();
            }
            var v = get_cf_value(data[type]);
            $('input[type=radio][name=cf]').val([v]);
            if ( v == 'number' )
              $('input[type=text][id=cf_dest]').val(data['destnum']);
            else
              $('input[type=text][id=cf_dest]').val("");
            if ( type == 'cfnocid' )
              $('#enable_extended').hide();
            else
              $('#enable_extended').show();
            show_extended = false;
          }
          if ( type == 'cfun' ) {
            $('#type_is_cfun').show();
          } else {
            $('#type_is_cfun').hide();
          }
          $('#edit_cfxx').modal();
        });
      });
    });
    if ( trigger == 'cfim' )
      $('#edit_cfim').trigger('click');

    // Klick auf einen Link in der Ansicht verarbeiten
    $('#edit_cffb_link').on('click', function() {
      var title = $(this).data('title');
      start_spinner_popup();
      $.get("/user/forwardings/get_data?type=cffb", function(data, status) {
        console.log({data});
        stop_spinner_popup();
        var v = get_cf_value(data['cffb']);
        $('input[type=radio][name=cffb]').val([v]);
        if ( v == 'number' ) {
          $('input[type=text][id=cffb_dest]').val(data['cffb']);
        } else {
          $('input[type=text][id=cffb_dest]').val("");
        }
        $('#edit_cffb #timeout').val(data['cffbto'])
        $('#edit_cffb').modal();
      });
    });
    $('input[type=radio][name=cffb]').on('change', function() {
      if ( $(this).val() != 'number' )
        $('#cffb_dest').val("");
    });
    $('#cffb_dest').click(function(e) {
      $('#cffb_number').prop("checked", true);
    });

    // Speichern der Einstellungen
    $('#edit_cffb #cffb-save-button').on('click', function() {
      var vals = {};
      vals['type'] = 'cffb';
      vals['cf'] = $('input[type=radio][name=cffb]:checked').val();
      vals['dest'] = $('#edit_cffb #cffb_dest').val();
      vals['timeout'] = $('#edit_cffb #timeout').val();
      console.log({vals});
      $('#edit_cffb').modal('hide');
      start_spinner_popup();
      $.post('/user/forwardings/set_data', vals, function(data, status) {
        console.log({data});
        window.location = '/user/forwardings';
      });
    });

    var par_data;
    $('input[type=radio][name=cf-parallel]').on('change', function() {
      if ( $(this).val() == 'on' ) {
        $('.par-enabled').show();
        $('#edit_parallel #par_delay').val(par_data['par_delay']);
        $('#edit_parallel #parallel').val(par_data['parallel']);
        if ( $('input[type=radio][name=cf-delay]:checked').val() == 'on' ) {
          $('.par-delayed').show();
        } else {
          $('.par-delayed').hide();
        }
      } else
        $('.par-enabled').hide();
    });

    $('input[type=radio][name=cf-delay]').on('change', function() {
      if ( $(this).val() == 'on' ) {
        $('.par-delayed').show();
        $('#edit_parallel #par_delay').val(par_data['par_delay']);
      } else
        $('.par-delayed').hide();
    });

    $('#edit_parallel #parallel-save-button').on('click', function() {
      var vals = {};
      vals['type'] = 'parallel';
      var enabled = $('input[type=radio][name=cf-parallel]:checked').val();
      vals['parallel'] = enabled == 'on' ? $('#edit_parallel #parallel').val() : "";
      var delay = $('input[type=radio][name=cf-delay]:checked').val();
      vals['par_delay'] = delay == 'on' ? $('#edit_parallel #par_delay').val() : "";
      vals['par_amd'] = $('#edit_parallel #par_amd').val();
      $('#edit_parallel').modal('hide');
      start_spinner_popup();
      $.post('/user/forwardings/set_data', vals, function(data, status) {
        console.log({data});
        window.location = '/user/forwardings';
      });
    });

    // Klick auf einen Link in der Ansicht verarbeiten
    $('button[name=save_parallel]').on('click', function() {
      start_spinner_popup();
      $.get("/user/forwardings/get_data?type=parallel", function(data, status) {
        console.log({data});
        stop_spinner_popup();
        if ( !data['par_amd'] )
          data['par_amd'] = 0;
        par_data = data;
        var enabled = data['parallel_notset'] ? 'off' : 'on';
        $('input[type=radio][name=cf-parallel]').val([enabled]);
        if ( enabled == 'on' )
          $('.par-enabled').show();
        else
          $('.par-enabled').hide();
        var v = data['delay_notset'] ? 'off' : 'on';
        $('input[type=radio][name=cf-delay]').val([v]);
        if ( v == 'on' ) {
          if ( enabled == 'on' )
            $('.par-delayed').show();
        } else {
          $('.par-delayed').hide();
        }
        $('#edit_parallel #par_delay').val(data['par_delay']);
        $('#edit_parallel #parallel').val(data['parallel']);
        $('#edit_parallel #par_amd').val(data['par_amd']);
        $('#edit_parallel').modal();
      });
    });

    $('#edit_dnd #dnd_add').on('click', function() {
      var vals = {};
      vals['type'] = 'dnd_add';
      vals['dndnew'] = $('#edit_dnd #dndnew').val();
      start_spinner_popup();
      $.post('/user/forwardings/set_data', vals, function(data, status) {
        console.log({data});
        stop_spinner_popup();
        $('#edit_dnd #dndnew').val("");
        var s = "";
        data['dnd_allow'].forEach(function(num) {
          s += '<option>' + num + '</option>';
        });
        $('#edit_dnd select[name="dndallow[]"]').html(s);
      });
    });

    $('#edit_dnd #dnd_del').on('click', function() {
      var v = $('#edit_dnd select[name="dndallow[]"]').val();
      var idx = v.indexOf($('#edit_dnd #dnddest').val());
      if ( idx >= 0 ) {
        v.splice(idx, 1);
      }
      var vals = {};
      vals['type'] = 'dnd_del';
      vals['dndallow'] = v.join(',');
      start_spinner_popup();
      $.post('/user/forwardings/set_data', vals, function(data, status) {
        console.log({data});
        stop_spinner_popup();
        var s = "";
        data['dnd_allow'].forEach(function(num) {
          s += '<option>' + num + '</option>';
        });
        $('#edit_dnd select[name="dndallow[]"]').html(s);
      });
    });

    $('#edit_dnd #dnd-save-button').on('click', function() {
      var vals = {};
      vals['type'] = 'dnd';
      vals['dnd_ena'] = $('input[type=radio][name=dnd_ena]:checked').val();
      vals['dnddest'] = $('#edit_dnd #dnddest').val();
      vals['dnd_int_allow'] = $('#dnd_int_allow').is(":checked");
      $('#edit_dnd').modal('hide');
      start_spinner_popup();
      $.post('/user/forwardings/set_data', vals, function(data, status) {
        console.log({data});
        window.location = '/user/forwardings';
      });
    });

    // Klick auf einen Link in der Ansicht verarbeiten
    $('button[name=save_dnd]').on('click', function() {
      start_spinner_popup();
      $.get("/user/forwardings/get_data?type=dnd", function(data, status) {
        console.log({data});
        stop_spinner_popup();
        $('input[type=radio][name=dnd_ena]').val([data['dnd_ena']]);
        $('#edit_dnd #dnddest').val(data['dnd_dest']);
        var s = "";
        data['dnd_allow'].forEach(function(num) {
          s += '<option>' + num + '</option>';
        });
        $('#edit_dnd select[name="dndallow[]"]').html(s);
        $('#edit_dnd #dndnew').val("");
        $('#dnd_int_allow').prop('checked', data['dnd_int_allow'] == '1')
        $('#edit_dnd').modal();
      });
    });

    $('input[type=radio][name=def_cf').on('change', function() {
      if ( $(this).val() != 'number' )
        $('#def_cf_dest').val("");
    });
    $('#def_cf_dest').click(function(e) {
      $('#def_cf_number').prop("checked", true);
    });

    $('#edit_default_cfwd #defcfwd-save-button').on('click', function() {
      var vals = {};
      vals['type'] = 'defcfwd';
      vals['preferred_cfwd_sel'] = $('input[type=radio][name=def_cf]:checked').val();
      vals['preferred_cfwd_num'] = $('#edit_default_cfwd #def_cf_dest').val();
      $('#edit_default_cfwd').modal('hide');
      start_spinner_popup();
      $.post('/user/forwardings/set_data', vals, function(data, status) {
        console.log({data});
        window.location = '/user/forwardings';
      });
    });

    // Klick auf einen Link in der Ansicht verarbeiten
    $('button[name=save_default_cfwd]').on('click', function() {
      var v = $(this).data('cfwd');
      if ( v == 'voicebox' || v == 'message' ) {
        $('input[type=radio][name=def_cf]').val([v]);
        $('#edit_default_cfwd #def_cf_dest').val("");
      } else {
        $('input[type=radio][name=def_cf]').val(['number']);
        $('#edit_default_cfwd #def_cf_dest').val(v);
      }
      $('#edit_default_cfwd').modal();
    });

    $('#edit_own_mobile #own_mobile-save-button').on('click', function() {
      var vals = {};
      vals['type'] = 'own_mobile';
      vals['own_mobile'] = $('#edit_own_mobile #own_mobile').val();
      $('#edit_own_mobile').modal('hide');
      start_spinner_popup();
      $.post('/user/forwardings/set_data', vals, function(data, status) {
        console.log({data});
        window.location = '/user/forwardings';
      });
    });

    // Klick auf einen Link in der Ansicht verarbeiten
    $('button[name=save_own_mobile]').on('click', function() {
      $('#edit_own_mobile #own_mobile').val($(this).data('mobile'));
      $('#edit_own_mobile').modal();
    });

    function set_current(value) {
      if ( /^[0-9\+]+$/.test(value) ) {
        $('input[type=radio][name=on_state]').val(['number']);
        $('#teams_select_fwd input#number').val(value);
      } else {
        $('input[type=radio][name=on_state]').val([value]);
        $('#teams_select_fwd input#number').val("");
      }
    }

    $('#set_on_busy').on('click', function() {
      var t = $('div[id=teams-state_text]').data('busy');
      $('.fwd_type').text(t);
      set_current($(this).data('current'));
      $('#teams_select_fwd #save-button').data("type", "busy");
      $('#system_setting').text($(this).data('sys-text'));
      $('#teams_select_fwd').modal();
    });

    $('#set_on_dnd').on('click', function() {
      var t = $('div[id=teams-state_text]').data('dnd');
      $('.fwd_type').text(t);
      set_current($(this).data('current'));
      $('#teams_select_fwd #save-button').data("type", "dnd");
      $('#system_setting').text($(this).data('sys-text'));
      $('#teams_select_fwd').modal();
    });

    $('#set_on_away').on('click', function() {
      var t = $('div[id=teams-state_text]').data('away');
      $('.fwd_type').text(t);
      set_current($(this).data('current'));
      $('#teams_select_fwd #save-button').data("type", "away");
      $('#system_setting').text($(this).data('sys-text'));
      $('#teams_select_fwd').modal();
    });

    $('#set_on_offline').on('click', function() {
      var t = $('div[id=teams-state_text]').data('offline');
      $('.fwd_type').text(t);
      set_current($(this).data('current'));
      $('#teams_select_fwd #save-button').data("type", "offline");
      $('#system_setting').text($(this).data('sys-text'));
      $('#teams_select_fwd').modal();
    });

    $('#teams_select_fwd input#number').on('focusin', function(e) {
      $('input[type=radio][name=on_state]').val(['number']);
    });

    $('input[type=radio][name=on_state]').on('click', function() {
      var v = $(this).val();
      if ( v != 'number' )
        $('#teams_select_fwd input#number').val("");
    });

    $('#teams_select_fwd #save-button').on('click', function() {
      var value = $('input[type=radio][name="on_state"]:checked').val();
      if ( value == 'number' ) {
        value = $('#teams_select_fwd input#number').val();
      }
      var key = $(this).data('type');
      var vals = {};
      vals['type'] = 'teams';
      vals['key'] = 'on_teams_' + key;
      vals['value'] = value;
      $('#teams_select_fwd').modal('hide');
      start_spinner_popup();
      $.post('/user/forwardings/set_data', vals, function(data, status) {
        console.log({data});
        window.location = '/user/forwardings';
      });
    });

  }

});

jQuery(document).ready(function() {
  
  if ( $('#mobility_title').length ) {
    
    if ( $('#mobile_client_enable_internal').prop('checked') ) {
      $('#show_internal').show();
    } else {
      $('#show_internal').hide();
    }
    $('#mobile_client_enable_internal').click(function() {
      if ( $('#mobile_client_enable_internal').prop('checked') ) {
        $('#show_internal').show();
      } else {
        $('#show_internal').hide();
      }
    });
    if ( $('#mobile_client_enable_callthrough').prop('checked') ) {
      $('#show_callthrough').show();
    } else {
      $('#show_callthrough').hide();
    }
    $('#mobile_client_enable_callthrough').click(function() {
      if ( $('#mobile_client_enable_callthrough').prop('checked') ) {
        $('#show_callthrough').show();
      } else {
        $('#show_callthrough').hide();
      }
    });
    
  }
});

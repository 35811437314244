import { block_enable } from '../iptam.js';

jQuery(document).ready(function() {

  if( $('#passwords_title').length ) {

    // Ein- und Ausblenden der Teilbereiche
    $('#h1-enable').css('cursor', 'pointer');
    $('#h2-enable').css('cursor', 'pointer');
    $('#h3-enable').css('cursor', 'pointer');
    $('#h4-enable').css('cursor', 'pointer');
    block_enable(1);
    block_enable(2);
    block_enable(3);
    block_enable(4);

    var exten_count = $('#jsdata').data('exten-count');
    var user_count = $('#jsdata').data('user-count');
    var faxexten_count = $('#jsdata').data('faxexten-count');
    var hint_empty = $('#jsdata').data('hint-empty');
    var hint_title = $('#jsdata').data('hint-title');
    var email_suggestion = $('#jsdata').data('email-suggestion');

    /*-------------------------------------------------------------------------
     * Einstellungen zu Web-GUI-Passwörtern
     *------------------------------------------------------------------------*/
    // Speichern der Einstellungen aus dem GUI-Modal Dialog
    $('#guipw-save-button').on('click', function() {
      var v = $("input[type=radio][name=gui_pw_create]:checked").val();
      if ( v == 'new' ) {
        $('#system_change_gui_auto_mode').val("new");
        $('#gui_auto_pw_dialog').modal('hide');
      } else if ( v == 'all' ) {
        $('#system_change_gui_auto_mode').val("all");
        $('#gui_auto_pw_dialog').modal('hide');
      } else if ( v == 'empty' ) {
        $('#system_change_gui_auto_mode').val("empty_only");
        $('#gui_auto_pw_dialog').modal('hide');
      }
    });

    // Bei Änderung der GUI-Passwort Einstellung: ggf. Modal-Dialog öffnen
    $('input[type=radio][name="system[gui_auto_pw]"]').change(function() {
      if ( $('input[type=radio][name="system[gui_auto_pw]"]:checked').val() != 'true' ) {
        $('#show_email_notify').hide();
      } else {
        $('#show_email_notify').show();
        if ( user_count > 0 ) {
          $('#system_change_gui_auto_mode').val("");
          $('#gui_auto_pw_dialog').modal();
        }
      }
    });

    // Initial: Ein- oder Ausblenden des E-Mail Notify Hinweises
    if ( $('input[type=radio][name="system[gui_auto_pw]"]:checked').val() != 'true' ) {
      $('#show_email_notify').hide();
    } else {
      $('#show_email_notify').show();
    }

    $('#system_auto_pw_notify').on('change', function() {
      if ( $(this).prop('checked') )
        $('#show_notify_action').show();
      else
        $('#show_notify_action').hide();
    });
    $('#system_auto_pw_notify').change();

    // Speichern der E-Mail Benachrichtigung aus dem Modal-Dialog
    $('#email-save-button').on('click', function() {
      $('#system_pw_notify_email').val($('#email_body').val());
      $('#system_pw_notify_is_html').val($('#pw_notify_is_html').prop('checked'));
      if ( $('#email_body').val().length > 0 )
        $('#edit_email_notify').attr('data-original-title', "<b>" + hint_title + "</b><p>" + $('#email_body').val() + "</p>");
      else
        $('#edit_email_notify').attr('data-original-title', "<b>" + hint_title + "</b><p>" + hint_empty) + "</p>";        
      $('#edit_email_dialog').modal('hide');
    });

    // Modifizieren der E-Mail Benachrichtigung
    $('#edit_email_notify').on('click', function() {
      $('#email_body').val($('#system_pw_notify_email').val());
      var v = $('#system_pw_notify_is_html').val();
      $('#pw_notify_is_html').prop('checked', v=="true");
      $('#edit_email_dialog').modal();
    });

    // Speichern der Einstellungen aus dem FAX-Modal Dialog
    $('#send-testmail-button').on('click', function() {
      $('#send_testmail').modal('hide');
      start_spinner_popup();
      var dest = $('#destination').val();
      var mailbody = $('#edit_email_notify').attr('data-original-title');
      var is_html = $('#pw_notify_is_html').prop('checked')
      $.post("/admin/passwords/send_testmail", {destination: dest, text: mailbody, is_html: is_html}, function(data, status) {
        stop_spinner_popup();
        if ( data == "Error" )
          $('#show_error_result').modal();
      });
    });

    // Anzeige der offenen Benachrichtigungen
    var user_without_email;
    var table1, table2;
    $('#link_show_notifies').on('click', function() {
      start_spinner_popup();
      $.get("/admin/passwords/show_notifies", function(data, status) {
        stop_spinner_popup();
        for ( var i=0; i < data.open.length; i++ ) {
          var line = "<tr><td>" + data.open[i]['name'] + "</td><td>" + data.open[i]['type'] + "</td><td>" + data.open[i]['email'] + "</td></tr>";
          $('#show_open_notifies tbody').append(line);
        }
        table1 = activate_tables('#open_notifications', false);
        if ( data.noemail.length > 0 ) {
          user_without_email = data.noemail;
          $('#show_open_notifies #noemail').text(data.noemail.length)
          $('#show_open_notifies #show_noemail').show();
        } else {
          $('#show_open_notifies #show_noemail').hide();          
        }
        $('#show_open_notifies').modal();
      });
    });

    // Anzeige der Anwender ohne E-Mail
    $('#link_show_noemail').on('click', function() {
      for ( var i=0; i < user_without_email.length; i++ ) {
        var line = "<tr><td>" + user_without_email[i]['name'] + "</td><td>" + user_without_email[i]['type'] + "</td></tr>";
        $('#show_list_noemail tbody').append(line);
      }
      table2 = activate_tables('#noemail_notifications', false);
      $('#show_open_notifies').modal('hide');
      $('#show_list_noemail').modal();
    });

    $('#copy_suggestion').on('click', function() {
      $('#email_body').val(email_suggestion);
    });

    $('#show_open_notifies').on('hidden.bs.modal', function() {
      table1.destroy();
      $('#show_open_notifies tbody tr').remove();
    });

    $('#show_list_noemail').on('hidden.bs.modal', function() {
      table2.destroy();
      $('#show_list_noemail tbody tr').remove();
    });

    // IP-Sperrdialog für fehlerhafte Logins
    $('#block_logins_configure').on('click', function() {
      $('#block_logins_dialog').modal();
    });

    $('#block_logins_dialog #save-button').on('click', function() {
      var vals = {};
      vals['active'] = $('#active').prop('checked');
      vals['max_failed'] = $('#max_failed').val();
      vals['bantime'] = $('#bantime').val();
      start_spinner_popup();
      $.post("/admin/passwords/set_block_ip_values", vals, function(data, status) {
        window.location = '/admin/passwords';
      });
    });

    var ip_table;
    $('#blocked_ips_list').on('click', function() {
      start_spinner_popup();
      $.get('/admin/passwords/get_blocked_ips', function(data) {
        console.log({data})
        stop_spinner_popup();
        for ( var i=0; i < data.length; i++ ) {
          var line = "<tr><td>" + data[i]['ip'] + "</td><td>" + data[i]['last_access'] + "</td></tr>";
          $('#table-blocked_ips tbody').append(line);
        }
        ip_table = activate_tables('#table-blocked_ips', false);
        $('#ip_blockedlist').modal();
      });
    });

    $('#ip_blockedlist').on('hidden.bs.modal', function() {
      ip_table.destroy();
      $('#table-blocked_ips tbody > tr').remove();
    });

    /*-------------------------------------------------------------------------
     * Einstellungen zu SIP-Passwörtern
     *------------------------------------------------------------------------*/
    // Speichern der Einstellungen aus dem SIP-Modal Dialog
    $('#sippw-save-button').on('click', function() {
      var v = $("input[type=radio][name=sip_pw_create]:checked").val();
      if ( v == 'new' ) {
        $('#system_change_sip_auto_mode').val("new");
        $('#sip_auto_pw_dialog').modal('hide');
      } else if ( v == 'all' ) {
        $('#system_change_sip_auto_mode').val("all");
        $('#sip_auto_pw_dialog').modal('hide');
      } else if ( v == 'empty' ) {
        $('#system_change_sip_auto_mode').val("empty_only");
        $('#sip_auto_pw_dialog').modal('hide');
      }
    });
  
    // Bei Änderung der SIP-Passwort Einstellung: ggf. Modal-Dialog öffnen
    $('input[type=radio][name="system[sip_auto_pw]"]').change(function() {
      if ( exten_count > 0 && $(this).val() == "true" ) {
        $('#system_change_sip_auto_mode').val("");
        $('#sip_auto_pw_dialog').modal();
      }
    });

    /*-------------------------------------------------------------------------
     * Einstellungen zu Telefon-Passwörtern
     *------------------------------------------------------------------------*/
    // Bei Änderung der Telefon-Passwort Einstellungen Ein- oder Ausblenden
    $('input[type=radio][name="provisioning[user_static_pw]"]').change(function() {
      if ( $('input[type=radio][name="provisioning[user_static_pw]"]:checked').val() != 'true' ) {
        $('#show_userpw').hide();
      } else {
        $('#show_userpw').show();
      }
    });
    $('input[type=radio][name="provisioning[user_static_pw]"]').change();

    $('input[type=radio][name="provisioning[admin_static_pw]"]').change(function() {
      if ( $('input[type=radio][name="provisioning[admin_static_pw]"]:checked').val() != 'true' ) {
        $('#show_adminpw').hide();
      } else {
        $('#show_adminpw').show();
      }
    });
    $('input[type=radio][name="provisioning[admin_static_pw]"]').change();

    /*-------------------------------------------------------------------------
     * Einstellungen zu Fax-Cleint-Passwörtern
     *-------------------------------------------------------------------------*/
    // Speichern der Einstellungen aus dem FAX-Modal Dialog
    $('#faxpw-save-button').on('click', function() {
      var v = $("input[type=radio][name=fax_pw_create]:checked").val();
      if ( v == 'new' ) {
        $('#system_change_fax_auto_mode').val("new");
        $('#fax_auto_pw_dialog').modal('hide');
      } else if ( v == 'all' ) {
        $('#system_change_fax_auto_mode').val("all");
        $('#fax_auto_pw_dialog').modal('hide');
      } else if ( v == 'empty' ) {
        $('#system_change_fax_auto_mode').val("empty_only");
        $('#fax_auto_pw_dialog').modal('hide');
      }
    });
  
    // Bei Änderung der FAX-Passwort Einstellung: ggf. Modal-Dialog öffnen
    $('input[type=radio][name="system[fax_auto_pw]"]').change(function() {
      if ( faxexten_count > 0 && $(this).val() == "true" ) {
        $('#system_change_fax_auto_mode').val("");
        $('#fax_auto_pw_dialog').modal();
      }
    });

  }
});

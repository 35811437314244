var PhoneGigaset_n720 = (function(modul) {

  var ext_info;

  function init(pdata, funcs) {
    console.log("Telefon " + pdata.vendor + " " + pdata.model);
    funcs.set_initial_data("", {});
    var config = funcs.get_configuration();

    if ( $('#support_g722').length > 0 ) {
      $('#support_g722').prop('checked', config.support_g722 ? true : false);
    }

    /* PIN: Focus setzen, wenn PIN nicht gesetzt oder Default-PIN ist */
    $('#pin').val(config.pin);
    if ( config.pin === '' || config.pin == '0000' ) {
      $('#pin').focus();
    }
  };


  function do_submit(funcs) {
    console.log("Submit");
    var pin = $('#pin').val();
    if ( pin === '' || pin == '0000' || pin.length != 4 ) {
      $('#dect-pin-warn-form').modal();
      return false;
    }

    var support_g722 = -1;
    if ( $('#support_g722').length > 0 ) {
      support_g722 = $('#support_g722').is(":checked");
    }
    funcs.update_dect(pin, support_g722, undefined, undefined, undefined);
    funcs.do_submit();
  };

  return {
    init: init,
    do_submit: do_submit
  };

})(PhoneGigaset_n720 || {});
window.PhoneGigaset_n720 = PhoneGigaset_n720;

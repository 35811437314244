var PhoneGigaset_max_3 = (function(modul) {

  function init(pdata, funcs) {
    console.log("Telefon " + pdata.vendor + " " + pdata.model);
    // Tastenbreite ermitteln (Unter Display) Tasten-ID und Anzahl
    funcs.set_softkeywidth('#dkey1', 5);
    funcs.set_initial_data(undefined, {});
    
    for (var i = 1; i <= 8; i++) {
      $('#pkey'+i).droppable({
        accept: '.key',
        hoverClass: 'highlight-target',
        drop: function(event, ui) {
          if ( ui.draggable.attr('id') == "f_line" ) {
            funcs.set_line($(this), true);
          } else if ( ui.draggable.attr('id') == "f_blf" ) {
            funcs.set_blf($(this), true);
          } else if ( ui.draggable.attr('id') == "f_direct" ) {
            funcs.set_direct($(this), true);
          } else if ( ui.draggable.attr('id') == "f_cfwdgrp" ) {
            funcs.set_cfwdgrp($(this), true);
          } else if ( ui.draggable.attr('id') == "f_agent" ) {
            funcs.set_agent($(this), true);
          } else if ( ui.draggable.attr('id') == "f_icom" ) {
            funcs.set_icom($(this), true);
          } else if ( ui.draggable.attr('id') == "f_dtmf" ) {
            funcs.set_dtmf($(this), true);
          } else if ( ui.draggable.attr('id') == "f_profileled" ) {
            funcs.set_profileled($(this), true);
          } else if ( ui.draggable.attr('id') == "f_action" ) {
            funcs.set_action_url($(this), true);
          } else if ( ui.draggable.attr('id') == "f_nconfig" || ui.draggable.attr('id') == "f_noop" ) {
            funcs.set_keyfunc($(this), $(ui.draggable), false);
          } else {
            funcs.set_keyfunc($(this), $(ui.draggable), true);
          }
        }
      });
    }

    var acc = function(drag) {
      // Nur die glisteten Tasten zulasssen
      return ['f_direct', 'f_icom', 'f_nconfig', 'f_noop' ].indexOf(drag.attr('id')) >= 0;
    };
    for (var i = 1; i <= 4; i++) {
      $('#dkey'+i).droppable({
        accept: acc,
        hoverClass: 'highlight-target',
        drop: function(event, ui) {
          if ( ui.draggable.attr('id') == "f_direct" ) {
            funcs.set_direct($(this));
          } else if ( ui.draggable.attr('id') == "f_icom" ) {
            funcs.set_icom($(this));
          } else {
            funcs.set_keyfunc($(this), $(ui.draggable));
          }
        }
      });
    }

    $('#dirkey').on('click', function() {
      funcs.click_change_text('dirkey', 1, 'gigaset-dirkey-texts');
    });

    $('#username').on('click', function() {
      funcs.click_change_text('username', 4, 'phone-name-texts');
    });
  };


  function do_submit(funcs) {
    console.log("Submit");
    funcs.do_submit();
  };

  return {
    init: init,
    do_submit: do_submit
  };
})(PhoneGigaset_max_3 || {});
window.PhoneGigaset_max_3 = PhoneGigaset_max_3;

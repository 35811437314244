import { activate_tables } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#faxstatus_title').length ) {
    var table;

    if ( $('input[type=radio][name="which"]:checked').val() ) {
      if ($('#faxstatus-table').find('thead > tr > th').length == 7 )
        table = activate_tables('#faxstatus-table', false, [-1]);
      else
        table = activate_tables('#faxstatus-table');
    }
    
    $('input[type=radio][name="which"]').on('change', function() {
      $('form[action$=lookup]').submit();
    });

    if ( table !== undefined ) {
      table.on( 'draw', function () {
        $("td.logfile").on('click', function (event) {
          if ( event.ctrlKey && event.altKey ) {
            var logid = $(this).data("logid");
            if ( logid )
              window.location = "/admin/faxstatus/download/" + logid;
          }
        });
      });
    }

    $("td.logfile").on('click', function (event) {
      if ( event.ctrlKey && event.altKey ) {
        var logid = $(this).data("logid");
        if ( logid )
          window.location = "/admin/faxstatus/download/" + logid;
      }
    });
  }

});

import { activate_tables } from '../iptam.js';
import { initial_setup_number_pattern, pattern_list } from '../number_patterns.js';

jQuery(document).ready(function() {

  if ( $('#sbr-table').length ) {
    /* Index-Seite: Tabelle aktivieren, erste und letzte Spalte ohne Sortierfunktion */
    var table = activate_tables('#sbr-table', false, [0, -1]);

    table.on( 'draw', function () {
      $('.alert').remove();
      $('.delete_entry').on('click', function() {
        delete_entry($(this));
      });
    });
    
    $('.delete_entry').on('click', function() {
      delete_entry($(this));
    });
    
    // Löschen eines Eintrags
    var entry_id;
    function delete_entry(entry) {
      entry.tooltip('hide');
      entry_id = entry.data('entry-id');
      $('#name').html(entry.data('name'));
      if ( entry.data('pattern').length ) {
        $('#pattern').html(entry.data('pattern'));
        $('#dest').html(entry.data('dest'));
        $('#is_active').show();
      } else {
        $('#is_active').hide();
      }
      $('#srcbased_destroy').modal();
    };

    /* Löschen tatsächlich ausführen */
    $('#delete-button').on('click', function() {
      $('#delete-form').attr('action', '/admin/srcbased/' + entry_id);
      $('#delete-form').submit();
    });
  }

  if ( $('#sbr_edit_title').length ) {

    /* Edit-Seite: Bearbeiten des Musters */

    var update_pattern_table = function() {
      var first_empty_line = -1;
      var x = 0;
      $(".lcr-rule").each(function(index) {
        var len = $(this).find('.lcr-dragbutton').length;
        x = ( len == 1 );
        if ( first_empty_line < 0 && len == 0 )
          first_empty_line = index;
      });
      var result = "";
      $(".lcr-rule").each(function(index) {
        if ( index > first_empty_line )
          $(this).hide();
        else
          $(this).show();
        var line = "";
        $(this).find('.lcr-dragbutton').each(function(index) {
          var button = $(this).text().trim();
          jQuery.each(pattern_list, function(idx, val) {
            if ( button == val ) {
              if ( line.length == 0 )
                line = idx.toString();
              else
                line += ',' + idx.toString();
            }
          });
        });
        if ( line.length > 0 ) {
          if ( result.length == 0 )
            result = line;
          else
            result += ';' + line;
        }
      });
      $('#sbr_rule_regex').val(result);
      var regex_text = "";
      var single = result.split(';');
      if ( result.length > 0 ) {
        for ( var i=0; i < single.length; i++ ) {
          var r;
          if ( /,/.test(single[i]) ) {
            var part = single[i].split(',');
            r = '[';
            for ( var j=0; j < part.length; j++ ) {
              r += pattern_list[part[j]];
            }
            r += ']';
          } else {
            r = pattern_list[single[i]];
          }
          regex_text += r;
        }
      }
      if ( regex_text == '' )
        regex_text = '[]';
      $('#current_regex').html(regex_text);
      if ( $('#old_regex').text() != regex_text )
        $('#current_regex').css('color', 'red');
      else
        $('#current_regex').css('color', '');
    }

    if ( $('#sbr_rule_regex').length ) {
      initial_setup_number_pattern($('#sbr_rule_regex').val(), update_pattern_table);
    }
  }

});

jQuery(document).ready(function() {

  if ( $('div #record-form').length > 0 ) {

    var upload_file_type;
    var type_of_action;
    var prompt_name;

    /* Autofocus beim Erscheinen der Dialog-Box */
    $('#select_phone').on('shown.bs.modal', function() {
      if ( $('#phone_own').length == 0 ) {
        $(this).find('#number').focus();
      }
    });

    $('#select_phone').keypress(function(e) {
      if ( e.which == 13) {
        $(this).find('#save-button').click();
        return false;
      }
    });

    $('#select_phone #number').click(function(e) {
      $('#phone_number').prop("checked", true);
    });

    $('input[type=radio][name=phone]').on('change', function() {
      if ( $(this).val() != 'number' )
        $('#number').val("");
    });

    /* Speichern Telefonauswahl und Aufnahme / Abspielen ausführen */
    $('#select_phone #save-button').on('click', function() {
      var phone = $('input[type=radio][name=prompt]:checked').val();
      var number = $('#number').val();
      var vals = get_current_vals();
      vals['what'] = type_of_action;
      vals['type'] = prompt_name;
      vals['phone'] = (phone == 'own' || !number) ? 'own' : number;
      $('#select_phone').modal('hide');
      $.post("/admin/records/ajax_update", vals, function(data, status) {
      });
    });

    var records_set_event_handler = function() {

      /* File-Upload Ansagen */
      $('button[name=prompt_load]').on('click', function(e) {
        var file_type = $(this).data('file-type');
        var mode = $("input[type='radio'][name=mode]:checked").val();
        set_upload("Sprachmenü zur Gesprächsaufzeichnung", file_type, mode);
        upload_file_type = "prompt";
      });
      $('button[name=ack_msg_load]').on('click', function(e) {
        var file_type = $(this).data('file-type');
        var mode = $("input[type='radio'][name=mode]:checked").val();
        set_upload("Zustimmung zur Aufzeichnung", file_type, mode);
        upload_file_type = "ack_msg";
      });
      $('button[name=nack_msg_load]').on('click', function(e) {
        var file_type = $(this).data('file-type');
        var mode = $("input[type='radio'][name=mode]:checked").val();
        set_upload("Ablehnung der Aufzeichnung", file_type, mode);
        upload_file_type = "nack_msg";
      });

      /* Aufnehmen von Ansagen via Telefon */
      $('button[name$=rec]').on('click', function(e) {
        console.log("Record click");
        type_of_action = 'record';
        prompt_name = this.name.substring(0, this.name.length-4);
        $('#select_phone').modal();
      });

      /* Abspielen von Ansagen via Telefon */
      $('button[name$=play]').on('click', function(e) {
        type_of_action = 'play';
        prompt_name = this.name.substring(0, this.name.length-5);
        $('#select_phone').modal();
      });

      /* Löschen von Ansagen */
      $('button[name$=del]').on('click', function(e) {
        var name = this.name.substring(0, this.name.length-4);
        $('#record_' + name).val("");
        var vals = get_current_vals();
        $.post("/admin/records/ajax_update", vals, function(data, status) {
        });
      });

      /* Dialog zur Auswahl der Nebenstellen */
      $('button[name=change_extensions]').on('click', function(e) {
        var selected_extens = $('#record_extensions').val();
        $('[data-toggle="tooltip"]').tooltip('hide');
        start_spinner_popup();
        $.post("/admin/records/edit_extens", {'record[extensions]': selected_extens}, function(data, status) {
        });
      });

      /* Speichern des File-Uploads */
      $('#upload-button').on('click', function() {
        var form_data = new FormData(jQuery('#upload-form')[0]);
        form_data.append('what', 'upload');
        var vals = get_current_vals();
        Object.keys(vals).forEach(function (key) {
          form_data.append(key, vals[key]);
        });
        form_data.append('type', upload_file_type);
        var opts = {
          url: '/admin/records/ajax_update',
          data: form_data,
          cache: false,
          contentType: false,
          processData: false,
          method: 'POST',
          success: function(data) {
          }
        };
        $('#file_upload').on('hidden.bs.modal', function (e) {
          jQuery.ajax(opts);
        });
        $('#file_upload').modal('hide');
      });
    };

    records_set_event_handler();  /* Setzen Event-Handler, s.a. record_xxx.js.erb */
    window.records_set_event_handler = records_set_event_handler;

    show_hide_on_demand = function() {
      if ( $("input[type='radio'][name=mode]:checked").val() == "2" && $('#record_extensions').val() == "ext_any" )
        $('#show_extensions').hide();
      else
        $('#show_extensions').show();
    };

    show_hide_on_demand();

    function set_upload(title, filetype, mode) {
      $('[data-toggle="tooltip"]').tooltip('hide');
      $('h5').html(title);
      $('#file_type').html(filetype);
      $('#file_upload').modal();
    }

    function get_current_vals() {
      var mode = $("input[type='radio'][name=mode]:checked").val();
      var keys = ['menucode_on', 'menucode_off', 'timeout', 'del_after', 'menu_noselection', 'prompt', 'ack_msg', 'nack_msg', 'code', 'extensions'];
      var result = {};
      result['record[mode]'] = mode;
      for(var i=0; i<keys.length; i++) {
        var k1 = '#record_' + keys[i];
        var k2 = 'record[' + keys[i] + ']';
        result[k2] = $(k1).val();
      }
      return result;
    }

    $('input[type=radio][name=mode').change(function() {
      var vals = get_current_vals();
      $.post("/admin/records/ajax_update", vals, function(data, status) {
      });
    });
  }
//   TODO Aktionen beim Verlassen des Modal (positiv wie negativ), entfernen Dropzone
//   $( "form" ).on( "submit", false );
//   $("form[action='/admin/records/update']").on('submit', function(e) {
//     alert("Wert " + $("input[type=radio][name=mode]:checked").val());
//     $('#record_mode').val($("input[type=radio][name=mode]:checked").val());
// //     $('#record_mode').val($("input[type='radio'][name=mode]:checked").val());
// //     $('#record_mode').val(1);
//   });
});

import { activate_tables, start_spinner_popup } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#ringtones_title').length ) {
    activate_tables('#ringtones-table', false, [-1]);

    var entry_id;
    $('.edit_entry').on('click', function() {
      $(this).tooltip('hide');
      entry_id = $(this).data('entry-id');
      $('#file_upload .modal-title').html($(this).data('dia-title'));
//       $('#file_upload #upload-button').attr('data-filetype', $(this).data('file-type'));
      $('#file_upload #upload-button').attr('data-entry_id', entry_id);
      $('#file_upload #file_type').html($(this).data('file-type'));
      $('#file_upload').modal();
    });

    /* Speichern des File-Uploads */
    $('#upload-button').on('click', function() {
      var formData = new FormData(jQuery('#upload-form')[0]);
      var entry_id = $('#file_upload #upload-button').attr('data-entry_id');
      formData.append('id', entry_id);
      $.ajax({
        url: '/admin/ringtones/' + entry_id + '/ajax_update',
        method: 'POST',
        data: formData,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          console.log({data})
          $('#file_upload').modal('hide');
          start_spinner_popup();
          window.location = "/admin/ringtones";
        }
      });
    });

    $('.delete_entry').on('click', function() {
      $(this).tooltip('hide');
      entry_id = $(this).data('entry-id');
      $('#ringtone_destroy').modal();
    });

    /* Löschen tatsächlich ausführen */
    $('#delete-button').on('click', function() {
      $('#delete-form').attr('action', '/admin/ringtones/' + entry_id);
      $('#delete-form').submit();
    });

  }

});

import { directory_entry_id } from '../edit_dir_entry_modal.js'

jQuery(document).ready(function() {

  if ( $('#user_voicemail_new-table').length || $('#user_voicemail_old-table').length ) {
    var entry_id, entry_type, url, pb_number;

    if ( $('a[id=refresh-all]').length ) {
      url = '/user/voicemail';
    } else if ( $('a[id=refresh-new]').length ) {
      url = '/user/voicemail/new';
    } else if ( $('a[id=refresh-old]').length ) {
      url = '/user/voicemail/old';
    }

    // Events in der Tabelle
    var set_event_handler_table = function(type) {

      // Download aus der Liste
      $('a[class=download]').on('click', function() {
        $('[data-toggle="tooltip"]').tooltip('hide');
        if ( type == $(this).data('dir') ) {
          var vals = {};
          vals['what'] = 'download';
          vals['type'] = $(this).data('dir');
          vals['id'] = $(this).data('id');
          $.post("/user/voicemail/ajax_update", vals, function(data, status) {
            if ( data.download ) {
              window.location = '/user/voicemail/download?fn=' + data.filename;
            }
          });
        }
      });

      // Wählen aus der Liste
      $('a[class=dial]').on('click', function() {
        $('[data-toggle="tooltip"]').tooltip('hide');
        if ( type == $(this).data('dir') ) {
          var vals = {};
          vals['what'] = 'dial';
          vals['type'] = $(this).data('dir');
          vals['id'] = $(this).data('id');
          $.post("/user/voicemail/ajax_update", vals, function(data, status) {
            console.log({data});
          });
        }
      });

      $('a[class=delete]').on('click', function() {
        if ( type == $(this).data('dir') ) {
          entry_id = $(this).data('id');
          entry_type = $(this).data('dir');
          $('#voicemail_destroy').modal();
        }
      });

      // Bearbeiten für das Telefonbuch aus der Liste
      $('a[class=edit]').on('click', function() {
        $('[data-toggle="tooltip"]').tooltip('hide');
        if ( type == $(this).data('dir') ) {
          entry_id = $(this).data('id');
          entry_type = $(this).data('dir');
          pb_number = $(this).data('number');
          $('input[type=radio][name=dir_type]').prop('checked', false);
          $('input[type=radio][name=use]').prop('checked', false);
          $('#search').val("");
          $('#edit_options .new_entry').hide();
          $('#edit_options .existing_entry').hide();
          $('#edit_options #type_long').val("");
          $('#edit_options #type_short').val("");
          $('#edit_options #type_long_sel').val("");
          $('#edit_options #directory_cont').prop('checked', false);
          $('#error_no_dir_type').hide();
          $('#error_no_edit_type').hide();
          $('#error_no_contact_sel').hide();
          $('#error_no_contact_data').hide();
          $('#error_no_number_type').hide();
          $('#edit_options').modal();          
        }
      });

      // Prüfen, ob mindestens ein Eintrag zum Löschen markiert ist
      $('input[type=checkbox][id^=del_' + type + ']').on('change', function() {
        var len = $('input[type=checkbox][id^=del_' + type + ']:checked').length;
        if ( len == 0 ) {
          $('a[id=delete-all-' + type + ']').addClass('btn-link disabled');
          $('a[id=delete-all-' + type + '] i[class~=fa-trash-alt]').addClass('fa-disabled');
        } else {
          $('a[id=delete-all-' + type + ']').removeClass('btn-link disabled');
          $('a[id=delete-all-' + type + '] i[class~=fa-trash-alt]').removeClass('fa-disabled');
        }
      });
    }

    // Events außerhalb der Tabelle
    var set_event_handler_delete = function(type) {

      var used_type;

      // Alle Markieren / Markierung aufheben
      $('#mark-all-'+type).on('click', function() {
        $(this).tooltip('hide');
        var state = $(this).data('state');
        var new_state, new_title;
        if ( state == 'on' ) {
          new_state = 'off';
          new_title = $(this).data('label-unmark');
          $('input[type=checkbox][id^=del_' + type + ']').prop('checked', true);
          $('a[id=delete-all-' + type + ']').removeClass('btn-link disabled');
          $('a[id=delete-all-' + type + '] i[class~=fa-trash-alt]').removeClass('fa-disabled');
        } else if ( state == 'off' ) {
          new_state = 'on';
          new_title = $(this).data('label-mark');
          $('input[type=checkbox][id^=del_' + type + ']').prop('checked', false);
          $('a[id=delete-all-' + type + ']').addClass('btn-link disabled');
          $('a[id=delete-all-' + type + '] i[class~=fa-trash-alt]').addClass('fa-disabled');
        }
        $(this).data('state', new_state);
        $(this).attr('data-original-title', new_title);
      });

      // Löschen der markierten Einträge
      $('#delete-all-' + type).on('click', function() {
        used_type = type;
        $('#voicemail_destroy_all').modal();
      });

      // Löschen Button im Dialog "Alle löschen" betätigt
      $('#voicemail_destroy_all #delete-button').on('click', function() {
        if ( !used_type )
          return;

        var list = [];
        $('input[type=checkbox][id^=del_' + type + ']:checked').each(function() {
          list.push($(this).val());
        });

        var vals = {};
        vals['what'] = 'delete_multi';
        vals['type'] = used_type;
        vals['list'] = list.join(',');
        used_type = undefined;
        $('#voicemail_destroy_all').modal('hide');
        start_spinner_popup();
        $.post("/user/voicemail/ajax_update", vals, function(data, status) {
          stop_spinner_popup();
          window.location = url
        });
      });

      // Löschen Button im Dialog "Eintrag löschen" betätigt
      $('#voicemail_destroy #delete-button').on('click', function() {
        if ( type != entry_type )
          return;

        var vals = {};
        vals['what'] = 'delete';
        vals['type'] = entry_type;
        vals['id'] = entry_id;
        $('#voicemail_destroy').modal('hide');
        start_spinner_popup();
        $.post("/user/voicemail/ajax_update", vals, function(data, status) {
          window.location = url;
        });
      });
    }

    $('#edit_options #save-button').on('click', function() {
      /* Prüfen auf Vollständigkeit */
      var dir_type = 'priv';
      var valid = true;
      if ( $('#edit_options input[type=radio][name=dir_type]').length ) {
        dir_type = $('#edit_options input[type=radio][name=dir_type]:checked').val();
        if ( !dir_type ) {
          $('#error_no_dir_type').show();
          valid = false;
        }
      }
      var edit_type = $('#edit_options input[type=radio][name=use]:checked').val();
      if ( edit_type == 'new' ) {
        if ( $('#edit_options #sur_name').val() == "" && $('#edit_options #given_name').val() == "" && $('#edit_options #company').val() == "" ) {
          $('#error_no_contact_data').show();
          valid = false;
        } else {
          $('#error_no_contact_data').hide();
        }
      } else if ( edit_type == 'existing' ) {
        if ( !directory_entry_id ) {
          $('#error_no_contact_sel').show();
          valid = false;
        }
      } else {
        $('#error_no_edit_type').show();
        valid = false;
      }
      if ( $('#edit_options #type_long').val() == "" && $('#edit_options #type_short').val() == "" && $('#edit_options #type_long_sel').val() == "" ) {
        $('#error_no_number_type').show();
        valid = false;
      } else {
        $('#error_no_number_type').hide();
      }

      if ( !valid )
        return;

      /* Übergabe der Daten */
      var vals = {};
      vals['what'] = 'update_directory';
      vals['type'] = entry_type;      // 'new' oder 'old'
      vals['id'] = entry_id;          // Listen-ID in der Voicemail-Liste, eindeutig in Verbindung mit type
      vals['entry_id'] = directory_entry_id;    // Contact-ID bei vorhandenem Kontakt
      vals['edit_type'] = edit_type;            // 'new' oder 'existing'
      vals['dir_type'] = dir_type;              // 'priv' oder 'pub'
      vals['number'] = pb_number;
      vals['directory_cont'] = $('#edit_options #directory_cont').prop('checked');
      var keys = ['given_name', 'sur_name', 'company', 'type_long', 'type_short', 'type_long_sel'];
      for(var i=0; i<keys.length; i++) {
        var k = '#' + keys[i];
        vals[keys[i]] = $(k).val();
      }
      $('#edit_options').modal('hide');
      start_spinner_popup();
      $.post("/user/voicemail/ajax_update", vals, function(data, status) {
        if ( data.error ) {
          stop_spinner_popup();
          show_flash('notice', "", data.msg)
        }
        if ( data.continue ) {
          window.location = "/user/directory/" + data.id + "/edit";
        } else {
          stop_spinner_popup();
        }
      });
    });

  }

  if ( $('#user_voicemail_new-table').length ) {
    var table = activate_tables('#user_voicemail_new-table', false, [-1]);

    table.on('draw', function () {
      set_event_handler_table('new');
    });

    set_event_handler_table('new');
    set_event_handler_delete('new');
  }

  if ( $('#user_voicemail_old-table').length ) {
    var table = activate_tables('#user_voicemail_old-table', false, [-1]);

    table.on('draw', function () {
      set_event_handler_table('old');
    });
    
    set_event_handler_table('old');
    set_event_handler_delete('old');
  }

});

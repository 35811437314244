jQuery(document).ready(function() {

  if ( $('#dialin_title').length > 0 ) {

    if ( $('#dialin_exten').val() == "" ) {
      $('#dialin_default').prop('disabled', true);
      $('#dialin_timeout').prop('disabled', true);
      $('#dialin_msn_id').prop('disabled', true);
    }

    $('#dialin_exten').on('change', function() {
      console.log("Geändert " + $(this).val());
      var disable;
      if ( $(this).val() ) {
        disable = false;
      } else {
        disable = true;
      }
      $('#dialin_default').prop('disabled', disable);
      $('#dialin_timeout').prop('disabled', disable);
      $('#dialin_msn_id').prop('disabled', disable);
    });

    /* Ergebnisse vom AJAX-Call auswerten */
    function get_results(data) {
      $('.alert').remove();
      if ( data['error'] ) {
        $('.iptam-main').prepend("<div class='alert alert-danger' role='alert'><h2>" + data['error']['title'] + "</h2>" + data['error']['msg'] + "</div>");
      } else if ( data['notice'] ) {
        $('.iptam-main').prepend("<div class='alert alert-warning' role='alert'><h2>" + data['notice']['title'] + "</h2>" + data['notice']['msg'] + "</div>");
      } else if ( data['info'] ) {
        if ( data['info']['title'] )
          $('.iptam-main').prepend("<div class='alert alert-info' role='alert'><h2>" + data['info']['title'] + "</h2>" + data['info']['msg'] + "</div>");
        else
          $('.iptam-main').prepend("<div class='alert alert-info' role='alert'>" + data['info']['msg'] + "</div>");
        if ( data['filename'] ) {
          $('#dialin_greeting').val(data['filename']);
          $('#show_play').show();
        }
      } else {
        if ( data['filename'] ) {
          $('#dialin_greeting').val(data['filename']);
          $('#show_play').show();
        }
      }
    }

    var type_of_action;

    /* Autofocus beim Erscheinen der Dialog-Box */
    $('#select_phone').on('shown.bs.modal', function() {
      if ( $('#phone_own').length == 0 ) {
        $(this).find('#number').focus();
      }
    });
    
    $('#select_phone').keypress(function(e) {
      if ( e.which == 13) {
        $(this).find('#save-button').click();
        return false;
      }
    });

    /* Anzeige "Play"-Button, falls Ansage existiert */
    if ( $('#dialin_greeting').length && $('#dialin_greeting').val().length ) {
      $('#show_play').show();
    } else {
      $('#show_play').hide();
    }

    /* Aufnehmen von Ansagen via Telefon */
    $('button[name=record]').on('click', function(e) {
      type_of_action = 'record';
      $('#select_phone').modal();
    });

      /* Abspielen von Ansagen via Telefon */
    $('button[name=play]').on('click', function(e) {
      type_of_action = 'play';
      $('#select_phone').modal();
    });

    /* Hochladen von Audio-Dateien */
    $('button[name=upload]').on('click', function(e) {
      $('[data-toggle="tooltip"]').tooltip('hide');
      $('h5').html($(this).data('title'));
      $('#file_type').html($(this).data('file-type'));
      $('#file_upload').modal();
    });

    $('#select_phone #number').click(function(e) {
      $('#phone_number').prop("checked", true);
    });

    $('input[type=radio][name=phone]').on('change', function() {
      if ( $(this).val() != 'number' )
        $('#number').val("");
    });

    /* Speichern Telefonauswahl und Aufnahme / Abspielen ausführen */
    $('#select_phone #save-button').on('click', function() {
      var phone = $('input[type=radio][name=prompt]:checked').val();
      var number = $('#number').val();
      var vals = {};
      vals['what'] = type_of_action;
      if ( type_of_action == "play" )
        vals['filename'] = $('#dialin_greeting').val();
      vals['phone'] = (phone == 'own' || !number) ? 'own' : number;
      $('#select_phone').modal('hide');
      $.post("/admin/dialins//ajax_update", vals, function(data, status) {
        get_results(data);
      });
    });

    $('#upload-button').on('click', function() {
      var form_data = new FormData(jQuery('#upload-form')[0]);
      form_data.append('what', 'upload');
      var opts = {
        url: '/admin/dialins//ajax_update',
        data: form_data,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
        success: function(data) {
          $('#file_upload').modal('hide');
          get_results(data);
        }
      };
      jQuery.ajax(opts);
    });
  }
});

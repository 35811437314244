var PhoneGigaset_de700 = (function(modul) {

  function init(pdata, funcs) {
    console.log("Telefon " + pdata.vendor + " " + pdata.model);
    funcs.set_initial_data(pdata.own_exten, {pkey: [1,7]});

    for (var i = 1; i <= 14; i++) {
      $('#pkey'+i).droppable({
        accept: '.key',
        hoverClass: 'highlight-target',
        drop: function(event, ui) {
          if ( ui.draggable.attr('id') == "f_line" ) {
            funcs.set_line($(this));
          } else if ( ui.draggable.attr('id') == "f_blf" ) {
            funcs.set_blf($(this));
          } else if ( ui.draggable.attr('id') == "f_direct" ) {
            funcs.set_direct($(this));
          } else if ( ui.draggable.attr('id') == "f_cfwdgrp" ) {
            funcs.set_cfwdgrp($(this));
          } else if ( ui.draggable.attr('id') == "f_agent" ) {
            funcs.set_agent($(this));
          } else if ( ui.draggable.attr('id') == "f_icom" ) {
            funcs.set_icom($(this));
          } else if ( ui.draggable.attr('id') == "f_dtmf" ) {
            funcs.set_dtmf($(this));
          } else if ( ui.draggable.attr('id') == "f_profileled" ) {
            funcs.set_profileled($(this));
          } else {
            funcs.set_keyfunc($(this), $(ui.draggable));
          }
        }
      });
    }

    $('#dirkey').on('click', function() {
      funcs.click_change_text('dirkey', 1, 'gigaset-dirkey-texts');
    });
    $('#okkey').bind("click", function() {
      funcs.click_change_text('okkey', 1, 'gigaset-okkey-texts');
    });

    function show_timecontrol() {
      var config = funcs.get_configuration();
      var tc_enable = config.tc_enabled || 0;
      if ( tc_enable > 0 ) {
        var off = config.tc_off;
        var until = config.tc_until;
        $('#display-time-control').text("Display von " + off + " bis " + until + " ausgeschaltet");
      } else {
        $('#display-time-control').text("Ausgeschaltet");
      }
    }

    show_timecontrol();
    $('#display-time-control').on('click', function() {
      $('.timepicker').clockpicker({autoclose: true, placement: 'top'});
      var config = funcs.get_configuration();
      var tc_enable = config.tc_enabled || 0;
      $('input[name=tc-enable]').val([tc_enable]);
      $('#tc_off').val(config.tc_off || '');
      $('#tc_on').val(config.tc_until || '');
      $('#display-time-control-form #save-button').on('click', function() {
        config.tc_off = $('#tc_off').val();
        config.tc_until = $('#tc_on').val();
        config.tc_enabled = $('input[name=tc-enable]:checked').val()
        show_timecontrol();
        $('#display-time-control-form').modal('hide');
      })
      $('#display-time-control-form').modal();
    });

  };


  function do_submit(funcs) {
    console.log("Submit");
    funcs.do_submit();
  };

  return {
    init: init,
    do_submit: do_submit
  };
})(PhoneGigaset_de700 || {});
window.PhoneGigaset_de700 = PhoneGigaset_de700;

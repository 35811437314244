import { activate_tables, block_enable } from '../iptam.js';

jQuery(document).ready(function() {

  // Index-Seite
  if ( $('#phoneoverview-table').length ) {
    var table = activate_tables('#phoneoverview-table', false, [-1]);

    var entry_id;
    function setup_handler() {
      // Löschen einer Telefonkonfiguration
      $('.delete_entry').on('click', function() {
        $(this).tooltip('hide');
        entry_id = $(this).data('entry-id');
        $('#phoneoverview_destroy').modal();
      });

      // Aufheben des HD-Status
      $('.delete_hd').on('click', function() {
        $(this).tooltip('hide');
        entry_id = $(this).data('entry-id');
        $('#phoneoverview_delete_hd').modal();
      });

      // Verbindung zwischen Telefon und PBX lösen ("Aktualisieren")
      $('a[data-action=refresh_phone]').on('click', function() {
        $(this).tooltip('hide');
        entry_id = $(this).data('entry-id');
        $('#phoneoverview_refresh_phone').modal();
      });

      // DECT-Basis austauschen ("Aktualisieren")
      $('a[data-action=refresh_dect]').on('click', function() {
        $(this).tooltip('hide');
        entry_id = $(this).data('entry-id');
        $('#phoneoverview_refresh_dect').modal();
      });
    }

    table.on( 'draw', function () {
      setup_handler();
    });

    setup_handler();

    // Auswahl der Liste
    $('input[name="which"]').change(function() {
      $('#phoneoverview-table').DataTable().search("").draw();
      $('#lookup_form').submit();
    });

    /* Löschen tatsächlich ausführen */
    $('#delete-button').on('click', function() {
      $('#delete-form').attr('action', '/admin/phoneoverview/' + entry_id);
      $('#delete-form').submit();
    });

    /* HD-Aufheben tatsächlich ausführen */
    $('#delete_hd-button').on('click', function() {
      $('#delete_hd-form').attr('action', '/admin/phoneoverview/' + entry_id + '/destroy_hd');
      $('#delete_hd-form').submit();
    });

    /* Aktualisieren tatsächlich ausführen */
    $('#phoneoverview_refresh_phone #refresh-button').on('click', function() {
      $('#refresh-form').attr('action', '/admin/phoneoverview/' + entry_id + '/refresh');
      $('#refresh-form').submit();
    });

    /* Aktualisieren DECT tatsächlich ausführen */
    $('#phoneoverview_refresh_dect #refresh-button').on('click', function() {
      $('#phoneoverview_refresh_dect input[name=id]').val(entry_id);
      $('#refresh_dect-form').submit();
      $('#phoneoverview_refresh_dect').modal('hide');
    });

  }

  // New-Seite
  if ( $('#phoneoverview_new_title').length > 0 ) {
    $('#phonetemplate_id').on('change', function() {
      var value = $(this).val();
      var opt = $('#phonetemplate_id :selected');
      if ( value.length ) {
        $('dd#vendor').text(opt.data('vendor'));
        $('dd#model').text(opt.data('model'));
        $('dd#desc').text(opt.data('desc'));
        $('#templ_info').show();
        $('#phonetype_id option').prop('selected', false);
      } else {
        $('#templ_info').hide();
      }
    });

    $('#phonetype_id').on('change', function() {
      if ( $(this).val().length ) {
        $('#phonetemplate_id option').prop('selected', false);
        $('#templ_info').hide();
      }
    });
  }

  // Finish-Seite: Autocompleter
  if ( $('#phoneoverview_finish_title').length > 0 ) {
    var count = 0;

    function get_extension_data(idx, extension) {
      count++;
      $.get('/misc/ajax/user_lookupjs?search=' + extension, function(data) {
        $('#eid_' + idx).val(data[0].ext_id);
        count--;
        if ( count == 0 )
          $('form[action="/admin/phoneoverview/create"]').submit();
      });
    }
    $('input[type=text][name^="exten_"]').each(function() {
      $(this).autocomplete( {
        source: function (request, response) {
          $.get('/misc/ajax/user_lookupjs?search=' + request.term, function(data) {
            var result = []
            for ( var i=0; i<data.length; i++ ) {
              var name = data[i].sur_name + ', ' + data[i].gv_name;
              var label = name + ' [' + data[i].exten + ']';
              var ext_id = data[i].ext_id;
              result.push({value: data[i].exten, label: label, location: data[i].location, name: name, ext_id: ext_id});
            }
            response(result);
          })
        },
        select: function(event, ui) {
          var name_id = event.target.id.replace('exten_', 'name_');
          var loc_id = event.target.id.replace('exten_', 'loc_');
          var ext_id = event.target.id.replace('exten_', 'eid_');
          $('#' + name_id).val(ui.item.name);
          $('#' + loc_id).val(ui.item.location);
          $('#' + ext_id).val(ui.item.ext_id);
        },
      });
    });

    $('form[action="/admin/phoneoverview/create"]').submit(function(e) {
      $('input[type=text][name^="exten_"]').each(function() {
        var idx = $(this).attr('id').substr(6);
        if ( $(this).val().length > 0 ) {
          var ext_id = $('#eid_'+idx).val();
          if ( ext_id.length == 0 ) {
            get_extension_data(idx, $(this).val());
          }
        }
      });
      if ( count != 0 )
        e.preventDefault();
    });

    $('#create_phone').on('click', function(e) {
      var cont = true;
      if ( $('#admin_pw').length ) {
        if ( $('#admin_pw').val().length == 0 )
          cont = false;
      }
      if ( $('#user_pw').length ) {
        if ( $('#user_pw').val().length == 0 )
          cont = false;
      }
      if ( !cont ) {
        $('#phoneoverview_empty_pw_warn').modal();
        e.preventDefault();
      }
    });

  }
  
  // Edit-Seite: Tastenerweiterungen und Weitere Identitäten
  if ( $('#phoneoverview_edit_title').length > 0 ) {
    
    $('#h1-enable').css('cursor', 'pointer');
    block_enable(1);
  
    // MAC-Adresse setzen/ändern
    $('#show-mac-dialog').on('click', function() {
      $('#phoneoverview_mac_dialog #invalid_mac').hide();
      $('#phoneoverview_mac_dialog').modal();
    });
    $('#phoneoverview_mac_dialog #save-button').on('click', function() {
      var value = $('#phoneoverview_mac_dialog #mac_address').val();
      var regex = /^[0-9A-F]{12}$/i;
      if ( regex.test(value) || value.length == 0 ) {
        $('#cur_mac_address').val(value);
        $('#show-mac-dialog').text("MAC: " + value);
        $('#phoneoverview_mac_dialog').modal('hide');
      } else {
        $('#phoneoverview_mac_dialog #invalid_mac').show();
      }
    });

    // Weitere Identitäten: Änderungen speichern?
    $('button[name=change_ids]').on('click', function() {
      var config = PhoneFunctions.get_configuration();
      if ( PhoneFunctions.check4changes() ) {
        $('#save_changes-form #save-button').on('click', function() {
          $('<input />').attr('type', 'hidden').attr('name', 'save_changes').attr('value', 'true').appendTo('form#phonetemplate_form');
          $('#save_changes-form').modal('hide');
        });
        $('#save_changes-form').modal();
        $('#save_changes-form').on('hidden.bs.modal', function() {
          $('<input />').attr('type', 'hidden').attr('name', 'change_ids').attr('value', 'Konifg.').appendTo('form#phonetemplate_form');
          $('input[type=hidden][id=phonesettings]').val(JSON.stringify(config));
          $('form#phonetemplate_form').submit();
        });
      } else {
        $('<input />').attr('type', 'hidden')
          .attr('name', 'change_ids')
          .attr('value', 'Konifg.')
          .appendTo('form#phonetemplate_form');
        $('input[type=hidden][id=phonesettings]').val(JSON.stringify(config));
        $('form#phonetemplate_form').submit();
      }
    });

    // Tastenerweiterungen: Änderungen speichern?
    $('button[name=config_ext]').on('click', function() {
      var config = PhoneFunctions.get_configuration();
      if ( PhoneFunctions.check4changes() ) {
        $('#save_changes-form #save-button').on('click', function() {
          $('<input />').attr('type', 'hidden').attr('name', 'save_changes').attr('value', 'true').appendTo('form#phonetemplate_form');
          $('#save_changes-form').modal('hide');
        });
        $('#save_changes-form').modal();
        $('#save_changes-form').on('hidden.bs.modal', function() {
          $('<input />').attr('type', 'hidden').attr('name', 'config_ext').attr('value', 'Konifg.').appendTo('form#phonetemplate_form');
          $('input[type=hidden][id=phonesettings]').val(JSON.stringify(config));
          $('form#phonetemplate_form').submit();
        });
      } else {
        $('<input />').attr('type', 'hidden')
          .attr('name', 'config_ext')
          .attr('value', 'Konifg.')
          .appendTo('form#phonetemplate_form');
        $('input[type=hidden][id=phonesettings]').val(JSON.stringify(config));
        $('form#phonetemplate_form').submit();
      }
    });

    // Klingeltöne: Dialog öffnen
    $('button[name=phone_ringer_button]').on('click', function() {
      $('#phone-ringer-form').modal();
    });

    $('#support_m5').on('click', function() {
      var v = $('#support_m5').prop('checked');
      console.log("M5 aktiv " + v);
      if ( v ) {
        $('#pin').val("0000");
      } else {
        
      }
    });

    // Schablone wählen/ändern
    $('button[name=change_template]').on('click', function() {
      $('#phoneoverview_chg_templ').modal();
    });

    var show_sec_warning = 0;
    var exten_id;
    var vals = {};
    /* Kopie einer Telefonkonfiguation als Schablone übernehmen */
    $('#phoneoverview_chg_templ #copy-button').on('click', function() {
      show_sec_warning = 1;
      exten_id = $('#phoneoverview_chg_templ #exten_id').val();
      vals['exten_id'] = exten_id;
      vals['templ_id'] = $('#phoneoverview_chg_templ #template option:selected').val();
      $('#phoneoverview_chg_templ').modal('hide');
    });

    $('#phoneoverview_chg_templ').on('hidden.bs.modal', function() {
      if ( show_sec_warning > 0 ) {
        show_sec_warning = 0;
        var templ_name = $('#phoneoverview_chg_templ #template option:selected').text();
        if ( vals['templ_id'] ) {
          $('#phoneoverview_templ_warning span#template_name').html(templ_name);
          $('#phoneoverview_templ_warning').modal();
        } else {
          var current_templ = $('#current_templ').val();
          if ( current_templ.length ) {
            console.log({current_templ});
            $('#phoneoverview_templ_warning2 span#template_name').html(current_templ);
            $('#phoneoverview_templ_warning2').modal();
          }
        }
      }
    });

    $('#phoneoverview_templ_warning #save-button').on('click', function() {
      console.log({vals});
      $.post("/admin/phoneoverview/ajax_update", vals, function(data, status) {
        console.log({data});
        if ( data.error ) {
          stop_spinner_popup();
          $('#errorExplanation').html("<h2>" + data.error.title + "</h2>" + data.error.msg).show();
        } else {
          $('#phoneoverview_chg_templ').modal('hide');
          window.location = '/admin/phoneoverview/' + exten_id + '/edit';
        }
      });
    });

    $('#phoneoverview_templ_warning2 #save-button').on('click', function() {
      console.log({vals});
      $.post("/admin/phoneoverview/ajax_update", vals, function(data, status) {
        console.log({data});
        if ( data.error ) {
          stop_spinner_popup();
          $('#errorExplanation').html("<h2>" + data.error.title + "</h2>" + data.error.msg).show();
        } else {
          $('#phoneoverview_chg_templ').modal('hide');
          window.location = '/admin/phoneoverview/' + exten_id + '/edit';
        }
      });
    });
  }

  // Weitere Identitäten konfigurieren
  if ( $('#phoneoverview_change_id_title').length > 0 ) {
    var count = 0;

    function get_extension_data(idx, extension) {
      count++;
      $.get('/misc/ajax/user_lookupjs?search=' + extension, function(data) {
        $('#eid_' + idx).val(data[0].ext_id);
        count--;
        if ( count == 0 )
          $('form[action$=update_identities]').submit();
      });
    }

    $('input[type=text][name^="exten_"]').each(function() {
      $(this).autocomplete( {
        source: function (request, response) {
          $.get('/misc/ajax/user_lookupjs?search=' + request.term, function(data) {
            var result = []
            for ( var i=0; i<data.length; i++ ) {
              var name = data[i].sur_name + ', ' + data[i].gv_name;
              var label = name + ' [' + data[i].exten + ']';
              var ext_id = data[i].ext_id;
              result.push({value: data[i].exten, label: label, location: data[i].location, name: name, ext_id: ext_id});
            }
            response(result);
          })
        },
        select: function(event, ui) {
          var name_id = event.target.id.replace('exten_', 'name_');
          var loc_id = event.target.id.replace('exten_', 'loc_');
          var ext_id = event.target.id.replace('exten_', 'eid_');
          $('#' + name_id).val(ui.item.name);
          $('#' + loc_id).val(ui.item.location);
          $('#' + ext_id).val(ui.item.ext_id);
        },
      });
    });

    $('input[type=text][name^="exten_"]').on('change', function() {
      if ( $(this).val() == "" ) {
        var idx = $(this).attr('id').substr(6);
        $('#eid_'+idx).val("");
      }
    });

    $('form[action$=update_identities]').submit(function(e) {
      $('input[type=text][name^="exten_"]').each(function() {
        var idx = $(this).attr('id').substr(6);
        if ( $(this).val().length > 0 ) {
          var ext_id = $('#eid_'+idx).val();
          if ( ext_id.length == 0 ) {
            get_extension_data(idx, $(this).val());
          }
        }
      });
      if ( count != 0 )
        e.preventDefault();
    });
  }

});

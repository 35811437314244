jQuery(document).ready(function() {

  if ( $('#user_speeddial-table').length ) {

    var entry_id;
    var table = activate_tables('#user_speeddial-table', false, [-1]);

    table.on( 'draw', function () {
      set_event_handler();
    });
    
    set_event_handler();

    // Neuer Eintrag
    $('a#new_entry').on('click', function() {
      $('#speeddial_form_title').text($('#speeddial_form_title').data('title-new'));
      $('#errorExplanation').hide();
      $('#speeddial_form #edit').hide();
      $('#speeddial_form #create').show();
      var keys = ['name', 'number', 'short'];
      for(var i=0; i<keys.length; i++) {
        $('#speeddial_form #' + keys[i]).val("");
      }
      $('#speeddial_form').modal();
    });

    // Speichern neuer/geänderter Eintrag
    $('#speeddial_form #save-button').on('click', function() {
      var vals = get_current_vals();
      if ( !$('#speeddial_form #create').is(':hidden') ) {
        vals['what'] = 'create';
      } else {
        vals['what'] = 'update';
        vals['id'] = entry_id;
      }
      start_spinner_popup();
      console.log({vals});
      $.post("/user/speeddials/ajax_update", vals, function(data, status) {
        console.log({data});
        if ( data.error ) {
          stop_spinner_popup();
          $('#errorExplanation').html("<h2>" + data.error.title + "</h2>" + data.error.msg).show();
        } else {
          $('#speeddial_form').modal('hide');
          window.location = '/user/speeddials';
        }
      });
    });

    /* Löschen tatsächlich ausführen */
    $('#speeddial_destroy #delete-button').on('click', function() {
      start_spinner_popup();
      vals = {};
      vals['what'] = 'destroy';
      vals['id'] = entry_id;
      console.log({vals});
      $.post("/user/speeddials/ajax_update", vals, function(data, status) {
        $('#speeddial_destroy').modal('hide');
        console.log({data});
        window.location = '/user/speeddials';
      });
    });

    function set_event_handler() {
      
      $('.edit_entry').on('click', function() {
        $(this).tooltip('hide');
        entry_id = $(this).data('entry-id');
        $('#errorExplanation').hide();
        $('#speeddial_form #create').hide();
        $('#speeddial_form #edit').show();
        var keys = ['name', 'number', 'short'];
        for(var i=0; i<keys.length; i++) {
          $('#speeddial_form #' + keys[i]).val($(this).data(keys[i]));
        }
        $('#speeddial_form').modal();
      });

      $('.delete_entry').on('click', function() {
        $(this).tooltip('hide');
        entry_id = $(this).data('entry-id');
        $('#speeddial_destroy #name').html($(this).data('name'));
        $('#speeddial_destroy #number').html($(this).data('number'));
        $('#speeddial_destroy').modal();
      });
    }

    function get_current_vals() {
      var keys = ['name', 'number', 'short'];
      var result = {};
      for(var i=0; i<keys.length; i++) {
        result[keys[i]] = $('#speeddial_form #' + keys[i]).val();
      }
      return result;
    }
  }

});

import { directory_entry_id } from '../edit_dir_entry_modal.js';

jQuery(document).ready(function() {

  if ( $('#call_list-table').length ) {

    var list_and_id;
    var url;
    var table = activate_tables('#call_list-table', false, [-1]);

    table.on( 'draw', function () {
      set_events();
    });
    set_events();

    $('#delete_list').on('click', function() {
      $('[data-toggle="tooltip"]').tooltip('hide');
      var list = $(this).data('list');
      var list_name = $(this).data('list-name');
      $('#delete_list_options #save-button').data('list', list);
      $('#delete_list_options #list_name').text(list_name);
      $('#delete_list_options').modal();
    });

    function get_list_id(list_name, id_val) {
      var list, id;

      if ( list_name == 'all' ) {
        var parts = id_val.split('_');
        list = parts[0];
        id = parts[1];
      } else {
        list = list_name;
        id = id_val;
      }
      return {list: list, id: id};
    }

    function set_events() {
      $('a[href="#"][class~=dial]').on('click', function() {
        $('[data-toggle="tooltip"]').tooltip('hide');
        list_and_id = get_list_id($(this).data('list'), $(this).data('id'));
        var vals = {};
        vals['what'] = 'dial';
        vals['type'] = list_and_id['list'];
        vals['id'] = list_and_id['id'];
        start_spinner_popup();
        $.post("/user/lists/ajax_update", vals, function(data, status) {
          stop_spinner_popup();
        });
      });

      $('a[href="#"][class~=edit]').on('click', function() {
        $('[data-toggle="tooltip"]').tooltip('hide');
        list_and_id = get_list_id($(this).data('list'), $(this).data('id'));
        $('input[type=radio][name=dir_type]').prop('checked', false);
        $('input[type=radio][name=use]').prop('checked', false);
        $('#search').val("");
        $('#edit_options .new_entry').hide();
        $('#edit_options .existing_entry').hide();
        $('#edit_options #type_long').val("");
        $('#edit_options #type_short').val("");
        $('#edit_options #type_long_sel').val("");
        $('#edit_options #directory_cont').prop('checked', false);
        $('#error_no_dir_type').hide();
        $('#error_no_edit_type').hide();
        $('#error_no_contact_sel').hide();
        $('#error_no_contact_data').hide();
        $('#error_no_number_type').hide();
        $('#edit_options').modal();
      });

      $('a[href="#"][class~=delete]').on('click', function() {
        $('[data-toggle="tooltip"]').tooltip('hide');
        list_and_id = get_list_id($(this).data('list'), $(this).data('id'));
        if ( $(this).data('list') == 'all' ) {
          url = '/user/lists/all';
        } else {
          url = '/user/lists/' + list_and_id['list'];
        }
        if ( list_and_id['list'] == 'missed' && $(this).data('rid') ) {
          // Eintrag bezieht sich verpassten Anruf aus Klingelgruppe
          start_spinner_popup();
          $.get('/misc/ajax/waitqjs', {id: "r_" + $(this).data('rid')}, function(data) {
            stop_spinner_popup();
            $('span#rg_name').text(data.name);
            $('#delete_options #del_entry_single').prop('checked', true);
            $('#delete_options').modal();
          });
        } else {
          delete_list_entry(list_and_id['list'], list_and_id['id'], false)
        }
      });
    }

    function delete_list_entry(list, id, single) {
      var vals = {};
      vals['what'] = 'delete';
      vals['list'] = list;
      vals['id'] = id;
      vals['group'] = single;
      start_spinner_popup();
      $.post("/user/lists/ajax_update", vals, function(data, status) {
        window.location = url;
      });
    }

    $('#edit_options #save-button').on('click', function() {
      /* Prüfen auf Vollständigkeit */
      var dir_type = 'priv';
      var valid = true;
      if ( $('#edit_options input[type=radio][name=dir_type]').length ) {
        dir_type = $('#edit_options input[type=radio][name=dir_type]:checked').val();
        if ( !dir_type ) {
          $('#error_no_dir_type').show();
          valid = false;
        }
      }
      var edit_type = $('#edit_options input[type=radio][name=use]:checked').val();
      if ( edit_type == 'new' ) {
        if ( $('#edit_options #sur_name').val() == "" && $('#edit_options #given_name').val() == "" && $('#edit_options #company').val() == "" ) {
          $('#error_no_contact_data').show();
          valid = false;
        } else {
          $('#error_no_contact_data').hide();
        }
      } else if ( edit_type == 'existing' ) {
        if ( !directory_entry_id ) {
          $('#error_no_contact_sel').show();
          valid = false;
        }
      } else {
        $('#error_no_edit_type').show();
        valid = false;
      }
      if ( $('#edit_options #type_long').val() == "" && $('#edit_options #type_short').val() == "" && $('#edit_options #type_long_sel').val() == "" ) {
        $('#error_no_number_type').show();
        valid = false;
      } else {
        $('#error_no_number_type').hide();
      }

      if ( !valid )
        return;

      /* Übergabe der Daten */
      var vals = {};
      vals['what'] = 'update_directory';
      vals['list'] = list_and_id['list'];
      vals['id'] = list_and_id['id'];
      vals['entry_id'] = directory_entry_id;
      vals['edit_type'] = edit_type;
      vals['dir_type'] = dir_type;
      vals['directory_cont'] = $('#edit_options #directory_cont').prop('checked');
      var keys = ['given_name', 'sur_name', 'company', 'type_long', 'type_short', 'type_long_sel'];
      for(var i=0; i<keys.length; i++) {
        var k = '#' + keys[i];
        vals[keys[i]] = $(k).val();
      }
      $('#edit_options').modal('hide');
      start_spinner_popup();
      $.post("/user/lists/ajax_update", vals, function(data, status) {
        if ( data.continue ) {
          window.location = "/user/directory/" + data.id + "/edit";
        } else {
          stop_spinner_popup();
        }
      });
    });

    $('#delete_options #save-button').on('click', function() {
      var group = $('#delete_options input[type=radio][name=del_entry]:checked').val();
      delete_list_entry(list_and_id['list'], list_and_id['id'], group=='group');
    });

    $('#delete_list_options #save-button').on('click', function() {
      var vals = {};
      var list = $(this).data('list');
      vals['what'] = 'delete_list';
      vals['list'] = list;
      start_spinner_popup();
      $.post("/user/lists/ajax_update", vals, function(data, status) {
        window.location = '/user/lists/' + list;
      });
    });
  }

});

import { activate_tables, clear_all_flash } from '../iptam.js';

$(document).ready(function() {
  if ( $('#conferences-table').length ) {
    var table = activate_tables('#conferences-table', false, [-1]);

    table.on( 'draw', function () {
      $('.alert').remove();
      $('.delete_entry').on('click', function() {
        delete_entry($(this));
      });
    });
    
    $('.delete_entry').on('click', function() {
      delete_entry($(this));
    });
    
    // Löschen eines Konferenzraums
    var entry_id;
    function delete_entry(entry) {
      entry.tooltip('hide');
      entry_id = entry.data('entry-id');
      $('#name').html(entry.data('name'));
      $('#room').html(entry.data('room'));
      $('#conference_destroy').modal();
    };

    $('#delete-button').on('click', function() {
      $('#delete-form').attr('action', '/admin/conferencerooms/' + entry_id);
      $('#delete-form').submit();
    });

    function show_menu() {
      $('.show_menu').show();
      if ( $('#conference_prompt').val().length > 0) {
        $('.audio_prompt').show();
      } else {
        $('.audio_prompt').hide();
      }
    }

    // Anzeige der Menü-Optionen steuern
    $('input[type=radio][name="conference[greet_lang]"]').on('change', function() {
      clear_all_flash();
      if ( this.value == "menu" ) {
        show_menu();
      } else {
        $('.show_menu').hide();
      }
    });
    if ( $('input[type=radio][name="conference[greet_lang]"]:checked').val() == "menu" )
      show_menu();
    else
      $('.show_menu').hide();

    var type_of_action;

    /* Autofocus beim Erscheinen der Dialog-Box */
    $('#select_phone').on('shown.bs.modal', function() {
      if ( $('#phone_own').length == 0 ) {
        $(this).find('#number').focus();
      }
    });

    $('#select_phone').keypress(function(e) {
      if ( e.which == 13) {
        $(this).find('#save-button').click();
        return false;
      }
    });

    /* Aufnehmen via Telefon: Dialog zur Auswahl des Telefons öffnen */
    $('button[name=record]').on('click', function(e) {
      type_of_action = 'record';
      $('#select_phone').modal();
    });

    /* Abspielen via Telefon: Dialog zur Auswahl des Telefons öffnen */
    $('button[name=play]').on('click', function(e) {
      type_of_action = 'play';
      $('#select_phone').modal();
    });

    /* Speichern Telefonauswahl und Aufnahme / Abspielen ausführen */
    $('#select_phone #save-button').on('click', function() {
      var phone = $('input[type=radio][name=prompt]:checked').val();
      var number = $('#number').val();
      var vals = {};
      vals['what'] = type_of_action;
      vals['phone'] = (phone == 'own' || !number) ? 'own' : number;
      vals['prompt_file'] = $('#conference_prompt').val();
      $('#select_phone').modal('hide');
      $.post("/admin/conferences/ajax_update", vals, function(data, status) {
      });
    });

    /* Löschen der Ansage */
    $('button[name=prompt_del]').on('click', function(e) {
      var name = this.name.substring(0, this.name.length-4);
      $('#conference_prompt').val("");
      var vals = {};
      $.post("/admin/conferences/ajax_update", vals, function(data, status) {
      });
    });
    
    /* File-Upload Ansagen */
    $('button[name=upload]').on('click', function(e) {
      $('#file_upload .modal-title').html($(this).data('title'));
      $('#file_upload #file_type').html($(this).data('filetype'));
      $('#file_upload').modal();
    });

    /* Speichern des File-Uploads */
    $('#upload-button').on('click', function() {
      var formData = new FormData(jQuery('#upload-form')[0]);
      formData.append('what', 'upload');
      formData.append('prompt_file', $('#conference_prompt').val());
                           
      $.ajax({
        url: '/admin/conferences/ajax_update',
        method: 'POST',
        data: formData,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          $('#file_upload').modal('hide');
        }
      });
    });

  }
});

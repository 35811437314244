jQuery(document).ready(function() {

  if ( $('#expert_mode_title').length ) {
    $('#config_options').on('change', function() {
      $('#restart_pbxmand').prop('checked', true);
    });

    $('#restore_config').on('change', function() {
      if ( $(this).prop('checked') ) {
        $.get('/expert_mode/get_options', function(data) {
          console.log({data});
          $('#config_options').val(data.text);
          $('#restore_config').prop('checked', false);
          $('#restart_pbxmand').prop('checked', false);
        });
      };
    });

    $('button[name=download]').on('click', function() {
      $('form#expert_mode_form').data('sendfile-no-spinner', 1);
    });

  }

});

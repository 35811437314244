jQuery(document).ready(function() {

  if ( $('#faxserver_title').length ) {
    /* Änderung der Quelle: Anpassen der Modems */
    $('#source').on('change', function(){
      var v = $('#source option:selected').val();
      if ( v ) {
        var max_channels = $('#modem_count_max').data('maxchans');
        var max = max_channels[v];
        $('#modem_count_max').text("Max.: " + max);
        $('#faxserver_modem_count').attr('max', max);
        $('#faxserver_modem_count').attr('disabled', false);
      } else {
        $('#modem_count_max').text("");
        $('#faxserver_modem_count').attr('disabled', true);
      }
    });
    $('#source').change();
  
    /* Hinzufügen einer IP-Adresse für E-Maiul zu Fax */
    $('#add_ip_permit').on('click', function() {
      var ips = $('#faxserver_mail2fax_ip_permit').val();
      var new_ip = $('#new_ip_permit').val();
      if ( ips === undefined || ips == '' )
        ips = new_ip;
      else
        ips += (';' + new_ip);
      var vals = {};
      vals['what'] = 'add';
      vals['ips'] = ips;
      $.post("/admin/faxservers/ajax_update", vals, function(data, status) {
      });
    });

    /* Entfernen einer IP-Adresse für E-Maiul zu Fax */
    $('#del_ip_permit').on('click', function() {
      var to_delete = $('#ip_permit_list').val();
      var list = $('#faxserver_mail2fax_ip_permit').val().split(';');
      $.each(to_delete, function(idx, entry) {
        var i = list.indexOf(entry);
        if ( i >= 0 )
          list.splice(i, 1);
      });
      var vals = {};
      vals['what'] = 'del';
      vals['ips'] = list.join(';');
      $.post("/admin/faxservers/ajax_update", vals, function(data, status) {
      });
    });
  }

});

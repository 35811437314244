jQuery(document).ready(function() {

  if ( $('#tapi_title').length ) {
    $('#asteriskproxy_use_atxfer').on('change', function() {
      var value = $(this).prop('checked');
      if ( value ) {
        $('.atxfer').show();
      } else {
        $('.atxfer').hide();
      }
    });
    $('#asteriskproxy_use_atxfer').trigger('change');
  }

});

var PhoneFunctions = (function(modul) {

  /* Private Variable, nicht exportiert
   * phone_config             speichert die aktuelle Telefononfiguration
   * db_phone_config          speichert die Konfiguration beim Aufruf
   * db_phone_template_config speichert die Schbalonenkonfiguration zum Abgleich
   */
  var phone_config = {}, db_phone_config = {}, db_phone_template_config = {};
  var phone_template_in_use;
  var phone_conv_av;
  var template_text = {};
  var key_layout_texts;
  var max_field_width;
  var phone_own_extension;
  var phone_blf_keys;
  var key_noop, key_direct;   // Texte aus der GUI, lokalisiert
  var module_no = 1;
  var current_ringer_str, db_ringer_str, db_phone_template_ringer_str, already_changed, phone_is_edit_ext_mod_form, blf_current_value;

  // Initial die Daten aus Rails in der View darstellen und Aktionen aktivieren
  function set_initial_data(own_exten, blf_keys) {

    // Übernehmen der lokalisierten Texte aus Rails
    var template_list = ['gigaset-dirkey-texts', 'gigaset-name-texts', 'gigaset-okkey-texts', 'phone-name-texts', 'mitel-datetime-texts', 'snom-led-texts', 'snom-display-texts', 'unify-datetime-texts', 'unify-display-texts', 'yealink-datetime-texts', 'yealink-borderled-texts', 'grandstream-led-texts', 'grandstream-datetime-texts' ];
    for ( var i=0; i < template_list.length; i++ ) {
      var array = [];
      $('div.'+template_list[i]).each(function(idx) {
        var key = $(this).data('id');
        var value = $(this).data('text');
        array[key] = value;
      });
      template_text[template_list[i]] = array;
    }

    key_layout_texts = [];
    for ( var i = 0; i < 2; i++ ) {
      key_layout_texts[i] = $('#extmod-display-texts').data('display-'+i);
    }

    // GGf gesetzte Klingeltoneinstellungen sichern für Vergleich auf Änderung
    db_ringer_str = $('input[type=hidden]#ringer').val();
    current_ringer_str = db_ringer_str;

    key_noop = $('span#f_noop').text();
    key_direct = $('span#f_direct').text();
    phone_own_extension = own_exten;
    phone_blf_keys = blf_keys;
    var p_config = $('input[type=hidden][id=p_config]').val();
    if ( p_config ) {
      var h = JSON.parse(p_config);
      phone_conv_av = h.conv_av;
      phone_template_in_use = h.templ_used;
      phone_is_edit_ext_mod_form = h.is_edit_ext_mod_form;
      already_changed = h.already_changed;
    }
    var data = $('input[type=hidden][id=phonesettings]').val();
    if ( data ) {
      // Übernehmen der Daten aus Rails
      jsdata = JSON.parse(data);

      // Kopieren der Daten in phone_config und db_phone_config
      for ( var key in jsdata ) {
        var keydata;
        if ( jsdata.hasOwnProperty(key) ) {
          keydata = jsdata[key];
          keydata['key'] = '#' + key;
          $('#' + key).data('keydata', JSON.stringify(keydata));
        }
        if ( Array.isArray(keydata) ) {
          phone_config[key] = JSON.parse(JSON.stringify(keydata));
          db_phone_config[key] = JSON.parse(JSON.stringify(keydata));
        } else {
          phone_config[key] = keydata;
          db_phone_config[key] = keydata;
        }
      }

      // Anzeige der Erweiterungsmodule
      $('div#extension_module').text(get_ext_module_names(phone_config['ext_mod_count'], phone_config['ext_mod_type']));

      // Übernehmen der Daten in die Darstellung
      var ekey_info = [];
      for ( var key in phone_config ) {
        if ( phone_config.hasOwnProperty(key) ) {
          if ( key.substring(0, 4) == "ekey" ) {
            var match = /^ekey_(\d+)_(\d+)$/.exec(key);
            // match[1] ist Modul, match[2] ist Index
            var modno = parseInt(match[1]);
            var keyidx = parseInt(match[2]);
            if ( ekey_info[modno] ) {
              if ( keyidx < ekey_info[modno]['min'] )
                ekey_info[modno]['min'] = keyidx;
              if ( keyidx > ekey_info[modno]['max'] )
                ekey_info[modno]['max'] = keyidx;
            } else {
              ekey_info[modno] = {min: keyidx, max: keyidx};
            }
          }
          var keydata = phone_config[key];
          if ( key == 'display' ) {
            if ( template_text['snom-display-texts'].length )
              set_clickable_text('display', keydata, 'snom-display-texts');
            else if ( template_text['unify-display-texts'].length )
              set_clickable_text('display', keydata, 'unify-display-texts');
            else if ( template_text['phone-name-texts'].length )
              set_clickable_text('display', keydata, 'phone-name-texts');
          } else if ( key == 'missed_led' ) {
            set_clickable_text('missed-led', keydata, 'snom-led-texts');
            set_clickable_text('missed-led', keydata, 'grandstream-led-texts');
          } else if ( key == 'key-layout_0' ) {
            set_key_layout(keydata);
          } else if ( key == 'username' ) {
            set_clickable_text('username', keydata, 'phone-name-texts');
          } else if ( key == 'extension' ) {
            set_clickable_text('extension', keydata, 'phone-name-texts');
          } else if ( key == 'datetime' ) {
            if ( template_text['mitel-datetime-texts'].length )
              set_clickable_text('datetime', keydata, 'mitel-datetime-texts');
            else if ( template_text['unify-datetime-texts'].length )
              set_clickable_text('datetime', keydata, 'unify-datetime-texts');
            else if ( template_text['yealink-datetime-texts'].length )
              set_clickable_text('datetime', keydata, 'yealink-datetime-texts');
            else if ( template_text['grandstream-datetime-texts'].length )
              set_clickable_text('datetime', keydata, 'grandstream-datetime-texts');
          } else if ( key == 'dirkey' ) {
            set_clickable_text('dirkey', keydata, 'gigaset-dirkey-texts');
          } else if ( key == 'okkey' ) {
            set_clickable_text('okkey', keydata, 'gigaset-okkey-texts');
          } else if ( key == 'border-led' ) {
            set_borderled_text(keydata);
          } else if ( key == 'display-tc' ) {
            set_display_tc_text(keydata);
          } else if ( key == 'doorcam' ) {
            set_doorcam_text(keydata);
          } else {
            // Text der Taste setzen
            $(phone_config[key]['key']).text(keydata.key_text);
            if ( keydata.cursor ) {
              $(phone_config[key]['key']).css('cursor', 'pointer');
              formid = get_formid(keydata['fcode']);
              $('#'+key).on('click', $.proxy(function(key, formid) {
                if ( !keydata['label'] )
                  $('.labeld-key').hide();
                set_edit_handler(key, formid);
              }, null, $('#'+key), formid));
            }
          }
          if ( keydata.key_title )
            $(phone_config[key]['key']).tooltip({title: keydata.key_title, html: keydata.title_is_html || false});
        }
      }
      for (var i = 0; i < ekey_info.length; i++) {
        phone_blf_keys['ekey_'+i] = [ ekey_info[i].min, ekey_info[i].max];
      }
    } else {
      phone_config = {};
      db_phone_config = {};
    }

    $('.modno').css("font-weight", 'normal');
    $('#modno_1').css("font-weight", 'bold');
  
    // Übernahme der Daten (Template-Einstellungen)
    if ( $('button[name=reset2template]').length ) {
      var s = $('input[type=hidden][id=template_phonesettings]').val();
      db_phone_template_config = JSON.parse(s);
      db_phone_template_ringer_str = $('input[type=hidden]#template_ringer').val();
      var blf_check = check_diff4template();
      $('button[name=reset2template]').on('click', function() {
        reset_phone2template();
      });
      if ( blf_check )
        check4ownblf();
    }
  }

  /* Die Konfiguration von Telefon bzw Schablone wurde beendet. 
   * Feststellen, ob die Konfiguration geändert wurde. Bei Änderungen ggf Dialog öffnen:
   * - Bei Schablonen, die bereits verwendet werden
   * - Bei Telefonen, die bereits in Verwendung sind
   */
  function do_submit() {
    var config = get_configuration();
    var changes = check4changes();
    var form;
    if ( $('form#phonetemplate_form').length )
      form = $('form#phonetemplate_form');
    else if ( $('form#extension_mod_form').length )
      form = $('form#extension_mod_form');

    if ( changes ) {
      if ( phone_template_in_use ) {
        $('#template_in_use-form #save-button').on('click', function() {
          var update_phones = $('#template_in_use-form input[name=update_phones]:checked').val();
          if ( update_phones ) {
            $('input[type=hidden][id=phonesettings]').val(JSON.stringify(config));
            $('input[type=hidden][id=update_phones]').val(update_phones);
            $('input[type=hidden][id=blf_correction]').val($('#template_in_use-form input[name=tpl-blf-correction]:checked').val());
            form.submit();
            $('#template_in_use-form').modal('hide');
          }
        });
        $('#template_in_use-form input[name=update_phones]').on('change', function() {
          if ( $('#template_in_use-form input[name=update_phones]:checked').val() > 0 )
            $('#template_in_use-form #handle-own-blf').show();
          else
            $('#template_in_use-form #handle-own-blf').hide();
        });
        if ( $('#template_in_use-form input[name=update_phones]:checked').val() > 0 )
          $('#template_in_use-form #handle-own-blf').show();
        else
          $('#template_in_use-form #handle-own-blf').hide();
        if ( phone_is_edit_ext_mod_form ) {
          config['template_changed'] = changes;
          $('input[type=hidden][id=phonesettings]').val(JSON.stringify(config));
          form.submit();
        } else {
          $('#template_in_use-form').modal();
        }
      } else if ( phone_conv_av ) {
        var update_phone = false;
        $('#phone_in_use-form #save-button').on('click', function() {
          update_phone = true;
          $('#phone_in_use-form').modal('hide');
        });
        $('#phone_in_use-form').on('hidden.bs.modal', function() {
          $('input[type=hidden][id=phonesettings]').val(JSON.stringify(config));
          $('input[type=hidden][id=update_phone]').val(update_phone);
          form.submit();
          $('#phone_in_use-form').modal('hide');
        });
        $('#phone_in_use-form').modal();
      } else {
        $('input[type=hidden][id=phonesettings]').val(JSON.stringify(config));
        form.submit();
      }
    } else {
      $('input[type=hidden][id=phonesettings]').val(JSON.stringify(config));
      form.submit();
    }
  }

  function change_ext_mod() {
    var config = get_configuration();
    $('input[type=hidden][id=phonesettings]').val(JSON.stringify(config));
  }

  function change_border_led() {
    var config = get_configuration();
    var key = config['border-led']['key'];
    $('#led-form #led-on').val(config['border-led']['static']);
    var flashval = config['border-led']['blink'];
    $('#led-form [name*=led-flash]').prop('checked', false);
    if ( flashval.indexOf('call') >= 0 )
      $('#led-form #led-flash-0').prop('checked', true);
    if ( flashval.indexOf('vm') >= 0 )
      $('#led-form #led-flash-1').prop('checked', true);
    if ( flashval.indexOf('mute') >= 0 )
      $('#led-form #led-flash-2').prop('checked', true);
    if ( flashval.indexOf('hold') >= 0 )
      $('#led-form #led-flash-3').prop('checked', true);
    if ( flashval.indexOf('missed') >= 0 )
      $('#led-form #led-flash-4').prop('checked', true);
    $('#led-form').modal();
  }

  function set_phone_background() {
    var config = get_configuration();
    var image = config['bg_image'];
    if ( !image )
      image = { image: 'default'};
    $('#image').val(image.image);
    $('#image').on('change', function() {
      $('#phone-bg-form #show-error').hide();
      if ( $(this).val() == 'custom' ) {
        $('#show-upload').show();
      } else {
        $('#show-upload').hide();
      }
    });
    $('#image').trigger('change');
    $('#phone-bg-form #newfile').on('change', function() {
      $('#phone-bg-form #show-error').hide();
    });
    $('#phone-bg-form #show-error').hide();
    $('#phone-bg-form #newfile').val("");
    $('#phone-bg-form').modal();
  }

  function set_line(key, label) {
    label = label || 0;
    $('#line_identities input[name=key_id]').val(key.attr('id'));
    if ( label )
      $('.labeld-key').show();
    else
      $('.labeld-key').hide();
    $('#line_identities').modal();
  }

  function set_blf(key, label) {
    label = label || 0;
    $('#blf-form input[name=key_id]').val(key.attr('id'));
    if ( label )
      $('.labeld-key').show();
    else
      $('.labeld-key').hide();
    $('#blf-form #value').autocomplete( {
      source: function (request, response) {
        $.get('/misc/ajax/user_lookupjs?search=' + request.term, function(data) {
          var result = get_blf_data(data);
          response(result);
        })
      },
      select: function (event, ui) {
        $('#blf-form input[name=value]').val(ui.item.value);
        $('#blf-form input[name=ext_id]').val(ui.item.ext_id);
        if ( ui.item.alias )
          $('#blf-form input[name=labeltext]').val(ui.item.alias);
      },
    });
    $('#blf-form').modal();
  }

  function set_direct(key, label) {
    label = label || 0;
    $('#direct-form input[name=key_id]').val(key.attr('id'));
    if ( label )
      $('.labeld-key').show();
    else
      $('.labeld-key').hide();
    $('#direct-form #value').autocomplete( {
      source: function (request, response) {
        $.get('/misc/ajax/phonebook_lookupjs?search=' + request.term, function(data) {
          console.log({data});
          var result = get_directcall_data(data);
          response(result);
        })
      },
      select: function (event, ui) {
        $('#direct-form input[name=value]').val(ui.item.value);
        $('#direct-form input[name=ext_id]').val(ui.item.ext_id);
      },
    });
    $('#direct-form').modal();
  }

  function set_cfim_0(key, label) {
    label = label || 0;
    $('#cfim-0-form input[name=key_id]').val(key.attr('id'));
    if ( label )
      $('.labeld-key').show();
    else
      $('.labeld-key').hide();
    $('#cfim-0-form').modal();
  }

  function set_cfwdgrp(key, label) {
    label = label || 0;
    $('#cfwdgrp-form input[name=key_id]').val(key.attr('id'));
    $('#cfwdgrp-form select option').prop('selected', false);
    $('#cfwdgrp-form #cfwd_dest').val('');
    if ( label )
      $('.labeld-key').show();
    else
      $('.labeld-key').hide();
    $('#cfwdgrp-form').modal();
  }

  function set_profileled(key, label) {
    $('#profileled-form input[name=key_id]').val(key.attr('id'));
    $('#profileled-form select option').prop('selected', false);
    $('#profileled-form #profile_dest').val('');
    if ( label )
      $('.labeld-key').show();
    else
      $('.labeld-key').hide();
    $('#profileled-form').modal();
  }

  function set_action_url(key, label) {
    label = label || 0;
    $('#action_url-form input[name=key_id]').val(key.attr('id'));
    if ( label )
      $('.labeld-key').show();
    else
      $('.labeld-key').hide();
    $('#action_url-form').modal();
  }

  function set_call_lists(key, label) {
    label = label || 0;
    $('#call_lists-form input[name=key_id]').val(key.attr('id'));
    if ( label )
      $('.labeld-key').show();
    else
      $('.labeld-key').hide();
    $('#call_lists-form').modal();
  }

  function set_agent(key, label) {
    label = label || 0;
    $('#agent-form input[name=key_id]').val(key.attr('id'));
    $('#agent-form select option').prop('selected', false);
    if ( label )
      $('.labeld-key').show();
    else
      $('.labeld-key').hide();
    $('#agent-form').modal();
  }

  function set_icom(key, label) {
    label = label || 0;
    $('#icom-form input[name=key_id]').val(key.attr('id'));
    if ( label )
      $('.labeld-key').show();
    else
      $('.labeld-key').hide();
    $('#icom-form').modal();
  }

  function set_dtmf(key, label) {
    label = label || 0;
    $('#dtmf-form input[name=key_id]').val(key.attr('id'));
    if ( label )
      $('.labeld-key').show();
    else
      $('.labeld-key').hide();
    $('#dtmf-form').modal();
  }

  function set_doorcam(key, label) {
    label = label || 0;
    $('#doorcam-form input[name=key_id]').val(key.attr('id'));
    if ( label )
      $('.labeld-key').show();
    else
      $('.labeld-key').hide();
    $('#doorcam-form').modal();
  }

  function set_directory(key, label) {
    label = label || 0;
    $('#directory-form input[name=key_id]').val(key.attr('id'));
    if ( label )
      $('.labeld-key').show();
    else
      $('.labeld-key').hide();
    $('#directory-form').modal();
  }

  function set_keyfunc(key, drag, label) {
    label = label || 0;
    if ( label ) {
      $('#label-form input[name=key_id]').val(key.attr('id'));
      $('#label-form input[name=key_code]').val(get_key_code(drag.attr('id')));
      $('#label-form input[name=key_text]').val(drag.text());
      $('#label-form').modal();
      return;
    }
    reset_key_function('#' + key.attr('id'));
    var key_id = key.attr('id')
    var keydata = set_keydata({key_name: drag.text(), key_id: '#' + key_id}, get_key_code(drag.attr('id')), false);
  }

  // Setzen der Click-Handler für verbundene Tasten (Tasten 1-4 beim Snom 821)
  function set_coupled_edit_handler(key_id, formid) {
    if ( $(key_id).data('coupledid') ) {
      var c_id = $(key_id).data('coupledid');
      $(c_id).on('click', function() {
        PhoneFunctions.set_edit_handler($(this), formid);
      });
    }
  }

  // Setzen der Click-Handler für Tasten mit Nachbearbeitung
  function set_edit_handler(keyobj, formid) {
    var keydata = JSON.parse(keyobj.data('keydata'));
    $(formid + ' input[name=key_id]').val(keyobj.attr('id'));
    if ( $.inArray(formid, ['#blf-form', '#direct-form', '#cfim-0-form', '#icom-form', '#dtmf-form', '#action_url-form']) >= 0 ) {
      $(formid + ' #ext_id').val(keydata.ext_id);
      $(formid + ' #value').val(keydata.value);
      if ( formid == '#blf-form' ) {
        $('#blf-form #value').autocomplete( {
          source: function (request, response) {
            $.get('/misc/ajax/user_lookupjs?search=' + request.term, function(data) {
              var result = get_blf_data(data);
              response(result);
            })
          },
          select: function (event, ui) {
            $('#blf-form input[name=value]').val(ui.item.value);
            $('#blf-form input[name=ext_id]').val(ui.item.ext_id);
            if ( ui.item.alias )
              $('#blf-form input[name=labeltext]').val(ui.item.alias);
          },
        });        
      }
      if ( formid == '#direct-form' ) {
        $('#direct-form #value').autocomplete( {
          source: function (request, response) {
            $.get('/misc/ajax/phonebook_lookupjs?search=' + request.term, function(data) {
              var result = get_directcall_data(data);
              response(result);
            })
          },
          select: function (event, ui) {
            $('#direct-form input[name=value]').val(ui.item.value);
            $('#direct-form input[name=ext_id]').val(ui.item.ext_id);
          },
        });
      }
    } else if ( formid == '#label-form' ) {
      $(formid + ' input[name=key_code]').val(keydata.fcode);
      $(formid + ' input[name=key_text]').val(keydata.key_text);
    } else if ( formid == '#line_identities' ) {
      $(formid + ' select').val(keydata.value);
    } else if ( formid == '#cfwdgrp-form' ) {
      $(formid + ' select').val(keydata.cf);
      $(formid + ' #cfwd_dest').val(keydata.value);
    } else if ( formid == '#call_lists-form' ) {
      $(formid + ' input[id=cl-mode_' + keydata.value + ']').prop('checked', true);
    } else if ( formid == '#agent-form' ) {
      $(formid + ' select').val(keydata.value);
    } else if ( formid == '#doorcam-form' ) {
      $(formid + ' select').val(keydata.cam);
      $(formid + ' #url').val(keydata.url);
    } else if ( formid == '#directory-form' ) {
      $(formid + ' input[id=dir-mode_' + keydata.value + ']').prop('checked', true);
    } else if ( formid == '#profileled-form' ) {
      $(formid + ' select').val(keydata.profile);
      $(formid + ' #profile_dest').val(keydata.value);
    }
    if ( keydata.label ) {
      $(formid + ' #labeltext').val(keydata.label);
      $('.labeld-key').show();
    } else {
      $('.labeld-key').hide();
    }
    $(formid).modal();
  }

  function set_softkeywidth(examplekey, key_count) {
    var fontsize = parseInt($(examplekey).css('font-size'));
    var key_width = get_width('<span class="progkey-func">Ohne Funktion</span>');
    
    $('.softkey').width(key_count * key_width + fontsize + 14);
  }

  function set_softkeywidth2(selector, examplekey, key_count) {
    var fontsize = parseInt($(examplekey).css('font-size'));
    var key_width = get_width('<span class="progkey-func">Ohne Funktion</span>');
    $(selector).width(key_count * key_width + fontsize + 14);
  }

  function reset_key_function(id) {
    $(id).off('click');
    $(id).css('cursor', 'auto');
    $(id).tooltip('dispose');
  }
  
  function set_border_led(solid, blink) {
    phone_config['border-led'] = { static: solid, blink: blink };
  }

  function set_yealink_tc(enabled, tc_off, tc_until) {
    phone_config['display-tc'] = {enabled: enabled, tc_off: tc_off, tc_until: tc_until};
  }

  function set_keydata(formid, code, cursor, options, value) {
    value = value || undefined;
    var key_name;
    var key_id;
    var label;
    if ( typeof formid == 'object' ) {
      key_name = formid.key_name;
      key_id = formid.key_id;
    } else {
      key_name = $(formid + ' input[name=key_text]').val();
      key_id = '#' + $(formid + ' input[name=key_id]').val();
      if ( $('.labeld-key').is(":visible") ) {
        label = $(formid +' #labeltext').val();
        if ( label.length == 0 )
          label = "(leer)"
      }
    }
    reset_key_function(key_id);

    var keydata = { fcode: code, key: key_id, label: label, cursor: cursor};
    var is_html = false;
    switch ( code ) {
      // Leitungstaste
      case 1:
        keydata.key_text = key_name + ": " + value;
        if ( label )
          keydata.key_title = label + ": " + key_name + " " + value;
        keydata.value = value;
        break;

      // BLF
      case 2:
        keydata.key_text = key_name + ": " + value;
        if ( label )
          keydata.key_title = label + ": " + key_name + " " + value;
        else
          keydata.key_title = key_name + " " + value;
        keydata.value = value;
        keydata.ext_id = options.ext_id;
        break;

      // Zielwahl
      case 3:
        keydata.key_text = key_name + ": " + value;
        if ( label )
          keydata.key_title = label + ": " + key_name + " " + value;
        else
          keydata.key_title = key_name + " " + value;
        keydata.value = value;
        break;

      // Gruppenumleitung
      case 8:
        keydata.key_text = key_name;
        keydata.value = value;
        keydata.cf = options.cf;
        value = value || '';
        if ( label )
          keydata.key_title = label + ": " + options.cf_text + " <i class=\"fas fa-arrow-right fa-sm\"></i> " + value;
        else
          keydata.key_title = options.cf_text + " <i class=\"fas fa-arrow-right fa-sm\"></i> " + value;
        is_html = true;
        break;

      // Telefonbuch (Dialog für T9 / Formular oder ohne diese Option)
      case 11:
        if ( value===undefined ) {
          // Telefonbuch ohne Option
          if ( label )
            keydata.key_title = label + ": " + key_name;
          keydata.key_text = key_name;
        } else {
          // T9 oder Formular
          keydata.key_text = key_name;
          keydata.value = value;
          var titel = options.title;
          if ( label )
            keydata.key_title = label + ": " + titel;
          else
            keydata.key_title = titel;
        }
        break;

      // Ruflisten
      case 13:
        keydata.key_text = key_name;
        keydata.value = value;
        var titel = options.title;
        if ( label )
          keydata.key_title = label + ": " + titel;
        else
          keydata.key_title = titel;
        break;

      // Agent
      case 16:
        keydata.key_text = key_name;
        keydata.value = value;
        var titel = options.title;
        if ( label )
          keydata.key_title = label + ": " + titel;
        else
          keydata.key_title = titel;
        break;

      // Intercom
      case 17:
        keydata.key_text = key_name + ": " + value;
        if ( label )
          keydata.key_title = label + ": " + key_name + " " + value;
        else
          keydata.key_title = key_name + " " + value;
        keydata.value = value;
        break;

      // DTMF
      case 24:
        keydata.key_text = key_name + ": " + value;
        if ( label )
          keydata.key_title = label + ": " + key_name + " " + value;
        else
          keydata.key_title = key_name + " " + value;
        keydata.value = value;
        break;

      // Kamera
      case 28:
        keydata.key_text = key_name;
        keydata.cam = options.cam;
        keydata.cam_text = options.cam_text;
        keydata.url = options.url;
        var titel;
        if ( options.cam > 0 )
          titel = keydata.key_text + " " + options.cam_text;
        else
          titel = keydata.key_text + " " + options.url;
        if ( label )
          keydata.key_title = label + ": " + titel;
        else
          keydata.key_title = titel;
        break;

      // Uml. ein/aus
      case 39:
        keydata.key_text = key_name + ": " + value;
        if ( label )
          keydata.key_title = label + ": " + key_name + " " + value;
        else
          keydata.key_title = key_name + " " + value;
        keydata.value = value;
        break;
      // Profile LED
      case 45:
        keydata.key_text = key_name;
        keydata.value = value;
        keydata.profile = options.profile;
        value = value || '';
        if ( label )
          keydata.key_title = label + ": " + options.profile_text + " <i class=\"fas fa-arrow-right fa-sm\"></i> " + value;
        else
          keydata.key_title = key_name + " " + options.profile_text + " <i class=\"fas fa-arrow-right fa-sm\"></i> " + value;
        keydata.value = value;
        is_html = true;
        break;

      // Zielwahl
      case 47:
        keydata.key_text = key_name + ": " + value;
        if ( label )
          keydata.key_title = label + ": " + key_name + " " + value;
        else
          keydata.key_title = key_name + " " + value;
        keydata.value = value;
        break;

      default:
        if ( label )
          keydata.key_title = label + ": " + key_name;
        keydata.key_text = key_name;
        break;
    }

    $(key_id).css('cursor', cursor ? 'pointer' : 'auto');
    if ( is_html )
      keydata.title_is_html = is_html;

    // Textfeld-Begrenzung
    if ( !max_field_width )
      max_field_width = get_width('<span class="progkey-func">' + key_noop +'</span>');
    var field_width = get_width('<span class="progkey-func">' + keydata.key_text + '</span>');
    while ( field_width > max_field_width ) {
      keydata.key_text = keydata.key_text.slice(0, -1);
      field_width = get_width('<span class="progkey-func">' + keydata.key_text + '</span>');
    }
    $(key_id).data('keydata', JSON.stringify(keydata));
    phone_config[key_id.substr(1)] = keydata;
    $(key_id).text(keydata.key_text);
    if ( keydata.key_title && keydata.key_title.length )
      $(key_id).tooltip({title: keydata.key_title, html: is_html});

    if ( $(key_id).data('coupledid') ) {
      var c_id = $(key_id).data('coupledid');
      var c_keydata = $.extend(true, {}, keydata);    // Kopieren der Einstellungen
      c_keydata['key'] = c_id;
      $(c_id).data('keydata', JSON.stringify(c_keydata));
      phone_config[c_id.substr(1)] = c_keydata;
      $(c_id).text(c_keydata.key_text);
      $(c_id).css('cursor', cursor ? 'pointer' : 'auto');
      if ( c_keydata.key_title && c_keydata.key_title.length ) {
        $(c_id).tooltip('dispose');
        $(c_id).tooltip({title: c_keydata.key_title, html: is_html});
      }
    }
    check_diff4template();
    return keydata;
  }

  // Tasten mit Belegung in mehreren Ebenen, Ebene nach oben wechseln
  function change_layer_up(layer, max_layer, first, last, name ) {

    // Bisherigen Werte der Tasten aus der Ebene sichern in phone_config
    for ( var i = first; i <= last; i++ ) {
      var key = name + ((layer-1)*(last-first+1) + i).toString();
      var s = $('#' + key).data('keydata');
      var keydata;
      if ( s )
        keydata = JSON.parse(s);
      else
        keydata = {fcode: 0, key: '#'+key, key_text: key_noop};
      var h = {}
      h[key] = keydata;
      phone_config = $.extend({}, phone_config, h);
    }

    // Ebene aktualisieren
    var old_layer = layer;
    layer += 1;
    if ( layer > max_layer )
      layer = 1;

    // Setzen der neuen Tastenbelegung
    for ( var i = first; i <= last; i++ ) {
      var id1 = '#' + name + ((old_layer-1)*(last-first+1) + i).toString();
      var id2 = name + ((layer-1)*(last-first+1) + i).toString();
      $(id1).attr('id', id2);
      var keydata = phone_config[id2];
      if ( !keydata )
        keydata = {fcode: 0, key: '#' + id2, key_text: key_noop};
      $('#' + id2).data('keydata', JSON.stringify(keydata));
      $('#' + id2).text(keydata.key_text);
      $('#' + id2).tooltip('dispose');
      if ( keydata.key_title )
        $('#' + id2).tooltip({title: keydata.key_title, html: keydata.title_is_html || false});
      
      $('#' + id2).off('click');
      if ( keydata.cursor ) {
        $('#' + id2).css('cursor', 'pointer');
        var formid = get_formid(keydata['fcode']);
        $('#' + id2).on('click', $.proxy(function(key, formid) {
          if ( !keydata['label'] )
            $('.labeld-key').hide();
          set_edit_handler(key, formid);
        }, null, $('#' + id2), formid));
      } else {
        $('#' + id2).css('cursor', 'auto');
      }
    }
  }

  function update_key(id) {
    var keydata = phone_config[id];
    if ( !keydata )
      keydata = {fcode: 0, key: '#' + id, key_text: key_noop};
    $('#' + id).data('keydata', JSON.stringify(keydata));
    $('#' + id).text(keydata.key_text);
    $('#' + id).tooltip('dispose');
    if ( keydata.key_title )
      $('#' + id).tooltip({title: keydata.key_title, html: keydata.title_is_html || false});

    $('#' + id).off('click');
    if ( keydata.cursor ) {
      $('#' + id).css('cursor', 'pointer');
      var formid = get_formid(keydata['fcode']);
      $('#' + id).on('click', $.proxy(function(key, formid) {
        if ( !keydata['label'] )
          $('.labeld-key').hide();
        set_edit_handler(key, formid);
      }, null, $('#' + id), formid));
    } else {
      $('#' + id).css('cursor', 'auto');
    }
  }

  function modno_change(module) {
    var mod = module.attr('id').substring(6);
    var first_key = phone_blf_keys['ekey_'+(module_no-1)][0];
    var last_key = phone_blf_keys['ekey_'+(module_no-1)][1];
    for ( var i=first_key; i <= last_key; i++ ) {
      var id1 = '#ekey_' + (module_no-1) + '_' + i;
      var id2 = 'ekey_' + (mod-1) + '_' + i;
      $(id1).attr('id', id2);
      var keydata = phone_config[id2];
      if ( !keydata )
        keydata = {fcode: 0, key: '#' + id2, key_text: key_noop};
      $('#' + id2).data('keydata', JSON.stringify(keydata));
      $('#' + id2).text(keydata.key_text);
      $('#' + id2).tooltip('dispose');
      if ( keydata.key_title )
        $('#' + id2).tooltip({title: keydata.key_title, html: keydata.title_is_html || false});
      
      $('#' + id2).off('click');
      if ( keydata.cursor ) {
        $('#' + id2).css('cursor', 'pointer');
        var formid = get_formid(keydata['fcode']);
        $('#' + id2).on('click', $.proxy(function(key, formid) {
          if ( !keydata['label'] )
            $('.labeld-key').hide();
          set_edit_handler(key, formid);
        }, null, $('#' + id2), formid));
      } else {
        $('#' + id2).css('cursor', 'auto');
      }
    }
    if ( $('span.key-layout').length ) {
      var val = phone_config['key-layout_' + (mod-1)];
      set_key_layout(val);
    }
    module_no = mod;
    $('.modno').css("font-weight", 'normal');
    module.css("font-weight", 'bold');
  }

  function click_change_text(id, max, templates, rails_id) {
    rails_id = rails_id || id;
    var val = parseInt($('#'+id).data('state'));
    val = val || 0;
    val += 1;
    if ( val > max )
      val = 0;
    set_clickable_text(id, val, templates);
    phone_config[rails_id] = val;
    check_diff4template();
  }

  function set_doorcam_text(value) {
    $.get('/misc/ajax/doorcam_showjs', {doorcams: value}, function(data) {
      $('#show_door_cam').html(data);
    });    
  }

  function set_gs_ringtone(id, value) {
    phone_config['ringtones'][id] = value;
  }

  function set_borderled_text(value) {
    /* Bei Erweiterungsmodul sind die Texte nicht vorhanden */
    if ( template_text['yealink-borderled-texts'].length == 0 )
      return;
    var static_texts = template_text['yealink-borderled-texts'][0].split(';');
    var blink_texts = template_text['yealink-borderled-texts'][1].split(';');
    var detail_texts = template_text['yealink-borderled-texts'][2].split(';');
    var text, title;
    if ( value.static == 'off' )
      text = static_texts[0];
    else if ( value.static == 'call' )
      text = static_texts[1];
    else if ( value.static == 'on' )
      text = static_texts[2];
    if ( value.blink.length > 0 ) {
      var cond = value.blink.split(',');
      var condtext = [];
      for ( var i=0; i < cond.length; i++ ) {
        if ( cond[i] == 'call' )
          condtext.push(detail_texts[0]);
        else if ( cond[i] == 'vm' )
          condtext.push(detail_texts[1]);
        else if ( cond[i] == 'mute' )
          condtext.push(detail_texts[2]);
        else if ( cond[i] == 'hold' )
          condtext.push(detail_texts[3]);
        else if ( cond[i] == 'missed' )
          condtext.push(detail_texts[4]);
      }
      title = text + ' / ' + condtext.join(',');
      text = text + ' / ' + blink_texts[1];
    } else {
      text = text + ' / ' + blink_texts[0];
      title = text;
    }
    $('span'+value.key).text(text);
    $('span'+value.key).tooltip('dispose');
    $('span'+value.key).tooltip({title: title});
  }

  function set_display_tc_text(value) {
    if ( value.enabled > 0 ) {
      var off = value['tc_off'];
      var until = value['tc_until'];
      $('#display-time-control').text("Display von "+off+" bis "+until+" Uhr ausgeschaltet");
    } else {
      $('#display-time-control').text("Ausgeschaltet");
    }
  }

  function set_clickable_text(id, val, templates) {
    $('#'+id).data('state', val);
    $('#'+id).text(template_text[templates][val]);
  }

  function click_key_layout() {
    var val = parseInt($('span.key-layout').data('state'));
    val = val || 0;
    val += 1;
    if ( val >= 2 )
      val = 0;
    set_key_layout(val);
    phone_config['key-layout_' + (module_no-1)] = val;
    check_diff4template();
  }

  function set_key_layout(val) {
    $('span.key-layout').data('state', val);
    $('span.key-layout').text(key_layout_texts[val]);
  }

  function get_configuration() {
    return phone_config;
  }

  function check4changes() {
    if ( phone_config['initial_setting'] )
      return false;   // Initiale DECT-Konfiguration, Änderung der PIN ignorieren
    return !Object.equals(db_phone_config, phone_config) || db_ringer_str != current_ringer_str || already_changed;
  }

  function update_doorcams(value) {
    phone_config['doorcam'] = value;
    set_doorcam_text(value);
    check_diff4template();
  }

  function update_ringer(type, ringer) {
    if ( type == 'db' ) {
      db_ringer_str = ringer;
    } else if (type == 'current' ) {
      current_ringer_str = ringer;
      if ( db_phone_template_ringer_str )
        check_diff4template();
    }
  }

  function get_current_modno() {
    return module_no;
  }

  function limit_field_width(text) {
    // Textfeld-Begrenzung
    if ( !max_field_width )
      max_field_width = get_width('<span class="progkey-func">' + key_noop +'</span>');
    var field_width = get_width('<span class="progkey-func">' + text + '</span>');
    while ( field_width > max_field_width ) {
      text = text.slice(0, -1);
      field_width = get_width('<span class="progkey-func">' + text + '</span>');
    }
    return text;
  }

  function check_layer_usage(keybase, first, last) {
    var config = get_configuration();
    for ( var i=first; i <= last; i++ ) {
      if ( config[keybase +i] ) {
        if ( config[keybase+i]['fcode'] > 0 )
          return true;
      }
    }
    return false;
  }

  function show_display_yealink_tc() {
    $('.timepicker').clockpicker({autoclose: true, placement: 'top'});
    var current = phone_config['display-tc'];
    $('#yealink-time-control-form input[id=tc-enable_' + current.enabled+ ']').prop('checked', true);
    if ( current.enabled > 0 ) {
      $('#yealink-time-control-form input[type=text]').removeClass('disabled');
      $('#yealink-time-control-form input[type=text]').prop('disabled', false);
    } else {
      $('#yealink-time-control-form input[type=text]').addClass('disabled');
      $('#yealink-time-control-form input[type=text]').prop('disabled', true);
    }
    $('#yealink-time-control-form #tc_off').val(current.tc_off);
    $('#yealink-time-control-form #tc_until').val(current.tc_until);
    $('#tc_off').on('change', function() {
      var val = $(this).val();
      $(this).val(val.split(':')[0]);
    });
    $('#tc_until').on('change', function() {
      var val = $(this).val();
      $(this).val(val.split(':')[0]);
    });
    $('#yealink-time-control-form input[name=tc-enable]').on('change', function() {
      if ( $(this).val() > 0 ) {
        $('#yealink-time-control-form input[type=text]').removeClass('disabled');
        $('#yealink-time-control-form input[type=text]').prop('disabled', false);
      } else {
        $('#yealink-time-control-form input[type=text]').addClass('disabled');
        $('#yealink-time-control-form input[type=text]').prop('disabled', true);
      }
    });
    $('#yealink-time-control-form').modal();
  }

  function update_dect(pin, support_g722, in_ident, out_ident, display) {
    if ( pin )
      phone_config['pin'] = pin;
    if ( support_g722 >= 0 )
      phone_config['support_g722'] = support_g722;
    if ( in_ident && in_ident.length > 0 ) {
      phone_config['in_ident'] = in_ident;
      phone_config['in_ident']['key'] = '#in_ident';
    }
    if ( out_ident && out_ident.length > 0 ) {
      phone_config['out_ident'] = out_ident;
      phone_config['out_ident']['key'] = '#out_ident';
    }
    if ( display && display.length > 0 ) {
      phone_config['display'] = display;
      phone_config['display']['key'] = '#display';
    }
  }

  function update_checkbox(key, value) {
    phone_config[key] = value;
  }

  function get_mcast_settings(count) {
    if ( phone_config['mcast_settings'] ) 
      return { settings: phone_config['mcast_settings'], barge_in: phone_config['mcast_barge_in']};
    var ar = [];
    for(var i=0; i < count; i++ )
      ar.push(0);
    var s = ar.join(',');
    phone_config['mcast_settings'] = s;
    return { settings: s, barge_in: false};
  }

  function set_mcast_settings(val, mcast_barge_in) {
    phone_config['mcast_settings'] = val
    phone_config['mcast_barge_in'] = mcast_barge_in;
    check_diff4template();
  }

  return {
    set_initial_data: set_initial_data,         // Initial Daten aus Rails aktivieren
    do_submit: do_submit,                       // Daten übergeben
    change_layer_up: change_layer_up,           // Tasten-Ebene wechseln
    modno_change: modno_change,                 // Erweiterungsmodul wechseln
    update_dect: update_dect,                   // Daten von DECT-Basen übergeben
    click_change_text: click_change_text,
    click_key_layout: click_key_layout,
    check4changes: check4changes,
    get_current_modno: get_current_modno,
    set_line: set_line,
    set_blf: set_blf,
    set_direct: set_direct,
    set_cfim_0: set_cfim_0,
    set_cfwdgrp: set_cfwdgrp,
    set_call_lists: set_call_lists,
    set_agent: set_agent,
    set_keyfunc: set_keyfunc,
    set_icom: set_icom,
    set_dtmf: set_dtmf,
    set_doorcam: set_doorcam,
    set_directory: set_directory,
    set_profileled: set_profileled,
    set_action_url: set_action_url,
    set_softkeywidth: set_softkeywidth,
    set_softkeywidth2: set_softkeywidth2,
    set_keydata: set_keydata,
    set_edit_handler: set_edit_handler,
    set_coupled_edit_handler: set_coupled_edit_handler,
    reset_key_function: reset_key_function,
    set_border_led: set_border_led,
    set_yealink_tc: set_yealink_tc,
    get_configuration: get_configuration,
    get_mcast_settings: get_mcast_settings,
    set_mcast_settings: set_mcast_settings,
    update_ringer: update_ringer,
    update_doorcams: update_doorcams,
    change_ext_mod: change_ext_mod,
    change_border_led: change_border_led,
    set_gs_ringtone: set_gs_ringtone,
    set_phone_background: set_phone_background,
    set_borderled_text: set_borderled_text,
    check_diff4template: check_diff4template,
    limit_field_width: limit_field_width,
    update_key: update_key,
    update_checkbox: update_checkbox,
    check_layer_usage: check_layer_usage,
    show_display_yealink_tc: show_display_yealink_tc,
    set_display_tc_text: set_display_tc_text,
  };

  function get_width(arg) {
    var $el = $('<span></span>').html(arg);
    $('#main-container').append($el);
    ret = $el.width();
    $el.remove();
    return ret;
  }

  function get_key_code(code) {
    switch ( code ) {
      case 'f_cfwd':
        code = 4;
        break;
      case 'f_cfim':
        code = 5;
        break;
      case 'f_defcfwd':
        code = 6;
        break;
      case 'f_cfwdled':
        code = 7;
        break;
      case 'f_dnd':
        code = 9;
        break;
      case 'f_dndint':
        code = 10;
        break;
      case 'f_dir':
        code = 11;
        break;
      case 'f_dirint':
        code = 12;
        break;
      case 'f_call-lists':
        code = 13;
        break;
      case 'f_callwaiting':
        code = 14;
        break;
      case 'f_redial':
        code = 15;
        break;
      case 'f_help':
        code = 18;
        break;
      case 'f_conf':
        code = 19;
        break;
      case 'f_trans':
        code = 20;
        break;
      case 'f_hold':
        code = 21;
        break;
      case 'f_rec':
        code = 22;
        break;
      case 'f_voicemail':
        code = 23;
        break;
      case 'f_mute':
        code = 25;
        break;
      case 'f_nconfig':
        code = -1;
        break;
      case 'f_noop':
        code = 0;
        break;
      case 'f_menu':
        code = 26;
        break;
      case 'f_menuint':
        code = 27;
        break;
      case 'f_reg':
        code = 29;
        break;
      case 'f_virt':
        code = 30;
        break;
      case 'f_call-lists-0':
        code = 31;
        break;
      case 'f_callback':
        code = 32;
        break;
      case 'f_bluetooth':
        code = 33;
        break;
      case 'f_bt1':
        code = 34;
        break;
      case 'f_bt2':
        code = 35;
        break;
      case 'f_save':
        code = 36;
        break;
      case 'f_del':
        code = 37;
        break;
      case 'f_switch':
        code = 38;
        break;
      case 'f_state':
        code = 40;
        break;
      case 'f_line-0':
        code = 41;
        break;
      case 'f_settings':
        code = 42;
        break;
      case 'f_pickup':
        code = 43;
        break;
      case 'f_profile':
        code = 44;
        break;
      case 'f_profileled':
        code = 45;
        break;
      case 'f_ring_off':
        code = 46;
        break;
      case 'f_clip':
        code = 48;
        break;
      case 'f_prev_layer':
        code = 49;
        break;
      case 'f_next_layer':
        code = 50;
        break;

      default:
        console.log("Fehler bei " + drag.attr('id'));
        return;
    }
    return code;
  }

  // Aus dem function-code "fcode" die Formular-Id des Modals bestimmen
  function get_formid(fcode) {
    var id4fcode = {1: '#line_identities', 2: '#blf-form', 3: '#direct-form', 8: '#cfwdgrp-form', 11: '#directory-form', 13: '#call_lists-form', 16: '#agent-form', 17: '#icom-form', 24: '#dtmf-form', 28: '#doorcam-form', 39: '#cfim-0-form', 45: '#profileled-form', 47: '#action_url-form' };

    var formid;
    if ( fcode ) {
      formid = id4fcode[fcode];
    }
    formid = formid || '#label-form';
    return formid;
  }

  // Feststellen, ob Änderungen gegenüber der Schablone existieren
  function check_diff4template() {
    var blf_check = false;
    if ( $('button[name=reset2template]').length ) {
      if ( Object.equals(db_phone_template_config, phone_config) && db_phone_template_ringer_str == current_ringer_str ) {
        $('button[name=reset2template]').addClass('disabled');
        $('button[name=reset2template]').prop('disabled', true);
        blf_check = true  // Bei Gleichheit BLF-Belegung prüfen
      } else {
        $('button[name=reset2template]').removeClass('disabled');
        $('button[name=reset2template]').prop('disabled', false);
      }
    }
    return blf_check;
  }

  // Rücksetzen der Telefonkonfiguraiton auf die Schablone
  function reset_phone2template() {
    if ( db_phone_template_config['ext_mod_count'] !=  phone_config['ext_mod_count'] || db_phone_template_config['ext_mod_type'] !=  phone_config['ext_mod_type'] ) {
      $('div#extension_module').text(get_ext_module_names(db_phone_template_config['ext_mod_count'], db_phone_template_config['ext_mod_type']));
    }
    phone_config = $.extend(true, {}, db_phone_template_config);
    current_ringer_str = db_phone_template_ringer_str;
    $('input[type=hidden]#ringer').val(current_ringer_str);
    update_ringer_template_reset(current_ringer_str);

    // TODO Layer bearbeitung. Aktuell Bei Layern muss auf Layer 1 gewechselt werden, die Tasten beschriftet werden. Dazu muss bekannt sein welche Tastennamen mit zurückgesetzt werden sollen (Bei Mitel gibt es ts und skey)
    var keys = ['missed_led', 'display', 'username', 'extension', 'datetime', 'dirkey', 'okkey', 'border-led', 'display-tc'];
    $('.ui-droppable').each(function(idx) {
      keys.push($(this).attr('id'));
    });

    for ( var i in keys ) {
      var key = keys[i];
      var keydata;
      if ( phone_config.hasOwnProperty(key) ) {
        keydata = phone_config[key];
      } else {
        keydata = {fcode: 0, key: '#' + key, key_text: key_noop};
      }
      if ( key == 'display' ) {
        if ( template_text['snom-display-texts'].length )
          set_clickable_text('display', keydata, 'snom-display-texts');
        else if ( template_text['unify-display-texts'].length )
          set_clickable_text('display', keydata, 'unify-display-texts');
        else if ( template_text['phone-name-texts'].length )
          set_clickable_text('display', keydata, 'phone-name-texts');
      } else if ( key == 'missed_led' ) {
        set_clickable_text('missed-led', keydata, 'snom-led-texts');
      } else if ( key == 'username' ) {
        set_clickable_text('username', keydata, 'phone-name-texts');
      } else if ( key == 'extension' ) {
        set_clickable_text('extension', keydata, 'phone-name-texts');
      } else if ( key == 'datetime' ) {
        if ( template_text['mitel-datetime-texts'].length )
          set_clickable_text('datetime', keydata, 'mitel-datetime-texts');
        else if ( template_text['unify-datetime-texts'].length )
          set_clickable_text('datetime', keydata, 'unify-datetime-texts');
        else if ( template_text['yealink-datetime-texts'].length )
          set_clickable_text('datetime', keydata, 'yealink-datetime-texts');
        else if ( template_text['grandstream-datetime-texts'].length )
          set_clickable_text('datetime', keydata, 'grandstream-datetime-texts');
      } else if ( key == 'dirkey' ) {
        set_clickable_text('dirkey', keydata, 'gigaset-dirkey-texts');
      } else if ( key == 'okkey' ) {
        set_clickable_text('okkey', keydata, 'gigaset-okkey-texts');
      } else if ( key == 'border-led' ) {
        set_borderled_text(keydata);
      } else if ( key == 'display-tc' ) {
        set_display_tc_text(keydata);
      } else if ( key == 'doorcam' ) {
        set_doorcam_text(keydata);
      } else {
        var key_id = '#' + key;
//           reset_key_function('#'+key);
        if ( !keydata )
          keydata = {fcode: 0, key: key_id, key_text: key_noop};
        $(key_id).data('keydata', JSON.stringify(keydata));
        $(key_id).text(keydata.key_text);
        $(key_id).tooltip('dispose');
        if ( keydata.key_title )
          $(key_id).tooltip({title: keydata.key_title, html: keydata.title_is_html || false});

        $(key_id).off('click');
        if ( keydata.cursor ) {
          $(key_id).css('cursor', 'pointer');
          var formid = get_formid(keydata['fcode']);
          $(key_id).on('click', $.proxy(function(key, formid) {
            if ( !keydata['label'] )
              $('.labeld-key').hide();
            set_edit_handler(key, formid);
          }, null, $(key_id), formid));
        } else {
          $(key_id).css('cursor', 'auto');
        }
      }
    }
    if ( check_diff4template() )
      check4ownblf();
  }

  function check4ownblf() {
    for ( var key in phone_config ) {
      if ( phone_config.hasOwnProperty(key) ) {
        var keydata = phone_config[key];
        if ( keydata.fcode == 2 ) {
          if ( keydata.value == phone_own_extension ) {
            var match;
            if ( key.substring(0, 4) == "ekey" )
              match = /^([a-z0-9_\-]+)_(\d+)$/.exec(key);
            else
              match = /^([a-z_\-]+)(\d+)$/.exec(key);
            $('#blf_correction-form #own_extension').text(phone_own_extension);
            $('#blf_correction-form #save-button').on('click', function() {
              change_blf_keys(match);
              $('#blf_correction-form').modal('hide');
              $('#blf_correction-form #save-button').off('click');
            });
            $('#blf_correction-form').modal();
            break;
          }
        }
      }
    }
  }

  function change_blf_keys(match) {
    var mode = $('#blf_correction-form input[name=blf-correction]:checked').val();
    if ( mode !== undefined && match ) {
      var keybase = match[1];
      var first = phone_blf_keys[keybase][0];
      var last = phone_blf_keys[keybase][1];
      if ( keybase.substr(0, 4) == 'ekey' )
        lastidx = keybase + '_' + last;
      else
        lastidx = keybase + last;
      if ( mode == 0 ) {
        for ( var i = parseInt(match[2]); i < last; i++ ) {
          var idx1, idx2;
          if ( keybase.substr(0, 4) == 'ekey' ) {
            idx1 = keybase + '_' + i;
            idx2 =  keybase + '_' + (i+1);
          } else {
            idx1 = keybase + i;
            idx2 = keybase+(i+1);
          }
          if ( typeof(phone_config[idx2]) == 'object' )
            phone_config[idx1] = $.extend(true, {}, phone_config[idx2]);
          else
            phone_config[idx1] = $.extend(true, {}, {fcode: 0, key: '#' + lastidx, key_text: key_noop, cursor: false});
          phone_config[idx1]['key'] = '#' + (idx1);
        }
        phone_config[lastidx] = {fcode: 0, key: '#' + lastidx, key_text: key_noop, cursor: false};
      } else if ( mode == 1 ) {
        var value = phone_config[match[0]]['value'];
        var label = phone_config[match[0]]['label'];
        if ( label ) {
          phone_config[match[0]] = {fcode: 3, key: match[0], key_text: (key_direct + ' ' + value), key_title: label + ": " + (key_direct + ' ' + value), value: value, label: label, cursor: true};
        } else {
          phone_config[match[0]] = {fcode: 3, key: match[0], key_text: (key_direct + ' ' + value), key_title: (key_direct + ' ' + value), value: value, cursor: true};
        }
      } else if ( mode == 2 ) {
        phone_config[match[0]] = {fcode: 0, key: '#' + keybase + last, key_text: key_noop, cursor: false};
      }

      for ( var i=first; i<=last; i++ ) {
        var key = match[1] + i;
        var keydata;
        if ( phone_config.hasOwnProperty(key) ) {
          keydata = phone_config[key];
        } else {
          keydata = {fcode: 0, key: '#' + key, key_text: key_noop};
        }
        var key_id = '#' + key;
        if ( !keydata )
          keydata = {fcode: 0, key: key_id, key_text: key_noop};
        $(key_id).data('keydata', JSON.stringify(keydata));
        $(key_id).text(keydata.key_text);
        $(key_id).tooltip('dispose');
        if ( keydata.key_title )
          $(key_id).tooltip({title: keydata.key_title, html: keydata.title_is_html || false});

        $(key_id).off('click');
        if ( keydata.cursor ) {
          $(key_id).css('cursor', 'pointer');
          var formid = get_formid(keydata['fcode']);
          $(key_id).on('click', $.proxy(function(key, formid) {
            if ( !keydata['label'] )
              $('.labeld-key').hide();
            set_edit_handler(key, formid);
          }, null, $(key_id), formid));
        } else {
          $(key_id).css('cursor', 'auto');
        }
      }
      check_diff4template();
    }
  }

  function get_ext_module_names(num, types) {
    var name = PhoneExtmodules.get_module_name(types);
    if ( !name || num == 0 )
      name="(keine)";
    var name_list = "";
    if ( num > 0 ) {
      for( var i=0; i<num; i++ ) {
        name_list += name + ', ';
      }
      name_list = name_list.slice(0, -2);
    } else {
      name_list = name;
    }
    return name_list;
  }

  function get_blf_data(data) {
    var result = [];
    for ( var i=0; i<data.length; i++ ) {
      var s = data[i].sur_name + ', ' + data[i].gv_name + ' [' + data[i].exten + ']';
      var ext_id = data[i].ext_id;
      result.push({value: data[i].exten, label: s, alias: data[i].short, ext_id: ext_id});
    }
    return result;
  }

  function get_directcall_data(data) {
    var result = [];
    for ( var i=0; i<data.length; i++ ) {
      result.push( { value: data[i].phone, label: (data[i].name + "\n" + data[i].phone) } );
    }
    return result;
  }

})(PhoneFunctions || {});
window.PhoneFunctions = PhoneFunctions;

// Event-Handler setzen
jQuery(document).ready(function() {

  // Ändern der Anzahl oder des Typs von Erweiterungsmodulen
  $('button[name=change_mod]').on('click', function() {
    PhoneFunctions.change_ext_mod();
  });

  // Autofocus beim Erscheinen der Dialog-Boxen
  $('#label-form').on('shown.bs.modal', function() {
    $(this).find('#labeltext').focus();
  });
  $('#line_identities').on('shown.bs.modal', function() {
    $(this).find('select').focus();
  });
  $('#blf-form').on('shown.bs.modal', function() {
    blf_current_value = $(this).find('#value').val();
    $(this).find('#value').focus();
  });
  $('#direct-form').on('shown.bs.modal', function() {
    $(this).find('#value').focus();
  });
  $('#cfwdgrp-form').on('shown.bs.modal', function() {
    $(this).find('#cfwd_list_id').focus();
  });
  $('#agent-form').on('shown.bs.modal', function() {
    $(this).find('#agent-queue_id').focus();
  });
  $('#icom-form').on('shown.bs.modal', function() {
    $(this).find('#value').focus();
  });
  $('#dtmf-form').on('shown.bs.modal', function() {
    $(this).find('#value').focus();
  });
  $('#profileled-form').on('shown.bs.modal', function() {
    $(this).find('#profile_list_id').focus();
  });
  $('#action_url-form').on('shown.bs.modal', function() {
    $(this).find('#value').focus();
  });

  // Speichern des Label-Dialogs
  $('#label-form #save-button').on('click', function() {
    var code = $('#label-form input[name=key_code]').val();
    var keydata = PhoneFunctions.set_keydata('#label-form', code, true, {});
    $('#label-form #labeltext').val('');

    // Klicken zum nachträglichen Bearbeiten der Taste
    $(keydata['key']).on('click', function() {
      PhoneFunctions.set_edit_handler($(this), "#label-form");
    });
    PhoneFunctions.set_coupled_edit_handler(keydata['key'], "#label-form");
    $('#label-form').modal('hide');
  });

  // Speichern Leitungstaste
  $('#line_identities #save-button').on('click', function() {
    var value = $('#line_identities select option:selected').val();
    var keydata = PhoneFunctions.set_keydata('#line_identities', 1, true, {}, value);
    $('#line_identities select option').prop('selected', false);
    $('#line_identities #labeltext').val('');
    
    // Klicken zum nachträglichen Bearbeiten der Taste
    $(keydata['key']).on('click', function() {
      PhoneFunctions.set_edit_handler($(this), "#line_identities");
    });
    PhoneFunctions.set_coupled_edit_handler(keydata['key'], "#line_identities");
    $('#line_identities').modal('hide');
  });
  
  // Speichern BLF
  $('#blf-form #save-button').on('click', function() {
    var exten = $('#blf-form #value').val();
    var ext_id = $('#blf-form #ext_id').val();
    if ( ext_id.length == 0 || exten != blf_current_value ) {
      $.get('/misc/ajax/user_lookupjs?search=' + exten, function(data) {
        if ( data.length > 0 ) {
          var ext_id = data[0].ext_id;
          var keydata = PhoneFunctions.set_keydata('#blf-form', 2, true, {ext_id: ext_id}, exten);
          $('#blf-form #ext_id').val('');
          $('#blf-form #value').val('');
          $('#blf-form #labeltext').val('');

          // Klicken zum nachträglichen Bearbeiten der Taste
          $(keydata['key']).on('click', function() {
            PhoneFunctions.set_edit_handler($(this), "#blf-form");
          });
          PhoneFunctions.set_coupled_edit_handler(keydata['key'], "#blf-form");
          $('#blf-form').modal('hide');
        }
      });
    } else {
      var keydata = PhoneFunctions.set_keydata('#blf-form', 2, true, {ext_id: ext_id}, exten);
      $('#blf-form #ext_id').val('');
      $('#blf-form #value').val('');
      $('#blf-form #labeltext').val('');

      // Klicken zum nachträglichen Bearbeiten der Taste
      $(keydata['key']).on('click', function() {
        PhoneFunctions.set_edit_handler($(this), "#blf-form");
      });
      PhoneFunctions.set_coupled_edit_handler(keydata['key'], "#blf-form");
      $('#blf-form').modal('hide');
    }
  });

  // Speichern Zielwahl
  $('#direct-form #save-button').on('click', function() {
    var value = $('#direct-form #value').val();
    var keydata = PhoneFunctions.set_keydata('#direct-form', 3, true, {}, value);
    $('#direct-form #value').val('');
    $('#direct-form #labeltext').val('');

    // Klicken zum nachträglichen Bearbeiten der Taste
    $(keydata['key']).on('click', function() {
      PhoneFunctions.set_edit_handler($(this), "#direct-form");
    });
    PhoneFunctions.set_coupled_edit_handler(keydata['key'], "#direct-form");
    $('#direct-form').modal('hide');
  });

  // Speichern Rufumleitung mit Zielangabe
  $('#cfim-0-form #save-button').on('click', function() {
    var value = $('#cfim-0-form #value').val();
    var keydata = PhoneFunctions.set_keydata('#cfim-0-form', 39, true, {}, value);
    $('#cfim-0-form #value').val('');
    $('#cfim-0-form #labeltext').val('');

    // Klicken zum nachträglichen Bearbeiten der Taste
    $(keydata['key']).on('click', function() {
      PhoneFunctions.set_edit_handler($(this), "#cfim-0-form");
    });
    PhoneFunctions.set_coupled_edit_handler(keydata['key'], "#cfim-0-form");
    $('#cfim-0-form').modal('hide');
  });

  // Speichern Telefonbuch mit Optionen (T9/Formular)
  $('#directory-form #save-button').on('click', function() {
    var value = $('#directory-form input[name=dir-mode]:checked').val();
    var title = $('label[for=dir-mode_' + value +']').text();
    var keydata = PhoneFunctions.set_keydata('#directory-form', 11, true, {title: title}, value);
    $('#directory-form #value').val('');
    $('#directory-form #labeltext').val('');

    // Klicken zum nachträglichen Bearbeiten der Taste
    $(keydata['key']).on('click', function() {
      PhoneFunctions.set_edit_handler($(this), "#directory-form");
    });
    PhoneFunctions.set_coupled_edit_handler(keydata['key'], "#directory-form");
    $('#directory-form').modal('hide');
  });

  // Speichern Gruppenumleitung
  $('#cfwdgrp-form #save-button').on('click', function() {
    var cf = $('#cfwdgrp-form select option:selected').val();
    var cf_text = $('#cfwdgrp-form select option:selected').text();
    var value = $('#cfwdgrp-form #cfwd_dest').val();
    var keydata = PhoneFunctions.set_keydata('#cfwdgrp-form', 8, true, {cf: cf, cf_text: cf_text}, value);
    $('#cfwdgrp-form select option').prop('selected', false);
    $('#cfwdgrp-form #cfwd_dest').val('');
    $('#cfwdgrp-form #labeltext').val('');

    // Klicken zum nachträglichen Bearbeiten der Taste
    $(keydata['key']).on('click', function() {
      PhoneFunctions.set_edit_handler($(this), "#cfwdgrp-form");
    });
    PhoneFunctions.set_coupled_edit_handler(keydata['key'], "#cfwdgrp-form");
    $('#cfwdgrp-form').modal('hide');
  });

  // Speichern Profile
  $('#profileled-form #save-button').on('click', function() {
    var profile = $('#profileled-form select option:selected').val();
    var profile_text = $('#profileled-form select option:selected').text();
    var value = $('#profileled-form #profile_dest').val();
    var keydata = PhoneFunctions.set_keydata('#profileled-form', 45, true, {profile: profile, profile_text: profile_text}, value);
    $('#profileled-form select option').prop('selected', false);
    $('#profileled-form #profile_dest').val('');
    $('#profileled-form #labeltext').val('');

    // Klicken zum nachträglichen Bearbeiten der Taste
    $(keydata['key']).on('click', function() {
      PhoneFunctions.set_edit_handler($(this), "#profileled-form");
    });
    PhoneFunctions.set_coupled_edit_handler(keydata['key'], "#profileled-form");
    $('#profileled-form').modal('hide');
  });
  $('#profileled-form select').on('change', function() {
    var value = $(this).val();
    var profiles = $('#jsdata-profile').data('profiles');
    $('#profileled-form #profile_dest').val(profiles[value]);
  });

  // Speichern Ruflisten
  $('#call_lists-form #save-button').on('click', function() {
    var value = $('#call_lists-form input[name=cl-mode]:checked').val();
    var title = $('label[for=cl-mode_' + value +']').text();
    var keydata = PhoneFunctions.set_keydata('#call_lists-form', 13, true, {title: title}, value);
    $('#call_lists-form input[name=cl-mode]').prop('checked', false);
    $('#call_lists-form #labeltext').val('');

    // Klicken zum nachträglichen Bearbeiten der Taste
    $(keydata['key']).on('click', function() {
      PhoneFunctions.set_edit_handler($(this), "#call_lists-form");
    });
    PhoneFunctions.set_coupled_edit_handler(keydata['key'], "#call_lists-form");
    $('#call_lists-form').modal('hide');
  });

  // Speichern Agentenan- oder Abmeldung
  $('#agent-form #save-button').on('click', function() {
    var value = $('#agent-form select option:selected').val();
    var queue_text = $('#agent-form select option:selected').text();
    var title;
    var parts = value.split('_');
    if ( parts[0] == 'r')
      title = "Klingelgruppe " + queue_text;
    else
      title = "Warteschlange " + queue_text;
    var keydata = PhoneFunctions.set_keydata('#agent-form', 16, true, {title: title}, value);
    $('#agent-form select option').prop('selected', false);
    $('#agent-form #labeltext').val('');

    // Klicken zum nachträglichen Bearbeiten der Taste
    $(keydata['key']).on('click', function() {
      PhoneFunctions.set_edit_handler($(this), "#agent-form");
    });
    PhoneFunctions.set_coupled_edit_handler(keydata['key'], "#agent-form");
    $('#agent-form').modal('hide');
  });

  // Speichern Gegensprechen
  $('#icom-form #save-button').on('click', function() {
    var value = $('#icom-form #value').val();
    var keydata = PhoneFunctions.set_keydata('#icom-form', 17, true, {}, value);
    $('#icom-form #value').val('');
    $('#icom-form #labeltext').val('');

    // Klicken zum nachträglichen Bearbeiten der Taste
    $(keydata['key']).on('click', function() {
      PhoneFunctions.set_edit_handler($(this), "#icom-form");
    });
    PhoneFunctions.set_coupled_edit_handler(keydata['key'], "#icom-form");
    $('#icom-form').modal('hide');
  });

  // Speichern DTMF
  $('#dtmf-form #save-button').on('click', function() {
    var value = $('#dtmf-form #value').val();
    var keydata = PhoneFunctions.set_keydata('#dtmf-form', 24, true, {}, value);
    $('#dtmf-form #value').val('');
    $('#dtmf-form #labeltext').val('');

    // Klicken zum nachträglichen Bearbeiten der Taste
    $(keydata['key']).on('click', function() {
      PhoneFunctions.set_edit_handler($(this), "#dtmf-form");
    });
    PhoneFunctions.set_coupled_edit_handler(keydata['key'], "#dtmf-form");
    $('#dtmf-form').modal('hide');
  });

  // Speichern Aktions-URL
  $('#action_url-form #save-button').on('click', function() {
    var value = $('#action_url-form #value').val();
    var keydata = PhoneFunctions.set_keydata('#action_url-form', 47, true, {}, value);
    $('#action_url-form #value').val('');
    $('#action_url-form #labeltext').val('');

    // Klicken zum nachträglichen Bearbeiten der Taste
    $(keydata['key']).on('click', function() {
      PhoneFunctions.set_edit_handler($(this), "#action_url-form");
    });
    PhoneFunctions.set_coupled_edit_handler(keydata['key'], "#action_url-form");
    $('#action_url-form').modal('hide');
  });

  // Speichern Kamera-Dialog
  $('#doorcam-form #save-button').on('click', function() {
    var cam = $('#doorcam-form select option:selected').val();
    var cam_text = $('#doorcam-form select option:selected').text();
    var url = $('#doorcam-form #url').val();
    var keydata =PhoneFunctions.set_keydata('#doorcam-form', 28, true, {cam: cam, cam_text: cam_text, url: url});
    $('#doorcam-form select option').prop('selected', false);
    $('#doorcam-form #url').val('');
    $('#doorcam-form #labeltext').val('');

    // Klicken zum nachträglichen Bearbeiten der Taste
    $(keydata['key']).on('click', function() {
      PhoneFunctions.set_edit_handler($(this), "#doorcam-form");
    });
    PhoneFunctions.set_coupled_edit_handler(keydata['key'], "#doorcom-form");
    $('#doorcam-form').modal('hide');
  });

  // Türsprechstellen / Kamera
  $('button[name=config_doorcams]').on('click', function() {
    $.get('/misc/ajax/doorcamjs', function(data, status) {
      for ( var i = 0; i < data.length; i++) {
        if ( $('#door_cams-form select option[value="'+data[i][0]+'"]').length === 0 ) {
          $('#door_cams-form select:last-child').append("<option value=\"" + data[i][0] + "\">" + data[i][1] + "</option>");
        }
      }
      var config = PhoneFunctions.get_configuration();
      var cams_sel = config['doorcam'].split(',');
      $('#door_cams-form select option').prop('selected', false);
      for ( i = 0; i < cams_sel.length; i++ ) {
        var s = '#cam_' + (i+1) + ' option[value="' + cams_sel[i] + '"]';
        $('#cam_' + (i+1) + ' option[value="' + cams_sel[i] + '"]').prop('selected', true);
      }
      $('#door_cams-form #save-button').on('click', function() {
        $('#door_cams-form').modal('hide');
        var a = [];
        var idx = 0;
        $('#door_cams-form select').each(function(x){
          if ( $(this).val() > 0 ) {
            a[idx] = parseInt($(this).val());
            idx++;
          }
        });
        for(; idx < 10; idx++)
          a.push(0);
        var door_cams_selected = a.join(',');
        PhoneFunctions.update_doorcams(door_cams_selected);
      });

      $('#door_cams-form').modal();
    });
  });

  // Speichern LED-Einstellungen
  $('#led-form #save-button').on('click', function() {
    var solid = $('#led-form #led-on').val();
    var blink = [];
    $('#led-form [name*=led-flash]').each(function() {
      if ( $(this).prop("checked") )
        blink.push($(this).val());
    });
    PhoneFunctions.set_border_led(solid, blink.join(','));
    var config = PhoneFunctions.get_configuration();
    PhoneFunctions.set_borderled_text(config['border-led']);
    PhoneFunctions.check_diff4template();
    $('#led-form').modal('hide');
  });

  // Speichern Display Zeitsteuerung bei Yealink
  $('#yealink-time-control-form #save-button').on('click', function() {
    var enabled = $('#yealink-time-control-form input[name=tc-enable]:checked').val();
    var tc_off = $('#yealink-time-control-form #tc_off').val();
    var tc_until = $('#yealink-time-control-form #tc_until').val();
    var config = PhoneFunctions.get_configuration();
    PhoneFunctions.set_yealink_tc(parseInt(enabled), tc_off, tc_until);
    PhoneFunctions.set_display_tc_text(config['display-tc']);
    PhoneFunctions.check_diff4template();
    $('#yealink-time-control-form').modal('hide');
  });

  // Speichern Bildschirm-Hintergrund
  $('#phone-bg-form #save-button').on('click', function() {
    var config = PhoneFunctions.get_configuration();
    var img = $('#phone-bg-form #image').val();
    if ( img == 'custom' ) {
      var form_data = new FormData(jQuery('#img-upload-form')[0]);
      $.ajax({
        url: '/misc/ajax/background_image_upload',
        method: 'POST',
        data: form_data,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          if ( data.error ) {
            $('#phone-bg-form #show-error').show();
            $('#phone-bg-form #error-msg').html(data.error);
          } else {
            config['bg_image'] = { image: img, name: data.name, temp_path: data.path }
            $('#phone-bg-form').modal('hide');
          }
        }
      });
      return;
    }
    config['bg_image'] = { image: img };
    $('#phone-bg-form').modal('hide');
  });

});

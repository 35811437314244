import { start_spinner_popup, stop_spinner_popup, escapeHtml } from '../iptam.js';

var agent_list;

jQuery(document).ready(function() {

  if ( $('#ringgroups-table').length || $('#ringgroups-user-table').length || $('#waitingqueues-table').length || $('#waitingqueues-user-table').length) {

    var group_type_id;
    var group_id;
    var gui_area;
    var agent_types;

    var agent_show_search = function() {
      agent_list = [];
      $('select[name="user_list"] option').each(function(idx, obj) {
        var value = obj.value;
        var text = obj.text;
        agent_list.push([value, text]);
      });

      if ( agent_list.length > 20 ) {
        $('#agent_add_member-dialog #search').show();
        $('#agent_del_member-dialog #search').show();
      } else {
        $('#agent_add_member-dialog #search').hide();
        $('#agent_del_member-dialog #search').hide();
      }
    };

    $('#agent_add_member-dialog #search').on('keyup', function() {
      var list = []
      var lookup = $(this).val();
      $.each(agent_list, function(idx, entry) {
        if ( entry[1].toLowerCase().indexOf(lookup.toLowerCase()) >= 0 ) {
          list.push(entry);
        }
      });
      var opts = '';
      $.each(list, function(idx, entry) {
        opts += "<option value='" + entry[0] + "'>" + escapeHtml(entry[1]) + "</option>"
      });
      $('#agent_add_member-dialog select[name="user_list"]').html(opts);
      var size;
      if ( list.length == 0) size = 1;
      else if ( list.length > 4 ) size = 4;
      else size = list.length;
      $('#agent_add_member-dialog select[name="user_list"]').attr('size', size);
      if ( size == 1 ) {
        $('#agent_add_member-dialog select[name="user_list"] option[value="' + list[0][0] + '"]').prop('selected', 'selected');
      }
    });

    $('#agent_add_member-dialog #save-button').on('click', function() {
      var url;
      var next_url;
      var values = {};
      var selected = $('#agent_add_member-dialog select[name="user_list"] :selected').val();
      var agent_type;
      if ( !$('#agent_add_member-dialog #show_agent_type').is(':hidden') ) {
        agent_type = $('#agent_add_member-dialog input[type=radio][name="agent_type"]:checked').val();
        if ( !agent_type )
          return;
      }
      $('#agent_add_member-dialog').modal('hide');
      if ( gui_area == 'user' ) {
        url = '/user/agents/agent_update';
        next_url = '/user/agents';
        values = { what: 'add', id: group_id, group_type: group_type_id, selected: selected, agent_type: agent_type };
      } else if ( gui_area == 'admin' ) {
        if ( group_type_id == 'r' ) {
          url = '/admin/ringgroups/' + group_id + '/agent_update';
          next_url = '/admin/ringgroups';
          values = { what: 'add', selected: selected, agent_type: agent_type };
        } else if ( group_type_id == 'q' ) {
          url = '/admin/waitingqueues/' + group_id + '/agent_update';
          next_url = '/admin/waitingqueues';
          values = { what: 'add', selected: selected, agent_type: agent_type };
        }
      }
      start_spinner_popup();
      $.post(url, values, function(data, status) {
        window.location = next_url;
      });
    });

    $('.agent_add_entry').on('click', function() {
      var t = $(this).data('name');
      $('#agent_add_member-dialog span#group_name').text(t);
      t = $(this).data('grouptype');
      $('#agent_add_member-dialog span#type_text').text(t);
      group_type_id = $(this).data('type-id');
      group_id = $(this).data('entry-id');
      gui_area = $(this).data('area');
      agent_types = $(this).data('agent-types');
      start_spinner_popup();
      $.post('/misc/ajax/group_users', { what: 'add', type: group_type_id, id: group_id }, function(data, status) {
        console.log({data});
        var opts = '';
        $.each(data, function(idx, entry) {
          opts += "<option value='" + entry[1] + "'>" + escapeHtml(entry[0]) + "</option>"
        });
        $('#agent_add_member-dialog select[name="user_list"]').html(opts);
        var size;
        if ( data.length == 0) size = 1;
        else if ( data.length > 4 ) size = 4;
        else size = data.length;
        $('#agent_add_member-dialog select[name="user_list"]').attr('size', size);
        agent_show_search();
        if ( parseInt(agent_types) == 3 )
          $('#agent_add_member-dialog #show_agent_type').show();
        else
          $('#agent_add_member-dialog #show_agent_type').hide();
        stop_spinner_popup();
        $('#agent_add_member-dialog').modal();
      });
    });

    $('#agent_del_member-dialog #search').on('keyup', function() {
      var list = []
      var lookup = $(this).val();
      $.each(agent_list, function(idx, entry) {
        if ( entry[1].toLowerCase().indexOf(lookup.toLowerCase()) >= 0 )
          list.push(entry);
      });
      var opts = '';
      $.each(list, function(idx, entry) {
        opts += "<option value='" + entry[0] + "'>" + escapeHtml(entry[1]) + "</option>"
      });
      $('#agent_del_member-dialog select[name="user_list"]').html(opts);
      var size;
      if ( list.length == 0) size = 1;
      else if ( list.length > 4 ) size = 4;
      else size = list.length;
      $('#agent_del_member-dialog select[name="user_list"]').attr('size', size);
    });

    $('#agent_del_member-dialog #save-button').on('click', function() {
      var url;
      var next_url;
      var values = {};
      var selected = $('#agent_del_member-dialog select[name="user_list"] :selected').val();
      $('#agent_del_member-dialog').modal('hide');
      if ( gui_area == 'user' ) {
        url = '/user/agents/agent_update';
        next_url = '/user/agents';
        values = { what: 'del', id: group_id, group_type: group_type_id, selected: selected };
      } else if ( gui_area == 'admin' ) {
        if ( group_type_id == 'r' ) {
          url = '/admin/ringgroups/' + group_id + '/agent_update';
          next_url = '/admin/ringgroups';
          values = { what: 'del', selected: selected };
        } else if ( group_type_id == 'q' ) {
          url = '/admin/waitingqueues/' + group_id + '/agent_update';
          next_url = '/admin/waitingqueues';
          values = { what: 'del', selected: selected };
        }
      }
      start_spinner_popup();
      $.post(url, values, function(data, status) {
        window.location = next_url;
      });
    });

    $('.agent_del_entry').on('click', function() {
      var t = $(this).data('name');
      $('#agent_del_member-dialog span#group_name').text(t);
      t = $(this).data('grouptype');
      $('#agent_del_member-dialog span#type_text').text(t);
      group_type_id = $(this).data('type-id');
      group_id = $(this).data('entry-id');
      gui_area = $(this).data('area');
      start_spinner_popup();
      $.post('/misc/ajax/group_users', { what: 'del', type: group_type_id, id: group_id }, function(data, status) {
        console.log({data});
        var opts = '';
        $.each(data, function(idx, entry) {
          opts += "<option value='" + entry[1] + "'>" + escapeHtml(entry[0]) + "</option>"
        });
        $('#agent_del_member-dialog select[name="user_list"]').html(opts);
        var size;
        if ( data.length == 0) size = 1;
        else if ( data.length > 4 ) size = 4;
        else size = data.length;
        $('#agent_del_member-dialog select[name="user_list"]').attr('size', size);
        agent_show_search();
        stop_spinner_popup();
        $('#agent_del_member-dialog').modal();
      });
    });
  }

});

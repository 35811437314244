jQuery(document).ready(function() {
  if ( $('#mail_title').length ) {
    $('#email_auth_enabled').on('change',  function() {
      if ( $(this).prop('checked') )
        $('.mail_with_auth').show();
      else
        $('.mail_with_auth').hide();
    });
    $('#email_auth_enabled').change();

    $('#email_always_bcc').on('change',  function() {
      if ( $(this).prop('checked') )
        $('.use_bcc').show();
      else
        $('.use_bcc').hide();
    });
    $('#email_always_bcc').change();
    if ( !$('#email_enabled').prop('checked') ) {
      $('button[type=submit][name=send_testmail]').prop('disabled', true);
    }
  }
});

jQuery(document).ready(function() {

  if ( $('#settings_title').length ) {

    function update_ntp_server_list() {
      var value = $('#ntp_server').val();
      var v = value.split(';');
      var options = "";
      for ( var i=0; i < v.length; i++ ) {
        options += ("<option value='" + v[i] + "'>" + v[i] + "</option>");
      }
      $('#system_ntp_server').html(options);
      $('#system_ntp_server').attr('size', v.length > 4 ? 4 : v.length);
    }

    $('.timepicker').clockpicker({
      placement: 'top',
      align: 'left',
      autoclose: true,
      'default': 'now'
    });

    if ( $('input[type=radio][name="system[ntp_client_enabled]"]:checked').val() == 'true' ) {
      $('#date').attr('disabled', true);
      $('#time').attr('disabled', true);
    }

    $('input[type=radio][name="system[ntp_client_enabled]"]').on ('change', function(e) {
      var ntp = $('input[type=radio][name="system[ntp_client_enabled]"]:checked').val();
      if ( ntp != 'true' ) {
        $('#date').attr('disabled', false);
        $('#time').attr('disabled', false);
      } else {
        $('#date').attr('disabled', true);
        $('#time').attr('disabled', true);
      }
    });

    $('#add_ntp_server').on('click', function() {
      if ( $('#new_ntp_server').val().length ) {
        var value = $('#ntp_server').val();
        if ( value.length > 0 )
          value = value + ';' + $('#new_ntp_server').val();
        else
          value = $('#new_ntp_server').val();
        $('#ntp_server').val(value);
        $('#new_ntp_server').val("");
        update_ntp_server_list();
      }
    });

    $('#del_ntp_server').on('click', function() {
      var value = $('#ntp_server').val();
      var to_del = $('#system_ntp_server').val();
      var v = value.split(';');
      value = "";
      for(var i = v.length - 1; i >= 0; i--) {
        if ( v[i] !== to_del ) {
          if ( value.length > 0 ) {
            value = value + ';' + v[i];
          } else {
            value = v[i];
          }
        }
      }
      $('#ntp_server').val(value);
      update_ntp_server_list();
    });
  }

});

jQuery(document).ready(function() {
  if ( $('.logout-timer').length > 0 ) {
    var logout_timer = $('.logout-timer');
    var session_timer = logout_timer.data('session-timer');
    var server_time = logout_timer.data('server-time');

    /* Funktion für Überwachung des Session-Timeouts */
    var timeout_f = function() {
      $.get('/logins/check_session', function(data, status) {
        /* Client sieht Zeit in Kürze abgelaufen, Prüfen und ggf Hinweis */
        var remaining = data.expire_at - data.now;
        if ( remaining > 60 ) {
          /* Die Session läuft noch mehr als eine Minute */
          setTimeout(timeout_f, (remaining-60) * 1000);
          return;
        }
        var relogin;
        $('#session_destroy').modal();
        $('#session-continue-button').on('click', function() {
          /* Klick auf "Fortsetzen" Session fortsetzen */
          $('#session_destroy').modal('hide');
          clearTimeout(relogin);
          $.get('/logins/update_session', function(data, status) {
            setTimeout(timeout_f, timeout);
          });
        });
        relogin = setTimeout(function() {
          $('#session_destroy').modal('hide');
          clearTimeout(timeout_f);
          window.location = "/logins/logout";
        }, 50000);
      });
    };

    if ( session_timer ) {
      /* Session-Timer aktiv */
      var timeout = (session_timer-60) * 1000;
      setTimeout(timeout_f, timeout);
    }
  }
});

import { start_spinner_popup } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#servicenumber_title').length ) {
    $('#cfxx-save-button').on('click', function() {
      $('#servicenumber_cfim').val($('#save-cfxx_cfim').val());
      $('#servicenumber_cfun').val($('#save-cfxx_cfun').val());
      $('#servicenumber_cfbs').val($('#save-cfxx_cfbs').val());
      $('#servicenumber_pre_on').val($('#sw_on1').val() + $('#sw_on2').val());
      $('#servicenumber_pre_off').val($('#sw_off1').val() + $('#sw_off2').val());
      $('#servicenumber_pre_list').val($('#sw_list1').val() + $('#sw_list2').val());
      $('form[action="/admin/servicenumbers/update"]').append("<input type='hidden' name='save_cf'>");
      $('#servicenumber_callfwd').modal('hide');
      start_spinner_popup();
      $('form[action="/admin/servicenumbers/update"]').submit();
    });
  }
});

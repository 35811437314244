import { activate_tables, start_spinner_popup, stop_spinner_popup } from './iptam.js';

$(document).ready(function() {

  if ( $('#show_extens_wo_location').length ) {

    $('#show_missing_locations').on('click', function() {
      start_spinner_popup();
      $.get("/admin/locations/get_extens_wo_location", function(data, status) {
        for ( var i=0; i < data.no_location.length; i++ ) {
          var line = "<tr><td>" + data.no_location[i]['exten'] + "</td><td>" + data.no_location[i]['type'] + "</td><td>" + data.no_location[i]['name'] + "</td></tr>";
          $('#show_extens_wo_location tbody').append(line);
        }
        var table_no_locations = activate_tables('#no_locations', false);
        $('#show_extens_wo_location').on('hidden.bs.modal', function (e) {
          table_no_locations.destroy();
          $('#show_extens_wo_location tbody tr').remove();
        });
        stop_spinner_popup();
        $('#show_extens_wo_location').modal();
      });
    });

    $('#show_extens_wo_location #save-button').on('click', function() {
      var vals = {};
      vals['default_location'] = $('#def_location option:selected').val();
      var url = $(this).data('url');
      $('#show_extens_wo_location').modal('hide');
      start_spinner_popup();
      $.post("/admin/locations/set_extens_wo_location", vals, function(data, status) {
        $('div.alert-info').remove();
        window.location.href = url
      });
    });

  }
  
});

jQuery(document).ready(function() {

  if ( $('#autobu-check_connect').length ) {
    $('.timepicker').clockpicker({autoclose: true, placement: 'top'});
    $('#autobu-check_connect').on('click', function() {
      var server = $('#auto_backup_server').val();
      var path = $('#auto_backup_path').val();
      var user = $('#auto_backup_user').val();
      var password = $('#auto_backup_password').val();
      var domain = $('#auto_backup_domain').val();
      var vals = {};
      vals['server'] = $('#auto_backup_server').val();
      vals['path'] = $('#auto_backup_path').val();
      vals['user'] = $('#auto_backup_user').val();
      vals['password'] = $('#auto_backup_password').val();
      vals['password_confirmation'] = $('#auto_backup_password_confirmation').val();
      vals['domain'] = $('#auto_backup_domain').val();
      $.post('/admin/autobackup/check_connect', vals, function(data, status) {
      });
    });
  }

});

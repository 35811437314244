import { start_spinner_popup, stop_spinner_popup, escapeHtml } from './iptam.js';

var perm_user_source_list;
var perm_user_dest_list;

var permitted_users_show_search = function() {

  perm_user_source_list = [];
  perm_user_dest_list = [];
  $('select[name="av_user_list[]"] option').each(function(idx, obj) {
    var value = obj.value;
    var text = obj.text;
    perm_user_source_list.push([value, text]);
  });

  $('select[name="permitted_user_list[]"] option').each(function(idx, obj) {
    var value = obj.value;
    var text = obj.text;
    perm_user_dest_list.push([value, text]);
  });

  if ( perm_user_source_list.length > 20 )
    $('#perm_source_search').show();
  else
    $('#perm_source_search').hide();

  if ( perm_user_dest_list.length > 20 )
    $('#perm_dest_search').show();
  else
    $('#perm_dest_search').hide();
};
window.permitted_users_show_search = permitted_users_show_search;

jQuery(document).ready(function() {

  $('#perm_source_search').on('keyup', function() {
    var list = []
    var lookup = $(this).val();
    $.each(perm_user_source_list, function(idx, entry) {
      if ( entry[1].toLowerCase().indexOf(lookup.toLowerCase()) >= 0 )
        list.push(entry);
    });
    var opts = '';
    $.each(list, function(idx, entry) {
      opts += "<option value='" + entry[0] + "'>" + escapeHtml(entry[1]) + "</option>"
    });
    $('select[name="av_user_list[]"]').html(opts);
  });

  $('#perm_dest_search').on('keyup', function() {
    var list = []
    var lookup = $(this).val();
    $.each(perm_user_dest_list, function(idx, entry) {
      if ( entry[1].toLowerCase().indexOf(lookup.toLowerCase()) >= 0 )
        list.push(entry);
    });
    var opts = '';
    $.each(list, function(idx, entry) {
      opts += "<option value='" + entry[0] + "'>" + escapeHtml(entry[1]) + "</option>"
    });
    $('select[name="permitted_user_list[]"]').html(opts);
  });

  function do_post(what, list, add_value) {
    var url_orig = $('form[action!=""]').attr('action');
    var parts = url_orig.split('/');
    var id = parts[parts.length-1];
    if ( isNaN(id) ) {
      parts.length = parts.length-1;
    }
    parts.push('update_permitted_user')
    var url = parts.join('/');
    start_spinner_popup();
    $.post(url, { what: what, id: id, data: list, add_value: add_value}, function(data, status) {
      stop_spinner_popup();
      $('#perm_source_search').val("");
      $('#perm_dest_search').val("");
    });
  }

  $('button[name=add_user_permitted]').on('click', function() {
    var list = [];
    $('select[name="av_user_list[]"] :selected').each(function(idx, obj) {
      list.push(obj.value);
    });
    if ( list.length == 0 ) {
      var add_obj = $('#perm_source_search ~ input');
      if ( add_obj.length == 1 ) {
        var value = add_obj.val();
        add_obj.val("");
      }
    }
    do_post('add', list.join(','), value);
  });

  $('button[name=del_user_permitted]').on('click', function() {
    var list = [];
    $('select[name="permitted_user_list[]"] :selected').each(function(idx, obj) {
      list.push(obj.value);
    });
    do_post('del', list.join(','));
  });

});

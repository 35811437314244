jQuery(document).ready(function() {

  if ( $('#vmbox_edit_title').length ) {

    function get_current_vals() {
      var keys = ['prompt_file_unav', 'prompt_file_busy', 'prompt_file_message'];
      var result = {};
      for(var i=0; i<keys.length; i++) {
        result[keys[i]] = $('#' + keys[i]).val();
      }
      return result;
    }

    $('input[type=radio][name=prompt_type]').on('change', function() {
      var v = $(this).val();
      if ( v ) {
        $('.audio_prompt').prop('disabled', false);
        if ( v == 'message' ) {
          $('button[name=reset2default]').prop('disabled', true);
        } else {
          $('button[name=reset2default]').prop('disabled', false);
        }
      } else {
        $('.audio_prompt').prop('disabled', true);
      }
    });
    if ( $('input[type=radio][name=prompt_type]:checked').val() ) {
      $('.audio_prompt').prop('disabled', false);
    } else {
      $('.audio_prompt').prop('disabled', true);
    }

    var type_of_action;

    /* Aufnehmen via Telefon: Dialog zur Auswahl des Telefons */
    $('button[name=record]').on('click', function(e) {
      var prompt_type =  $('input[type=radio][name=prompt_type]:checked').val();
      type_of_action = 'record';
      $('#select_phone').modal();
    });

    /* Abspielen via Telefon: Dialog zur Auswahl des Telefons */
    $('button[name=play]').on('click', function(e) {
      var prompt_type =  $('input[type=radio][name=prompt_type]:checked').val();
      type_of_action = 'play';
      $('#select_phone').modal();
    });

    $('button[name=upload]').on('click', function(e) {
      var prompt_type =  $('input[type=radio][name=prompt_type]:checked').val();
      $('#file_upload .modal-title').html($(this).data('title'));
      $('#file_upload #upload-button').attr('data-filetype', prompt_type);
//       $('#file_upload #upload-button').attr('data-entry_id', "id");
      $('#file_upload #file_type').html($(this).data('filetype'));
      $('#file_upload').modal();
    });

    $('#select_phone #number').click(function(e) {
      $('#phone_number').prop("checked", true);
    });

    $('input[type=radio][name=phone]').on('change', function() {
      if ( $(this).val() != 'number' )
        $('#number').val("");
    });

    /* Speichern Telefonauswahl und Aufnahme / Abspielen ausführen */
    $('#select_phone #save-button').on('click', function() {
      var phone = $('input[type=radio][name=prompt]:checked').val();
      var number = $('#number').val();
      var vals = get_current_vals();
      vals['what'] = type_of_action;
      vals['phone'] = (phone == 'own' || !number) ? 'own' : number;
      vals['prompt_type'] = $('input[type=radio][name=prompt_type]:checked').val();
      $('#select_phone').modal('hide');
      $.post("/user/vmboxes/ajax_update", vals, function(data, status) {
      });
    });

    /* Speichern des File-Uploads */
    $('#upload-button').on('click', function() {
      var formData = new FormData(jQuery('#upload-form')[0]);
      formData.append('what', 'upload');
      formData.append('prompt_type', $('#file_upload #upload-button').attr('data-filetype'));
                           
      var vals = get_current_vals();
      for (key in vals) {
        formData.append(key, vals[key]);
      }
      $.ajax({
        url: '/user/vmboxes/ajax_update',
        method: 'POST',
        data: formData,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          console.log({data});
          $('#file_upload').modal('hide');
        }
      });
    });

    /* Download des Prompts */
    $('button[name=download]').on('click', function(e) {
      var prompt_type =  $('input[type=radio][name=prompt_type]:checked').val();
      var vals = get_current_vals();
      vals['what'] = 'download';
      vals['prompt_type'] = prompt_type;
      $.post("/user/vmboxes/ajax_update", vals, function(data, status) {
        console.log({data})
        if ( data.download ) {
          window.location = '/user/vmboxes/download?fn=' + data.filename;
        }
      });
    });

    $('button[name=reset2default]').on('click', function(e) {
      var prompt_type =  $('input[type=radio][name=prompt_type]:checked').val();
      if ( prompt_type == 'unav' ) {
        $('#reset2default_warning #unav_text').show();
        $('#reset2default_warning #busy_text').hide();
        $('#reset2default_warning #unav_text span#number').text($(this).data('exten'))
      } else if ( prompt_type == 'busy' ) {
        $('#reset2default_warning #unav_text').hide();
        $('#reset2default_warning #busy_text').show();
        $('#reset2default_warning #busy_text span#number').text($(this).data('exten'))
      }
      $('#reset2default_warning').modal();
    });

    /* Speichern Telefonauswahl und Aufnahme / Abspielen ausführen */
    $('#reset2default_warning #reset-button').on('click', function() {
      var vals = get_current_vals();
      vals['what'] = 'reset2default';
      vals['prompt_type'] = $('input[type=radio][name=prompt_type]:checked').val();
      $('#reset2default_warning').modal('hide');
      $.post("/user/vmboxes/ajax_update", vals, function(data, status) {
      });
    });

  }

});

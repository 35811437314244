jQuery(document).ready(function() {

  if ( $('#xmppserver_title').length ) {

    if ( $('#xmppserver_use_fqdn').prop("checked") )
      $('#row_hostname').hide();
    else
      $('#row_hostname').show();

    $('#xmppserver_use_fqdn').change(function () {
      $('#row_hostname').toggle();
    });

    $('#xmppserver_muc_active').change(function () {
      if ( $(this).prop('checked') ) {
        $('#xmppserver_muc_admin_only').removeClass('disabled');
        $('#xmppserver_muc_admin_only').prop('disabled', false);
      } else {
        $('#xmppserver_muc_admin_only').addClass('disabled');
        $('#xmppserver_muc_admin_only').prop('disabled', true);
      }
    });
    $('#xmppserver_muc_active').trigger('change');
    
    $('input[type=checkbox]').change(function() {
      $('[data-toggle="tooltip"]').tooltip('hide');
    });

  }

});

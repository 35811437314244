jQuery(document).ready(function() {

  if ( $('#user_settings').length ) {
    $('#set_user_prefs').on('click', function() {
      $('#user_settings #password').attr('type', 'password');
      $('#user_settings #password_confirmation').attr('type', 'password');
      var auto_logout = $(this).data('auto-logout');
      $('#user_settings #password').val("******");
      $('#user_settings #password_confirmation').val("******");
      $('#user_settings #auto_logout').prop('checked', auto_logout);
      var start_page = $(this).data('start-page');
      $('#user_settings input[type=radio][name=start_page]').val([start_page]);
      $('#user_settings').modal();
    });
    $('#user_settings #pref_save-button').on('click', function() {
      start_spinner_popup();
      var vals = {};
      vals['user_id'] = $('#user_settings #user_id').val();
      vals['password'] = $('#user_settings #password').val();
      vals['password_confirmation'] = $('#user_settings #password_confirmation').val();
      vals['auto_logout'] = $('#user_settings #auto_logout').prop('checked');
      vals['start_page'] = $('#user_settings input[type=radio][name=start_page]:checked').val();
      $.post('/user/main/update_prefs', vals, function(data, status) {
        stop_spinner_popup();
        if ( data.errors ) {
          s = "<ul>";
          $.each(data.errors, function(index, e) {
            s += "<li>" + e + "</li>";
          });
          s += "</ul>"
          $('#user_settings #show_error').append(s)
          $('#user_settings #show_error').show();
        } else {
          $('#user_settings #show_error').hide();
          $('#user_settings').modal('hide');
          $('#set_user_prefs').data('auto-logout', vals['auto_logout']);
       }
      });
    });
    $('#user_settings').on('hidden.bs.modal', function() {
      $('#user_settings #password').attr('type', 'text');
      $('#user_settings #password_confirmation').attr('type', 'text');
    });
  }

});
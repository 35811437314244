import { block_enable } from '../iptam.js';

jQuery(document).ready(function() {

  if( $('#voicemails_title').length ) {

    /* Voicemail-Einstelliungen */
    $('#h1-enable').css('cursor', 'pointer');
    $('#h2-enable').css('cursor', 'pointer');
    $('#h3-enable').css('cursor', 'pointer');
    block_enable(1);
    block_enable(2);
    block_enable(3);

    $('input[type=radio][name="voicemail[allow_attach]"]').on('change', function() {
      if ( $(this).val() == 'true' ) {
        $('#default-attachment').show();
      } else {
        $('#default-attachment').hide();
      }
    });
    if ( $('input[type=radio][name="voicemail[allow_attach]"]:checked').val() != 'true' ) {
      $('#default-attachment').hide();
    }

    $('input[type=radio][name="public_voicemail[vm_attach]"]').on('change', function() {
      if ( $(this).val() == 'true' ) {
        $('#delete-after-send').show();
      } else {
        $('#delete-after-send').hide();
      }
    });
    if ( $('input[type=radio][name="public_voicemail[vm_attach]"]:checked').val() != 'true' ) {
      $('#delete-after-send').hide();
    }

    $('#public_voicemail_enabled').on('change', function() {
      if ( $(this).prop('checked') ) {
        $('.public_voicemail').removeClass('disabled');
        $('.public_voicemail').prop('disabled', false);
      } else {
        $('.public_voicemail').addClass('disabled');
        $('.public_voicemail').prop('disabled', true);
      }
    });
    $('#public_voicemail_enabled').trigger('change');
  }
});

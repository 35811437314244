import { activate_tables } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#phoneupload-table').length ) {

    var table = activate_tables('#phoneupload-table', false, [-1]);

    table.on( 'draw', function () {
      $('.alert').remove();
      $('.delete_entry').on('click', function() {
        delete_entry($(this));
      });
    });
    
    $('.delete_entry').on('click', function() {
      delete_entry($(this));
    });
    
    /* Löschen einer Datei */
    var file, vendor, model;
    function delete_entry(entry) {
      entry.tooltip('hide');
      file = entry.data('file');
      vendor = entry.data('vendor');
      model = entry.data('model');
      $('span[id=name]').html(entry.data('file'));
      $('span[id=vendor]').html(entry.data('vendor'));
      $('span[id=type]').html(entry.data('type'));
      $('#phoneupload_destroy').modal();
    }

    $('#filename').change(function() {
      if ( this.value.search("(gui|web)_lang.*xml") >= 0 ) {
        $('#enter_version').show();
      } else
        $('#enter_version').hide();
      if ( this.value.search("lang_.*txt") >= 0 ) {
        $('#select_phonetype').show();
      } else
        $('#select_phonetype').hide();
      if ( this.value.search(".*rom") >= 0 ) {
        if ( this.value.search("T31XX-.*rom") >= 0 ) {
          $('#phonevendor_tiptel').prop('checked', true);
        } else {
          $('#select_phonevendor').show();
        }
      } else
        $('#select_phonevendor').hide();
      if ( this.value.search(".*dob") >= 0 ) {
        $('#select_phonedisplay').show();
      } else
        $('#select_phonedisplay').hide();
    });
    $('#enter_version').hide();
    $('#select_phonetype').hide();
    $('#select_phonevendor').hide();
    $('#select_phonedisplay').hide();
    $('.action_error').each(function(){
      if ( $(this).text().search("Snom Telefone") > 0 )
        $('#enter_version').show();
      else if ( $(this).text().search("Aastra Telefone") > 0 )
        $('#select_phonetype').show();
      else if ( $(this).text().search("Yealink") > 0 )
        $('#select_phonevendor').show();
      else if ( $(this).text().search("Display-Größe") > 0 )
        $('#select_phonedisplay').show();
    });

    $('#phoneupload_destroy #delete-button').on('click', function() {
      $('#phoneupload_destroy #delete-form').attr('action', '/admin/phoneuploads/destroy');
      $('#phoneupload_destroy #delete-form').append('<input type="hidden" name="file" value="' + file + '" /><input type="hidden" name="vendor" value="' + vendor + '" /><input type="hidden" name="model" value="' + model + '" />').submit();
    });
 }

});


var ExtMod_yealink_exp43 = (function(modul) {

  function init(pdata, funcs) {
    console.log("Erweiterungsmodul " + pdata.model + " Tasten " + pdata.keys_per_mod);
    // Initiales setzen, eigene Nebenstelle, Tasten mit BLF-Verschiebung
    var possible_blf_key = {};
    for ( var i=0; i < pdata.mod_count; i++) {
      possible_blf_key['ekey_' + i] = [1, pdata.keys_per_mod];
    }
    funcs.set_initial_data(pdata.own_exten, possible_blf_key);

    for (var i = 1; i <= pdata.key_count; i++) {
      $('#ekey_0_'+i).droppable({
        accept: '.key',
        hoverClass: 'highlight-target',
        drop: function(event, ui) {
          if ( ui.draggable.attr('id') == "f_line" ) {
            funcs.set_line($(this), true);
          } else if ( ui.draggable.attr('id') == "f_blf" ) {
            funcs.set_blf($(this), true);
          } else if ( ui.draggable.attr('id') == "f_direct" ) {
            funcs.set_direct($(this), true);
          } else if ( ui.draggable.attr('id') == "f_cfwdgrp" ) {
            funcs.set_cfwdgrp($(this), true);
          } else if ( ui.draggable.attr('id') == "f_call-lists" ) {
            funcs.set_call_lists($(this), true);
          } else if ( ui.draggable.attr('id') == "f_agent" ) {
            funcs.set_agent($(this), true);
          } else if ( ui.draggable.attr('id') == "f_icom" ) {
            funcs.set_icom($(this), true);
          } else if ( ui.draggable.attr('id') == "f_dir" ) {
            funcs.set_directory($(this), true);
          } else if ( ui.draggable.attr('id') == "f_dtmf" ) {
            funcs.set_dtmf($(this), true);
          } else if ( ui.draggable.attr('id') == "f_profileled" ) {
            funcs.set_profileled($(this), true);
          } else if ( ui.draggable.attr('id') == "f_action" ) {
            funcs.set_action_url($(this), true);
          } else if ( ui.draggable.attr('id') == "f_nconfig" || ui.draggable.attr('id') == "f_noop" ) {
            funcs.set_keyfunc($(this), $(ui.draggable), false);
          } else {
            funcs.set_keyfunc($(this), $(ui.draggable), true);
          }
        }
      });
    }

    // Modul wechseln
    $('.modno').on("click", function() {
      funcs.modno_change($(this));
    });
  };

  function do_submit(funcs) {
    console.log("Submit");
    funcs.do_submit();
  };

  return {
    init: init,
    do_submit: do_submit
  };
})(ExtMod_yealink_exp43 || {});
window.ExtMod_yealink_exp43 = ExtMod_yealink_exp43;

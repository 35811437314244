import { activate_tables, url_get_id } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#blacklist_title').length ) {

    activate_tables('#blacklists-table', false, [-1]);

    $('#blacklist_destnum').click(function(e) {
      $('#blacklist_destination_hangup').prop("checked", false);
      $('#blacklist_destination_busy').prop("checked", false);
      $('#blacklist_destination_notfound').prop("checked", false);
      $('#blacklist_destination_ringing').prop("checked", false);
      $('#blacklist_destination_newdest').prop("checked", true);
    });
    $('#blacklist_destination_hangup').click(function(e) {
      $('#blacklist_destnum').val("");
    });
    $('#blacklist_destination_busy').click(function(e) {
      $('#blacklist_destnum').val("");
    });
    $('#blacklist_destination_notfound').click(function(e) {
      $('#blacklist_destnum').val("");
    });
    $('#blacklist_destination_ringing').click(function(e) {
      $('#blacklist_destnum').val("");
    });
    $('#addsource').on('click', function() {
      var source = $('#blacklist_source').val();
      var newsource = $('#newsource').val();
      if ( newsource == "" )
        return;
      if ( source === undefined || source == "" )
        source = newsource;
      else
        source += (';' + newsource);
      var vals = {};
      vals['what'] = 'add';
      vals['source'] = source;
      var id = url_get_id('form[action^="/admin/blacklists/"]');
      $.post("/admin/blacklists/" + id + "/ajax_update", vals, function(data, status) {
      });
    });
    $('#delsource').on('click', function() {
      var to_delete = $('#sources_list').val();
      var source = $('#blacklist_source').val().split(';');
      $.each(to_delete, function(idx, entry) {
        var i = source.indexOf(entry);
        if ( i >= 0 )
          source.splice(i, 1);
      });
      var vals = {};
      vals['what'] = 'del';
      vals['source'] = source.join(';');
      var id = url_get_id('form[action^="/admin/blacklists/"]');
      $.post("/admin/blacklists/" + id + "/ajax_update", vals, function(data, status) {
      });
    });
  }
});

import { activate_tables, start_spinner_popup, stop_spinner_popup, clear_flash, show_result_info } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#faxextension_title').length ) {

    /* Auf der Index-Seite */
    var entry_id;
    var table;
    function delete_entry(id) {
      start_spinner_popup();
      entry_id = id;
      $.get('/admin/faxextensions/' + entry_id + '/get_data4delete', function(data, status) {
        stop_spinner_popup();
        if ( data['error'] ) {
          $('.iptam-main').prepend("<div class='alert alert-danger' role='alert'>" + data['error'] + "</div>");
        } else {
          $('span[id=user_name]').html(data['user_name']);
          $('span[id=faxexten]').html(data['faxexten']);
          $('span[id=user_login]').html(data['user_login']);
          if ( data['is_standalone'] ) {
            $('#fax_standalone_destroy').modal();
          } else {
            $('#fax_exten_destroy').modal();
          }
        }
      });
    };

    /* Löschen tatsächlich ausführen */
    $('#delete-button-standalone').on('click', function() {
      $('#delete-form-standalone').attr('action', '/admin/faxextensions/' + entry_id);
      $('#fax_standalone_destroy').modal('hide');
      start_spinner_popup();
      $('#delete-form-standalone').submit();
    });
    $('#delete-button-exten').on('click', function() {
      $('#delete-form-exten').attr('action', '/admin/faxextensions/' + entry_id);
      $('#fax_exten_destroy').modal('hide');
      start_spinner_popup();
      $('#delete-form-exten').submit();
    });

    function testfax_entry(id, faxexten, pw_empty) {
      entry_id = id;
      $('span[id=faxexten]').html(faxexten);
      clear_flash('notice');
      clear_flash('info');
      if ( pw_empty )
        $('#fax_exten_set_pw').modal();
      else
        $('#fax_exten_testfax').modal();
    };

    if ( $('#faxextension-table').data('ajax-load') == true ) {
      table = activate_tables('#faxextension-table', false, [-2, -1], true, '/admin/faxextensions/get_table');
    } else {
      table = activate_tables('#faxextension-table', false, [-2, -1]);
      $('.delete_entry').on('click', function() {
        delete_entry($(this).data('entry-id'));
      });
      $('.testfax_entry').on('click', function() {
        testfax_entry($(this).data('entry-id'), $(this).data('faxexten'), $(this).data('pw-empty'));
      });
    }

    table.on( 'draw', function () {
      $('.alert').remove();
      $('.delete_entry').on('click', function() {
        delete_entry($(this).data('entry-id'));
      });
      $('.testfax_entry').on('click', function() {
        testfax_entry($(this).data('entry-id'), $(this).data('faxexten'), $(this).data('pw-empty'));
      });
    });

    /* Löschen tatsächlich ausführen */
    $('#delete-button').on('click', function() {
      $('#delete-form').attr('action', '/admin/faxextensions/' + entry_id);
      $('#delete-form').submit();
    });

    /* Testfax versenden */
    $('#sendtestfax-button').on('click', function() {
      console.log("Sende Fax");
      console.log("ID " + entry_id);
      $('#fax_exten_testfax').modal('hide');
      var vals = {};
      vals["dest"] = $('#fax_exten_testfax #dest').val();
      start_spinner_popup();
      $.post("/admin/faxextensions/" + entry_id + "/ajax_testfax", vals, function(data, status) {
        stop_spinner_popup();
        console.log({data});
        show_result_info(data);
      });
    });

  }

  if ( $('#faxextension_edit_title').length ) {

    $('#show_sync_hint').click(function() {
      $('#faxextension_sync_hint').modal();
    });

    $('#showfaxpw').click(function (event) {
      if ( $('#faxpw').is(":hidden") ) {
        $('#faxpw').text($('#showfaxpw').data('pw'));
        $('#showfaxpw').text("Passwort verbergen");
        $('#faxpw').show();
      } else {
        $('#showfaxpw').text("Aktuelles Passwort anzeigen");
        $('#faxpw').hide();
      }
    });

  }

});

import { block_enable } from '../iptam.js';

jQuery(document).ready(function() {
  
  if ( $('#pbxgeneric_title').length ) {
    $('#h1-enable').css('cursor', 'pointer');
    $('#h2-enable').css('cursor', 'pointer');
    $('#h3-enable').css('cursor', 'pointer');
    block_enable(1);
    block_enable(2);
    block_enable(3);    
  }

});

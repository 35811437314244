import { activate_tables, start_spinner_popup, stop_spinner_popup, url_get_id } from './iptam.js';

jQuery(document).ready(function() {

  if ( $('#schedule-table').length ) {
    activate_tables('#schedule-table', false, [-1]);

    $('.delete_entry').on('click', function() {
      entry_id = $(this).data('entry-id');
      if ( parseInt(entry_id) > 0 ) {
        $('#schedule_destroy span[id=name]').html($(this).data('entry-name'));
        $('#schedule_destroy').modal();
      }
    });
    $('#schedule_destroy #delete-button').on('click', function() {
      $('#delete-form').attr('action', '/schedules/' + entry_id);
      $('#delete-form').submit();
    });
  }

  if ( $('#schedule_title').length ) {

    var timeframes_set_event_handler = function() {
      console.log("timeframes_set_event_handler");
      $('.edit_entry').off('click');
      $('.edit_entry').on('click', function() {
        entry_id = $(this).data('entry-id');
        $('#schedule_timeframe h5').html($(this).data('head'));
        $('#schedule_timeframe #start_time').val($(this).data('start-time'));
        $('#schedule_timeframe #stop_time').val($(this).data('stop-time'));
        $('#schedule_timeframe #start_date').val($(this).data('start-date'));
        $('#schedule_timeframe #stop_date').val($(this).data('stop-date'));
        $('#schedule_timeframe input[type=checkbox]').prop('checked', false);
        if ( $(this).data('wkday') ) {
          var wk = $(this).data('wkday');
          for( var i=0; i<=7; i++ ) {
            if ( wk & (1<<i) ) {
              $('#schedule_timeframe #wkd_' + i).prop('checked', true);
            }
          }
        }
        $('.datepicker').datepicker({
          todayHighlight: true,
          autoclose: true,
        });
        $('#schedule_timeframe').modal();
      });

      /* Löschen eines Zeitrahmens, Sicherheitsabfrage */
      $('.delete_entry').off('click');
      $('.delete_entry').on('click', function() {
        entry_id = $(this).data('entry-id');
        $('#schedule_tf_destroy').modal();
      });

      /* Löschen eines Zeitrahmens nach der Sicherheitsabfrage */
      $('#schedule_tf_destroy #delete-tf-button').off('click');
      $('#schedule_tf_destroy #delete-tf-button').on('click', function() {
        $('#schedule_tf_destroy').modal('hide');
        var vals = {};
        vals['what'] =  'delete';
        vals['entry_id'] = entry_id;
        var sched_id = url_get_id('form[action^="/schedules/');
        start_spinner_popup();
        $.post('/schedules/' + sched_id + '/ajax_update', vals, function(data, status) {
          stop_spinner_popup();
        });
      });

      /* Speichern eines Zeitrahmens */
      $('#save_tf-button').off('click');
      $('#save_tf-button').on('click', function() {
        $('#schedule_timeframe').modal('hide');
        var vals = {};
        vals['what'] =  entry_id > 0 ? 'edit' : 'create';
        vals['entry_id'] = entry_id;
        vals['start_time'] = $('#start_time').val();
        vals['stop_time'] = $('#stop_time').val();
        vals['start_date'] = $('#start_date').val();
        vals['stop_date'] = $('#stop_date').val();
        var wkd = 0;
        for( var i=0; i<=7; i++ ) {
          if ( $('#wkd_' + i).prop('checked') ) {
            wkd += (1<<i)
          }
        }
        vals['weekday'] = wkd;
        var sched_id = url_get_id('form[action^="/schedules/');
        start_spinner_popup();
        $.post('/schedules/' + sched_id + '/ajax_update', vals, function(data, status) {
          stop_spinner_popup();
        });
      });
    };

    var table = activate_tables('#timeframe-table', false, [-1]);
    table.on( 'draw', function () {
      timeframes_set_event_handler();
    });
    timeframes_set_event_handler();
    window.timeframes_set_event_handler = timeframes_set_event_handler;

    $('.timepicker').clockpicker({autoclose: true, placement: 'bottom'});

    var entry_id;
    $('#add_timeframe').off('click');
    $('#add_timeframe').on('click', function() {
      entry_id = 0;
      $('#schedule_timeframe h5').html($(this).data('head'));
      $('#schedule_timeframe #start_time').val("");
      $('#schedule_timeframe #stop_time').val("");
      $('#schedule_timeframe #start_date').val("");
      $('#schedule_timeframe #stop_date').val("");
      $('#schedule_timeframe input[type=checkbox]').prop('checked', false);
      $('.datepicker').datepicker({
        todayHighlight: true,
        autoclose: true,
      });
      $('#schedule_timeframe').modal();
    });

  }

});

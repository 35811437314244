import { activate_tables, url_get_id, start_spinner_popup, stop_spinner_popup } from '../iptam.js';

$(document).ready(function() {

  if ( $('#call_protection_title').length ) {
    /* Auf der Index-Seite */
    var table = activate_tables('#call_protection-table', false, [-1]);
    table.on( 'draw', function () {
      $('.delete_entry').on('click', function() {
        delete_entry($(this));
      });
    });
    $('.delete_entry').on('click', function() {
      delete_entry($(this));
    });

    var entry_id;
    function delete_entry(entry) {
      entry.tooltip('hide');
      entry_id = entry.data('entry-id');
      $('span[id=name]').html(entry.data('name'));
      $('span[id=exten').html(entry.data('extension'));
      $('#call_protection_destroy').modal();
    };

    /* Löschen tatsächlich ausführen */
    $('#delete-button').on('click', function() {
      $('#call_protection_destroy #delete-form').attr('action', '/admin/call_protections/' + entry_id);
      $('#call_protection_destroy #delete-form').submit();
    });

  }

  if ( $('#call_protection_new_title').length || $('#call_protection_edit_title').length) {

    /* Auf New- oder Edit-Seite */

    function get_extension_data(data) {
      var result = [];
      for ( var i=0; i<data.length; i++ ) {
        if ( data[i].location_id ) {
          result.push( { value: data[i].exten, label: (data[i].name + " [" + data[i].location + "]\n" + data[i].exten), type_id: data[i].id } );
        } else {
          result.push( { value: data[i].exten, label: (data[i].name + "\n" + data[i].exten), type_id: data[i].id } );
        }
      }
      return result;
    }

    $('#call_protection_exten').autocomplete( {
      source: function (request, response) {
        $.get('/misc/ajax/all_exten_lookupjs?search=' + request.term, function(data) {
          console.log({data})
          if ( data.length == 0 ) {
            $('#call_protection_extension_id').val("");
            $('#call_protection_menue_id').val("");
            $('#call_protection_ringgroup_id').val("");
            $('#call_protection_waitingqueue_id').val("");
          }
          var result = get_extension_data(data);
          response(result);
        })
      },
      select: function (event, ui) {
        $('#call_protection_exten').val(ui.item.value);
        var type = ui.item.type_id;
        $('#call_protection_exten').data('type-id', type);
        if ( type ) {
          $('#call_protection_extension_id').val("");
          $('#call_protection_menue_id').val("");
          $('#call_protection_ringgroup_id').val("");
          $('#call_protection_waitingqueue_id').val("");
          var parts = type.split('_');
          switch (parts[0]) {
            case 'e':
              $('#call_protection_extension_id').val(parts[1]);
              break;
            case 'm':
              $('#call_protection_menue_id').val(parts[1]);
              break;
            case 'r':
              $('#call_protection_ringgroup_id').val(parts[1]);
              break;
            case 'q':
              $('#call_protection_waitingqueue_id').val(parts[1]);
              break;
          }
        }
      },
    });

    $('#call_protection_exten').on('change', function() {
      if ( $('#call_protection_extension_id').val() )
        $('#user_allowed_pin').show();
      else
        $('#user_allowed_pin').hide();
    });
    $('#call_protection_exten').trigger('change');
  }

  if ( $('#call_protection_edit_title').length ) {
    /* Auf der Edit-Seite  */

    var entry_id;

    /* Hinzufügen einer PIN */
    $('#add_pin').on('click', function() {
      var pin_list = $('#call_protection_pin_list').val();
      var new_pin= $('#new_pin').val();
      if ( new_pin != '' ) {
        if ( pin_list === undefined || pin_list == '' )
          pin_list = new_pin;
        else
          pin_list += (';' + new_pin);
      }
      var vals = {};
      vals['what'] = 'add';
      vals['pin_list'] = pin_list;
      entry_id = url_get_id('form[action^="/admin/call_protections/');
      $.post("/admin/call_protections/" + entry_id + "/ajax_update", vals, function(data, status) {
      });
    });

    /* Entfernen einer PIN */
    $('#del_pin').on('click', function() {
      var to_delete = $('#pin_list').val();
      var list = $('#call_protection_pin_list').val().split(';');
      $.each(to_delete, function(idx, entry) {
        var i = list.indexOf(entry);
        if ( i >= 0 )
          list.splice(i, 1);
      });
      var vals = {};
      vals['what'] = 'del';
      vals['pin_list'] = list.join(';');
      entry_id = url_get_id('form[action^="/admin/call_protections/');
      $.post("/admin/call_protections/" + entry_id + "/ajax_update", vals, function(data, status) {
      });
    });

    var type_of_action;

    /* Autofocus beim Erscheinen der Dialog-Box */
    $('#select_phone').on('shown.bs.modal', function() {
      if ( $('#phone_own').length == 0 ) {
        $(this).find('#number').focus();
      }
    });

    $('#select_phone').keypress(function(e) {
      if ( e.which == 13) {
        $(this).find('#save-button').click();
        return false;
      }
    });

    $('#select_phone #number').click(function(e) {
      $('#phone_number').prop("checked", true);
    });

    $('input[type=radio][name=phone]').on('change', function() {
      if ( $(this).val() != 'number' )
        $('#number').val("");
    });

    /* Aufnehmen von Ansagen via Telefon */
    $('button[name$=record]').on('click', function(e) {
      type_of_action = 'record';
      $('#select_phone').modal();
    });

      /* Abspielen von Ansagen via Telefon */
    $('button[name$=play]').on('click', function(e) {
      type_of_action = 'play';
      $('#select_phone').modal();
    });

    /* Hochladen von Audio-Dateien */
    $('button[name=upload]').on('click', function(e) {
      $('[data-toggle="tooltip"]').tooltip('hide');
      entry_id = url_get_id('form[action^="/admin/call_protections/');
      $('h5').html($(this).data('title'));
      $('#file_upload #file_type').html($(this).data('file-type'));
      $('#file_upload #upload-save-button').attr('data-url', '/admin/call_protections/' + entry_id + '/ajax_update');
      $('#file_upload').modal();
    });

    /* Speichern Telefonauswahl und Aufnahme / Abspielen ausführen */
    $('#select_phone #save-button').on('click', function() {
      var phone = $('input[type=radio][name=prompt]:checked').val();
      var number = $('#number').val();
      var id = url_get_id('form[action^="/admin/call_protections/');
      var vals = get_current_vals();
      vals['what'] = type_of_action;
      if ( type_of_action == "play" )
        vals['filename'] = $('#call_protection_prompt').val();
      vals['phone'] = (phone == 'own' || !number) ? 'own' : number;
      $('#select_phone').modal('hide');
      $.post("/admin/call_protections/" + id + "/ajax_update", vals, function(data, status) {
      });
    });

    $('#upload-button').on('click', function() {
      var form_data = new FormData(jQuery('#upload-form')[0]);
      form_data.append('what', 'upload');
      form_data.append('type', $('#file_upload #upload-save-button').attr('data-filetype'));
      var vals = get_current_vals();
      for (let key in vals) {
        form_data.append(key, vals[key]);
      }
      $.ajax({
        url: '/admin/call_protections/' + entry_id + '/ajax_update',
        method: 'POST',
        data: form_data,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          $('#file_upload').modal('hide');
        }
      });
    });
    function get_current_vals() {
      var result = {};
      result['prompt'] = $('#call_protection_prompt').val();
      result['pin_list'] = $('#call_protection_pin_list').val();
      return result;
    }

    if ( $('#call_protection_prompt').length > 0 ) {
      if ( $('#call_protection_prompt').val().length > 0) {
        $('button[name=play]').show();
      } else {
        $('button[name=play]').hide();
      }
    }

  }

});

import { activate_tables, start_spinner_popup, url_get_id } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#menue_title').length ) {
    var table = activate_tables('#menue-table', false, [-1]);

    table.on( 'draw', function () {
      $('.alert').remove();
      $('.delete_entry').on('click', function() {
        delete_entry($(this));
      });
    });
    
    $('.delete_entry').on('click', function() {
      delete_entry($(this));
    });
    
    // Löschen einer Eintrags
    var entry_id;
    function delete_entry(entry) {
      entry.tooltip('hide');
      entry_id = entry.data('entry-id');
      $('span[id=name]').html(entry.data('name'));
      $('#desc').html(entry.data('desc'));
      $('#size').html(entry.data('size'));
      entry_id = entry.data('entry-id');
      $('#menue_destroy').modal();
    };

    $('#menue_destroy #delete-button').on('click', function() {
      $('#menue_destroy #delete-form').attr('action', '/admin/menues/' + entry_id);
      $('#menue_destroy #delete-form').submit();
    });
  }

  if ( $('form[action^="/admin/menues"]').length > 0 ) {

    var type_of_action;

    /* Autofocus beim Erscheinen der Dialog-Box */
    $('#select_phone').on('shown.bs.modal', function() {
      if ( $('#phone_own').length == 0 ) {
        $(this).find('#number').focus();
      }
    });

    $('#select_phone').keypress(function(e) {
      if ( e.which == 13) {
        $(this).find('#save-button').click();
        return false;
      }
    });

    /* Änderung "Verhalten bei Fehler" */
    $('#menue_failure_dest').on('click', function(e) {
      $('#menue_forward_on_failure_false').prop("checked", false);
      $('#menue_forward_on_failure_true').prop("checked", true);
    });

    $('#menue_forward_on_failure_false').click(function() {
      $('#menue_failure_dest').val("");
    });

    /* Aufnehmen von Ansagen via Telefon */
    $('button[name$=record]').on('click', function(e) {
      type_of_action = 'record';
      $('#select_phone').modal();
    });

      /* Abspielen von Ansagen via Telefon */
    $('button[name$=play]').on('click', function(e) {
      type_of_action = 'play';
      $('#select_phone').modal();
    });

    /* Hochladen von Audio-Dateien */
    var entry_id;
    $('button[name=upload]').on('click', function(e) {
      $('[data-toggle="tooltip"]').tooltip('hide');
      entry_id = url_get_id('form[action^="/admin/menues/');
      $('h5').html($(this).data('title'));
      $('#file_upload #file_type').html($(this).data('file-type'));
      $('#file_upload #upload-save-button').attr('data-url', '/admin/menues/' + entry_id + '/ajax_update');
      $('#file_upload').modal();
    });

    $('#select_phone #number').click(function(e) {
      $('#phone_number').prop("checked", true);
    });

    $('input[type=radio][name=phone]').on('change', function() {
      if ( $(this).val() != 'number' )
        $('#number').val("");
    });

    /* Speichern Telefonauswahl und Aufnahme / Abspielen ausführen */
    $('#select_phone #save-button').on('click', function() {
      var phone = $('input[type=radio][name=prompt]:checked').val();
      var number = $('#number').val();
      var id = url_get_id('form[action^="/admin/menues/');
      var vals = get_current_vals();
      vals['what'] = type_of_action;
      if ( type_of_action == "play" )
        vals['filename'] = $('#dialin_greeting').val();
      vals['phone'] = (phone == 'own' || !number) ? 'own' : number;
      $('#select_phone').modal('hide');
      $.post("/admin/menues/" + id + "/ajax_update", vals, function(data, status) {
      });
    });

    $('#upload-button').on('click', function() {
      var form_data = new FormData(jQuery('#upload-form')[0]);
      form_data.append('what', 'upload');
      form_data.append('type', $('#file_upload #upload-save-button').attr('data-filetype'));
      var vals = get_current_vals();
      for (let key in vals) {
        form_data.append(key, vals[key]);
      }
      $.ajax({
        url: '/admin/menues/' + entry_id + '/ajax_update',
        method: 'POST',
        data: form_data,
        processData: false,  // tell jQuery not to process the data
        contentType: false,  // tell jQuery not to set contentType
        success: function(data) {
          $('#file_upload').modal('hide');
        }
      });
    });

    if ( $('#menue_prompt').length > 0 ) {
      if ( $('#menue_prompt').val().length > 0) {
        $('button[name=play]').show();
      } else {
        $('button[name=play]').hide();
      }
    }

    $('#save_details').on('click', function() {
      var result = [];
      var callnum = $('form[action$="update_details').data('destcall');
      $('.menue-dropzone').find('.menue-dragbutton').each(function(index) {
        var value = $(this).text().trim();
        if ( value.substring(0, callnum.length) == callnum )
          result.push('dial=' + value.substring(callnum.length+1));
        else {
          result.push($(this).data('used-rules-id'));
        }
      });
      $('#menue_entrylist').val(result.join(";"));
      start_spinner_popup();
      $('.edit_menue_form').submit();
    });

    var menue_num_dropped = 0;
    $('.menue-av-entry').draggable({
      helper: function() {
        var w = $(this).outerWidth();
        return $(this).clone().width(w-2);
      },
      zIndex: 200,
      opacity: 0.7,
      connectToSortable: ".connectedSortable",
    });

    var callnum = $('form[action$="update_details').data('destcall');
    $('.menue-num-entry').draggable({
      disabled: true,
      helper: function() {
        var v = $(this).find(":input").val();
        var elem = $("<div class='menue-av-entry menue-dragbutton text-center'>" +  callnum + " " + v + "</div>");
        $(this).find(":input").val("");
        $('.menue-num-entry').draggable("option", "disabled", true);
        elem.width($(this).outerWidth()-2);
        elem.draggable({
          connectToSortable: ".connectedSortable",
        });
        return elem;
      },
      opacity: 0.7,
      connectToSortable: ".connectedSortable",
      start: function (event, ui) {
        $(this).data('preventBehaviour', true);
      },
    });

    $('.menue-num-entry').find(":input").on('keypress', function (e) {
      if ( $(this).val().length > 0 )
        $('.menue-num-entry').draggable("option", "disabled", false);
      else
        $('.menue-num-entry').draggable("option", "disabled", true);
    });
    
    $('.menue-num-entry').find(":input").on('mousedown', function (e) {
      var mdown = new MouseEvent("mousedown", {
          screenX: e.screenX,
          screenY: e.screenY,
          clientX: e.clientX,
          clientY: e.clientY,
          view: window
      });
      $(this).closest('.menue-num-entry')[0].dispatchEvent(mdown);
    }).on('click', function (e) {
      var draggable = $(this).closest('.menue-num-entry');
      draggable.outerWidth(205.5);
      if (draggable.data("preventBehaviour")) {
        e.preventDefault();
        draggable.data("preventBehaviour", false)
      }
      $('.menue-dropzone .menue-av-entry').each(function() {
        $(this).outerWidth(draggable.outerWidth());
      });
    });

    $('.menue-dropzone').sortable({
      connectWith: ".connectedSortable",
      out: function(event, ui) {
        if ( ui.position.left < 0 && menue_num_dropped == 0 ) {
          ui.item.remove();
        }
        menue_num_dropped = 0;
      },
      receive: function(event, ui) {
        menue_num_dropped = 1;
      },
    });

    function get_current_vals() {
      var keys = ['timeout', 'failure_dest', 'prompt'];
      var on_failure = $("input[type='radio'][name='menue[forward_on_failure]']:checked").val();
      var result = {};
      result['menue[forward_on_failure]'] = on_failure;
      for(var i=0; i<keys.length; i++) {
        var k1 = '#menue_' + keys[i];
        var k2 = 'menue[' + keys[i] + ']';
        result[k2] = $(k1).val();
      }
      return result;
    }
  }
  
});

jQuery(document).ready(function() {

  if ( $('#phone-ringer-form').length && $('#ringer-js-data').length ) {

    console.log("Modaler Dialog für Klingeltöne vorhanden");

    // Klingeltöne je Identität
    var current_ringer_settings = [];
    // Anzahl der Identitäten am Telefon
    var identity_count = $('#ringer-js-data').data('identity-count');
    // Einstellungen zu Klingeltönen im Phone_type
    var phone_type_ringer = $('#ringer-js-data').data('phone-type-ringer');
    // Aktuelle Klingeltoneinstellungen des Telefons
    var ringer_str = $('input[type=hidden]#ringer').val();
    
    // Klingeltoneinstellungen für alle Identitäten auf "Standard"
    if ( identity_count < 0 ) {
      current_ringer_settings.push(0);
    } else {
      for (var i=1; i <= identity_count; i++ ) {
        current_ringer_settings.push(0);
      }
    }
    // Klingeltoneinstellungen aus DB übernehmen, falls gesetzt
    if ( ringer_str ) { 
      var values = ringer_str.split(',');
      for ( var i=0; i < values.length; i++ ) {
        current_ringer_settings[i] = values[i];
      }
    }

    // Prüfen, ob für alle Identitäen der gleiche Ton eingestellt ist
    var ringer = current_ringer_settings[0];
    var one4all = true;
    for ( var i=0; i < current_ringer_settings.length; i++ ) {
      if ( current_ringer_settings[i] != ringer ) {
        one4all = false;
        break;
      }
    }

    ringer_str = current_ringer_settings.join(',');
    PhoneFunctions.update_ringer('db', ringer_str);

    // Wenn Telefon keinen "Globalen" Klingelton unterstützt, die Auswahl deaktivieren
    if ( phone_type_ringer.global_ring ) {
      one4all = true;
      $('#one4all').prop('disabled', true);
    }

    var save_ringer_dialog;
    var old_ringer_settings;
    $('#phone-ringer-form').on('shown.bs.modal', function() {
      save_ringer_dialog = false;
      current_id = 1;
      if ( one4all ) {
        $('#one4all').prop('checked', true);
        $('div#idents').hide();
      }
      $('#identities_1').prop('checked', true);
      var ring = current_ringer_settings[current_id-1];
      if ( isNaN(ring) ) {
        $('#ringtone').val(98);
        $('#uploaded_ringtone').val(ring);
        $('#show_uploaded_ringtones').show();
      } else {
        $('#ringtone').val(ring);
        $('#show_uploaded_ringtones').hide();
      }
      $('#ringtone').attr('autofocus', true);
      old_ringer_settings = $.extend(true, {}, current_ringer_settings);
    });
    
    $('#phone-ringer-form').on('hidden.bs.modal', function() {
      if ( save_ringer_dialog ) {
        var ring = $('#ringtone').val();
        if ( ring == 98 ) {
          ring = $('#uploaded_ringtone').val();
        }
        one4all = false;
        if ( $('#one4all').length === 0 || $('#one4all').prop('checked') ) {
          for (var i=0; i < current_ringer_settings.length; i++ ) {
            current_ringer_settings[i] = ring;
            one4all = true;
          }
        }
        var new_ringer = current_ringer_settings.join(',');
        $('input[type=hidden]#ringer').val(new_ringer);
        PhoneFunctions.update_ringer('current', new_ringer);
      } else {
        current_ringer_settings = $.extend(true, {}, old_ringer_settings);
      }
    });

    // Speichern-Taste im Dialog betätigt
    $('#phone-ringer-form #save-button').on('click', function() {
      save_ringer_dialog = true;
      $('#phone-ringer-form').modal('hide');
    });

    // Ansicht Klingelton je Identität wechseln
    $('#phone-ringer-form #one4all').change(function() {
      $('div#idents').toggle();
    });

    // Ändern der Identität
    $('#phone-ringer-form input[name=identities]').on('change', function() {
      var ring = current_ringer_settings[$(this).val()-1];
      if ( isNaN(ring) ) {
        $('#ringtone').val(98);
        $('#uploaded_ringtone').val(ring);
        $('#show_uploaded_ringtones').show();
      } else {
        $('#ringtone').val(ring);
        $('#show_uploaded_ringtones').hide();
      }
      current_id = $(this).val();
    });

    // Klingelton verändert
    $('#phone-ringer-form #ringtone').change(function() {
      if ( $(this).val() == 98 ) {
        $('#show_uploaded_ringtones').show();
        current_ringer_settings[current_id-1] = $('#uploaded_ringtone').val();
      } else {
        $('#show_uploaded_ringtones').hide();
        current_ringer_settings[current_id-1] = $(this).val();
      }
    });

    $('#phone-ringer-form #uploaded_ringtone').change(function() {
      current_ringer_settings[current_id-1] = $('#uploaded_ringtone').val();
    });


  }

  var update_ringer_template_reset = function(ringer_str) {
    current_ringer_settings = ringer_str.split(',');
  }
  window.update_ringer_template_reset = update_ringer_template_reset;
});

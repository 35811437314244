
jQuery(document).ready(function() {
  if ( $('.backup-status').length > 0 ) {
    var state = '';
    var linktext = '';
    var linecount = 0;
    var dot_count = 0;
    var load_function = function() {
      $.getJSON("/api/backup-state", function(json) {
        if ( state == '' && json.state == 'active' )
          $('.iptam-main').css('cursor', 'wait');
        if ( state == 'active' && (json.state == 'finished' || json.state == 'download') )
          $('.iptam-main').css('cursor', 'default');
        state = json.state;
        linktext = json.linktext;
        s = "";
        for (var i = 0; i < json.state_arr.length-1; i++ ) {
          s += json.state_arr[i] + "<br />";
        }
        s += json.state_arr[json.state_arr.length-1];
        if ( linecount != json.state_arr.length ) {
          linecount = json.state_arr.length;
          dot_count = 0;
        } else {
          dot_count++;
          var dots = new Array(dot_count + 1).join( '.' );
          s +=  dots;
          if (dot_count > 100 )
            dot_count = 0;
        }
        s +=  "<br />";
        if ( state == 'finished' || state == 'download' ) {
          s += "<a href=\"/admin/backup/" + json.action + "\" class=\"iptam-link\">" + linktext + "</a>";
        }
        $('.backup-status').html(s);
        if ( state == 'active' ) {
          setTimeout(load_function, 500);
        }
      });
    };
    load_function();
  }
});

import { activate_tables, start_spinner_popup, stop_spinner_popup, clear_all_flash } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#ads-check_connect').length ) {
    // Edit-Seite
    $('#ad_sync_setting_use_tls').on('change', function(){
      if ( $(this).prop('checked') ) {
        $('.show_ssl').show();
      } else {
        $('.show_ssl').hide();
      }
    });
    $('#ad_sync_setting_use_tls').trigger('change');

    $('#ads-check_connect').on('click', function() {
      start_spinner_popup();
      $.post('/admin/ad_sync/check_connect', {}, function(data, status) {
        stop_spinner_popup();
      });
    });
  }

  if ( $('#ad_base_filter_title').length ) {
    var table;
    // Der letzte Parameter stellt die Navigation der Tabelle auch bei leerer Tabelle dar
    table = activate_tables('#check_details-table', false, false, false, "", true);
    $('button[id^=check_filter_]').on('click', function() {
      start_spinner_popup();
      $.post('/admin/ad_sync/check_search', {id: $(this).attr('id')}, function(data, status) {
        console.log({data});
        stop_spinner_popup();
        $('#check_result').text(data['head']);
        for ( var i = 0; i < data['values'].length; i++ ) {
          table.row.add(data['values'][i]).draw();
        }
        $('#check_search_result').on('hidden.bs.modal', function() {
          table.clear();
        });
        $('#check_search_result').modal();
      });
    });

    $('button[id^=reset_filter_]').on('click', function() {
      var id = $(this).attr('id').split('_')[2];
      $('#filter_'+id).val("(&(objectCategory=person)(objectClass=user)(telephoneNumber=*))");
      $('#reset_filter_'+id).prop('disabled', true);
    });

    $('textarea[id^=filter_]').on('change', function() {
      var id = $(this).attr('id').split('_')[1];
      if ( $(this).val() == "(&(objectCategory=person)(objectClass=user)(telephoneNumber=*))" ) {
        $('#reset_filter_'+id).prop('disabled', true);
      } else {
        $('#reset_filter_'+id).prop('disabled', false);
      }
    });
    $('textarea[id^=filter_]').trigger('change');

    $('input[type=text][id^=base_dn_]').on('change', function() {
      var id = $(this).attr('id').split('_')[2];
      $('button#check_filter_'+id).prop('disabled', true);
    });
    $('input[type=text][id^=base_dn_]').each(function() {
      var id = $(this).attr('id').split('_')[2];
      if ( $(this).val() == '' ) {
        $('button#check_filter_'+id).prop('disabled', true);
      }
    });
  }

  if ( $('#ad_edit_attributes_title').length ) {
    $('#ad_sync_setting_include_faxextensions').on('change', function() {
      var value = $(this).prop('checked');
      if ( value ) {
        $('.is_fax').show();
      } else {
        $('.is_fax').hide();
      }
    });
    $('#ad_sync_setting_include_faxextensions').trigger('change');
    $("input[type=radio][name='ad_sync_setting[login_selection]']").on('change', function() {
      var selected = $("input[type=radio][name='ad_sync_setting[login_selection]']:checked");
      var v;
      if ( selected.length > 0 ) {
        v = selected.val();
      }
      if ( v == 0 ) {
        $('#adval_1 option[value=mail]').prop('selected', true);
      } else if ( v == 1 ) {
        $('#adval_1 option[value=sAMAccountName]').prop('selected', true);
      }
    });
    if ( $('#adval_1').val() == "" ) {
      $("input[type=radio][name='ad_sync_setting[login_selection]']").trigger('change');
    }
  }

  if ( $('#ad_edit_sync_title').length ) {

    function set_progress_bar(value) {
      $('#sync_state_1 .progress-bar').attr('aria-valuenow', value);
      $('#sync_state_1 .progress-bar').css('width', value);
    }

    var import_status;
    var get_sync_status = function (mode) {
      $.post('/admin/ad_sync/do_import', {what: 'status_update_pbx'}, function(data, status) {
        console.log({data});
        if ( mode ) {
          if ( data['status'] == 0 ) {
            $('#sync_state_1_info').text(data['progress_info']);
            set_progress_bar("100%");
            console.log({import_status});
            show_import_result(true, import_status);
          } else {
            var p = 50 + (data["progress"] / 100) * 50
            console.log("Fortschritt "+p);
            set_progress_bar(p + "%");
            $('#sync_state_1_info').text(data['progress_info']);
            get_sync_status(mode);
          }
        } else {
          if ( data['status'] == 0 ) {
            stop_spinner_popup();
            $('#sync_state_1').hide();
            $('#sync_state_1 .progress-bar').removeClass("bg-warning");
            set_progress_bar("0%");
          } else {
            var p = 50 + (data["progress"] / 100) * 50
            console.log("Fortschritt "+p);
            set_progress_bar(p + "%");
            $('#sync_state_1_info').text(data['progress_info']);
            get_sync_status(mode);
          }
        }
      });
    }

    function show_import_result(import_mode, data) {
      stop_spinner_popup();
      console.log({data});
      if ( data['error'] ) {
        console.log("Fehler in der Kommunikation");
        show_result_info(data)
      } else {
        console.log("Zeige Ergebnis");
        clear_all_flash();
        if ( data['empty_extens'] && data['modified_phones'] ) {
          show_flash('notice', '', data['empty_extens'] + "<br />" + data['modified_phones']);
        } else {
          if ( data['empty_extens'] ) {
            show_flash('notice', '', data['empty_extens']);
          }
          if ( data['modified_phones'] ) {
            show_flash('notice', '', data['modified_phones']);
          }
        }
        $('#import_details').html("");
        $('#test_import_result td:first').text(data.total);
        $('#test_import_result td:nth-child(2)').text(data.new);
        $('#test_import_result td:nth-child(3)').text(data.modified);
        $('#test_import_result td:nth-child(4)').text(data.deleted);
        $('#test_import_result td:nth-child(5)').text(data.errors);
        if ( data.errors ) {
          $('#show_errors').show();
          $('#show_no_errors').hide();
          $.each(data.err_records, function(index, error) {
            console.log({error});
            var s = "<b>" + error.dn + "</b><ul>";
            $.each(error.errors, function(index, e) {
              s += "<li>" + e + "</li>";
            });
            s += "</ul>"
            $('#import_details').append(s);
          });
        } else {
          $('#show_errors').hide();
          $('#show_no_errors').show();
        }
        if ( import_mode ) {
          $('span#to_delete_count').text(data.to_delete_count);
          if ( data.to_delete_count > 0 ) {
            $('button[name=delete_imported]').prop('disabled', false);
          }
        }
        $('#sync_state_1').css('z-index', 1000);
        if ( import_mode ) {
          $('#test_import_result_title').text($('#test_import_result_title').data('title2'));
          $('#test_import_result modal-dialog').addClass('modal-dialog-centered');
        } else {
          $('#test_import_result modal-dialog').removeClass('modal-dialog-centered');
        }
        $('#test_import_result').modal();
      }
      $('#test_import_result').on('hidden.bs.modal', function() {
        $('#sync_state_1').css('z-index', 2000);
        $('#sync_state_1 .progress-bar').removeClass("bg-warning");
        $('#sync_state_1').hide();
        set_progress_bar("0%");
      });
    }

    $('#ad_sync_setting_periodic_sync').on('change', function() {
      var value = $(this).prop('checked');
      if ( value ) {
        $('.repitition').show();
        if ( $('#ad_sync_setting_sync_when').val() == -2 )
          $('.blocktime').show();
      } else {
        $('.repitition').hide();
        $('.blocktime').hide();
      }
    });
    $('#ad_sync_setting_periodic_sync').trigger('change');

    $('.timepicker').clockpicker({autoclose: true, placement: 'top'});
    $('#ad_sync_setting_sync_when').on('change', function() {
      console.log("Wert " + $(this).val());
      if ( $(this).val() != -2 ) {
        $('.daily').show();
        $('.blocktime').hide();
      } else {
        $('.daily').hide();
        if ( $('#ad_sync_setting_periodic_sync:checked') )
          $('.blocktime').show();
      }
    });
    $('#ad_sync_setting_sync_when').trigger('change');

    $('button[name=test_import]').on('click', function() {
      start_spinner_popup();
      $.post('/admin/ad_sync/do_import', {what: 'test'}, function(data, status) {
        show_import_result(false, data);
      });
    });

    $('button[name=manual_import]').on('click', function() {
      start_spinner_popup();
      $('#sync_state_1').show();
      $('#sync_state_1 .progress-bar').css('height', '2rem');
      $.post('/admin/ad_sync/do_import', {what: 'import_read'}, function(data, status) {
        console.log({data});
        if ( data['error'] ) {
          stop_spinner_popup();
          $('#sync_state_1').hide();
          show_result_info(data);
          return;
        }
        set_progress_bar('25%');
        $('#sync_state_1_info').text(data['progress_info']);
        $.post('/admin/ad_sync/do_import', {what: 'import_data', filename: data.filename}, function(data, status) {
          set_progress_bar('50%');
          console.log({data});
          import_status = data;
          $('#sync_state_1_info').text(data['progress_info']);
          var vals = {};
          vals['what'] = 'update_pbx'
          vals['new_user'] = data['to_pbx'].users.join();
          vals['new_exten'] = data['to_pbx'].extensions.join();
          vals['new_fax'] = data['to_pbx'].faxextensions.join();
          console.log({vals});
          $.post('/admin/ad_sync/do_import', vals, function(data, status) {
            console.log({data});
            import_status['to_delete_count'] = data.to_delete_count
            import_status['deleted'] = data.deleted || 0
            if ( data['status'] == 0 ) {
              set_progress_bar("100%");
              $('#sync_state_1_info').text(data['progress_info']);
              console.log("Zeige Ergebnnis");
              show_import_result(true, import_status);
            } else if ( data['status'] == 1 ) {
              $('#sync_state_1_info').text(data['progress_info']);
              get_sync_status(true);
            } else {
              console.log("Fehler!!!!")
            }
          });
        });
      });
    });

    $('button[id=delete-button]').on('click', function() {
      $('#delete_imported').modal('hide');
      start_spinner_popup();
      $('#sync_state_1').show();
      $('#sync_state_1 .progress-bar').css('height', '2rem');
      $('#sync_state_1 .progress-bar').addClass("bg-warning");
      set_progress_bar('5%');
      $('#sync_state_1_info').text('');
      $.post('/admin/ad_sync/do_import', {what: 'delete'}, function(data, status) {
        if ( data['error'] ) {
          stop_spinner_popup();
          show_result_info(data);
          return;
        }
        if ( data['status'] == 0 ) {
          stop_spinner_popup();
          $('#sync_state_1 .progress-bar').removeClass("bg-warning");
          $('#sync_state_1').hide();
        } else if ( data['status'] == 1 ) {
          set_progress_bar('50%');
          get_sync_status(false);
        } else {
          console.log("Fehler!!!!")
        }
        console.log({data});
        $('span#to_delete_count').text(data.to_delete_count);
        if ( data['empty_extens'] ) {
          show_flash('notice', '', data['empty_extens']);
        } else {
          clear_all_flash();
        }
        if ( data.to_delete_count == 0 ) {
          $('button[name=delete_imported]').prop('disabled', true);
        }
      });
    });

  }

});

var PhoneSnom_d717 = (function(modul) {

  function init(pdata, funcs) {
    console.log("Telefon " + pdata.vendor + " " + pdata.model);
    var layer = 1;
    // Tastenbreite ermitteln (Unter Display) Tasten-ID und Anzahl
    funcs.set_softkeywidth('#pkey0', 4);
    // Initiales setzen, eigene Nebenstelle, Tasten mit BLF-Verschiebung
    funcs.set_initial_data(pdata.own_exten, {pkey: [0,31]});

    // Tasten unter dem Bildschirm
    var acc = function(drag) {
      return ['f_line', 'f_blf', 'f_direct', 'f_cfwdled', 'f_cfwdgrp', 'f_callwaiting', 'f_agent', 'f_icom', 'f_dtmf', 'f_doorcam', 'f_profile', 'f_profileled', 'f_action'].indexOf(drag.attr('id')) < 0;
    }
    for (var i = 1; i <= 4; i++) {
      $('#gkey'+i).droppable({
        accept: acc,
        hoverClass: 'highlight-target',
        drop: function(event, ui) {
          if ( ui.draggable.attr('id') == "f_call-lists" ) {
            funcs.set_call_lists($(this));
          } else {
            funcs.set_keyfunc($(this), $(ui.draggable));
          }
        }
      });
    }

    // Fest beschriftete Tasten
    var acc = function(drag) {
      return ['f_line', 'f_blf', 'f_direct', 'f_cfwdled', 'f_cfwdgrp', 'f_agent', 'f_icom', 'f_dtmf', 'f_doorcam', 'f_profileled'].indexOf(drag.attr('id')) < 0;
    }
    for (var i = 1; i <= 6; i++) {
      $('#fkey'+i).droppable({
        accept: acc,
        hoverClass: 'highlight-target',
        drop: function(event, ui) {
          if ( ui.draggable.attr('id') == "f_call-lists" ) {
            funcs.set_call_lists($(this));
          } else if ( ui.draggable.attr('id') == "f_action" ) {
            funcs.set_action_url($(this));
          } else {
            funcs.set_keyfunc($(this), $(ui.draggable));
          }
        }
      });
    }

    // Funktionstasten in Ebenen
    for (var i = 0; i < 3; i++) {
      $('#pkey'+i).droppable({
        accept: '.key',
        hoverClass: 'highlight-target',
        drop: function(event, ui) {
          if ( ui.draggable.attr('id') == "f_line" ) {
            funcs.set_line($(this), true);
          } else if ( ui.draggable.attr('id') == "f_blf" ) {
            funcs.set_blf($(this), true);
          } else if ( ui.draggable.attr('id') == "f_direct" ) {
            funcs.set_direct($(this), true);
          } else if ( ui.draggable.attr('id') == "f_cfwdgrp" ) {
            funcs.set_cfwdgrp($(this), true);
          } else if ( ui.draggable.attr('id') == "f_call-lists" ) {
            funcs.set_call_lists($(this), true);
          } else if ( ui.draggable.attr('id') == "f_agent" ) {
            funcs.set_agent($(this), true);
          } else if ( ui.draggable.attr('id') == "f_icom" ) {
            funcs.set_icom($(this), true);
          } else if ( ui.draggable.attr('id') == "f_dtmf" ) {
            funcs.set_dtmf($(this), true);
          } else if ( ui.draggable.attr('id') == "f_doorcam" ) {
            funcs.set_doorcam($(this), true);
          } else if ( ui.draggable.attr('id') == "f_profileled" ) {
            funcs.set_profileled($(this), true);
          } else if ( ui.draggable.attr('id') == "f_action" ) {
            funcs.set_action_url($(this), true);
          } else if ( ui.draggable.attr('id') == "f_nconfig" || ui.draggable.attr('id') == "f_noop" ) {
            funcs.set_keyfunc($(this), $(ui.draggable), false);
          } else {
            funcs.set_keyfunc($(this), $(ui.draggable), true);
          }
        }
      });
    }

    $('#show-bg-dialog').on('click', function() {
      funcs.set_phone_background();
    });

    $('span#missed-led').on('click', function() {
      funcs.click_change_text('missed-led', 2, 'snom-led-texts', 'missed_led');
    });

    $('span#display').on('click', function() {
      funcs.click_change_text('display', 5, 'snom-display-texts');
    });
  };


  function do_submit(funcs) {
    console.log("Submit");
    funcs.do_submit();
  };

  return {
    init: init,
    do_submit: do_submit
  };
})(PhoneSnom_d717 || {});
window.PhoneSnom_d717 = PhoneSnom_d717;

import { activate_tables } from '../iptam.js';

jQuery(document).ready(function() {
  if ( $('#distinctive-ringings-table').length )
    activate_tables('#distinctive-ringings-table', false, [-1]);
  
  if ( $('#distinctive_ringing_title').length ) {
    $('#exten').autocomplete( {
      source: function (request, response) {
        $.get('/misc/ajax/user_lookupjs?search=' + request.term, function(data) {
          var result = []
          for ( var i=0; i<data.length; i++ ) {
            var s = data[i].sur_name + ', ' + data[i].gv_name + ' [' + data[i].exten + ']';
            result.push({value: data[i].exten, label: s, location_id: data[i].location_id});
          }
          response(result);
        })
      },
      select: function (event, ui) {
        if ( ui.item.location_id )
          $('input[name=exten_loc_to_test]').val(ui.item.value + "_" + ui.item.location_id);
      },
    });
  }
});

import { activate_tables, url_get_id } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#permissionclasses-table').length ) {
    var table = activate_tables('#permissionclasses-table', false, [-1]);

    table.on( 'draw', function () {
      $('.alert').remove();
      $('.delete_entry').on('click', function() {
        delete_entry($(this));
      });
    });
    
    $('.delete_entry').on('click', function() {
      delete_entry($(this));
    });
    
    // Löschen einer Berechtigungsklasse
    var entry_id;
    function delete_entry(entry) {
      entry.tooltip('hide');
      entry_id = entry.data('entry-id');
      start_spinner_popup();
      $.get('/admin/permissionclasses/' + entry_id + '/get_data4delete', function(data,  status) {
        stop_spinner_popup();
        if ( data['error'] ) {
          $('.iptam-main').prepend("<div class='alert alert-danger' role='alert'>" + data['error'] + "</div>");
        } else {
          $('span[id=name]').html(data['name']);
          if ( data['used_class'] ) {
            var len = data['user_count'] > 10 ? 10 : data['user_count'];
            for( var i=0; i < len; i++ ) {
              var line = "<tr><td>" + data['users'][i]['name'] + "</td><td>" + data['users'][i]['login'] + "</td><td>" + data['users'][i]['exten'] + "</td></tr>";
              $('#user_table').append(line);
            }
            if ( data['user_count'] > 10 ) {
              $('#user_table').append("<tr><td colspan=\"3\">" + data['overflow_txt'] + "</td></tr>");
            }
            $('#used_class').show();
            $('#unused_class').hide();
          } else {
            $('#unused_class').show();
            $('#used_class').hide();
          }
          $('#permissionclass_destroy').modal();
        }
      });
    }

    /* Löschen tatsächlich ausführen */
    $('#delete-button').on('click', function() {
      $('#delete-form').attr('action', '/admin/permissionclasses//' + entry_id);
      $('#delete-form').submit();
    });
  }

  var permissionclasses_set_event_handler = function () {
    
    /* Klick auf Link für neue indiv. Rufnummern */
    $('a[data-action=new_permitted_num]').click(function(e) {
      e.preventDefault();
      $('#enter-individual-num #indiv-num-id').val(0);
      $('#enter-individual-num #name').val("");
      $('#enter-individual-num #pattern').val("");
      $('#enter-individual-num').modal();
      $(this).tooltip('hide');
    });

    /* Klick auf Link für bearbeiten indiv. Rufnummern */
    $('a[data-action=edit_permitted_num]').click(function(e) {
      e.preventDefault();
      $('#enter-individual-num #indiv-num-id').val($(this).data('id'));
      $('#enter-individual-num #name').val($(this).data('name'));
      $('#enter-individual-num #pattern').val($(this).data('pattern'));
      $('#enter-individual-num').modal();
      $(this).tooltip('hide');
    });

    /* Klick auf Link zum Löachen indiv Rufnummern */
    $('a[data-action=delete_permitted_num]').click(function(e) {
      e.preventDefault();
      $('#delete-individual-num #indiv-num-id').val($(this).data('id'));
      $('#delete-individual-num #name').val($(this).data('name'));
      $('#delete-individual-num #pattern').val($(this).data('pattern'));
      $('#delete-individual-num').modal();
      $(this).tooltip('hide');
    });
  };
  window.permissionclasses_set_event_handler = permissionclasses_set_event_handler;

  if ( $('#permissionclass_form_title').length ) {
    
    activate_tables('#permclass_indiv_table', false, [-1]);
    permissionclasses_set_event_handler();

    $('#mark-all').click(function(e) {
      e.preventDefault();
      $('input[id^=rule_]').prop('checked', true);
      $('input[id^=perm_]').prop('checked', true);
    });
    $('#mark-none').click(function(e) {
      e.preventDefault();
      $('input[id^=rule_]').prop('checked', false);
      $('input[id^=perm_]').prop('checked', false);
    });

    /* Darstellung der indiv. Rufnummern zum Bearbeiten zeigen/verbergen */
    $('#show_permitted_nums').on('click', function(e) {
      e.preventDefault();
      var iconclass = $('#indiv-icon').attr('class');
      var up_text = $(this).data('up-text');
      var down_text = $(this).data('down-text');
      if ( iconclass.includes('up') ) {
        $('#indiv-icon-text').text(up_text);
        $('#indiv-icon').attr('class', iconclass.replace('up', 'down'));
        $('#indiv-permissions').hide();
      } else {
        $('#indiv-icon-text').text(down_text);
        $('#indiv-icon').attr('class', iconclass.replace('down', 'up'));
        $('#indiv-permissions').show();
      }
    });

    /* Speichern einer neuen Rufnummer */
    $('#enter-individual-num-button').on('click', function(e) {
      var name = $('#enter-individual-num #name').val();
      var pattern = $('#enter-individual-num #pattern').val();
      var permitted_number_id = $('#enter-individual-num #indiv-num-id').val();
      var what = permitted_number_id > 0 ? 'update' : 'create'
      var perm_id;
      if ( $('form[action="/admin/permissionclasses"]').length > 0 ) {
        perm_id = 0;
      } else {
        perm_id = url_get_id('form[action^="/admin/permissionclasses/"]');
      }
      var save = 1;
      $('#enter-individual-num').on('hidden.bs.modal', function (e) {
        /* Wenn Modal verschwunden ist... */
        if ( save == 1 ) {
          save+=1;
          /* Aber nur, wenn Speichern betätigt wurde */
          $.post("/admin/permissionclasses/update_indiv_perm",
                { what: what, id: permitted_number_id, permissionclass_id: perm_id, name: name, pattern: pattern},
                function(data, status) {
                    if ( typeof(data) == 'object' ) {
                      $('#error-intro').html(data.error_into);
                      var list = "";
                      $.each(data.msgs, function(key, value) {
                        list = list + "<li>" + value + "</li>";
                      });
                      $('#error-list').html(list);
                      $('#show_error').on('shown.bs.modal', function(e) {
                        $('[data-toggle="tooltip"]').tooltip('hide');
                      });
                      $('#show_perm_error').modal();
                    }
                }
          );
        }
      });

      $('#enter-individual-num').modal('hide');
    });

    /* Löschen einer neuen Rufnummer */
    $('#delete-individual-num-button').on('click', function(e) {
      var permitted_number_id = $('#delete-individual-num #indiv-num-id').val();
      var perm_id;
      if ( $('form[action="/admin/permissionclasses"]').length > 0 ) {
        perm_id = 0;
      } else {
        perm_id = url_get_id('form[action^="/admin/permissionclasses/"]');
      }
      var save = 1;
      $('#delete-individual-num').on('hidden.bs.modal', function (e) {
        /* Wenn Modal verschwunden ist... */
        if ( save == 1 ) {
          save += 1;
          /* Aber nur, wenn Löschen betätigt wurde */
          $.post("/admin/permissionclasses/update_indiv_perm",
                { what: 'delete', id: permitted_number_id, permissionclass_id: perm_id},
                function(data, status) {
                }
          );
        }
      });

      $('#delete-individual-num').modal('hide');
    });
  }

});

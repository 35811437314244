jQuery(document).ready(function() {
  
  if ( $('#hotdesk_title').length ) {
    
    $('input[type=radio][name="hot_desk[logout_auth]"]').on('change', function() {
      console.log($(this).val() );
      if ( $(this).val() == "true" ) {
        $('#hd-relogin').show();
      } else {
        $('#hd-relogin').hide();
      }
    });
    if ( $('input[name="hot_desk[logout_auth]"]:radio:checked').val() == "true" )
      $('#hd-relogin').show();
    else
      $('#hd-relogin').hide();

    $('#hot_desk_autologout').on('change', function() {
      if ( $('#hot_desk_autologout').prop('checked') ) {
        $('#hd-autologout').show();
        $('#no-hd-autologout').hide();
      } else {
        $('#hd-autologout').hide();
        $('#no-hd-autologout').show();
      }
    });
    $('#hot_desk_autologout').change();
    
    $('.timepicker').clockpicker({autoclose: true, placement: 'top'});
  }

});

var PhoneSnom_c520 = (function(modul) {

  function init(pdata, funcs) {
    console.log("Telefon " + pdata.vendor + " " + pdata.model);
    var layer = 1;
    // Tastenbreite ermitteln (Unter Display) Tasten-ID und Anzahl
    funcs.set_softkeywidth('#gkey1', 3);
    // Initiales setzen, eigene Nebenstelle, Tasten mit BLF-Verschiebung
    funcs.set_initial_data('', {});
    
    // Funktionen der Zifferntasten durch langes drücken
    var acc = function(drag) {
      // Nur die gelisteten Tasten zulasssen
      return ['f_direct', 'f_nconfig', 'f_noop' ].indexOf(drag.attr('id')) >= 0;
    };
    for (var i = 0; i <= 9; i++) {
      $('#skey'+i).droppable({
        accept: acc,
        hoverClass: 'highlight-target',
        drop: function(event, ui) {
          if ( ui.draggable.attr('id') == "f_direct" ) {
            funcs.set_direct($(this), false);
          } else {
            funcs.set_keyfunc($(this), $(ui.draggable), false);
          }
        }
      });
    }

    // Funktionstasten am Display in drei Ebenen
    for (var i = 1; i <= 3; i++) {
      $('#gkey'+i).droppable({
        accept: '.key',
        hoverClass: 'highlight-target',
        drop: function(event, ui) {
          if ( ui.draggable.attr('id') == "f_direct" ) {
            funcs.set_direct($(this), true);
          } else {
            funcs.set_keyfunc($(this), $(ui.draggable), false);
          }
        }
      });
    }

    // Ebenenwechsel
    $('#layer').on('click', function() {
      funcs.change_layer_up(layer, 3, 1, 3, 'gkey' );
      layer += 1;
      if ( layer > 3 )
        layer = 1;
      $('#layer').text("Ebene " + layer);
    });

    $('span#missed-led').on('click', function() {
      funcs.click_change_text('missed-led', 2, 'snom-led-texts', 'missed_led');
    });

    $('span#display').on('click', function() {
      funcs.click_change_text('display', 5, 'snom-display-texts');
    });
  };


  function do_submit(funcs) {
    console.log("Submit");
    funcs.do_submit();
  };

  return {
    init: init,
    do_submit: do_submit
  };
})(PhoneSnom_c520 || {});
window.PhoneSnom_c520 = PhoneSnom_c520;

jQuery(document).ready(function() {
  if ( $('#cdr-check_connect').length ) {
    $('.timepicker').clockpicker({autoclose: true, placement: 'top'});
    $('#cdr-description').hide();
    $('#cdr-show').click(function() {
      $('#cdr-description').show();
      $('#cdr-show').hide();
      $('#cdr-hide').show();
    });
    $('#cdr-hide').click(function() {
      $('#cdr-description').hide();
      $('#cdr-show').show();
      $('#cdr-hide').hide();
    });
    $('#auto_cdr_mode').change(function() {
      $('#AutoCdr_errorExplanation').html("");
      if ( $('#auto_cdr_mode').val() == 'off' ) {
        $('.cdr-mode-server').hide();
        $('.cdr-mode-email').hide();
        $('.cdr-mode-any').hide();
      } else if ( $('#auto_cdr_mode').val() == 'server' ) {
        $('.cdr-mode-server').show();
        $('.cdr-mode-any').show();
        $('.cdr-mode-email').hide();
      } else if ( $('#auto_cdr_mode').val() == 'email' ) {
        $('.cdr-mode-server').hide();
        $('.cdr-mode-email').show();
        $('.cdr-mode-any').show();
      }
    });
    if ( $('#auto_cdr_mode').val() == 'off' ) {
      $('.cdr-mode-server').hide();
      $('.cdr-mode-email').hide();
      $('.cdr-mode-any').hide();
    } else if ( $('#auto_cdr_mode').val() == 'server' ) {
      $('.cdr-mode-server').show();
      $('.cdr-mode-any').show();
      $('.cdr-mode-email').hide();
      $('#show_result').hide();
    } else if ( $('#auto_cdr_mode').val() == 'email' ) {
      $('.cdr-mode-server').hide();
      $('.cdr-mode-email').show();
      $('.cdr-mode-any').show();
    }
    $('#cdr-check_connect').on('click', function() {
      var server = $('#auto_cdr_server').val();
      var path = $('#auto_cdr_path').val();
      var user = $('#auto_cdr_user').val();
      var password = $('#auto_cdr_password').val();
      var password_confirmation = $('#auto_cdr_password_confirmation').val();
      var domain = $('#auto_cdr_domain').val();
      var vals = {};
      vals['server'] = server;
      vals['path'] = path;
      vals['user'] = user;
      vals['password'] = password
      vals['password_confirmation'] = password_confirmation;
      vals['domain'] = domain;
      $.post('/admin/cdr/check_connect', vals, function(data, status) {
      });
    });
  }
});

import { activate_tables, url_get_id, start_spinner_popup, stop_spinner_popup } from '../iptam.js';

$(document).ready(function() {

  if ( $('#user_call_protection_edit_title').length ) {

    /* Auf der User-Edit-Seite  */

    /* Hinzufügen einer PIN */
    $('#add_pin').on('click', function() {
      var pin_list = $('#call_protection_pin_list').val();
      var new_pin= $('#new_pin').val();
      if ( new_pin != '' ) {
        if ( pin_list === undefined || pin_list == '' )
          pin_list = new_pin;
        else
          pin_list += (';' + new_pin);
      }
      var vals = {};
      vals['what'] = 'add';
      vals['pin_list'] = pin_list;
      $.post("/user/call_protections/ajax_update", vals, function(data, status) {
      });
    });

    /* Entfernen einer PIN */
    $('#del_pin').on('click', function() {
      var to_delete = $('#pin_list').val();
      var list = $('#call_protection_pin_list').val().split(';');
      $.each(to_delete, function(idx, entry) {
        var i = list.indexOf(entry);
        if ( i >= 0 )
          list.splice(i, 1);
      });
      var vals = {};
      vals['what'] = 'del';
      vals['pin_list'] = list.join(';');
      $.post("/user/call_protections/ajax_update", vals, function(data, status) {
      });
    });
  }

});

import { activate_tables, start_spinner_popup } from '../iptam.js';

$(document).ready(function() {
  if ( $('#dhcpserver_title').length ) {

    var table = activate_tables('#dhcppool-table', false, [-1]);

    table.on( 'draw', function () {
      $('.alert').remove();
      $('.delete_entry').on('click', function() {
        delete_entry($(this));
      });
    });
    
    $('.delete_entry').on('click', function() {
      delete_entry($(this));
    });
    
    // Löschen eines DHCP-Pools
    var entry_id;
    function delete_entry(entry) {
      entry.tooltip('hide');
      entry_id = entry.data('entry-id');
      $('#first_ip').html(entry.data('first-ip'));
      $('#last_ip').html(entry.data('last-ip'));
      $('#lease_time_text').html(entry.data('lease'));
      $('#dhcp_destroy').modal();
    }

    $('#delete-button').on('click', function() {
      $('#delete-form').attr('action', '/admin/dhcppools/' + entry_id);
      $('#delete-form').submit();
    });

    $('input[type=radio][name="dhcpserver[mode]"]').change(function() {
      start_spinner_popup();
      $('button[type=submit][name=save_dhcpserver]').click();
    });

  }
});

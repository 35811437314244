import { activate_tables } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#siptrunks-table').length ) {
    var table = activate_tables('#siptrunks-table', false, [-1]);

    table.on( 'draw', function () {
      $('.alert').remove();
      $('.delete_provider').on('click', function() {
        delete_provider($(this));
      });
      $('.delete_account').on('click', function() {
        delete_account($(this));
      });
    });
    
    $('.delete_provider').on('click', function() {
      delete_provider($(this));
    });
    
    $('.delete_account').on('click', function() {
      delete_account($(this));
    });
    
    // Löschen eines Providers
    var provider_id, account_id;
    function delete_provider(entry) {
      entry.tooltip('hide');
      provider_id = entry.data('provider-id');
      $('#sipprovider_destroy #name').html(entry.data('name'));
      $('#sipprovider_destroy').modal();
    };

    function delete_account(entry) {
      entry.tooltip('hide');
      provider_id = entry.data('provider-id');
      account_id = entry.data('acc-id');
      $('#sipaccount_destroy #account').html(entry.data('account'));
      $('#sipaccount_destroy #provider').html(entry.data('prov-name'));
      $('#sipaccount_destroy #used_ext').hide();
      $('#sipaccount_destroy #used_acc').hide();
      $('#sipaccount_destroy #is_ddi').hide();
      $('#sipaccount_destroy #used_not').hide();
      if ( entry.data('is-ddi') ) 
        $('#sipaccount_destroy #is_ddi').show();
      else {
        if ( entry.data('user-name-nat') ) {
          $('#sipaccount_destroy #used_acc').show();
          $('#sipaccount_destroy #user_name_nat').html(entry.data('user-name-nat'));
        } else {
          if ( entry.data('exten') ) {
            $('#sipaccount_destroy #used_ext').show();
            $('#sipaccount_destroy #acc_exten').html(entry.data('exten'));
          } else {
            $('#sipaccount_destroy #used_not').show();
          }
        }
      }
      $('#sipaccount_destroy').modal();
    };

    $('#siptrunk_dial').change(function() {
      var v = $('#siptrunk_dial > option:selected').val();
      if ( v == 0 ) {
        $('.dial_manual').show();
        $('.dial_auto').hide();
      } else if ( v == 1 ) {
        $('.dial_manual').hide();
        $('.dial_auto').show();
      } else {
        $('.dial_manual').hide();
        $('.dial_auto').hide();
      }
    });
    $('#siptrunk_dial').change();

    $('#siptrunk_bandwidth').change(function() {
      var v = $('#siptrunk_bandwidth').val();
      $('#siptrunk_reservate_outbound').attr("max", (v-1));
    });
    $('#siptrunk_bandwidth').change();

    $('#siptrunk_use_stun4nat').change(function() {
      if ( $(this).prop('checked') ) {
        $('.use_stun4nat').show();
        $('.dont_use_stun4nat').hide();
      } else {
        $('.use_stun4nat').hide();
        $('.dont_use_stun4nat').show();
      }
    });
    if ( $('#siptrunk_use_stun4nat').prop('checked') ) {
      $('.use_stun4nat').show();
      $('.dont_use_stun4nat').hide();
    } else {
      $('.use_stun4nat').hide();
      $('.dont_use_stun4nat').show();
    }

    /* Löschen des Providers tatsächlich ausführen */
    $('#sipprovider_destroy #delete-button').on('click', function() {
      $('#sipprovider_destroy #delete-form').attr('action', '/admin/sipproviders/' + provider_id);
      $('#sipprovider_destroy #delete-form').submit();
    });

    /* Löschen des Account tatsächlich ausführen */
    $('#sipaccount_destroy #delete-button').on('click', function() {
      $('#sipaccount_destroy #delete-form').attr('action', '/admin/siptrunkaccs/' + account_id);
      $('#sipaccount_destroy #delete-form').submit();
    });

    var sip_ips_active, sip_ips_max_retries, sip_ips_findtime, sip_ips_bantime, sip_ips_fw_action;
    $('#sip_ips_configure').on('click', function() {
      sip_ips_active = $('#active').prop('checked');
      sip_ips_max_retries = $('#max_retries').val();
      sip_ips_findtime = $('#findtime').val();
      sip_ips_bantime = $('#bantime').val();
      sip_ips_fw_action = $('input[type=radio][name="fw_action"]:checked').val();
      $('#sip_ips_dialog').modal();
    });

    $('#sip_ips_dialog #save-button').on('click', function() {
      var vals = {};
      vals['active'] = $('#active').prop('checked');
      vals['max_retries'] = $('#max_retries').val();
      vals['findtime'] = $('#findtime').val();
      vals['bantime'] = $('#bantime').val();
      vals['fw_action'] = $('input[type=radio][name="fw_action"]:checked').val();
      start_spinner_popup();
      $.post("/admin/siptrunks/set_sips_values", vals, function(data, status) {
        window.location = '/admin/siptrunks';
      });
    });

    $('#sip_ips_dialog').on('hidden.bs.modal', function() {
      $('#active').prop('checked', sip_ips_active);
      $('#max_retries').val(sip_ips_max_retries);
      $('#findtime').val(sip_ips_findtime);
      $('#bantime').val(sip_ips_bantime);
      $('#fw_action_' + sip_ips_fw_action).prop('checked', true);
    });

    var ip_table;
    $('#sip_ips_list').on('click', function() {
      start_spinner_popup();
      $.get('/admin/siptrunks/get_blocked_ips', function(data) {
        stop_spinner_popup();
        for ( var i=0; i < data.length; i++ ) {
          var line = "<tr><td>" + data[i]['ip'] + "</td><td>" + data[i]['since'] + "</td></tr>";
          $('#table-blocked_ips tbody').append(line);
        }
        ip_table = activate_tables('#table-blocked_ips', false);
        $('#sip_ips_blockedlist').modal();
      });
    });

    $('#sip_ips_blockedlist').on('hidden.bs.modal', function() {
      ip_table.destroy();
      $('#table-blocked_ips tbody > tr').remove();
    });

    /* Löschen tatsächlich ausführen */
    $('#sip_ips_blockedlist #delete-button').on('click', function() {
      $('#delete-form').attr('action', '/admin/siptrunks/blocked_ips');
      $('#delete-form').submit();
    });

  }

});

jQuery(document).ready(function() {
  if ( $('#BusyHour').length > 0 ) {
    var load_function = function() {
      $.getJSON("/api/external-calls", function(json) {
        $('#inbound-con').text(json.Inbound);
        $('#outbound-con').text(json.Outbound);
        if ( json.BusyHour  ) {
          $('#BusyHour').text(json.BusyHour);
          $('#CallVolume').text(json.CallVolume);
          $('#MaxActive').text(json.MaxActive);
          $('#call-volume-data').show();
        } else {
          $('#call-volume-data').hide();
        }
        $('#timestamp_sec').text(json.timestamp_sec);
        $('#uptime').text(json.uptime);
      });
      setTimeout(load_function, 15000);
    };
    load_function();    
  }
});

import { activate_tables, start_spinner_popup } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#forwarding_title').length ) {

    activate_tables('#forwardings-table', false, [-1]);

    $('#exten_id').change(function() {
      if ( $('#type').val() !== 0 ) {
        start_spinner_popup();
        $('#forwardings_new_form').submit();
      } else if ( $('#group').val() !== 0 ) {
        $('#group').val(0);
        start_spinner_popup();
        $('#forwardings_new_form').submit();
      }
    });
    $('#type').change(function() {
      if ( $('#exten_id').val() !== 0 ) {
        start_spinner_popup();
        $('#forwardings_new_form').submit();
      } else if ( $('#group').val() !== 0 ) {
        $('#group').val(0);
        start_spinner_popup();
        $('#forwardings_new_form').submit();
      }
    });
    $('#group').change(function() {
      start_spinner_popup();
      $('#forwardings_new_form').submit();
    });
    if ( $('#group').val() !== undefined && $('#group').val() != "0" ) {
      $('#type-row').hide();
    }
    var types = ['', '_i', '_e'];
    types.forEach(function(type) {
      $('#dest' + type).click(function(e) {
        $('#cf' + type + '_off').prop("checked", false);
        $('#cf' + type + '_voicebox').prop("checked", false);
        $('#cf' + type + '_message').prop("checked", false);
        $('#cf' + type + '_number').prop("checked", true);
      });
      $('#cf' + type + '_voicebox').click(function(){
        $('#dest' + type).val("");
      });
      $('#cf' + type + '_message').click(function(){
        $('#dest' + type).val("");
      });
      $('#cf' + type + '_off').click(function(){
        $('#dest' + type).val("");
      });
    });

    if ( $('#cf-parallel_on').is(":checked") ) {
      if ( $('#par_delay').val().length === 0 ) {
        $('#cf-delay_off').prop("checked", true);
      }
    }
    $('#parallel').on('focusin', function(e) {
      $('#cf-parallel_on').prop("checked", true);
      $('#cf-parallel_off').prop("checked", false);
    });
    $('#parallel').on('focusout', function(e) {
      if ( $('#parallel').val().length === 0 )
        $('#cf-parallel_off').prop("checked", true);
    });
    $('#par_delay').on('focusin', function(e) {
      $('#cf-delay_on').prop("checked", true);
      $('#cf-delay_off').prop("checked", false);
    });
    $('#par_delay').on('focusout', function(e) {
      if ( $('#par_delay').val().length === 0 )
        $('#cf-delay_off').prop("checked", true);
    });
    $('#cf-parallel_off').on('click', function(e) {
      $('#parallel').val("");
    });
    $('#cf-delay_off').on('click', function(e) {
      $('#par_delay').val("");
    });

    
  }
});

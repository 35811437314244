var PhoneSnom_82x = (function(modul) {

  function init(pdata, funcs) {
    console.log("Telefon " + pdata.vendor + " " + pdata.model);
    funcs.set_softkeywidth('#pkey0', 4);
    funcs.set_initial_data();

    // Tasten unter dem Bildschirm
    var acc = function(drag) {
      return ['f_line', 'f_blf', 'f_direct', 'f_cfwdled', 'f_cfwdgrp', 'f_callwaiting', 'f_agent', 'f_icom', 'f_dtmf', 'f_doorcam', 'f_profile', 'f_profileled', 'f_action'].indexOf(drag.attr('id')) < 0;
    }
    for (var i = 1; i <= 4; i++) {
      $('#gkey'+i).droppable({
        accept: acc,
        hoverClass: 'highlight-target',
        drop: function(event, ui) {
          if ( ui.draggable.attr('id') == "f_call-lists" ) {
            funcs.set_call_lists($(this));
          } else {
            funcs.set_keyfunc($(this), $(ui.draggable));
          }
        }
      });
    }

    // Funktionstasten am Displayrand (dedoppelt zu Tasten 1-4)
    for (var i = 0; i < 4; i++) {
      $('#skey'+i).data('coupledid', '#pkey'+i);
      $('#skey'+i).droppable({
        accept: '.key',
        hoverClass: 'highlight-target',
        drop: function(event, ui) {
          if ( ui.draggable.attr('id') == "f_line" ) {
            funcs.set_line($(this), true);
          } else if ( ui.draggable.attr('id') == "f_blf" ) {
            funcs.set_blf($(this), true);
          } else if ( ui.draggable.attr('id') == "f_direct" ) {
            funcs.set_direct($(this), true);
          } else if ( ui.draggable.attr('id') == "f_cfwdgrp" ) {
            funcs.set_cfwdgrp($(this), true);
          } else if ( ui.draggable.attr('id') == "f_call-lists" ) {
            funcs.set_call_lists($(this), true);
          } else if ( ui.draggable.attr('id') == "f_agent" ) {
            funcs.set_agent($(this), true);
          } else if ( ui.draggable.attr('id') == "f_icom" ) {
            funcs.set_icom($(this), true);
          } else if ( ui.draggable.attr('id') == "f_dtmf" ) {
            funcs.set_dtmf($(this), true);
          } else if ( ui.draggable.attr('id') == "f_doorcam" ) {
            funcs.set_doorcam($(this), true);
          } else if ( ui.draggable.attr('id') == "f_profileled" ) {
            funcs.set_profileled($(this), true);
          } else if ( ui.draggable.attr('id') == "f_action" ) {
            funcs.set_action_url($(this), true);
          } else if ( ui.draggable.attr('id') == "f_nconfig" || ui.draggable.attr('id') == "f_noop" ) {
            funcs.set_keyfunc($(this), $(ui.draggable), false);
          } else {
            funcs.set_keyfunc($(this), $(ui.draggable), true);
          }
        }
      });
    }

    // Funktionstasten
    for (var i = 0; i < 12; i++) {
      if ( i < 4 ) {
        $('#pkey'+i).data('coupledid', '#skey'+i);
      }
      $('#pkey'+i).droppable({
        accept: '.key',
        hoverClass: 'highlight-target',
        drop: function(event, ui) {
          if ( ui.draggable.attr('id') == "f_line" ) {
            funcs.set_line($(this), true);
          } else if ( ui.draggable.attr('id') == "f_blf" ) {
            funcs.set_blf($(this), true);
          } else if ( ui.draggable.attr('id') == "f_direct" ) {
            funcs.set_direct($(this), true);
          } else if ( ui.draggable.attr('id') == "f_cfwdgrp" ) {
            funcs.set_cfwdgrp($(this), true);
          } else if ( ui.draggable.attr('id') == "f_call-lists" ) {
            funcs.set_call_lists($(this), true);
          } else if ( ui.draggable.attr('id') == "f_agent" ) {
            funcs.set_agent($(this), true);
          } else if ( ui.draggable.attr('id') == "f_icom" ) {
            funcs.set_icom($(this), true);
          } else if ( ui.draggable.attr('id') == "f_dtmf" ) {
            funcs.set_dtmf($(this), true);
          } else if ( ui.draggable.attr('id') == "f_doorcam" ) {
            funcs.set_doorcam($(this), true);
          } else if ( ui.draggable.attr('id') == "f_profileled" ) {
            funcs.set_profileled($(this), true);
          } else if ( ui.draggable.attr('id') == "f_action" ) {
            funcs.set_action_url($(this), true);
          } else if ( ui.draggable.attr('id') == "f_nconfig" || ui.draggable.attr('id') == "f_noop" ) {
            funcs.set_keyfunc($(this), $(ui.draggable), false);
          } else {
            funcs.set_keyfunc($(this), $(ui.draggable), true);
          }
        }
      });
    }

    $('span#missed-led').on('click', function() {
      funcs.click_change_text('missed-led', 2, 'snom-led-texts', 'missed_led');
    });

    $('span#display').on('click', function() {
      funcs.click_change_text('display', 5, 'snom-display-texts');
    });
  };

  function do_submit(funcs) {
    console.log("Submit");
    funcs.do_submit();
  };

  return {
    init: init,
    do_submit: do_submit
  };
})(PhoneSnom_82x || {});
window.PhoneSnom_82x = PhoneSnom_82x;

import { activate_tables } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#sipgateway_title').length ) {

    var table = activate_tables('#sipgateways-table', false, [-1]);

    table.on( 'draw', function () {
      $('.alert').remove();
      $('.delete_entry').on('click', function() {
        delete_entry($(this));
      });
    });
    
    $('.delete_entry').on('click', function() {
      delete_entry($(this));
    });
    
    /* Löschen eines Gateways, Sicherheitsabfrage */
    var entry_id;
    function delete_entry(entry) {
      entry.tooltip('hide');
      entry_id = entry.data('entry-id');
      $('span[id=name]').html(entry.data('name'));
      $('#type').html(entry.data('type'));
      $('#sipgateway_destroy').modal();
    };

    $('#delete-button').on('click', function() {
      $('#delete-form').attr('action', '/admin/sipgateways/' + entry_id);
      $('#delete-form').submit();
    });
    
  }

});


jQuery(document).ready(function() {

  if ( $('#im_settings-title').length ) {

    activate_tables('#im_open-table', false, [-1], false);
    
    var account;

    $('#cfwd_dest').click(function(e) {
      $('#cfwd_number').prop("checked", true);
    });

    $('input[type=radio][name=cfwd]').on('change', function() {
      if ( $(this).val() != 'number' )
        $('#cfwd_dest').val("");
    });

    $('.delete_entry').on('click', function() {
      account = $(this).data('entry-acc');
      $('#contact_destroy').modal();
    });

    /* Löschen tatsächlich ausführen */
    $('#delete-button').on('click', function() {
      $('#delete-form').attr('action', '/user/im_settings/' + account);
      $('#delete-form').submit();
    });
  }
});
var PhoneGrandstream_ht80x = (function(modul) {

  var ext_info;

  function init(pdata, funcs) {
    console.log("Telefon " + pdata.vendor + " " + pdata.model);
    funcs.set_initial_data("", {});
    var config = funcs.get_configuration();
  };

  function do_submit(funcs) {
    console.log("Submit");
    funcs.do_submit();
  };

  return {
    init: init,
    do_submit: do_submit
  };

})(PhoneGrandstream_ht80x || {});
window.PhoneGrandstream_ht80x = PhoneGrandstream_ht80x;

import { activate_tables } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#iaxtrunk_title').length ) {
    
    var table = activate_tables('#iaxtrunk-table', false, [-1]);

    table.on( 'draw', function () {
      $('.alert').remove();
      $('.delete_entry').on('click', function() {
        delete_entry($(this));
      });
    });
    
    $('.delete_entry').on('click', function() {
      delete_entry($(this));
    });
    
    var entry_id;
    function delete_entry(entry) {
      entry.tooltip('hide');
      entry_id = entry.data('entry-id');
      $('#name').html(entry.data('name'));
      $('#server').html(entry.data('server'));
      $('#prefix').html(entry.data('prefix'));
      $('#iaxtrunkacc_destroy').modal();
    }

    $('#delete-button').on('click', function() {
      $('#delete-form').attr('action', '/admin/iaxtrunkaccs/' + entry_id);
      $('#delete-form').submit();
    });

    var tol = parseInt($('input[name="iaxtrunk[type_of_limit]"]:radio:checked').val());
    if ( tol == 0 ) {
      $('.common-limit').show();
      $('.separated-limit').hide();
    }
    if ( tol == 1 ) {
      $('.common-limit').hide();
      $('.separated-limit').show();
    }
    $('input[type=radio][name="iaxtrunk[type_of_limit]"]').change(function() {
      if ( $(this).val() == "0" ) {
        $('.common-limit').show();
        $('.separated-limit').hide();
      } else if ( $(this).val() == "1" ) {
        $('.common-limit').hide();
        $('.separated-limit').show();
      }
    });

    $('#bw_sip_com').change(function() {
      var v = $(this).val();
      $('#bw_sip_sep').val(v);
    })

  }

});

export var directory_entry_id;

jQuery(document).ready(function() {

  if ( $('#edit_options').length ) {

    $('#edit_options input[type=radio][name=dir_type]').on('change', function() {
      $('#error_no_dir_type').hide();
    });

    $('#edit_options input[type=radio][name=use]').on('change', function() {
      if ( $(this).val() == 'new' ) {
        $('#error_no_contact_sel').hide();
        $('#error_no_edit_type').hide();
        $('#edit_options .existing_entry').hide();
        $('#edit_options .new_entry').show();
        $('#edit_options #sur_name').val("");
        $('#edit_options #given_name').val("");
        $('#edit_options #company').val("");
      } else if ( $(this).val() == 'existing' ) {
        $('#error_no_contact_data').hide();
        $('#error_no_edit_type').hide();
        $('#edit_options .new_entry').hide();
        $('#edit_options .existing_entry').show();
        $('#edit_options #search').val("")
        $('#edit_options span[id^=sel]').text("");
        entry_id = "";
      } else {
        $('#edit_options .new_entry').hide();
        $('#edit_options .existing_entry').hide();
      }
    });

    $('#edit_options #search').autocomplete( {
      source: function (request, response) {
        $.get('/misc/ajax/contact_lookup?search=' + request.term, function(data) {
          var result = [];
          for ( var i=0; i<data.length; i++ ) {
            var s;
            if ( data[i].company.length )
              s = data[i].sur_name + ', ' + data[i].given_name + ' [' + data[i].company + ']';
            else
              s = data[i].sur_name + ', ' + data[i].given_name;
            result.push({value: data[i].given_name + " " + data[i].sur_name, label: s, company: data[i].company, address: data[i].address, entry_id: data[i].id})
          }
          response(result);
        })
      },
      select: function (event, ui) {
        directory_entry_id = ui.item.entry_id;
        $('#error_no_contact_sel').hide();
        $('span#sel_name').text(ui.item.value);
        $('span#sel_company').text(ui.item.company);
        $('span#sel_address').text(ui.item.address);
      },
    });

  }

});

jQuery(document).ready(function() {

  if ( $('#tagline_title').length > 0 ) {

    /* Seite zur Konfiguratoin der Tagline (Vorgabe oder Nebenstell) */
    var tagline_num_dropped = 0;
    var element_width = $('.tagline-text-entry').width();

    /* Aktualisieren der gewählten Einträge */
    function update_tagline() {
      var result_array = [];
      var result = "";
      var element = ""
      $('.tagline-dropzone').find('div').each(function() {
        if ( $(this).attr('id') ) {
          result += $(this).attr('id').replace('tag_', '');
          if ( $(this).attr('id') == 'tag_|') {
            result_array.push(element);
            element = "";
          } else {
            element += ("[" + $(this).text().trim() + "]");
          }
        } else {
          result += $(this).text();
          element += $(this).text();
        }
      });
      if ( element.length != 0 )
        result_array.push(element);
      $('#tagline').val(result);
      var t = ""
      for ( var i=0; i < result_array.length; i++ ) {
        t += "<td style='text-align: center'>" + result_array[i] + "</td>";
      }
      $('#tagline-fields').html(t);
      if ( t.length > 0 ) {
        $('#preset_active').hide();
        $('#no_preset_active').show();
      } else {
        $('#preset_active').show();
        $('#no_preset_active').hide();
      }
      $('.tagline-dropzone .tagline-av-entry').outerWidth(element_width);
    }

    /* Elemente aus der Liste */
    $('.tagline-av-entry').draggable({
      helper: function() {
        return $(this).clone();
      },
      zIndex: 200,
      opacity: 0.7,
      connectToSortable: ".connectedSortable",
    });

    /* Freitext-Feld */
    $('.tagline-text-entry').draggable({
      disabled: true,
      helper: function() {
        var v = $(this).find(":input").val();
        var elem = $("<div class='tagline-av-entry tagline-dragbutton text-center'>" +   v + "</div>");
        $(this).find(":input").val("");
        $('.tagline-text-entry').draggable("option", "disabled", true);
        elem.draggable({
          connectToSortable: ".connectedSortable",
        });
        return elem;
      },
      opacity: 0.7,
      connectToSortable: ".connectedSortable",
      start: function (event, ui) {
        $(this).data('preventBehaviour', true);
      },
    });

    $('.tagline-text-entry').find(":input").on('keypress', function (e) {
      $('.tagline-text-entry').draggable("option", "disabled", false);
    });

    $('.tagline-text-entry').find(":input").on('mousedown', function (e) {
      var mdown = new MouseEvent("mousedown", {
          screenX: e.screenX,
          screenY: e.screenY,
          clientX: e.clientX,
          clientY: e.clientY,
          view: window
      });
      $(this).closest('.tagline-text-entry')[0].dispatchEvent(mdown);
    }).on('click', function (e) {
      var draggable = $(this).closest('.tagline-text-entry');
      if (draggable.data("preventBehaviour")) {
        e.preventDefault();
        draggable.data("preventBehaviour", false)
      }
    });

    $('.tagline-dropzone').sortable({
      connectWith: ".connectedSortable",
      out: function(event, ui) {
        if ( ui.position.left < 0 && tagline_num_dropped == 0 ) {
          ui.item.remove();
        }
        tagline_num_dropped = 0;
      },
      receive: function(event, ui) {
        tagline_num_dropped = 1;
      },
      stop: function(event, ui) {
        update_tagline();
      },
    });

    /* Initial Felder darstellen */
    update_tagline();
  }
});

$(document).ready(function() {
  if ( $('#diag_proxy_title').length ) {
    $('.timepicker').clockpicker({autoclose: true, placement: 'top', 'default': 'now'});
    $('.datepicker').datepicker({todayHighlight: true});

    $('#timestamp_date').on('change', activate_dir);
    $('#timestamp_time').on('change', activate_dir);

    function activate_dir() {
      if ( $('input[name=timestamps]:checked').val() === undefined )
        $('#timestamps_after').prop('checked', true);
    }
  }

});

import { activate_tables } from '../iptam.js';

jQuery(document).ready(function() {
  if ( $('#diag-isdn-table2').length ) {
    activate_tables('#diag-isdn-table2');

    /* Löschen eines Gateways: Sicherheitsabfrage */
    var card_id;
    $('.delete_entry').on('click', function() {
      $(this).tooltip('hide');
      $.get("diag_isdn/delgw?mac=" + $(this).data('mac'), function(data) {
        $('#ipaddr').html(data.ipaddr);
        card_id = data.id;
        var body = $('#isdn_destroy').find('tbody');
        body.html("");
        var len = data.ports.length;
        for (var i=0; i < len; i++) {
          body.append("<tr><td>" + data.ports[i].portno + "</td><td>" + data.ports[i].type + "</td><td>" + data.ports[i].parameter + "</td></tr>");
        }
        $('#isdn_destroy').modal();
      });
    });

    /* Löschen eines Gateways ausführen */
    $('#delete-button').on('click', function() {
      var form = $('#isdn_destroy').find('form');
      form.find('#id').val(card_id);
      form.attr('action', '/admin/diag_isdn/destroy').submit();
    });

    /* File-Upload */
    var mac_addr;
    $('.link_fileupload').on('click', function() {
      $(this).tooltip('hide');
      $(this).find('#newfile').val("");
      $('h5').html($(this).data('headline'));
      $('#file_type').html($(this).data('file-type'));
      mac_addr = $(this).data('mac');
      $('#file_upload').modal();
    });

    $('#upload-button').on('click', function() {
      $('#file_upload').modal('hide');
      start_spinner_popup();
      $('#upload-form').attr('action', '/admin/diag_isdn/fileupload');
      $('#upload-form').append("<input type='hidden' id='mac' name='mac' value='" + mac_addr + "' />");
      $('#upload-form').submit();
    });

    /* Dialog für externes Gateway: Anzeige Passwort */
    $('span.extgw').click(function(event) {
      if ( event.shiftKey && event.altKey ) {
        $.get("diag_isdn/extgw?ip="+$(this).text(), function(data) {
          $('#pwd').text(data);
          $('#show_gwpwd').modal();
        });
      }
    });

    /* Dialog für internes Gateway: Proxy starten/stoppen */
    $('span.intgw').click(function(event) {
      if ( event.shiftKey && event.altKey ) {
        mac_addr = $(this).text();
        $.get("diag_isdn/intgw?mac=" + mac_addr, function(data) {
          if (data) {
            var url = data;
            $('#leave_remote a').attr('href', url).attr('target', '_blank');
            $('#proxy-close-progress').hide();
            $('#leave_remote').modal();
          } else {
            $('#proxy-start-div').show();
            $('#status').hide();  // ?
            $('#proxy-terminate').hide();
            $('#proxy-start-progress').hide();
            $('#proxy-access').hide();
            $('#enter_remote').modal();
          }
          return false;
        });
      }
    });

    /* Start des Proxy */
    $('#proxy-start-button').click(function() {
      $(this).tooltip('hide');
      $.get("diag_isdn/intgwproxy?mac=" + mac_addr + "&mode=start", function(data) {
        if ( data!='Error') {
          var url = data;
          $('#proxy-access a').attr('href', url);
          var progress_bar = $('#proxy-start-progress .progress-bar');
          progress_bar.css('width', '0%').attr('aria-valuenow', 0);
          $('#proxy-start-progress').show();
          progress(progress_bar, 'start');
        } else {
          $('#status').html("<br/><b>Beim Start ist ein Fehler aufgetreten.</b>");
        }
      });
    });

    /* Stoppen des Proxy */
    $('#proxy-stop-button').click(function() {
      $(this).tooltip('hide');
      $.get("diag_isdn/intgwproxy?mode=stop", function(data) {
        var progress_bar = $('#proxy-start-progress .progress-bar');
        progress_bar.css('width', '0%').attr('aria-valuenow', 0);
        $('#proxy-access').hide();
        $('#status').show().html("Proxy wird beendet...");
        $('#proxy-start-progress').show();
        progress(progress_bar, 'close');
      });
    });

    /* Stoppen des Proxy */
    $('#proxy-stop-button2').click(function() {
      $(this).tooltip('hide');
      $.get("diag_isdn/intgwproxy?mode=stop", function(data) {
        var progress_bar = $('#proxy-close-progress .progress-bar');
        progress_bar.css('width', '0%').attr('aria-valuenow', 0);
        $('#proxy-access').hide();
        $('#status').show().html("Proxy wird beendet...");
        $('#proxy-close-progress').show();
        progress(progress_bar, 'terminate');
      });
    });
    
    $('#enter_remote').on('status-started', function(e) {
      $('#status').show().html("Proxy wurde gestartet.");
      $('#proxy-start-progress').hide();
      $('#proxy-start-div').hide();
      $('#proxy-access').show();
      $('#proxy-terminate').show();
    });

    $('#enter_remote').on('status-stopped', function(e) {
      $('#status').show().html("Proxy wurde beendet.");
      $('#proxy-start-progress').hide();
      $('#proxy-access').show();
      $('#proxy-terminate').show();
      $('#enter_remote').modal('hide');
    });

    $('#leave_remote').on('status-stopped', function(e) {
      $('#status').show().html("Proxy wurde beendet.");
      $('#proxy-close-progress').hide();
      $('#proxy-access').show();
  //     $('#proxy-terminate').show();
      $('#leave_remote').modal('hide');
    });

    function progress(progress_bar, mode) {
      var val = parseInt(progress_bar.attr('aria-valuenow')) || 0;
      val += 1;
      progress_bar.css('width', val+'%').attr('aria-valuenow', val);
      if ( val < 99 ) {
        setTimeout( progress, 60, progress_bar, mode );
      } else {
        if ( mode == 'start')
          $('#enter_remote').triggerHandler('status-started');
        else if ( mode == 'close' )
          $('#enter_remote').triggerHandler('status-stopped');
        else
          $('#leave_remote').triggerHandler('status-stopped');
      }
    }
    
  }

});

jQuery(document).ready(function() {

  if ( $('#conferenceroom_title').length > 0 ) {
    if ( $('input[type=radio][name="conferenceroom[schedule]"]:checked').val() != 'true' ) {
      $(".conf-schedules").hide();
    } else {
      $(".conf-schedules").show();
    }
    if ( $('input[type=radio][name="conferenceroom[schedule]"]:checked').length == 0 ) {
      $('#conferenceroom_schedule_false').prop('checked', true);
    }
    $('input[type=radio][name="conferenceroom[schedule]"]').change(function() {
      if ( this.value == "true" )
        $(".conf-schedules").show();
      else
        $(".conf-schedules").hide();
    });
    $('.timepicker').clockpicker({autoclose: true, placement: 'top'});
  }
});


import { start_spinner_popup, stop_spinner_popup } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#admin_main_title').length ) {

    function do_post(what, list) {
      start_spinner_popup();
      $.post('/admin/main/update_favs', { what: what, data: list}, function(data, status) {
        stop_spinner_popup();
      });
    }

    $('button[name=add_admin_fav]').on('click', function() {
      var list = [];
      $('select[name="av_entries"] :selected').each(function(idx, obj) {
        list.push(obj.value);
      });
      do_post('add', list.join(','));
    });

    $('button[name=del_admin_fav]').on('click', function() {
      var list = [];
      $('select[name="used_entries[]"] :selected').each(function(idx, obj) {
        list.push(obj.value);
      });
      do_post('del', list.join(','));
    });

    $('#edit_dashboard').on('hidden.bs.modal', function() {
      window.location = "/admin/main";
    });

    var width = $('#sample-row').width()-50;
    var col_width = width / 6;
    var col_height = col_width * 1.3;
    $('#favlist').width(width);
    $('#favlist li').width(col_width);
    $('#favlist li').height(col_height);
    $('#favlist').sortable({
      stop: function(event, ui) {
        var list = []
        $('#favlist li').each(function(idx, obj) {
          list.push($(this).data('fav-id'));
        });
        do_post('prio', list.join(','));
      }
    });
  }
});

jQuery(document).ready(function() {

  if ( $('.forwaring-dest').length ) {
    var user_id = 0;
    if ( $('.forwaring-dest[data-user]').length )
      user_id = $('.forwaring-dest[data-user]').data('user');
    $('.forwaring-dest').autocomplete( {
      source: function (request, response) {
        $.get('/misc/ajax/forwarding_lookupjs?user_id=' + user_id + '&search=' + request.term, function(data) {
          console.log({data});
          var result = [];
          for ( var i=0; i<data.length; i++ ) {
            var s;
            if ( data[i].company && data[i].company.length )
              s = data[i].sur_name + ', ' + data[i].gv_name + ' [' + data[i].company + ']';
            else if ( data[i].gv_name.length )
              s = data[i].sur_name + ', ' + data[i].gv_name;
            else
              s = data[i].sur_name;
            if ( data[i].type.length )
              s += ' (' + data[i].type + ')'
            result.push( {value: data[i].number, label: s} )
          }
          response(result);
        })
      },
      select: function (event, ui) {
        $('#direct-form input[name=value]').val(ui.item.value);
      },
    });

  }
  
});

var PhoneGigaset_de310 = (function(modul) {

  function init(pdata, funcs) {
    console.log("Telefon " + pdata.vendor + " " + pdata.model);
    funcs.set_initial_data(undefined, {});
    
    $('#dirkey').on('click', function() {
      funcs.click_change_text('dirkey', 1, 'gigaset-dirkey-texts');
    });
  };


  function do_submit(funcs) {
    console.log("Submit");
    funcs.do_submit();
  };

  return {
    init: init,
    do_submit: do_submit
  };
})(PhoneGigaset_de310 || {});
window.PhoneGigaset_de310 = PhoneGigaset_de310;
